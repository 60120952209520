import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import { styles } from './StylesPageGuide';

const PageGuide = (props) => {
  const { steps, restart } = props;
  const { t } = useTranslation();
  const filteredSteps = steps && steps.filter((it) => it);

  return (
    <Joyride
      key={restart}
      steps={filteredSteps}
      run={true}
      continuous={true}
      showSkipButton={true}
      {...{ styles }}
      locale={{
        last: t('endTour'),
        skip: t('closeTour')
      }}
    />
  );
};

export default PageGuide;
