import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ReactComponent as Clear } from 'assets/img/cancel_btn_grey_bg&border.svg';

import { CircularProgress, IconButton } from '@material-ui/core';

import {
  serverDefaultPath,
  DEFAULT_AVATAR,
  CHOOSE_FROM_LIST
} from '../../helpers/constants';
import { isDesktopView } from '../../helpers/selectors';

import CoachForm from '../../components/QuickTournRegistration/CoachForm/CoachForm';
import SearchInput from '../../components/SearchInput/SearchInput';
import Button from '../Buttons/ActionButtons';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import Modal from '../Modal/ModalNewDesign';
import TableList from '../Table/CardsList';

import { useStyles } from './StylesInvitation';

const ADD_NEW = 'ADD_NEW';

const Invitation = (props) => {
  const {
    modalData,
    hideModal,
    showModal,
    filteredCoaches,
    select2Invite,
    allRegions,
    tournInvitations,
    tournamentData,
    textChange,
    pressEnter,
    clearSearch,
    filters,
    viewportWidth,
    shouldShowDiscardChanges,
    onSaveInvitations,
    showError,
    discardOrKeepEditing,
    onChangeTxtForm,
    changeImage,
    countries,
    changeDate,
    onSave,
    selectOption,
    fetchRegionBySelectedCountry,
    onBlur,
    onDelete,
    errors
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const hasDesktopSize = isDesktopView(viewportWidth);

  const FIRST_NAME = !+tournamentData?.last_name_first
    ? 'first_name'
    : 'last_name';
  const LAST_NAME = !+tournamentData?.last_name_first
    ? 'last_name'
    : 'first_name';
  const FIRST_NAME_COACH = !+tournamentData?.last_name_first
    ? 'c_first_name'
    : 'c_last_name';
  const LAST_NAME_COACH = !+tournamentData?.last_name_first
    ? 'c_last_name'
    : 'c_first_name';
  const COACH_FIRST_NAME_VAL = !+tournamentData?.last_name_first
    ? 'coach_first_name'
    : 'coach_last_name';

  const COACH_LAST_NAME_VAL = !+tournamentData?.last_name_first
    ? 'coach_last_name'
    : 'coach_first_name';

  const COACH_FIRST_NAME_LABEL = !+tournamentData?.last_name_first
    ? t('coachFirstName')
    : t('coachLastName');

  const COACH_LAST_NAME_LABEL = !+tournamentData?.last_name_first
    ? t('coachLastName')
    : t('coachFirstName');

  const dojoInfo = [
    {
      name: FIRST_NAME,
      label: !+tournamentData?.last_name_first ? t('firstname') : t('lastname'),
      required: true
    },
    {
      name: LAST_NAME,
      label: !+tournamentData?.last_name_first ? t('lastname') : t('firstname'),
      required: true
    },
    {
      name: 'patronymic',
      label: t('patronymic')
    },
    {
      name: 'email',
      label: t('email'),
      shouldEditCoach: false,
      required: true
    },
    { birthday: true, isDate: true },
    { gender: true, isFilter: true },
    {
      name: 'branch_chief',
      label: t('nameBranchChief')
    },
    {
      name: COACH_FIRST_NAME_VAL,
      label: COACH_FIRST_NAME_LABEL
    },
    {
      name: COACH_LAST_NAME_VAL,
      label: COACH_LAST_NAME_LABEL
    },
    {
      country: true,
      isFilter: true
    },
    allRegions?.length > 0
      ? {
          region: true,
          isFilter: true
        }
      : {
          name: 'region',
          label: t('region')
        },
    {
      name: 'city',
      label: t('city')
    },
    {
      name: 'club',
      label: t('cluborFederationName')
    },
    {
      label: t('phone'),
      name: 'phone'
    },
    { label: t('website'), name: 'website' },
    { save: true }
  ];

  const recordDataParams = (item, idx) => {
    return {
      class: {
        gridRecordsValue: classes.gridRecordsValue,
        gridRecords: classes.gridRecords,
        thumbnail: classes.thumbnail
      },
      main: {
        rowOrder: {
          value: (
            <CheckboxBtn
              classControlLabel={classes.margin0}
              onChange={() => select2Invite(item)}
              checked={Array.from([
                ...modalData?.selectedCheckboxes,
                ...modalData?.selectedCheckboxesDefault
              ])?.some((it) => +it?.id === +item?.id)}
              disabled={modalData?.selectedCheckboxesDefault.some(
                (it) => +it.id === +item.id
              )}
            />
          ),
          class: classes.rowOrder
        },
        style: {
          cardMainPart: classes.cardValuePart
        },
        name: [
          item?.[FIRST_NAME_COACH],
          item?.[LAST_NAME_COACH],
          ...(!!+tournamentData?.show_reg_patronymic ? [item?.patronymic] : [])
        ].join(' '),
        hasPhoto: true,
        photo: item?.photo
      },
      info: [
        { value: item.email, name: t('email'), className: classes.emailTable },
        { value: item.iso3, name: t('country') },
        { value: item.region, name: t('region') },
        { value: item.city, name: t('city') },
        { value: item.club, name: t('club') }
      ]
    };
  };

  const buttons = [
    {
      purpose: t('chooseFromList'),
      onClick: (e) => {
        showModal({ targetID: CHOOSE_FROM_LIST });
      },
      id: CHOOSE_FROM_LIST
    },
    {
      purpose: t('addRecord', { name: '' }),
      onClick: () => showModal({ targetID: ADD_NEW }),
      id: ADD_NEW
    }
  ];

  return (
    <>
      <h3 className={classes.headerMain}>{t('invitationListTitle')}</h3>
      <Modal
        open={Boolean(modalData?.open)}
        close={hideModal}
        dialogTitle={
          modalData?.targetID === CHOOSE_FROM_LIST
            ? t('RegisterOrganisationInTheAdmissionList')
            : t('addOrganisation')
        }
        onClick={
          modalData?.targetID === CHOOSE_FROM_LIST
            ? () =>
                onSaveInvitations({
                  tournamentId: tournamentData?.id,
                  selectedCheckboxes: modalData?.selectedCheckboxes,
                  isMultiInvitations: true
                })
            : undefined
        }
        buttonPurpose={
          modalData?.targetID === CHOOSE_FROM_LIST &&
          t('addRecord', { name: '' })
        }
        {...{ shouldShowDiscardChanges }}
        {...{ discardOrKeepEditing }}
        specificDetails={{
          className: {
            ...(hasDesktopSize ? { paper: classes.modalWidth } : {})
          }
        }}>
        {!modalData?.isLoading ? (
          modalData?.open &&
          (modalData?.targetID !== CHOOSE_FROM_LIST ? (
            <CoachForm
              inputs={dojoInfo}
              onBlur={onBlur}
              {...{ showError }}
              changeTxt={onChangeTxtForm}
              {...{ countries }}
              saveCoachData={onSave}
              changeImage={changeImage}
              changeDate={(date) => changeDate(date, 'modalData', errors, true)}
              values={modalData}
              param={'modalData'}
              {...{ errors }}
              selectOption={selectOption}
              fetchRegionBySelectedCountry={fetchRegionBySelectedCountry}
              {...{ allRegions }}
            />
          ) : (
            <>
              <SearchInput
                onChange={(e) => textChange(e, 'modalData')}
                id="search-input_table_registration"
                onKeyPress={(e) => pressEnter(e, 'modalData')}
                value={modalData?.searchBar || ''}
                isSearchInactive={!modalData?.searchBar}
                clearSearch={() => clearSearch('modalData')}
                className={classes.marginBottom}
              />
              <TableList data={filteredCoaches} {...{ recordDataParams }} />
            </>
          ))
        ) : (
          <CircularProgress style={{ margin: 'auto', display: 'flex' }} />
        )}
      </Modal>
      <div
        style={{
          background: '#fff',
          border: '1px solid #A2ABBA',
          borderRadius: '0.3rem',
          padding: '1.5rem'
        }}>
        <div className={classes.toolbar}>
          <SearchInput
            onChange={(e) => textChange(e, 'filters')}
            id="search-input_registration"
            onKeyPress={(e) => pressEnter(e, 'filters')}
            value={filters?.searchBar || ''}
            isSearchInactive={!filters?.searchBar}
            clearSearch={() => clearSearch('filters')}
            className={classes.search}
          />
          {buttons.map(({ purpose, onClick, id }) => (
            <Fragment key={id}>
              <Button label={purpose} {...{ onClick, id }} isSaveBtn={true} />
            </Fragment>
          ))}
        </div>

        <div className={classes.wrapperInvitations}>
          {tournInvitations?.map((el, elIdx) => {
            return (
              <span
                key={elIdx}
                style={{
                  border: '1px solid #8996AC',
                  height: '10.5em',
                  minWidth: '22.75em',
                  display: 'grid',
                  borderRadius: '0.3rem',
                  padding: '0.63rem'
                }}>
                <span
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '3.375em 1fr',
                    gap: '0.5rem',
                    height: 'fit-content'
                  }}>
                  <img
                    style={{
                      width: '3.375em',
                      height: '4.5em',
                      objectFit: 'cover'
                    }}
                    src={
                      el?.photo ? serverDefaultPath + el?.photo : DEFAULT_AVATAR
                    }
                    alt={t('avatar')}
                  />
                  <span style={{ display: 'grid' }}>
                    <span
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1.5em',
                        gap: '0.5rem'
                      }}>
                      <span style={{ fontSize: '1rem', fontWeight: 500 }}>
                        {[el[FIRST_NAME], el[LAST_NAME]].join(' ')}
                      </span>

                      <Clear
                        onClick={() => onDelete(el)}
                        style={{
                          cursor: 'pointer',
                          width: '1.5em',
                          height: 'auto'
                        }}
                      />
                    </span>
                    <span
                      className={classes.ellipsis}
                      style={{
                        fontSize: '1rem',
                        fontWeight: 500,
                        alignContent: 'end'
                      }}>
                      {el.email}
                    </span>
                  </span>
                </span>
                <span style={{ color: '#8996AC', fontSize: '0.7rem' }}>
                  {t('cluborFederationName')}
                </span>
                <span style={{ fontSize: '1rem', fontWeight: 500 }}>
                  {el.club}
                </span>
              </span>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Invitation;
