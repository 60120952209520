import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Collapse, List, ListItem, Tooltip } from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { OK } from '../../helpers/constants';

import useStyles from './style';

const CategoriesList = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({});
  const subItemToggle = (item) => setState({ [item]: !state[item] });
  const {
    user,
    tournamentId,
    openModal,
    participantId,
    onClick,
    itemToggle,
    collapsed,
    tournamentApplications,
    processing,
    selectedId,
    listIconsClasses,
    shouldShowNoncollapsibleList,
    actionIconsList,
    shouldDisableEditing
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const moveToEvent = (item) => {
    return navigate(`/event/${item.tournament_id}`, {
      state: { prevUrl: location.pathname }
    });
  };

  const attachedParticipant = (user) =>
    !shouldShowNoncollapsibleList
      ? parseInt(user.tournaments_count) !== 0
      : parseInt(user.categories_count) !== 0;

  const categoryRelated = (user, items, param) => {
    return (
      <>
        {Array.isArray(items[param]) &&
          items[param].map((category, index) => (
            <ListItem
              key={index}
              className={clsx(
                classes.flex,
                classes.maxWidth,
                index !== 0 && classes.marginTop,
                classes.listRecord,
                classes.condensedViewLeftAligned
              )}>
              <span
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                className={clsx(
                  (category.issues ||
                    (category.issue && category.issue !== OK)) &&
                    classes.issue,
                  classes.noEvent,
                  classes.breakWord
                )}>
                {[
                  category.team_id ? `(${category.team_id}) ` : '',
                  category.category_name
                ].join('')}
              </span>
              <span className={clsx(classes.flex, classes.marginLeft)}>
                {actionIconsList &&
                  actionIconsList(user, category, listIconsClasses)}
                {!shouldDisableEditing && (
                  <Tooltip arrow title={t('removeParticipantCateory')}>
                    <ClearIcon
                      id="clearIcon"
                      className={clsx(listIconsClasses)}
                      onClick={(e) => {
                        e.stopPropagation();
                        openModal(
                          e,
                          user,
                          items.tournament_id || tournamentId,
                          category.category_id
                        );
                      }}
                    />
                  </Tooltip>
                )}
              </span>
            </ListItem>
          ))}
      </>
    );
  };
  return (
    <>
      {!shouldShowNoncollapsibleList ? (
        <span onClick={(e) => e.stopPropagation()}>
          <span
            className={clsx(
              classes.flex,
              classes.centerVertically,
              attachedParticipant(user) && classes.icons
            )}
            onClick={() =>
              attachedParticipant(user)
                ? itemToggle(user.participant_id || user.id)
                : onClick(user)
            }>
            {parseInt(user.tournaments_count) !== 0
              ? user.tournaments_count
              : '-'}
            {attachedParticipant(user) && (
              <ArrowDropDownIcon
                className={clsx(
                  classes.flex,
                  collapsed[user.participant_id || user.id] &&
                    classes.arrowDropUp
                )}
              />
            )}
          </span>
          <Collapse
            in={collapsed[user.participant_id || user.id]}
            timeout="auto"
            unmountOnExit>
            {!processing ? (
              tournamentApplications.map((key) => [
                <div key={key.tournament_id}>
                  <div className={clsx(classes.flex, classes.maxWidth)}>
                    <List
                      className={clsx(
                        classes.flex,
                        classes.content,
                        classes.maxWidthListItem
                      )}>
                      <ListItem
                        disableGutters={true}
                        className={clsx(
                          classes.ellipsis,
                          participantId && selectedId === user
                            ? classes.text
                            : classes.linkText
                        )}
                        onClick={() => moveToEvent(key)}>
                        {key.tournament_name}
                      </ListItem>
                    </List>
                    <ArrowDropDownIcon
                      className={clsx(
                        classes.flex,
                        classes.arrowDropIc,
                        state[key.tournament_id] && classes.arrowDropUp
                      )}
                      onClick={() => subItemToggle(key.tournament_id)}
                    />
                  </div>
                  <Collapse
                    in={state[key.tournament_id]}
                    timeout="auto"
                    unmountOnExit>
                    {categoryRelated(user, key, 'categories')}
                  </Collapse>
                </div>
              ])
            ) : (
              <Skeleton />
            )}
          </Collapse>
        </span>
      ) : (
        categoryRelated(user, user, 'categories_info')
      )}
    </>
  );
};

export default CategoriesList;
