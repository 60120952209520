import clsx from 'clsx';

import { ReactComponent as Bronze } from '../../assets/img/medal_prize_3.svg';
import { ReactComponent as Gold } from '../../assets/img/medal_prize_1.svg';
import { ReactComponent as Silver } from '../../assets/img/medal_prize_2.svg';

import { useStyles } from './styles';

const Places2Win = (props) => {
  const { item, paramName, className } = props;

  const classes = useStyles();
  const medal =
    `${item[paramName]}` === '1' ? (
      <Gold className={classes.icon} />
    ) : `${item[paramName]}` === '2' ? (
      <Silver className={classes.icon} />
    ) : (
      `${item[paramName]}` === '3' && <Bronze className={classes.icon} />
    );

  return (
    <div
      className={clsx(classes.iconWrapper, classes.left, className?.wrapper)}>
      {medal}
    </div>
  );
};

export default Places2Win;
