import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import DeleteIcon from '@material-ui/icons/Delete';

import { DELETE_ICON } from '../../helpers/constants';
import {
  fetchLog,
  deleteLog,
  downloadFile,
  fetchLogs,
  fetchLogsStatistics,
  changeModal
} from '../../helpers/util';
import { changeTitle } from 'helpers/actions';

import { AuthContext } from '../../AuthContext';

import SideModal from '../../components/Snackbar/SideModal';
import Modal from '../../components/Modal/Modal';
import Table from '../../components/Table/Table';
import LoadingState from '../../components/LoadingState/LoadingState';

import styles from './Styles';

class Logs extends Component {
  state = {
    records: [],
    modalData: {},
    open: false,
    showModal: false,
    success: true,
    filteredRecords: [],
    loading: true,
    filters: {},
    statistics: {},
    langOnLoad: localStorage.getItem('i18nextLng')
  };

  fetchLog = fetchLog.bind(this);
  downloadFile = downloadFile.bind(this);
  fetchLogs = fetchLogs.bind(this);
  fetchLogsStatistics = fetchLogsStatistics.bind(this);
  deleteLog = deleteLog.bind(this);
  changeModal = changeModal.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      changeTitle(t('logs'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { t } = this.props;

    this.fetchData();

    changeTitle(t('logs'));
  }

  fetchData = () => {
    this.fetchLogsStatistics((data) => this.setState({ statistics: data }));
    this.fetchLogs((data) => {
      this.setState({
        loading: false,
        records: data,
        filteredRecords: data
      });
    });
  };

  showModal = (item) => {
    this.setState({
      modalData: { name: item },
      open: true
    });
  };

  handleTextChange = (evt) => {
    this.setState((prevState) => ({
      filters: { ...prevState.filters, searchBar: evt.target.value }
    }));
  };

  searchHandler = () => {
    const { records, filters } = this.state;
    const copyRecords = [...records];
    let filteredRecords = copyRecords.filter((it) =>
      it.toLowerCase().includes(filters.searchBar.toLowerCase().trim())
    );

    this.setState((prevState) => ({
      filteredRecords,
      filters: {
        ...prevState.filters,
        searchedValue: filters?.searchBar
      }
    }));
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.searchHandler()
    );
  };

  pressEnterHandler = (evt) => {
    if (evt.key === 'Enter') {
      this.searchHandler();
    }
  };

  hideModal = () => this.setState({ modalData: {}, open: false });

  hideSnackbar = () => {
    this.setState({ showModal: false });
  };

  onfetchIndividualLog = (evt, item) => {
    const fileName = item.replace(/\.[^/.]+$/, '');

    this.fetchLog(fileName);
  };

  onDeleteLog = () => {
    const { modalData } = this.state;
    const fileName = modalData?.name?.replace(/\.[^/.]+$/, '');

    this.deleteLog(fileName, () => {
      this.fetchData();
      this.hideModal();
    });
  };

  render() {
    const {
      success,
      open,
      showModal,
      modalInfo,
      modalData,
      filteredRecords,
      filters,
      statistics,
      loading
    } = this.state;
    const { t, classes } = this.props;

    const header = [{ label: t('name') }];

    const tableBody = ['logName'];

    const condensedTableData = {
      info: [{ key: 'logName', name: t('name') }]
    };

    const headerStatistics = [
      { label: 'user_role' },
      { label: 'agent' },
      { label: 'response_code' },
      { label: 'requests' },
      { label: 'avg_time' },
      { label: 'max_time' }
    ];

    const tableBodyStatistics = [
      'user_role',
      'agent',
      'response_code',
      'requests',
      'spend_time_avg',
      'spend_time_max'
    ];

    const condensedTableDataStatistics = {
      info: [
        { key: 'user_role', name: 'user_role' },
        { key: 'agent', name: 'agent' },
        { key: 'response_code', name: 'response_code' },
        { key: 'requests', name: 'requests' },
        { key: 'spend_time_avg', name: 'spend_time_avg' },
        { key: 'spend_time_max', name: 'spend_time_max' }
      ]
    };

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <Modal
              {...{ open }}
              close={this.hideModal}
              subHeader={modalData.name}
              dialogTitle={t('deleteRecord', { name: t('logs') })}
              dialogContent={t('deleteMsg')}
              onClick={this.onDeleteLog}
              buttonPurpose={t('deleteRecord', { name: '' })}
            />
            <div className={classes.marginBottom1}>
              <Table
                shouldHideSearch
                toolbarIndividualContent={
                  <span className={classes.freeSpaceWrapper}>
                    <span className={classes.freeSpaceLabel}>
                      free_space_mb
                    </span>
                    <span className={classes.leftAuto}>
                      {statistics.free_space_mb}
                    </span>
                  </span>
                }
                condensedTableData={condensedTableDataStatistics}
                header={headerStatistics}
                body={statistics?.requests ?? []}
                data={tableBodyStatistics}
              />
            </div>
            <Table
              order
              {...{ condensedTableData }}
              search={filters.searchBar}
              pressEnter={this.pressEnterHandler}
              clearSearch={this.clearSearch}
              textChange={this.handleTextChange}
              onClick={(evt, item) => this.onfetchIndividualLog(evt, item)}
              {...{ header }}
              body={filteredRecords}
              data={tableBody}
              shouldAllowEditing={() => true}
              actionIcons={(item, _, className, iconWrapper, marginLeft) => (
                <>
                  <DeleteIcon
                    id={DELETE_ICON}
                    className={clsx(className, iconWrapper, marginLeft)}
                    onClick={() => this.showModal(item)}
                  />
                </>
              )}
              selected={(item) => {
                return item === modalData.name;
              }}
            />
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Logs)));
