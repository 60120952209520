import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  btn: {
    width: '14rem'
  },

  modalpaddingLeftRight30: {
    padding: '0 1.875rem'
  },

  discardWrapper: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    padding: '20px'
  },
  discardChanges: {
    fontWeight: 500,
    marginRight: '5px'
  },
  discardBtn: {
    backgroundColor: 'rgb(224,30,90)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgb(224,30,90)',
      opacity: 0.7
    }
  },
  onHoldEditing: {
    backgroundColor: 'rgb(232,232,232)',
    paddingBottom: 0,
    pointerEvents: 'none'
  },
  header: {
    fontSize: '20px',
    fontWeight: 500
  },

  bgWhite: {
    background: '#fff'
  },

  bgGrey: {
    background: 'rgb(232,232,232)'
  },

  wrapper_header: {
    position: 'sticky',
    top: 0,
    zIndex: 10
  },

  paddingBottom08: {
    paddingBottom: '.8rem'
  },

  paddingTop30: {
    paddingTop: '1.875rem'
  },

  marginRight: {
    marginRight: '20px'
  },

  left: {
    marginLeft: 'auto'
  },

  maxWidth: {
    '@media (min-width:901px)': {
      width: '50vw'
    }
  },
  marginTop20: {
    marginTop: '1.5rem'
  },

  boxShadowHeader: {
    boxShadow: '0 1px 0px 0 rgb(63 63 68 / 35%)'
  },

  boxShadowFooter: {
    boxShadow: '0 -1px 0px 0 rgb(63 63 68 / 35%)'
  },

  wrapper_footer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#fff',
    paddingBottom: '1.875rem',
    zIndex: 1
  },

  bgOldDefaultSave: {
    background: '#001D3D'
  }
}));
