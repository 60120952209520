import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { TextField } from '@material-ui/core';

import { useStyles } from './StylesFilter';

const TextInput = (props) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <TextField
      {...props}
      className={styles.placeholder}
      InputLabelProps={{
        style: { color: '#8996AC' },
        shrink: true
      }}
    />
  );
};

export default TextInput;
