import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  headerMain: {
    fontSize: '1.2rem',
    textAlign: 'center',
    fontWeight: 500,
    marginTop: '2.5rem',
    marginBottom: '1.5rem'
  },

  toolbar: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateAreas: "'search . .'",
      gridTemplateColumns: '1fr 13.75em 13.75em'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateAreas: "'search search' '. .'"
    },
    gap: '1rem',
    marginBottom: '1.5rem'
  },

  gridRecords: {
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr'
    }
  },

  gridRecordsValue: {
    '@media (max-width:1023px) ': {
      gridTemplateAreas: "'. .' '. .''. .'",
      gridTemplateColumns: '1fr 1fr',
      marginLeft: '5.5rem',
      marginTop: '-1.5rem',
      padding: 0
    }
  },

  cardValuePart: {
    fontSize: '1rem',
    fontWeight: 400
  },

  modalWidth: {
    '@media (min-width:1024px)': {
      minWidth: 'fit-content'
    }
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  search: {
    '@media (min-width:768px)': {
      gridArea: 'search'
    }
  },

  margin0: { margin: 0 },

  rowOrder: { minWidth: '3em' },

  thumbnail: {
    width: '2.438em',
    height: '3.25em'
  },

  emailTable: {
    width: '10em'
  },

  wrapperInvitations: {
    height: '15em',
    overflowY: 'auto',
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr 1fr'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr'
    },
    gap: '1.5em'
  },

  root: {
    padding: '20px',
    backgroundColor: '#fff'
  },

  comboBox: {
    '@media (min-width:651px)': {
      maxWidth: 'calc(100% - 220px)',
      marginRight: '15px'
    }
  },

  btnWrapper: {
    maxWidth: '220px'
  },

  column: {
    flexDirection: 'column'
  },

  header: {
    width: '100%',
    marginBottom: '15px'
  },

  textField: {
    margin: '10px'
  },

  startAdornment: {
    paddingRight: '10px'
  },

  marginRight10: {
    marginRight: '10px'
  },

  previewEmailsList: {
    width: '50vw'
  },

  templatePreview: {
    whiteSpace: 'pre-line',
    marginTop: '15px'
  },

  divider: {
    borderTop: '1px solid #D1D6DD'
  },

  sendTo: {
    margin: '10px 0'
  },

  icon: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      color: '#1a237e'
    }
  },

  outlined: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 0
    }
  },

  label: {
    '& .MuiInputLabel-outlined': {
      lineHeight: 0
    }
  },

  flex: {
    display: 'flex'
  },

  center: {
    alignItems: 'center'
  },

  sub: {
    marginLeft: '10px'
  },

  alignColumn: {
    '@media (max-width:650px)': {
      flexDirection: 'column'
    }
  },

  errorText: {
    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: '55px'
    }
  },

  checkboxes: {
    padding: '5px 5px 0 0'
  },

  chips: {
    marginTop: '2px'
  },

  paper: {
    cursor: 'text',
    alignItems: 'center',
    padding: '0 5px'
  },

  marginBottom: {
    marginBottom: '20px'
  },

  fullWidth: {
    width: '100%'
  },

  minWidth: {
    minWidth: '100%'
  },

  subjectInput: {
    height: '50px'
  },

  chipsWrapper: {
    flexFlow: 'row wrap'
  },

  link2CopyHeader: {
    fontWeight: 700,
    marginBottom: '0.5rem'
  },

  marginBottom20: {
    marginBottom: '20px'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },

  alignItems: {
    alignItems: 'center'
  }
}));

export const spinner = {
  width: '25px',
  height: '25px'
};
