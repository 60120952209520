import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { root, header } from './StylesErrorBoundary';

class ErrorBoundary extends Component {
  state = {
    error: null,
    errorInfo: null
  };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    const { errorInfo, error } = this.state;
    const { children, t } = this.props;

    return (
      <>
        {errorInfo ? (
          <div style={root}>
            <h2 style={header}>{t('errorBoundary')}</h2>
            <details>
              {error && error.toString()}
              {errorInfo.componentStack}
            </details>
          </div>
        ) : (
          children
        )}
      </>
    );
  }
}
export default withTranslation()(ErrorBoundary);
