import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { TextField } from '@material-ui/core';

import { AuthContext } from '../../AuthContext';

import { ID, serverDefaultPath, DELETE_ICON } from '../../helpers/constants';
import {
  fetchTournaments,
  deleteTournament,
  changeModal,
  pageChangeHandler,
  rowsPerPageHandler,
  fetchReferenceRegion,
  textChangeHandler,
  selectOption,
  fetchReference,
  fetchUsers
} from '../../helpers/util';
import {
  selectedValue,
  isDesktopView,
  generate6DigitsCode,
  finishedTournament,
  formatDate
} from '../../helpers/selectors';
import { changeTitle } from '../../helpers/actions';

import LoadingState from '../../components/LoadingState/LoadingState';
import SideModal from '../../components/Snackbar/SideModal';
import _ from 'lodash';

import TableList from './TableList';
import Filter from '../../components/Filter/Filter';
import Button from '../../components/Buttons/ActionButtons';
import { SearchInput } from 'components';
import Modal from '../../components/Modal/ModalNewDesign';
import AddTournament from '../AddTournament/AddTournament';
import Pagination from '../../components/TablePagination/TablePagination';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';

import styles from './Styles';
import EmptyState from 'components/EmptyState/EmptyState';

class Tournaments extends Component {
  state = {
    tournamentsList: [],
    showModal: false,
    shouldShowForm: false,
    shouldUpdateTable: false,
    success: false,
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    open: false,
    tournamentId: null,
    filteredTournaments: [],
    sortingFilters: [],
    tournamentsCount: 0,
    allRegions: [],
    filters: {
      searchBar: ''
    },
    rowsPerPage:
      (localStorage.getItem('rowsPerPage') &&
        +localStorage.getItem('rowsPerPage')) ||
      25,
    page: 1
  };

  fetchTournaments = fetchTournaments.bind(this);
  deleteTournament = deleteTournament.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  changeModal = changeModal.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  selectOption = selectOption.bind(this);
  fetchReference = fetchReference.bind(this);
  fetchUsers = fetchUsers.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { shouldUpdateTable, searchBar, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      shouldUpdateTable !== prevState.shouldUpdateTable
    ) {
      this.fetchTournaments(
        null,
        null,
        searchBar,
        () => this.filterTournaments(),
        true
      );
    }

    if (langOnLoad !== currentLang) {
      changeTitle(t('event'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  filterTournaments = () => {
    const { tournamentsList, filters } = this.state;
    const { searchBar, tournamentStatus } = filters;

    let filteredTournaments = tournamentsList.filter((tournament) => {
      const isDefinitelyColsed = finishedTournament(tournament);
      const nameMatches = tournament.tournament_name
        .toLowerCase()
        .includes(searchBar.toLowerCase().trim());
      const typeMatches =
        tournamentStatus === 2
          ? isDefinitelyColsed
          : tournamentStatus === 1
          ? !isDefinitelyColsed
          : true;

      return nameMatches && typeMatches;
    });

    this.setState({ filteredTournaments });
  };

  handleFilterChange = (event, value) => {
    const statusId = value && value.id ? value.id : null;

    ti;

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, tournamentStatus: statusId }
      }),
      () => this.filterTournaments()
    );
  };

  componentDidMount() {
    const { langOnLoad } = this.state;
    const { role } = this.context.authState;
    this.fetchTournaments(null, null, null, () =>
      this.setState({ loading: false })
    );

    changeTitle(this.props.t('event'));

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter((item) => item.name === 'events');
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );

    role === 'administrator' && this.setState({ type: 1 });
  }

  selectType = (event, value) => {
    const { searchBar } = this.state;
    if (value === null) {
      this.setState({ type: value });
      this.fetchTournaments(null, value, searchBar, null, true);
    } else {
      this.setState({ type: value.id });
      this.fetchTournaments(null, value.id, searchBar, null, true);
    }
  };

  rowClickedHandler = (evt, item) => {
    evt.preventDefault();

    const { navigate, location } = this.props;

    navigate('/event/' + item.id, {
      state: { prevUrl: location.pathname }
    });
  };

  searchHandler = (event) => {
    const { value } = event.target;
    const { tournamentsList } = this.state;
    this.setState(
      {
        searchBar: value,
        page: 1,
        filteredTournaments: tournamentsList.filter((tournament) => {
          return tournament.tournament_name
            .toLowerCase()
            .includes(value.toLowerCase().trim());
        })
      },
      () =>
        this.setState({
          tournamentsCount: this.state.filteredTournaments.length
        })
    );
  };

  orderTournamentsByColumn = (columnName) => {
    const { sortingFilters, filteredTournaments } = this.state;
    const sortingFiltersExisting = sortingFilters.indexOf(columnName);
    if (sortingFiltersExisting !== -1) {
      sortingFilters.splice(sortingFiltersExisting, 1);
    } else {
      sortingFilters.push(columnName);
    }
    const sortedFilteredTournament = _.orderBy(
      filteredTournaments,
      [...sortingFilters],
      ['asc']
    );
    this.setState({
      sortingFilters,
      filteredTournaments: sortedFilteredTournament
    });
  };

  showModal = (evt, item) => {
    const targetID = evt.currentTarget.id;
    const id = item?.id;
    let tournamentData = {};

    if (id) {
      this.fetchReferenceRegion(item?.c_id);
      tournamentData = {
        ...item,
        ...(item.poster
          ? { imagePreview: serverDefaultPath + item.poster }
          : {}),
        country_id: item.c_id,
        date: item.start_date,
        tour_time: item.start_time,
        tournament_id: item.id,
        id: item.id,
        targetID
      };
    } else {
      const getNextSaturdayDate = () => {
        const today = new Date();
        const daysUntilSaturday = (6 - today.getDay() + 7) % 7;
        const nextSaturday = new Date(today);
        nextSaturday.setDate(today.getDate() + daysUntilSaturday);
        return formatDate(nextSaturday);
      };

      const getCloseDate = () => {
        const nextSaturday = getNextSaturdayDate();
        const closeDate = new Date(nextSaturday);
        closeDate.setDate(closeDate.getDate() - 1);
        return formatDate(closeDate);
      };

      tournamentData = {
        type: '1',
        country_id: '',
        city: '',
        date: getNextSaturdayDate(),
        tour_time: '9:00',
        duration_days: '1',
        registration_date: getCloseDate(),
        reg_judge_date: getCloseDate(),
        reg_date: getCloseDate(),
        reg_time: '9:00',
        calc_age_date: getNextSaturdayDate(),
        places_number: '3',
        kata_final: '6',
        scoreboard_theme: '4',
        participant_up_color: 'white',
        participant_down_color: 'red',
        tatami_set: 'en',
        tournament_name: '',
        address: '',
        region: '',
        tg_order_type: '4640'
      };

      this.fetchUsers(
        localStorage.getItem('user_id'),
        'userForm',
        (userData) => {
          const { region } = userData;

          this.setState({
            tournamentData: {
              ...tournamentData,
              region: region || tournamentData.region
            },
            tournamentId: id,
            open: true,
            selectedRow: id
          });
        }
      );
      return;
    }

    this.setState({
      tournamentId: id,
      open: true,
      tournamentData,
      selectedRow: id
    });
  };

  triggerTableUpdate = () => this.setState({ shouldUpdateTable: true });

  hideModal = () => this.setState({ open: false, selectedRow: null });

  closeModalHandler = () => this.setState({ showModal: false });

  clearSearch = () => {
    const { tournamentsList } = this.state;
    const len = tournamentsList?.length;

    this.setState({
      searchBar: '',
      filteredTournaments: tournamentsList,
      tournamentsCount: len
    });
  };

  onDelete = (data, action) => {
    const { tournamentData } = this.state;

    this.deleteTournament(
      {
        tournament_id: tournamentData?.id,
        ...(data ?? {})
      },
      (msg) => {
        action && action(msg);
      }
    );
  };

  onConfirmDelete = () => {
    const { tournamentData } = this.state;

    const callback = () => {
      this.triggerTableUpdate();
      this.hideModal();
    };

    if (tournamentData?.force) {
      this.onDelete({ force: true, shouldGetHash: true }, (msg) => {
        this.onDelete({ ...(msg ?? {}) }, () => {
          callback();
        });
      });
    } else {
      this.onDelete({}, () => {
        callback();
      });
    }
  };

  onToggleForce = (evt) => {
    const { tournamentData } = this.state;
    const { checked } = evt.target;
    let cpy = { ...tournamentData };

    cpy = {
      ...cpy,
      force: checked,
      random: checked ? generate6DigitsCode() : null,
      hash: checked ? cpy.hash : null
    };

    this.setState({ tournamentData: cpy });
  };

  render() {
    const {
      showModal,
      success,
      modalInfo,
      filteredTournaments,
      open,
      loading,
      searchBar,
      selectedRow,
      tournamentsCount,
      page,
      rowsPerPage,
      tournamentData,
      filters
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth, authState } = this.context;
    const { role } = authState;
    const hasDesktopSize = isDesktopView(viewportWidth);

    const TOURNAMENT_STATUS = [
      { id: 1, name: t('upcoming') },
      { id: 2, name: t('finished') }
    ];

    let dialogTitle,
      subHeader,
      buttonPurpose,
      dialogContent,
      onClick,
      specificDetails;

    if (tournamentData?.targetID === DELETE_ICON) {
      subHeader = tournamentData?.id && (
        <span className={clsx(classes.flex, classes.marginTop1)}>
          {tournamentData.tournament_name}
        </span>
      );
      dialogTitle = t('deleteRecord', { name: t('event') });
      dialogContent =
        role !== 'administrator' ? (
          t('deleteEventMsg')
        ) : (
          <div style={{ display: 'grid', gap: '1rem' }}>
            {t('deleteEventMsg')}
            <CheckboxBtn
              checked={tournamentData?.force ?? false}
              onChange={this.onToggleForce}
              label={t('deletePermanently')}
            />
            {tournamentData?.force && tournamentData?.random && (
              <>
                {t('confirm2deletePermanently', {
                  name: tournamentData?.random
                })}
                <TextField
                  className={classes.txtField}
                  name="confirmDigits"
                  value={tournamentData?.confirmDigits || ''}
                  onChange={(e) => this.textChangeHandler(e, 'tournamentData')}
                  variant="outlined"
                />
              </>
            )}
          </div>
        );
      buttonPurpose = t('deleteRecord', { name: '' });
      onClick = this.onConfirmDelete;
      specificDetails = {
        disabled:
          role === 'administrator' &&
          tournamentData?.force &&
          (!tournamentData?.confirmDigits ||
            tournamentData?.confirmDigits !== tournamentData.random)
      };
    } else {
      dialogTitle = tournamentData?.id
        ? t('updateRecord', { name: t('event') })
        : t('addRecord', { name: t('event') });
      dialogContent = (
        <AddTournament
          state={this.state}
          triggerTableUpdate={this.triggerTableUpdate}
        />
      );
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          success={success}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{ dialogTitle, dialogContent, subHeader, onClick, buttonPurpose }}
          specificDetails={{
            ...(specificDetails ?? {}),
            className: {
              ...(hasDesktopSize &&
                (tournamentData?.targetID === DELETE_ICON
                  ? { paper: classes.modalWidth }
                  : { paper: classes.modalWidthViewEvent }))
            }
          }}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <>
            <div className={classes.grid}>
              <SearchInput
                className={classes.gridSearchArea}
                onChange={this.searchHandler}
                value={searchBar || ''}
                clearSearch={this.clearSearch}
              />
              <Button
                className={classes.gridButtonArea}
                id="add_new_button"
                onClick={this.showModal}
                isSaveBtn={true}
                label={
                  <>
                    <span className={classes.plus}>&#x002B;</span>
                    {t('addNew')}
                  </>
                }
              />
              <Filter
                classes={{
                  root: classes.outlined
                }}
                className={classes.gridFilterArea}
                disableClearable
                options={TOURNAMENT_STATUS}
                onChange={(e, val) => {
                  this.selectOption(
                    e,
                    val,
                    'filters',
                    'tournamentStatus',
                    ID,
                    null,
                    this.filterTournaments
                  );
                }}
                label={t('event')}
                variant={'outlined'}
                item={'name'}
                value={
                  selectedValue(
                    TOURNAMENT_STATUS,
                    ID,
                    filters.tournamentStatus
                  ) || ''
                }
              />
            </div>
            {filteredTournaments.length > 0 ? (
              <>
                <TableList
                  {...{
                    page,
                    rowsPerPage,
                    selectedRow,
                    tournamentData,
                    filteredTournaments,
                    role
                  }}
                  showModal={this.showModal}
                  rowClickedHandler={this.rowClickedHandler}
                />
                <Pagination
                  count={tournamentsCount}
                  onPageChange={this.pageChangeHandler}
                  onRowsPerPageChange={this.rowsPerPageHandler}
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              </>
            ) : (
              <EmptyState
                className={classes.emptyState}
                emptyStateText={t('noResults')}
              />
            )}
          </>
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Tournaments)));
