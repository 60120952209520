import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  fullWidth: {
    width: '100%'
  },
  img: {
    objectFit: 'cover',
    width: '4.5em',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    height: '6em',
    borderRadius: '4px'
  },
  marginLeftRight: {
    padding: '0 .5em'
  },
  paddingBottom: {
    paddingBottom: '.5em'
  },
  wordBreak: {
    wordBreak: 'break-all'
  },
  secondaryTxt: {
    paddingRight: '.2em',
    minWidth: 'fit-content',
    whiteSpace: 'break-spaces',
    color: '#92979F'
  },
  paper: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    '@media (min-width:901px)': {
      borderRadius: '4px 0 0 4px'
    },
    backgroundColor: '#fff',
    padding: '.65em',
    marginBottom: '40px'
  },
  left: {
    marginLeft: 'auto'
  },
  iconBtn: {
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
    padding: '5px',
    width: '2.2rem',
    height: '2.2rem',
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  }
}));
