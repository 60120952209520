import React, { useState } from 'react';

import { TextField } from '@material-ui/core';

import { onChangeText } from '../../helpers/selectors';

const EditableTableCell = (props) => {
  const { specificDetails, updateParticipant, triggerTableUpdate } = props;
  const { item, name, role, tournamentData, participantIndividualData } =
    specificDetails;
  const [value, setValue] = useState(item?.[name] || '');
  const [errors, setErrors] = useState({});

  const onChange = (evt) => {
    const result = onChangeText(evt, evt.target.value, errors);

    setValue(result[name]);
    setErrors(result.errors);
  };

  const onUpdate = (evt) => {
    if (value !== item[name]) {
      if (Object.values(errors).length === 0) {
        updateParticipant(
          evt,
          {
            ...participantIndividualData(item),
            [name]: value
          },
          null,
          tournamentData,
          null,
          null,
          role,
          null,
          {
            callbackNoRerender: triggerTableUpdate
          }
        );
      } else {
        setErrors({});
        setValue(item?.weight);
      }
    }
  };

  return (
    <TextField
      style={{ width: '80px' }}
      value={value || ''}
      name={name}
      onChange={onChange}
      onBlur={onUpdate}
      error={Boolean(errors?.[name])}
      helperText={errors?.[name]}
      fullWidth
      onKeyUp={(evt) => {
        if (evt.key === 'Enter') {
          onUpdate(evt);
          evt.target.blur();
        }
      }}
      variant="outlined"
    />
  );
};

export default EditableTableCell;
