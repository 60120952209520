import React, { Fragment } from 'react';
import clsx from 'clsx';

import { Collapse, ListItem } from '@material-ui/core';

import { useStyles } from './StylesListItems';

const ListItems = ({
  elem,
  itemParamN,
  elemParamN,
  shouldAllowEditing,
  actionIconsList,
  styles,
  paramElemName,
  subElemParamN,
  collapsed,
  showAllItemsAtOnce,
  specificDetails
}) => {
  const classes = useStyles();

  const itemComponent = (currentVal, currentIdx) => {
    return (
      <span
        key={currentIdx}
        className={clsx(
          classes.flex,
          classes.categoryTxt,
          specificDetails?.className?.categoryTxt
        )}>
        <ListItem
          key={currentIdx}
          className={clsx(
            classes.flex,
            classes.categoryListItem,
            specificDetails?.className?.categoryListItem
          )}>
          <span
            className={clsx(
              classes.noEvent,
              currentVal?.issues && classes.issue
            )}>
            {[
              currentVal?.team_id ? `(${currentVal.team_id}) ` : '',
              currentVal?.[itemParamN]
            ].join('')}
          </span>
        </ListItem>
      </span>
    );
  };

  return (
    <>
      {!showAllItemsAtOnce ? (
        <>
          {[
            ...(elem?.[elemParamN]?.length > 1 ? [0, 1] : [0]) //this method displays up to two categories. Dropdown menu shows for three or more
          ].map((valIdx) => {
            return (
              <Fragment key={valIdx}>
                {elem?.[elemParamN]?.[valIdx] && (
                  <span
                    key={elem?.[elemParamN]?.[valIdx]?.[subElemParamN]}
                    className={clsx(
                      classes.gridDropdown,
                      classes.centerVertically,
                      styles?.gridDropdown
                    )}>
                    {itemComponent(elem?.[elemParamN]?.[valIdx], valIdx)}
                    {shouldAllowEditing && actionIconsList && (
                      <span className={clsx(classes.flex)}>
                        {actionIconsList(
                          elem?.[elemParamN]?.[valIdx],
                          elem?.[elemParamN]?.[valIdx]?.[subElemParamN],
                          [
                            classes.onHover,
                            classes.buttonsSize,
                            classes.buttonsBorder
                          ]
                        )}
                      </span>
                    )}
                  </span>
                )}
              </Fragment>
            );
          })}
          {collapsed && (
            <Collapse
              in={collapsed[elem[paramElemName]]}
              className={classes.collpasewrapperInner}
              timeout="auto"
              unmountOnExit>
              {[...(elem?.[elemParamN]?.slice(2) ?? [])]?.map(
                (categoryVal, categoryIdx) => {
                  return (
                    <span
                      key={categoryIdx}
                      className={clsx(
                        classes.gridDropdown,
                        classes.centerVertically,
                        styles?.gridDropdown
                      )}>
                      {itemComponent(categoryVal, categoryIdx)}
                      {shouldAllowEditing && actionIconsList && (
                        <span className={clsx(classes.flex)}>
                          {actionIconsList(
                            categoryVal,
                            categoryVal?.[subElemParamN],
                            [
                              classes.onHover,
                              classes.buttonsSize,
                              classes.buttonsBorder
                            ]
                          )}
                        </span>
                      )}
                    </span>
                  );
                }
              )}
            </Collapse>
          )}
        </>
      ) : (
        elem?.[elemParamN]?.map((categoryVal, categoryIdx) => {
          return (
            <span
              key={categoryIdx}
              className={clsx(
                classes.gridDropdown,
                classes.centerVertically,
                styles?.gridDropdown
              )}
              style={styles?.inlineStyle}>
              {itemComponent(categoryVal, categoryIdx)}
              {shouldAllowEditing && actionIconsList && (
                <span className={clsx(classes.flex)}>
                  {actionIconsList(categoryVal, categoryVal?.[subElemParamN], [
                    classes.onHover,
                    classes.buttonsSize
                  ])}
                </span>
              )}
            </span>
          );
        })
      )}
    </>
  );
};

export default ListItems;
