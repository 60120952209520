import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Sidebar, TopbarP2, Footer } from './components';

import { AuthContext } from 'AuthContext';

import { useStyles } from './StylesMain';
import HelpInfo from 'components/HelpInfo/HelpInfo';
import { getCurrentLanguage } from 'i18n';

import {
  DISTRIBUTE_PARTICIPANTS_TAB,
  REASSIGN_CATEGORIES_TAB,
  CATEGORIES_TAB,
  TATAMIS_TAB
} from 'helpers/constants';

const componentMap = {
  '/event/:id/participants': 'eventParticipants',
  '/event/:id/staff': 'eventStaff',
  '/event/:id/event-setting': {
    [TATAMIS_TAB]: 'eventAssignTatami',
    [CATEGORIES_TAB]: 'eventAssignCategories',
    [REASSIGN_CATEGORIES_TAB]: 'eventDistributeCategories',
    [DISTRIBUTE_PARTICIPANTS_TAB]: 'eventDistributeParticipants'
  },
  '/event/:id/registration': 'eventRegistration',
  '/event/:id/coaches_requests': 'eventCoachesRequests',
  '/event/:id/judges_requests': 'eventJudgesRequests',
  '/event/:id/select-attachment': 'eventSelectAttachment',
  '/event/:id/winners': 'eventWinners',
  '/event/:id/reports': 'eventReports',
  '/account': 'account',
  '/': 'main',
  '/more-apps': 'eventMorApps',
  '/users': 'users',
  '/categories': 'categories',
  '/participants': 'participants',
  '/events': 'event',
  default: 'EmptyStateInfo'
};

const getComponentForPath = (pathname, search) => {
  const searchParams = new URLSearchParams(search);
  const tab = searchParams.get('tab') ?? TATAMIS_TAB;

  for (const pathPattern in componentMap) {
    if (matchDynamicPath(pathname, pathPattern)) {
      const component = componentMap[pathPattern];
      if (typeof component === 'string') {
        return component;
      }
      return component[tab] || component.default;
    }
  }
  return componentMap.default;
};

const matchDynamicPath = (pathname, pattern) => {
  const regex = new RegExp(`^${pattern.replace(/:[^\s/]+/g, '[^/]+')}$`);
  return regex.test(pathname);
};

const Main = (props) => {
  const { children, specificDetails } = props;
  const { viewportWidth, authState } = useContext(AuthContext);
  const { role } = authState;
  const classes = useStyles();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openQuestionMark, setQuestionMarkOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const [DialogContent, setDialogContent] = useState(() => () => null);

  useEffect(() => {
    const loadComponents = async () => {
      const language = getCurrentLanguage();
      const componentName = getComponentForPath(
        location.pathname,
        location.search
      );

      const component = (
        await import(`helpers/helpInfo/${componentName}/${language}`)
      ).default;

      setDialogContent(() => component);
    };

    loadComponents();
  }, [location.pathname, location.search, t]);

  const toggleDrawer = (open) => {
    setOpenSidebar(open);
  };

  const openQuestion = (open) => {
    setQuestionMarkOpen(open);
  };

  const DialogContentComponent = DialogContent;

  return (
    <>
      <TopbarP2
        {...{ specificDetails, viewportWidth, role }}
        onSidebarOpen={() => toggleDrawer(true)}
        onQuestionMarkOpen={() => openQuestion(true)}
      />
      {openQuestionMark && (
        <HelpInfo
          open={openQuestionMark}
          close={() => setQuestionMarkOpen(false)}
          dialogTitle={t('help')}
          dialogContent={<DialogContentComponent />}
          closeButton={() => setQuestionMarkOpen(false)}
        />
      )}
      {openSidebar && (
        <Sidebar
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
          open={openSidebar}
        />
      )}
      <main className={clsx(classes.content, classes.paddingTop)}>
        <div className={classes.body}>{children}</div>
        <Footer className={classes.footer} styleTxt={classes.footerTxt} />
      </main>
    </>
  );
};

export default Main;
