import { Component } from 'react';
import i18n from '../../i18n';

import { changeTitle } from '../../helpers/actions';
import {
  textChangeHandler,
  toggleShow,
  selectOption,
  fetchCountries,
  changeModal,
  onBlur,
  validateForm,
  usersRegisterNewUser,
  fetchReferenceRegion
} from '../../helpers/util';

import SideModal from '../../components/Snackbar/SideModal';
import SignUpFormP2 from '../../components/SignUpFormP2/SignUpFormP2';
import PatternP2 from '../../components/PatternP2/PatternP2';

const formInitialState = {
  first_name: '',
  last_name: '',
  email: '',
  country_id: '',
  password: '',
  confirm_password: ''
};

class SignUp extends Component {
  state = {
    countries: [],
    allRegions: [],
    isSending: false,
    success: false,
    showModal: false,
    open: false,
    showError: {},
    isPasswordVisible: {},
    singUpForm: {},
    singUpErrors: {}
  };

  textChangeHandler = textChangeHandler.bind(this);
  toggleShow = toggleShow.bind(this);
  selectOption = selectOption.bind(this);
  fetchCountries = fetchCountries.bind(this);
  changeModal = changeModal.bind(this);
  onBlur = onBlur.bind(this);
  validateForm = validateForm.bind(this);
  usersRegisterNewUser = usersRegisterNewUser.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);

  componentDidMount() {
    changeTitle(i18n.t('signUp'));

    this.fetchCountries();

    sessionStorage.clear();
  }

  componentDidUpdate() {
    i18n.on('languageChanged', () => {
      changeTitle(i18n.t('signUp'));
    });
  }

  hideSnackBar = () => this.setState({ showModal: false });

  createNewUser = (evt, action) => {
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        singUpForm: {
          ...prevState.singUpForm,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.singUpForm;

        this.validateForm(
          evt,
          formInitialState,
          form,
          'singUpErrors',
          () => {
            this.usersRegisterNewUser(evt, form.tournament_id, form, action);
          },
          () =>
            this.setState({
              showError: {
                email: true,
                confirm_email: true,
                password: true,
                confirm_password: true
              }
            })
        );
      }
    );
  };

  fetchRegionBySelectedCountry = (key) => {
    const { singUpForm } = this.state;
    let cpy = { ...singUpForm };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ singUpForm: cpy });
  };

  render() {
    const {
      singUpForm,
      singUpErrors,
      countries,
      allRegions,
      isSending,
      success,
      showModal,
      modalInfo,
      isPasswordVisible,
      showError
    } = this.state;

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          success={success}
          show={showModal}
          info={modalInfo}
        />
        <PatternP2>
          <SignUpFormP2
            handleChange={(evt) =>
              this.textChangeHandler(
                evt,
                'singUpForm',
                singUpErrors,
                null,
                singUpForm,
                null,
                { input_language: localStorage.getItem('input_language') },
                true // require city
              )
            }
            onBlur={this.onBlur}
            {...{ showError }}
            values={singUpForm}
            usersRegisterNewUser={this.createNewUser}
            {...{ countries }}
            errors={singUpErrors}
            {...{ isSending }}
            toggleShow={this.toggleShow}
            {...{ isPasswordVisible }}
            selectOption={this.selectOption}
            fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
            {...{ allRegions }}
          />
        </PatternP2>
      </>
    );
  }
}
export default SignUp;
