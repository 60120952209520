const styles = () => ({
  flex: {
    display: 'flex'
  },

  maxWidth: {
    '@media (min-width:1024px)': {
      minWidth: 'fit-content'
    }
  },

  maxWidthConfirmDelete: {
    '@media (min-width:1024px)': {
      minWidth: '20em'
    }
  },

  marginTop1: {
    marginTop: '1rem'
  },

  addressText: {
    fontSize: '14px',
    color: '#8996AC'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },

  column: {
    flexDirection: 'column'
  },

  marginBottom05: {
    marginBottom: '.5em'
  },

  marginBottom2: {
    marginBottom: '2rem'
  },

  iframeSize: {
    border: 'none',
    '@media (min-width:1024px)': {
      width: '780px',
      height: '430px'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      width: '500px',
      height: '430px'
    },
    '@media (max-width:767px)': {
      width: '303px',
      height: '303px'
    }
  },

  marginBottom1: {
    marginBottom: '1rem'
  }
});

export default styles;
