import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  paperBorderRadius: {
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },
  paper: {
    backgroundColor: '#fff',
    padding: '10px',
    '@media (min-width:1200px)': {
      padding: '10px'
    }
  },
  heightFitContent: {
    height: 'fit-content'
  },
  formInput: {
    '@media (min-width:901px)': {
      width: 'calc(100%/3)'
    }
  },

  twoInputsInARow: {
    width: 'calc((100%) / 2)'
  },

  threeInputsInARow: {
    width: 'calc((100% - 1rem)/3)'
  },

  fourInputsInARow: {
    width: 'calc((100% - 1.5rem) / 4)'
  },

  sixInputsInARow: {
    width: 'calc((100% - 2.5rem) / 6)'
  },

  coachShrinkedSizeFormInputWidth: {
    '@media (max-width:900px)': {
      width: 'calc(100% + 5rem)',
      marginLeft: '-5rem'
    }
  },
  maxHeightAutocomplete: {
    '& .MuiFormControl-root': {
      height: '64px'
    }
  },

  maxHeightTxtInput: {
    height: '4em'
  },

  smallViewportFormWithoutImg: {
    width: 'calc(100% - 5rem)'
  },

  assignCategoryFilters: {
    width: 'calc(100% - 5em)'
  },

  fullViewportFormWithoutImg: {
    width: 'calc(100% - 7.5rem)'
  },

  inlineFlex: {
    display: 'inline-flex'
  },
  mainContent: {
    '@media (min-width:901px)': {
      display: 'flex'
    }
  },
  container: {
    padding: '10px 20px'
  },
  categoriesWrapper: {
    height: '150px',
    overflow: 'auto'
  },
  mainTitle: {
    textAlign: 'center'
  },
  bold: {
    fontWeight: 'bold'
  },
  center: {
    alignItems: 'center'
  },
  centerHorizontally: {
    justifyContent: 'center'
  },
  icon: {
    cursor: 'pointer',
    backgroundColor: '#E9ECF6',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    },
    padding: '5px',
    borderRadius: '4px',
    color: '#8996AC',
    height: '32px',
    width: '32px',
    border: '1px solid rgba(137, 150, 172, 0.7)',
    '&:hover': {
      opacity: 0.6
    }
  },
  rotate180: {
    transform: 'rotate(180deg)'
  },

  formImg: {
    width: '8.3em',
    height: '10.2em',
    marginRight: '.5rem'
  },

  fullViewportImg: {
    width: '7em',
    height: '9.7em',
    '@media (max-width:900px)': {
      width: '4.5em',
      height: '6em'
    },
    marginRight: '.5rem'
  },

  fullViewportOneHalfOfParentWidth: {
    '@media (min-width:901px)': {
      width: 'calc((100% - .5rem)/2)'
    }
  },

  partCondensedView: {
    width: 'calc(100% + 7.7rem)',
    marginLeft: '-7.7rem'
  },

  widthLargeInput: {
    width: 'calc(100% - 100%/4)'
  },

  widthLargeInput3: {
    width: 'calc(100% - 100%/3)'
  },

  addCategoriesWrapper: {
    paddingBottom: 0,
    borderBottom: '1px solid rgba(0, 29, 61, 0.4)'
  },
  fullWidth: {
    width: '100%'
  },
  label: {
    '& .MuiInputLabel-outlined': {
      lineHeight: 0
    }
  },
  txtInput: {
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-outlined': {
      lineHeight: 0
    }
  },
  outlined: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 0
    }
  },
  row: {
    flexDirection: 'row'
  },
  marginTop: {
    marginTop: '20px'
  },
  endAdornment: {
    '& .MuiOutlinedInput-adornedEnd': {
      padding: 0
    },
    '& .MuiIconButton-root': {
      paddingRight: '10px'
    },
    '@media (max-width:900px)': {
      '& .MuiInputAdornment-positionEnd': {
        display: 'none'
      }
    }
  },
  autoAlign: {
    margin: 'auto'
  },
  padding: {
    padding: '10px'
  },
  left: {
    marginLeft: 'auto',
    marginBottom: '10px'
  },
  left_save: {
    marginLeft: 'auto'
  },
  classNameHideTableSpecificWidthFirstView: {
    '@media (max-width:1400px)': {
      display: 'none'
    }
  },
  classNameHideCondensedTableSpecificWidthFirstView: {
    '@media (min-width:1401px)': {
      display: 'none'
    }
  },
  classNameHideTableSpecificWidth: {
    '@media (max-width:600px)': {
      display: 'none'
    }
  },
  classNameHideCondensedTableSpecificWidth: {
    '@media (min-width:601px)': {
      display: 'none'
    }
  },

  endHorizontally: {
    justifyContent: 'end'
  },
  btn: {
    width: '8.5em'
  },

  overlappingLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    right: '40px',
    lineHeight: 'unset !important',
    marginTop: '-10px'
  },

  labelShrinked: {
    right: 'unset'
  },
  flex: {
    display: 'flex'
  },
  lang: {
    backgroundColor: theme.palette.primary.main,
    height: '40px',
    borderRadius: '4px',
    color: '#fff',
    fontWeight: 500,
    '& .MuiAutocomplete-input:first-child': {
      textAlign: 'center'
    },
    '& .MuiSvgIcon-root': {
      color: '#fff'
    },
    '& .MuiAutocomplete-endAdornment': {
      right: '30%'
    }
  },
  margin20: {
    margin: '0 20px'
  },
  marginBottom10: {
    marginBottom: '10px'
  },
  relative: {
    position: 'relative'
  },
  maxWidthPartForm: {
    '@media (min-width:901px)': {
      margin: '0px 20px 20px 20px',
      position: 'sticky',
      top: '20px',
      maxWidth: '29em'
    }
  },
  copyTxt: {
    position: 'fixed',
    bottom: '15px',
    width: '50%'
  },
  column: {
    flexDirection: 'column'
  },

  marginLeft05: {
    marginLeft: '.5rem'
  },

  marginRight05: {
    marginRight: '.5rem'
  },

  marginMiddleInput: {
    '@media (min-width:901px)': {
      margin: '0 .5rem'
    }
  },

  fullViewportMarginLeftRight05: {
    margin: '0 .5rem'
  },

  labelNum: {
    paddingRight: '1.5px'
  },
  linkTxt: {
    cursor: 'pointer',
    color: '#0000FF',
    '&:hover': {
      color: 'rgba(0, 0, 255,0.5)'
    }
  },
  contentMarginForm: {
    marginBottom: '40px'
  },
  noEvent: {
    pointerEvents: 'none'
  },

  disabled: {
    opacity: 0.5
  },

  marginBottom: {
    marginBottom: '20px'
  },
  categoryTypeFilter: {
    margin: '20px',
    marginLeft: 0
  },
  categories: {
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%)',
    padding: '5px 20px 0 20px'
  },
  asterisk: {
    '& .MuiFormLabel-asterisk': {
      color: '#005FEE'
    }
  },
  issue: {
    color: '#ff0000'
  },
  displayNone: {
    display: 'none'
  },
  condensedView: {
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },
  bottomBtnsCondensedView: {
    '@media (max-width:1300px)': {
      flexDirection: 'column'
    }
  },
  classNameCellWidth: {
    width: '20%'
  },
  marginRight: {
    marginRight: '5px'
  },

  marginRight20: {
    marginRight: '20px'
  },
  inputRightMargin: {
    paddingRight: '20px'
  },

  history: {
    width: 'calc(100% - 100%/3 - 0.2rem)'
  },

  block: {
    display: 'block'
  },

  scrollToTopBtn: {
    display: 'none',
    bottom: '3.5rem',
    right: '30px',
    zIndex: 10,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 29, 61, 0.5)',
    width: '3em',
    height: '3em',
    position: 'fixed',
    padding: '5px',
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  textare: {
    '& .MuiOutlinedInput-multiline': {
      padding: 0
    }
  },
  labelCheckbox: {
    '&.MuiFormControlLabel-root': {
      marginRight: 0
    }
  },

  stickyBtn: {
    position: 'sticky',
    bottom: 0,
    background: '#fff',
    width: 'calc(100% + 7.7rem)',
    marginLeft: '-7.7rem',
    zIndex: 1,
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
    boxShadow: '0px -0.5px 0px rgba(0, 29, 61, 0.8)'
  },

  sendApplicationWrapper: {
    position: 'sticky',
    bottom: 0,
    marginLeft: 'auto',
    height: '4em',
    padding: '0.5rem',
    alignItems: 'center',
    margin: '20px',
    width: 'fit-content'
  },

  sendApplicationBtn: {
    width: '20em',
    transition: 'all 2s ease'
  },

  transform: {
    height: 0,
    width: 0,
    padding: 0,
    background: 'transparent',
    boxShadow: 'none',
    fontSize: 0
  },

  anglesLeftIc: {
    width: '3em',
    color: '#fff',
    padding: '5px',
    borderRadius: '50%',
    border: '1px solid #001D3D',
    background: '#fff',
    '&:hover': {
      opacity: '0.6',
      cursor: 'pointer'
    }
  },

  exportBtn: {
    '@media (min-width:901px)': {
      width: 'fit-content',
      marginLeft: '0.5rem'
    }
  },

  checkboxPaddingLeft: {
    '&.MuiCheckbox-root': {
      paddingLeft: 0
    }
  },

  noMarginCheckbox: {
    '&.MuiFormControlLabel-root': {
      marginRight: 0,
      marginLeft: 0
    }
  },

  judgeCheckbox: {
    transform: 'scale(1.3)'
  },

  noPaddingCheckbox: {
    '&.MuiCheckbox-root': {
      padding: 0
    }
  },

  innerPartForm: {
    '@media (min-width:901px)': {
      maxHeight: 'calc(100vh - 6.5em)',
      overflowY: 'auto',
      paddingTop: '1rem',
      paddingRight: '10px'
    }
  },
  marginRight: {
    '@media (min-width:901px)': {
      marginRight: '20px'
    }
  },

  innerPagesWrapper: {
    margin: '-1rem 0 1rem 1.25rem',
    color: '#2D63EE'
  },

  individualInnerPage: {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    color: '#000',
    border: '1px solid #ccc',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#001D3D',
      color: '#fff'
    }
  },

  selectedInnerPage: {
    backgroundColor: '#001D3D',
    color: '#fff'
  },

  inlineBlock: {
    display: 'inline-block'
  },

  marginBottom30: {
    marginBottom: '30px'
  },

  marginRight65: {
    marginRight: '0.65rem'
  },

  marginTop05: {
    marginTop: '0.5rem'
  },

  maxColumnWidth: {
    maxWidth: '150px'
  },

  marginBottom55: {
    marginBottom: '55px'
  },
  gridIconsArea: {
    gridArea: 'gridIconsArea',
    display: 'flex'
  },
  grid: {
    display: 'grid',
    gap: '24px',
    '@media (min-width:768px)': {
      gridTemplateColumns: '600px 90px',
      gridTemplateAreas: `
        "gridFilterArea gridIconsArea"
      `
    },
    '@media (max-width:767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        "gridFilterArea"
        "gridIconsArea"
      `
    }
  },
  gridFilterArea: {
    gridArea: 'gridFilterArea',
    '@media (min-width:768px)': {
      width: '600px',
      marginBottom: '24px'
    },
    '@media (max-width:767px)': {
      width: 'auto',
      marginTop: '15px'
    }
  }
}));
