import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { SwipeableDrawer, Avatar } from '@material-ui/core';

import { ReactComponent as Exit } from 'assets/img/new_close_modal.svg';
import { ReactComponent as Myprofile } from 'assets/img/my_profile_menu.svg';
import { ReactComponent as Users } from '../../../../assets/img/users_menu.svg';
import { ReactComponent as Tournament } from '../../../../assets/img/events_menu.svg';
import { ReactComponent as Participants } from '../../../../assets/img/my_team_menu.svg';
import { ReactComponent as LogOut } from '../../../../assets/img/logout_menu.svg';
import { ReactComponent as Organisation } from '../../../../assets/img/federation_menu.svg';

import { ReactComponent as Tablo } from '../../../../assets/img/tablo_menu.svg';
import { ReactComponent as CardMembershipIcon } from '../../../../assets/img/categories_menu.svg';
import { ReactComponent as Logo } from '../../../../assets/img/logo_white.svg';
import { ReactComponent as Download } from '../../../../assets/img/apps_menu.svg';
import { ReactComponent as Reference } from '../../../../assets/img/reference.svg';
import { ReactComponent as Logs } from '../../../../assets/img/logs.svg';

import { DEFAULT_AVATAR, PRODUCT_LANG } from '../../../../helpers/constants';
import { onLogout } from 'helpers/util';
import { roleName } from 'helpers/selectors';

import { AuthContext } from '../../../../AuthContext';
import { SidebarNav } from './components';

import { useStyles } from './StylesSideBar';

const Sidebar = (props) => {
  const { open, onClose, onOpen } = props;

  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const authContext = useContext(AuthContext);
  const role = authContext.authState.role;
  const currentLang = localStorage.getItem('i18nextLng') || 'en';

  const onSignOut = () => {
    sessionStorage.clear();
    onLogout(authContext, () =>
      navigate('/sign-in', {
        state: { prevUrl: location.pathname }
      })
    );
  };

  const switchLang = (evt, item) => {
    i18n.changeLanguage(item.lang);
  };

  const adminPages = [
    {
      title: t('profile'),
      href: '/account',
      icon: Myprofile,
      id: 'sidebar-profile-link'
    },
    {
      title: t('events'),
      href: '/events',
      icon: Tournament,
      id: 'sidebar-events-link'
    },
    {
      title: t('users'),
      href: '/users',
      icon: Users,
      id: 'sidebar-users-link'
    },
    {
      title: t('athletes'),
      href: '/participants',
      icon: Participants,
      id: 'sidebar-participants-link'
    },
    {
      title: t('categories'),
      href: '/categories',
      icon: CardMembershipIcon,
      id: 'sidebar-categories-link'
    },
    {
      title: t('reference'),
      href: '/reference',
      icon: Reference,
      id: 'sidebar-reference-link'
    },
    {
      title: t('logs'),
      href: '/logs',
      icon: Logs,
      id: 'sidebar-logs-link'
    },
    {
      title: t('tablo'),
      href: '/tablo',
      icon: Tablo,
      id: 'sidebar-tablo-link'
    },
    {
      title: t('moreApps'),
      href: '/more-apps',
      icon: Download,
      id: 'sidebar-settings-link'
    },
    {
      title: t('logout'),
      onClick: onSignOut,
      icon: LogOut,
      id: 'sidebar-logout'
    }
  ];
  const masterPages = [
    {
      title: t('profile'),
      href: '/account',
      icon: Myprofile,
      id: 'sidebar-profile-link'
    },
    {
      title: t('events'),
      href: '/events',
      icon: Tournament,
      id: 'sidebar-events-link'
    },
    {
      title: t('myTeam'),
      href: '/participants',
      icon: Participants,
      id: 'sidebar-participants-link'
    },
    {
      title: t('myOrganisation'),
      href: '/organisation',
      icon: Organisation,
      id: 'sidebar-organisation-link'
    },
    {
      title: t('categories'),
      href: '/categories',
      icon: CardMembershipIcon,
      id: 'sidebar-categories-link'
    },
    {
      title: t('users'),
      href: '/users',
      icon: Users,
      id: 'sidebar-users-link'
    },
    {
      title: t('moreApps'),
      href: '/more-apps',
      icon: Download,
      id: 'sidebar-settings-link'
    },
    {
      title: t('logout'),
      onClick: onSignOut,
      icon: LogOut,
      id: 'sidebar-logout'
    }
  ];
  const coachPages = [
    {
      title: t('profile'),
      href: '/account',
      icon: Myprofile,
      id: 'sidebar-profile-link'
    },
    {
      title: t('myTeam'),
      href: '/participants',
      icon: Participants,
      id: 'sidebar-participants-link'
    },
    {
      title: t('logout'),
      onClick: onSignOut,
      icon: LogOut,
      id: 'sidebar-logout'
    }
  ];

  const recorderPages = [
    {
      title: t('logout'),
      onClick: onSignOut,
      icon: LogOut,
      id: 'sidebar-logout'
    }
  ];
  const operatorPages = [
    {
      title: t('moreApps'),
      href: '/more-apps',
      icon: Download,
      id: 'sidebar-settings-link'
    },
    {
      title: t('logout'),
      onClick: onSignOut,
      icon: LogOut,
      id: 'sidebar-logout'
    }
  ];

  const navItems = () => {
    switch (role) {
      case 'administrator':
        return adminPages;
      case 'master':
        return masterPages;
      case 'operator':
        return operatorPages;
      case 'recorder':
        return recorderPages;
      default:
        return coachPages;
    }
  };

  const avatarIcon = localStorage.getItem('avatar');
  const isRuLang = localStorage.getItem('i18nextLng') === 'ru';
  const firstN = localStorage.getItem('first_name');
  const lastN = localStorage.getItem('last_name');

  const FIRST_NAME = !isRuLang ? firstN : lastN;
  const LAST_NAME = !isRuLang ? lastN : firstN;

  return (
    <SwipeableDrawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      {...{ onClose }}
      {...{ open }}
      {...{ onOpen }}>
      <span className={clsx(classes.flex, classes.profileWrapper)}>
        <Avatar
          alt={t(t('profile'))}
          className={clsx(classes.avatar, classes.onHover)}
          component={Link}
          src={avatarIcon ? avatarIcon : DEFAULT_AVATAR}
          to="/account"
          id="sidebar_avatar"
          onClick={onClose}
        />
        <span className={classes.profileDetailsWrapper}>
          {role !== 'operator' && role !== 'recorder' ? (
            <Link
              onClick={onClose}
              className={clsx(classes.flex, classes.onHover)}
              to="/account">
              <span className={clsx(classes.name)}>
                {[FIRST_NAME, LAST_NAME].join(' ')}
              </span>
            </Link>
          ) : (
            <span className={clsx(classes.name)}>
              {[FIRST_NAME, LAST_NAME].join(' ')}
            </span>
          )}
          <span className={clsx(classes.flex, classes.role, classes.text)}>
            {roleName(role)}
          </span>
        </span>
        <Exit onClick={onClose} className={classes.onHover} />
      </span>
      <SidebarNav pages={navItems()} {...{ onClose }} />
      <span className={classes.langWrapper}>
        {PRODUCT_LANG.map((el, elIdx) => (
          <span
            onClick={(evt) => switchLang(evt, el)}
            className={clsx(
              classes.flex,
              classes.lang,
              currentLang === el.lang && classes.activeLang
            )}
            key={elIdx}>
            {el.label}
          </span>
        ))}
      </span>
      <Logo className={classes.logo} />
    </SwipeableDrawer>
  );
};

export default Sidebar;
