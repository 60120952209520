import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  paper: {
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },
  informativeMsg: {
    fontSize: '14px'
  },

  radioGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, max-content)',
    gridColumnGap: '1rem'
  },

  container: {
    alignItems: 'center',
    border: '1px solid #8996AC',
    borderRadius: '4px',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    padding: '1.4rem'
  },

  dropFile: {
    height: '10em'
  },

  ic: {
    width: '1.876em',
    height: 'auto'
  },

  centerVertically: {
    alignItems: 'center'
  },

  centerHorizontally: {
    justifyContent: 'center'
  },

  btnUpload: {
    width: '20em',
    background: '#8996AC',
    color: '#fff'
  },

  plusIc: {
    marginRight: '.5rem',
    fontSize: '26px'
  },

  marginRight0dot65: {
    marginRight: '0.65rem'
  },

  textLink: {
    color: theme.palette.text.link,
    '&:hover': {
      opacity: 0.8
    }
  },
  browseText: {
    paddingLeft: '5px'
  },
  removeButton: {
    marginLeft: '15px'
  },
  rejectFile: {
    color: '#fff',
    backgroundColor: '#ff8080'
  },
  marginTop: {
    marginTop: '20px'
  },
  alertWrapper: {
    marginBottom: '10px',
    justifyContent: 'center'
  },
  alertBanner: {
    width: '60%'
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '350px',
    textOverflow: 'ellipsis',
    display: 'block'
  },

  wrapper: {
    display: 'grid',
    gap: '1rem'
  }
}));

export default useStyles;
