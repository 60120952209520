import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  left: {
    marginLeft: 'auto'
  },
  iconWrapper: {
    height: '1.5em'
  },
  icon: {
    width: '1.5em',
    height: '1.5em'
  }
}));
