import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import ActivationInfo from '../../components/ActivateAccout/ActivateAccout';
import LoadingState from '../../components/LoadingState/LoadingState';
import { activateAccount, changeModal } from '../../helpers/util';

class ActivateAccount extends Component {
  state = {
    userToken: this.props.match.params.userToken,
    isLoading: true,
    success: false
  };
  activateAccount = activateAccount.bind(this);
  changeModal = changeModal.bind(this);

  componentDidMount() {
    this.activateAccount();
  }
  render() {
    const { isLoading, success } = this.state;
    return (
      <div>
        {isLoading ? <LoadingState /> : <ActivationInfo success={success} />}
      </div>
    );
  }
}
export default withRouter(ActivateAccount);
