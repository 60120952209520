import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  gridGap1: {
    '@media (min-width:1024px)': {
      display: 'grid',
      height: 'fit-content',
      gap: '1rem'
    },
    '@media (max-width:1023px)': {
      display: 'contents'
    }
  },

  about: {
    '@media  (min-width:768px)': {
      gridArea: 'about'
    }
  },

  logo: {
    '@media  (min-width:768px)': {
      gridArea: 'logo'
    }
  },

  gridElemWrapper: {
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateAreas: "'logo about . . .'",
      gridTemplateColumns: '1fr 2fr repeat(2, 1fr) 13.75em',
      gap: '2.625rem'
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateAreas: "'logo logo logo''about about about''. . .''. . .'",
      gridTemplateColumns: 'repeat(3, calc((100% - 1.5rem)/3))',
      gap: '1.5rem'
    },
    '@media (max-width:767px)': {
      gap: '1rem'
    }
  },

  otherVersionBtn: {
    background: '#8996AC',
    color: '#fff',
    '&:hover': {
      background: '#8996AC'
    }
  },

  marginTop1: {
    '@media (min-width:1024px)': {
      marginTop: '1rem'
    }
  },

  paper: {
    backgroundColor: '#fff',
    padding: '1.25rem',
    borderRadius: '10px',
    marginBottom: '40px',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  fullWidth: {
    width: '100%'
  },

  padding05: {
    padding: '0.5rem 0'
  },

  onHover: {
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.9'
    }
  },

  actionBtnModal: {
    '@media (min-width:768px)': {
      marginLeft: 'auto',
      width: '18em'
    },
    '@media (max-width:767px)': {
      width: '100%'
    }
  },

  weight: {
    fontWeight: 500
  },

  logoMobile: {
    height: 'auto',
    display: 'block'
  },

  importContainer: {
    display: 'flex',
    flexDirection: 'column'
  },

  radio: {
    marginRight: '0.3rem'
  },

  labelsForRadioButton: {
    paddingBottom: '0.3rem',
    marginBottom: '0.7rem',
    borderBottom: '1px solid #8996AC',
    '& .MuiTypography-body1': {
      width: '100%'
    }
  },

  modalInnerContentHeader: (props) => ({
    display: 'grid',
    gridTemplateColumns:
      props?.role !== 'administrator' ? '1fr 5em' : '1fr 5em 2em',
    gap: '0.5rem',
    marginBottom: '1rem'
  }),

  modalInnerContentBody: (props) => ({
    display: 'grid',
    gridTemplateColumns:
      props?.role !== 'administrator' ? '1fr 5.5em' : '1fr 5.5em 2em',
    gap: '0.5rem'
  }),

  modalWidth: {
    '@media (min-width:1024px)': {
      minWidth: '40em'
    }
  }
}));
