import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useStyles } from './StylesPagesHeader';

const PagesHeader = (props) => {
  const { children, onClick, className, shouldHideCloseBtn, hasSpecificFont } =
    props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <span className={clsx(classes.flex, className, classes.formTitle)}>
      <span
        className={clsx(
          classes.fullWidth,
          hasSpecificFont ? classes.formTitleTxtException : classes.formTitleTxt
        )}>
        {children}
      </span>
      {!shouldHideCloseBtn && (
        <Tooltip title={t('close')}>
          <CloseIcon
            className={clsx(classes.icon, classes.closeIcon)}
            {...{ onClick }}
          />
        </Tooltip>
      )}
    </span>
  );
};

export default PagesHeader;
