import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useStyles } from './styles';
import { TextField, Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import Button from '../Buttons/ActionButtons';
import BlueCheckbox from '../../assets/img/bluecheckbox.png';

const NewPasswordForm = ({
  resetPassword,
  textChange,
  errors,
  isPasswordVisible,
  showPassword,
  onBlur,
  showError
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <span className={classes.fonSize20} >{t('chooseNewPassword')}</span>
      <TextField
        label={t("password")}
        name="password"
        type={!isPasswordVisible.password ? 'password' : 'text'}
        onChange={textChange}
        onBlur={onBlur}
        variant="outlined"
        className={clsx(classes.inputField, classes.marginTop25)}
        error={showError.password && Boolean(errors.password)}
        helperText={showError.password && errors.password}
        InputProps={{
          className: classes.endAdornment,
          endAdornment: (
            <div
              id="password"
              className={clsx(
                classes.iconWrapper,
                isPasswordVisible.password && classes.icon
              )}
              onClick={showPassword}>
              <VisibilityOutlinedIcon className={classes.outlinedIcon} />
            </div>
          )
        }}
      />
      <TextField
        label={t("confirmPassword")}
        name="confirm_password"
        type={!isPasswordVisible.confirm_password ? 'password' : 'text'}
        onChange={textChange}
        onBlur={onBlur}
        variant="outlined"
        className={clsx(classes.inputField, classes.marginTop10)}
        error={showError.confirm_password && Boolean(errors.confirm_password)}
        helperText={showError.confirm_password && errors.confirm_password}
        InputProps={{
          className: classes.endAdornment,
          endAdornment: (
            <div
              id="confirm_password"
              className={clsx(
                classes.iconWrapper,
                isPasswordVisible.confirm_password && classes.icon
              )}
              onClick={showPassword}>
              <VisibilityOutlinedIcon className={classes.outlinedIcon} />
            </div>
          )
        }}
      />
      <span className={clsx(classes.disabledBtn, classes.marginTop10)}>
        <Button
          className={classes.backgroundColor}
          onClick={resetPassword}
          label={t('save')}
          isSaveBtn={true}
          disabled={Object.keys(errors).length > 0}
        />
      </span>
      <Link component={RouterLink} to="/sign-in">
        <div className={clsx(classes.wrapper, classes.marginTop12)}>
            <img className={classes.marginRight5} src={BlueCheckbox} alt="checkbox"/>
            <span className={clsx(classes.black, classes.fontSize14)}>{t('logIn')}</span>
        </div>
      </Link>
    </div>
  );
};
export default NewPasswordForm;
