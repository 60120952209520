import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  condensedView: {
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },

  marginBottom2: {
    marginBottom: '2rem'
  },

  registrationStatus: {
    fontWeight: '400',
    lineHeight: '1.5rem'
  },

  goBackArrow: {
    width: 'fit-content'
  },

  heightFitContent: {
    height: 'fit-content'
  },

  left: {
    marginLeft: 'auto'
  },

  paper: {
    backgroundColor: '#fff',
  },

  padding10: {
    padding: '10px'
  },

  padding3: {
    padding: '3px'
  },

  halfWidth: {
    width: 'calc(100%/2 - .5rem)'
  },

  paddingRight: {
    paddingRight: '.65rem'
  },

  paddingLeft: {
    paddingLeft: '.65rem'
  },

  column: {
    flexDirection: 'column'
  },

  fullWidth: {
    width: '100%'
  },

  img: {
    objectFit: 'cover',
    width: '5.667em',
    border: '2px solid #E9ECF6',
    height: '7.5em',
    borderRadius: '0.5rem'
  },

  panelWithoutImgBtn: {
    width: 'calc(100% - 11em)'
  },

  marginLeftRight: {
    padding: '0 .5em'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  marginBottom0Dot3: {
    marginBottom: '.3rem'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  wordBreak: {
    wordBreak: 'break-all'
  },

  labelName: {
    color: '#8996AC',
    fontSize: '0.7rem',
    fontWeight: 500,
    marginBottom: '0.1rem'
  },

  borderRadius: {
    borderRadius: '.5rem'
  },

  textAlign: {
    textAlign: 'center'
  },

  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  },

  marginRight1Dot5: {
    marginRight: '1.5rem'
  },

  title: {
    fontSize: '14px'
  },

  marginBottom5: {
    marginBottom: '5px'
  }
}));
