import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },

  categoriesPanel: {
    padding: '1rem',
    border: '2px solid rgb(209, 214, 221)',
    minWidth: '7.5em'
  },

  width30: {
    width: '30em'
  },

  btnHover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  centerHorizontally: {
    justifyContent: 'center'
  },

  fullWidth: {
    width: '100%'
  },

  fullHeight: {
    height: '100%'
  },

  rotate270: {
    transform: 'rotate(270deg)'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  rotate90: {
    transform: 'rotate(90deg)'
  },

  dropdownIc: {
    border: '2px solid rgb(77, 77, 77)',
    borderLeft: 'none',
    height: '100%'
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  },

  minHeight: {
    minHeight: '15em'
  },

  centerVertically: {
    alignItems: 'center'
  },

  alignLeft: {
    marginLeft: 'auto',
    marginRight: '40px',
    alignItems: 'center',
    flexDirection: 'row-reverse'
  },

  selectAllCheckbox: {
    justifyContent: 'end',
    marginRight: '1.1rem'
  },

  filterBtn: {
    border: '1px solid #B5BAC2',
    width: '100%',
    height: '100%',
    minHeight: '48px',
    color: '#FFFFFF',
    backgroundColor: '#8996AC',
    fontWeight: 700,
    borderRadius: '0.3rem 0.3rem 0 0',
    '&:hover': {
      color: '#262441'
    }
  },

  noEvent: {
    pointerEvents: 'none'
  },

  fontSize08: {
    fontSize: '0.8rem'
  },

  filterWrapperPanel0: {
    width: '40em'
  },

  filterWrapperPanel1: {
    width: '44em'
  },

  filterSummary: {
    flexWrap: 'wrap',
    justifyContent: 'center'
  },

  activeBtn: {
    background: '#B5BAC2'
  },

  marginRight0: {
    marginRight: 0
  },

  marginRight05: {
    marginRight: '0.5rem'
  },

  marginLeft02: {
    marginLeft: '0.2rem'
  },

  padding0: {
    padding: 0
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  marginLeft15: {
    marginLeft: '1.5rem'
  },

  column: {
    flexDirection: 'column'
  },

  padding05: {
    padding: '0.5rem'
  },

  search: {
    marginRight: 0,
    marginLeft: 0
  },

  actionBtn: {
    maxWidth: '4em',
    overflow: 'hidden',
    height: 'calc((100% - 0.5rem)/2)',
    color: '#546e7a',
    marginLeft: '1rem',
    marginRight: '1rem',
    border: '2px solid rgb(209, 214, 221)',
    padding: '0 1rem',
    alignItems: 'center'
  },

  marginLeft2: {
    marginLeft: '2rem'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },

  pointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  categoryWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '7px'
  },

  paddingLeft05: {
    paddingLeft: '0.5rem'
  },

  categoryFont: {
    fontSize: '14px'
  },

  padding065: {
    padding: '0.65rem'
  },

  backGroundGrey: {
    backgroundColor: '#8996AC'
  },

  arrowContainer: {
    color: '#FFFFFF',
    borderRadius: '0 7px 7px 0'
  },

  participant_wrapper: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    borderRadius: '7px',
    border: 'solid 1px #8996AC',
    padding: '11px',
    fontSize: '14px'
  },

  marginBottom5: {
    marginBottom: '5px'
  },

  participant_name: {
    width: '100%'
  },

  justifyContent: {
    justifyContent: 'space-between'
  },

  fullWidth: {
    width: '100%'
  },

  marginLeft50: {
    marginLeft: '50px'
  },

  marginLeft80: {
    marginLeft: '80px'
  },

  marginBottom10: {
    marginBottom: '10px'
  },

  clearIcon: {
    backgroundColor: '#FFFFFF',
    color: '#8996AC',
    borderRadius: '2px'
  },

  center: {
    justifyContent: 'center'
  },

  flexGrow1: {
    flexGrow: 1
  },

  fullWidth: {
    width: '100%'
  }
});
