import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  left: {
    marginLeft: 'auto'
  },
  expandLess: {
    transform: 'rotate(180deg)'
  },
  column: {
    flexDirection: 'column'
  },
  paper: {
    margin: '20px 0',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    fontWeight: 'bold',
    fontSize: '20px',
    transitionDuration: '0.8s',
    alignContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    }
  },
  relative: {
    position: 'relative'
  },
  marginBottom: {
    marginBottom: '1em'
  },
  kataRoundTitle: {
    borderRadius: '4px',
    justifyContent: 'center',
    height: '2em',
    padding: '0.5em',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  kataFighterWrapper: {
    backgroundColor: theme.palette.white,
    border: '1px solid #A6A6A6',
    boxSizing: 'border-box',
    borderRadius: '4px',
    marginBottom: '.5em'
  },
  kataFighter: {
    '@media (max-width:901px)': {
      width: 'calc(100% - 40%)'
    },
    '@media (min-width:901px)': {
      width: 'calc(100% - 25%)'
    },
    borderLeft: '1px solid #99A5B1',
    borderRight: '1px solid #99A5B1'
  },
  score: {
    width: 'calc(100%/5)',
    height: '2em',
    border: '1px solid #001D3D',
    boxSizing: 'border-box',
    borderRadius: '3px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  minmaxScore: {
    background: '#99A5B1'
  },
  kataName: {
    padding: '.5em',
    borderBottom: '1px solid #99A5B1'
  },
  orderNum: {
    padding: '0.5em',
    width: '10%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  result: {
    '@media (max-width:900px)': {
      width: '30%'
    },
    '@media (min-width:901px)': {
      width: '15%'
    }
  },
  resultItem: {
    padding: '.5em'
  },
  item: {
    height: '2em',
    borderTop: '1px solid #99A5B1',
    bottom: 0
  },
  sum: {
    height: '2em',
    borderTop: '1px solid #99A5B1',
    position: 'absolute',
    bottom: 0,
    width: '100%'
  },
  place: {
    height: 'calc(100% - 2em)',
    alignItems: 'center'
  },
  kataWinners: {
    backgroundColor: theme.palette.white,
    boxSizing: 'border-box',
    border: '1px solid #99A5B1',
    borderRadius: '4px'
  },
  kataWinner: {
    padding: '.5em',
    width: '100%',
    alignItems: 'center'
  },
  borderBottom: {
    borderBottom: '1px solid #99A5B1'
  }
}));
