import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      overflowX: 'hidden'
    }
  },
  paper: {
    backgroundColor: '#fff',
    marginLeft: 0,
    '@media (min-width:1024px)': {
      margin: '1rem 1rem 1rem 0',
      padding: '1em',
      width: '25em',
      borderRadius: '7px',
      height: '28em'
    },
    '@media (min-width:481px) and (max-width:1023px)': {
      display: 'flex',
      margin: '1rem 1rem 1rem 0',
      width: '25em',
      padding: '.65em'
    },
    '@media (max-width:480px)': {
      width: 'calc(100vw - 3em)',
      marginTop: '1em',
      padding: '.65em'
    },
    borderRadius: '4px',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  noOwnerBorder: {
    border: `3px solid ${theme.palette.info.dark}`
  },

  marginLeft10: {
    marginLeft: '10px'
  },
  txtAlignCenter: {
    textAlign: 'center'
  },
  flex: {
    display: 'flex'
  },
  icon: {
    width: '2.2rem',
    height: '2.2rem',
    marginRight: '1rem',
    color: '#001D3D'
  },
  icTxt: {
    '@media(max-width:767px)': {
      fontSize: '14px'
    }
  },
  wrap: {
    flexWrap: 'wrap'
  },
  bold: {
    fontWeight: 700
  },
  verticallyCenter: {
    alignItems: 'center'
  },
  marginRight05: {
    marginRight: '.5em'
  },
  marginRight1: {
    marginRight: '1em'
  },
  marginBottom1: {
    marginBottom: '1em'
  },
  tournamentTypeTitle: {
    color: '#FFFFFF',
    fontSize: '20px',
    borderRadius: '5px',
    backgroundColor: '#8996AC',
    alignItems: 'center',
    '@media (max-width:900px)': {
      fontSize: '18px'
    }
  },
  marginLeftCenter: {
    marginLeft: 'auto'
  },
  padding10: {
    padding: '10px'
  },
  fullWidth: {
    width: '100%'
  },
  clockSvg: {
    '@media (min-width:768px)': {
      height: '14em'
    },
    '@media (max-width:767px)': {
      height: '11em'
    }
  },
  img: {
    maxHeight: '14em',
    width: '10em',
    objectFit: 'cover',
    border: '1px solid rgba(137, 150, 172, 0.7)',
    borderRadius: '5px',
    '@media (max-width: 767px)': {
      width: '8.5em',
      height: '11.25em'
    },
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8
    }
  },
  spinner: {
    height: '20em',
    margin: 'auto'
  },
  column: {
    flexDirection: 'column'
  },
  cardsWrapperOnly: {
    overflow: 'hidden'
  },
  empty: {
    width: '100%'
  },
  day: {
    top: '30%',
    left: '15%',
    '@media(max-width:767px)': {
      left: '10%'
    }
  },
  hour: {
    top: '30%',
    right: '15%',
    '@media(max-width:767px)': {
      right: '10%'
    }
  },
  min: {
    bottom: '17%',
    left: '15%',
    '@media(max-width:767px)': {
      left: '10%'
    }
  },
  sec: {
    bottom: '17%',
    right: '15%',
    '@media(max-width:767px)': {
      right: '10%'
    }
  },
  timerDigits: {
    width: '4em',
    position: 'absolute',
    color: '#fff'
  },
  clockWrapper: {
    '@media(max-width:767px)': {
      marginRight: '-.65em'
    },
    height: '100%',
    justifyContent: 'center'
  },

  relative: {
    position: 'relative'
  },

  noOwnerInnerIc: {
    position: 'absolute',
    color: '#FF0000',
    fontWeight: 700,
    bottom: '1rem',
    right: '0.8rem'
  },

  wrapper: {
    position: 'relative'
  },
  activeArrow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#001D3D'
    }
  },
  arrow: {
    height: '2.2rem',
    width: '2.2rem',
    backgroundColor: '#FFFFFF',
    color: '#8996AC',
    borderRadius: '5px',
    transition: '.3s',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      opacity: 0.6
    }
  },
  disableIcon: {
    cursor: 'not-allowed',
    color: 'rgba(0, 0, 0, 0.3)'
  },
  nextArrow: {
    '@media(max-width:503px)': {
      display: 'none'
    },
    right: '5px',
    transform: 'rotate(180deg)'
  },
  prevArrow: {
    left: '5px',
    '@media(max-width:503px)': {
      display: 'none'
    }
  },
  downArrow: {
    marginRight: '24px'
  },
  rotated: {
    transform: 'rotate(180deg)'
  },
  hiddenText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical'
  },

  whiteSpace: {
    whiteSpace: 'normal'
  },

  wordBreak: {
    wordBreak: 'normal'
  },

  coachRecorderUseQuickRegLinkInfo: {
    color: 'brown'
  },

  marginTop05: {
    marginTop: '.5rem'
  },

  marginTop30: {
    marginTop: '30px'
  },

  txtField: {
    width: 'calc(100% - 4em)'
  },

  noEvent: {
    pointerEvents: 'none'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },

  marginLeft1_5: {
    marginLeft: '1.5rem'
  },

  nowrap: {
    whiteSpace: 'nowrap'
  },

  numbers: {
    fontSize: '18px',
    color: '#FFFFFF',
    marginBottom: '5px'
  },

  letters: {
    fontSize: '12px',
    color: '#E9ECF6'
  },

  tournInfoWrapper: {
    '@media(max-width:767px)': {
      fontSize: '14px'
    }
  },

  height36: {
    height: '39px'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  marginBottomForStatistics: {
    marginBottom: '.8em',
    '@media(max-width:767px)': {
      marginBottom: '.75em'
    }
  },

  btnOk: {
    width: '90px',
    height: '50px',
    marginLeft: '25px'
  },

  center: {
    alignItems: 'center'
  }
}));
