import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    loadingContainer: {
        height: 'calc(100vh - 120px)',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
})