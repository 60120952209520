import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Dialog } from '@material-ui/core';

import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';

import { ReactComponent as Exit } from 'assets/img/close_white_bg&grey_border.svg';

import {
  KEEP_EDITING,
  CLOSE_DISCARD,
  CLOSE,
  SAVE
} from '../../helpers/constants';

import Button from '../Buttons/ActionButtons';

import { useStyles } from './StylesNewDesign';

const Modal = (props) => {
  const {
    open,
    close,
    children,
    buttonPurpose,
    onClick,
    dialogTitle,
    dialogContent,
    shouldShowDiscardChanges,
    discardOrKeepEditing,
    subHeader,
    specificDetails,
    onSwitchBetween,
    classNameBtn,
    closeButton,
    closeButtonlabel
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const buttonComponent = (
    action,
    label,
    autoFocus,
    isSaveBtn,
    className,
    id,
    warning
  ) => (
    <Button
      {...{ id }}
      {...{ className }}
      {...{ isSaveBtn }}
      {...{ autoFocus }}
      onClick={action}
      {...{ label }}
      specificallyStyledBtn={warning}
    />
  );

  const discardBtns = [
    {
      id: KEEP_EDITING,
      label: t('Cancel'),
      onClick: (e) => discardOrKeepEditing(e, KEEP_EDITING),
      className: [classes.left, classes.marginRight1]
    },
    {
      id: CLOSE_DISCARD,
      label: t('closeDiscard'),
      onClick: (e) => discardOrKeepEditing(e, CLOSE_DISCARD),
      isSaveBtn: true
    }
  ];

  const buttonData = [
    onClick && {
      id: SAVE,
      label: buttonPurpose,
      onClick,
      disabled: specificDetails?.disabled,
      isSaveBtn: true,
      className: [
        classes.btn,
        classes.margin1dot5,
        classes.marginLeftAuto,
        classNameBtn
      ],
      autoFocus: true
    },
    closeButton && {
      id: CLOSE,
      label: closeButtonlabel ? closeButtonlabel : t('close'),
      onClick: closeButton,
      className: [classes.btn, classes.margin1dot5, classes.marginRightAuto]
    }
  ];

  const modalContent = (
    <>
      <span className={clsx(classes.wrapper_header)}>
        <span className={clsx(classes.flex, classes.header)}>
          <span className={classes.marginRight1}>{dialogTitle}</span>
          {onSwitchBetween && (
            <>
              <ArrowLeft
                onClick={onSwitchBetween.prev}
                className={clsx(
                  classes.onHover,
                  classes.toggleIcons,
                  classes.marginRight1,
                  classes.left
                )}
              />
              <ArrowRight
                onClick={onSwitchBetween.next}
                className={clsx(
                  classes.onHover,
                  classes.toggleIcons,
                  classes.marginRight2
                )}
              />
            </>
          )}
          <Exit
            className={clsx(classes.onHover, !onSwitchBetween && classes.left)}
            onClick={() => close({ targetID: 'TEST_ID_CLOSE_IC' })}
          />
        </span>
        {subHeader && (
          <span className={clsx(classes.flex, classes.contentSubheader)}>
            {subHeader}
          </span>
        )}
      </span>
      <span
        className={clsx(classes.contentWrappper, {
          [classes.onHoldEditing]: shouldShowDiscardChanges
        })}>
        {dialogContent}
        {children}
      </span>
    </>
  );

  return (
    <Dialog
      {...{ open }}
      classes={{ paper: specificDetails?.className?.paper ?? classes.maxWidth }}
      onClose={close}>
      <span className={clsx(classes.flex, classes.column)}>
        {modalContent}
        {shouldShowDiscardChanges ? (
          <span
            className={clsx(classes.discardWrapper, classes.wrapper_footer)}>
            <div className={clsx(classes.flex, classes.discardTextWrapper)}>
              <span className={classes.discardChanges}>
                {t('discardChanges')}
              </span>
              <span>{t('cancelUnsavedChangessMsg')}</span>
            </div>
            <div className={clsx(classes.btnContainer)}>
              {discardBtns.map((it, idx) =>
                it ? (
                  <Fragment key={idx}>
                    <Button
                      id={it.id}
                      isSaveBtn={it.isSaveBtn}
                      onClick={it.onClick}
                      className={clsx(classes.btn, it.className)}
                      label={it.label}
                    />
                  </Fragment>
                ) : null
              )}
            </div>
          </span>
        ) : (
          onClick && (
            <span className={clsx(classes.flex, classes.wrapper_footer)}>
              {buttonData.map(
                (it, idx) =>
                  it && (
                    <Fragment key={idx}>
                      <Button
                        id={it.id}
                        isSaveBtn={it.isSaveBtn}
                        onClick={it.onClick}
                        className={clsx(
                          it.className,
                          specificDetails?.className?.actionBtn
                        )}
                        label={it.label}
                        disabled={it.disabled}
                      />
                    </Fragment>
                  )
              )}
            </span>
          )
        )}
      </span>
    </Dialog>
  );
};

export default Modal;
