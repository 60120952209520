import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },

  centerVertically: {
    alignItems: 'center'
  },

  cardValuePart: {
    backgroundColor: '#AEBBD0',
    color: '#262441'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  marginLeft0dot4: {
    marginLeft: '0.4rem'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  bgWhite: { background: '#fff' },

  numOfCategories: {
    color: '#fff',
    backgroundColor: '#2D63EE',
    borderRadius: '0.3rem',
    marginBottom: '0.2rem',
    width: '1.5rem',
    height: '1.5rem',
    textAlign: 'center',
    fontWeight: 500
  },

  checkboxSize: {
    width: '1em',
    height: '1em',
    '&::before': {
      width: '1em',
      height: '1em'
    }
  },

  padding0: {
    padding: 0
  },

  margin0: {
    margin: 0
  },

  marginLeft1: {
    marginLeft: '1rem'
  }
});

export const useStylesRecords = makeStyles((theme) => ({
  gridRecordsValue: (props) => {
    return {
      '@media (min-width: 1024px)': {
        display: 'grid',
        gridTemplateColumns: props?.map((it) => it.width ?? '1fr').join(' '),
        gap: '0.5rem'
      },
      '@media (max-width:1023px) and (min-width:768px)': {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
      },
      '@media (max-width:1000px) and (min-width:480px)': {
        gridTemplateColumns: '1fr 1fr 1fr'
      },
      '@media (max-width:479px) and (min-width:300px)': {
        gridTemplateColumns: '1fr 1fr'
      }
    };
  }
}));
