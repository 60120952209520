import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import { useStyles } from './StylesAlert';

const AlertMsg = ({ message, className, success }) => {
  const classes = useStyles();

  return (
    <Alert
      icon={false}
      {...{ className }}
      classes={{
        root: clsx(!success && classes.error, classes.noPadding),
        message: classes.alertMsg
      }}>
      {message}
    </Alert>
  );
};
export default AlertMsg;
