import { Component, Fragment } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

import { AuthContext } from 'AuthContext';

import { changeTitle } from 'helpers/actions';
import {
  changeHead,
  changeModal,
  fetchBracketOld,
  fetchBracket,
  fetchTournaments,
  swapParticipants,
  fetchAllCategoryTypes,
  fetchTournamentTatamis,
  renumberBracket
} from 'helpers/util';
import {
  finishedTournament,
  selectedValue,
  isDesktopView,
  categoryTypesPresentOnTournament
} from 'helpers/selectors';
import { ID, NAME } from 'helpers/constants';

import SideModal from 'components/Snackbar/SideModal';
import HeaderTournInfo from 'components/HeaderTournInfo/HeaderTournInfo';
import Filter from 'components/Filter/Filter';
import Button from 'components/Buttons/ActionButtons';
import IndividualGrid from './IndividualGrid';
import TournamentBracket from 'components/Tournament/TournamentBracket';
import TournamentBracketSmallScreen from 'components/Tournament/BracketSmallViewport/Bracket';

import styles from './StylesGridTournament';

class GridTournament extends Component {
  constructor(props) {
    super(props);

    this.searchParams = new URLSearchParams(location.search);
    this.state = {
      tournamentId: props.match.params.id,
      tournamentData: {},
      loading: true,
      langOnLoad: localStorage.getItem('i18nextLng'),
      showModal: false,
      success: false,
      open: false,
      gridData: { categories: [] },
      filters: {
        ...(props?.specificDetails
          ? props?.specificDetails
          : {
              typeId: this.searchParams.get('category_type'),
              tatamiId: this.searchParams.get('tatami'),
              categoryTypes: [],
              tatamis: []
            })
      },
      collapsed: {}
    };

    this.changeHead - changeHead.bind(this);
    this.changeModal = changeModal.bind(this);
    this.fetchBracket = fetchBracket.bind(this);
    this.fetchBracketOld = fetchBracketOld.bind(this);
    this.fetchTournaments = fetchTournaments.bind(this);
    this.swapParticipants = swapParticipants.bind(this);
    this.fetchAllCategoryTypes = fetchAllCategoryTypes.bind(this);
    this.fetchTournamentTatamis = fetchTournamentTatamis.bind(this);
    this.renumberBracket = renumberBracket.bind(this);
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      changeHead(null, t('bracketInformation'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    const { t, specificDetails } = this.props;
    const { tournamentId, filters } = this.state;

    changeTitle(t('bracketInformation'));

    this.setState({ loading: true });

    if (tournamentId) {
      this.fetchTournaments(tournamentId, null, null, () => {
        const { tournamentData } = this.state;
        const shouldDisableEditing =
          finishedTournament(tournamentData) || !!+tournamentData?.finished;

        this.setState({ shouldDisableEditing });

        new Promise((resolve) => {
          if (!specificDetails?.bracketOnly) {
            this.fetchAllCategoryTypes((allSystemTypes) => {
              const categoryTypes = categoryTypesPresentOnTournament(
                allSystemTypes || [],
                tournamentData.category_types
              );

              this.setState((prev) => ({
                filters: { ...prev.filters, categoryTypes }
              }));

              resolve(categoryTypes);
            });

            this.fetchTournamentTatamis(
              {
                tournamentId: tournamentData.id,
                typeId: filters.typeId
              },
              (tatamis) => {
                this.setState((prev) => ({
                  filters: { ...prev.filters, tatamis }
                }));
              }
            );
          } else {
            resolve();
          }
        }).then(() => this.onFetchData());
      });
    }
  }

  onFetchData = () => {
    const { filters, tournamentData } = this.state;
    const ruleKumite =
      filters.categoryTypes.find((it) => +it.id === +filters.typeId)?.rule ===
      'kumite';

    if (ruleKumite) {
      this.fetchBracket({
        tournamentId: tournamentData?.id,
        categoryType: filters.typeId,
        tatamiId: filters.tatamiId,
        categoryId: filters.categoryId
      });
    } else {
      this.fetchBracketOld({
        tournamentId: tournamentData?.id,
        categoryType: filters.typeId,
        tatamiId: filters.tatamiId
      });
    }
  };

  onSelect = (_, value, elem) => {
    const { param, arg } = elem;

    this.setState(
      (prevState) => ({
        filters: {
          ...prevState.filters,
          [param]: value ? value[arg] : null
        }
      }),
      () => {
        this.applyFilters();
      }
    );
  };

  applyFilters = () => {
    const { tournamentData, filters } = this.state;
    const { navigate, location } = this.props;

    this.onFetchData();

    navigate(
      `/event/${tournamentData?.id}/grid?category_type=${filters.typeId}&tatami=${filters?.tatamiId}`,
      {
        state: { prevUrl: location.pathname }
      }
    );
  };

  itemToggle = (key) =>
    this.setState((prevState) => ({
      collapsed: {
        ...prevState.collapsed,
        [key]: !this.state.collapsed[key]
      }
    }));

  onSwapParticipants = (elem) => {
    const { gridData, tournamentData } = this.state;
    let cpy = { ...gridData };

    if (cpy.swapParticipants?.length > 0) {
      if (
        !cpy.swapParticipants.some(
          (it) => +it.participant.id === +elem.participant.id
        )
      ) {
        this.swapParticipants(
          {
            tournamentId: tournamentData.id,
            categoryId: elem.categoryId,
            participantId1: cpy.swapParticipants[0].participant.id,
            participantId2: elem.participant.id
          },
          () => this.onFetchData()
        );
      } else {
        cpy.swapParticipants = null;

        this.setState({ gridData: cpy });
      }
    } else {
      cpy.swapParticipants = [elem];

      this.setState({ gridData: cpy });
    }
  };

  onRenumberBracket = () => {
    const { tournamentData } = this.state;

    this.renumberBracket({ tournamentId: tournamentData?.id }, () => {
      this.onFetchData();
    });
  };

  render() {
    const {
      gridData,
      filters,
      showModal,
      success,
      modalInfo,
      tournamentData,
      currentRounds,
      collapsed,
      loading,
      bracketCategoryType
    } = this.state;
    const { t, classes, specificDetails } = this.props;
    const { viewportWidth } = this.context;
    const findCategoryType = filters.categoryTypes.find(
      (it) => +it.id === +filters.typeId
    );
    const ruleKumite = findCategoryType?.rule === 'kumite';

    const pageHeaderStatistics = !specificDetails?.bracketOnly && {
      title: t('drawStatistics'),
      info: [
        {
          label: t('totalCategories'),
          name: gridData?.categories?.length
        },
        {
          label: t('totalParticipants'),
          name: gridData?.participants?.length
        }
      ]
    };
    const filterData = !specificDetails?.bracketOnly && [
      {
        options: filters.categoryTypes,
        onChange: (e, val) =>
          this.onSelect(e, val, { arg: ID, param: 'typeId' }),
        label: t('type'),
        item: NAME,
        value: selectedValue(filters.categoryTypes, ID, +filters?.typeId, true)
      },
      {
        options: filters.tatamis,
        onChange: (e, val) =>
          this.onSelect(e, val, { arg: 'tatami_id', param: 'tatamiId' }),
        label: t('tatamis'),
        item: 'tatami_name',
        value: selectedValue(
          filters.tatamis,
          'tatami_id',
          +filters?.tatamiId,
          true
        )
      },
      ...(gridData?.categories
        ? [
            {
              options: gridData.categories,
              onChange: (e, val) =>
                this.onSelect(e, val, { arg: ID, param: 'categoryId' }),
              label: t('categories'),
              item: NAME,
              value: selectedValue(
                gridData.categories,
                ID,
                +filters?.categoryId,
                true
              ),
              arg: 'categoryId',
              disableClearable: true
            }
          ]
        : [])
    ];

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {tournamentData && !specificDetails?.bracketOnly && (
          <HeaderTournInfo
            {...{ tournamentData, pageHeaderStatistics, viewportWidth }}
          />
        )}
        {!loading ? (
          <>
            {!specificDetails?.bracketOnly && (
              <div className={classes.wrapperActions}>
                {filterData.map(
                  (
                    {
                      options,
                      onChange,
                      label,
                      item,
                      value,
                      className,
                      arg,
                      disableClearable
                    },
                    index
                  ) => {
                    const content = () => (
                      <Filter
                        className={className}
                        {...{ options }}
                        {...{ onChange }}
                        {...{ label }}
                        {...{ item, disableClearable }}
                        value={value || ''}
                        variant="outlined"
                      />
                    );

                    return (
                      <Fragment key={index}>
                        {arg === 'categoryId' &&
                        !+tournamentData?.no_participant_number ? (
                          <div className={classes.wrapperCategoryAndBtn}>
                            {content()}
                            <Button
                              label={t('renumber')}
                              className={classes.saveBtn}
                              isSaveBtn
                              onClick={this.onRenumberBracket}
                            />
                          </div>
                        ) : (
                          content()
                        )}
                      </Fragment>
                    );
                  }
                )}
              </div>
            )}
            {ruleKumite ? (
              gridData?.grids?.map((grid, gridIdx) => {
                return (
                  <IndividualGrid
                    key={gridIdx}
                    {...{ grid, tournamentData }}
                    data={gridData}
                    onSwapParticipants={this.onSwapParticipants}
                  />
                );
              })
            ) : isDesktopView(viewportWidth) ? (
              <TournamentBracket
                {...{ tournamentData }}
                bracketTatami={gridData}
                {...{ bracketCategoryType }}
                itemToggle={this.itemToggle}
                {...{ collapsed }}
              />
            ) : (
              Array.isArray(currentRounds) && (
                <TournamentBracketSmallScreen
                  {...{ tournamentData }}
                  {...{ bracketCategoryType }}
                  bracketTatami={gridData}
                  itemToggle={this.itemToggle}
                  {...{ collapsed }}
                  initialCategoriesArrayWithIndexes={currentRounds}
                />
              )
            )}
          </>
        ) : (
          <CircularProgress style={{ display: 'flex', margin: 'auto' }} />
        )}
      </>
    );
  }
}

export default withTranslation()(
  withStyles(styles)(withRouter(GridTournament))
);
