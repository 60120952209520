const styles = () => ({
  buttons: {
    display: 'grid',
    gridGap: '1%',
    textAlign: 'center',
    '@media (min-width: 1024px)': {
      gridTemplateColumns:
        '18.5% 18.5% 30% 30% repeat(auto-fill, minmax(300px, 1fr))'
    },

    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridGap: '1em',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr'
    },

    '@media (max-width: 767px)': {
      gridGap: '0.5em'
    }
  },

  selected: {
    backgroundColor: '#F58A46'
  },

  notSelected: {
    backgroundColor: '#00409F'
  },

  colorTxt: {
    color: '#FFFFFF',
    '&:hover': {
      color: '#000000'
    }
  },
  emptyState: {
    marginTop: '40px'
  }
});

export default styles;
