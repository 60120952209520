import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  imgSize: {
    border: '1px solid rgba(137, 150, 172, 0.7)',
    borderRadius: '5px',
    '@media (min-width: 1024px)': {
      height: 'auto',
      width: '270px'
    },
    '@media (max-width: 1023px)': {
      height: 'auto',
      width: '240px'
    }
  },

  centerVertically: {
    alignItems: 'center'
  },

  paper: {
    backgroundColor: '#fff',
    borderRadius: '5px',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  column: {
    flexDirection: 'column'
  },

  marginLeft20: {
    '@media (min-width: 768px)': {
      marginLeft: '1rem'
    }
  },

  padding14: {
    padding: '14px',
    '@media (max-width: 768px)': {
      padding: '7px 14px'
    }
  },

  marginBottom14: {
    marginBottom: '14px'
  },

  defaultText: {
    fontSize: '16px',
    lineHeight: '20.1px'
  },

  title: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#005FEE',
    '@media (min-width: 1024px)': {
      whiteSpace: 'nowrap'
    }
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },

  marginRight05: {
    marginRight: '0.5rem'
  },
  inviteBtn: {
    backgroundColor: '#2D63EE',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1C48B9'
    }
  },

  paddingBottom11: {
    paddingBottom: '11px'
  },

  paragraph: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  keepAll: {
    wordBreak: 'keep-all'
  },

  marginBottom16: {
    marginBottom: '1rem'
  },

  marginBottom6: {
    marginBottom: '6px'
  },

  maxHeight: {
    maxHeight: '360px'
  },

  imgStatistics: {
    display: 'flex',
    '@media (max-width: 1023px)': {
      flexDirection: 'column'
    }
  },

  marginStatistics: {
    '@media (max-width: 1023px)': {
      marginTop: '1rem',
      width: '240px'
    },
    '@media (min-width: 1024px)': {
      marginLeft: '1rem',
      width: '245px'
    },
    '@media (max-width: 767px)': {
      marginBottom: '1rem',
      width: '100%'
    }
  },

  containerBtnsAndInfo: {
    '@media (max-width: 767px)': {
      marginLeft: 0
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      marginLeft: '1rem'
    },
    '@media (min-width: 1024px)': {
      marginLeft: '-250px'
    }
  },

  nowrap: {
    whiteSpace: 'nowrap'
  },

  orderFirst: {
    '@media (max-width: 1023px)': {
      marginTop: '1rem',
      order: 1
    }
  },

  orderSecond: {
    '@media (min-width: 1024px)': {
      marginTop: '1rem'
    },
    '@media (max-width: 1023px)': {
      order: 2
    }
  },

  mobileColumn: {
    '@media (max-width: 767px)': {
      flexDirection: 'column'
    }
  },

  imgWrapper: {
    '@media (max-width: 767px)': {
      justifyContent: 'center',
      display: 'flex'
    }
  },

  weight500: {
    fontWeight: 500
  },

  icon: {
    width: '2.25em',
    height: '2.25em'
  },

  radiusIc: {
    borderRadius: '.2rem',
    border: '1px solid #98A5BA'
  },

  pointer: {
    cursor: 'pointer'
  },

  arrowDropUp: {
    transform: 'rotate(180deg)'
  },

  nowrap: {
    '@media (min-width: 1024px)': {
      whiteSpace: 'nowrap'
    }
  },

  fullWidth: {
    width: '100%'
  },

  marginTop16: {
    marginTop: '16px'
  },

  minHeight: {
    minHeight: '360px'
  },

  border: {
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  link: {
    textDecoration: 'underline',
    '&:hover': {
      color: '#005FEE',
      cursor: 'pointer'
    }
  }
}));
