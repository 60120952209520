import { Fragment, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SkirmishesWhiteIcon } from 'assets/img/grid_skirmish.svg';

import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import Katas from '../Katas/Katas';
import Tameshiwari from '../Tameshiwari/Tameshiwari';
import Places2Win from 'components/Places2Win/Places2Win';

import { useStyles } from './Style';

const TournamentBracketSmallScreen = (props) => {
  const {
    bracketTatami,
    itemToggle,
    collapsed,
    bracketCategoryType,
    initialCategoriesArrayWithIndexes,
    tournamentData
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [currentRounds, setCurrentRounds] = useState([
    ...initialCategoriesArrayWithIndexes
  ]);

  const onNextRound = (idx) => {
    const current = [...currentRounds];
    const round = current[idx] + 1;
    current[idx] = round;
    setCurrentRounds(current);
  };
  const onPrevRound = (idx) => {
    const current = [...currentRounds];
    const round = current[idx] - 1;
    current[idx] = round;
    setCurrentRounds(current);
  };

  const placesComponent = (item) => {
    return <Places2Win {...{ item }} paramName="Place" />;
  };

  const skirmisheComponent = (number, fighters) => (
    <li className={classes.item}>
      <div className={classes.match}>
        <div className={classes.skirmisheNum}>{number}</div>
        <div className={classes.fighters}>
          {fighters.map((fighter, fighterIdx) => {
            const currentColor =
              tournamentData?.[
                fighterIdx === 0
                  ? 'participant_up_color'
                  : 'participant_down_color'
              ];

            return (
              <div
                style={{
                  borderLeft: `.4em solid ${
                    currentColor === 'white' ? 'lavender' : currentColor
                  }`
                }}
                className={clsx(classes.fighter)}
                key={fighterIdx}>
                <span
                  className={clsx(
                    classes.flex,
                    classes.fullWidth,
                    classes.centerVertically
                  )}>
                  <span className={classes.ellipsis}>
                    {fighter.Number
                      ? [fighter.Number, '.', fighter.Name].join(' ')
                      : fighter.Name}
                  </span>
                  {!fighter.Place
                    ? fighter.WonThisFight && (
                        <div className={classes.left}>
                          <div className={classes.winnerTriangle} />
                        </div>
                      )
                    : placesComponent(fighter)}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </li>
  );

  return (
    <div className={classes.marginTop40}>
      {bracketTatami ? (
        <>
          <span className={clsx(classes.panelHeader)}>
            <SkirmishesWhiteIcon style={{ marginRight: '1rem' }} />
            <span style={{ marginRight: '1rem' }}>{bracketCategoryType}</span>
            {[t('tatami'), bracketTatami && bracketTatami.Name].join(' ')}
          </span>
          {bracketTatami.Categories &&
            bracketTatami.Categories.length > 0 &&
            bracketTatami.Categories.map((category, categoryIdx) => {
              const lastRoundNumber = category.Rounds[0].Number;
              const allRounds = [...category.Rounds].reverse();
              const roundsLen = category.Rounds.length;
              const prevRound = allRounds[currentRounds[categoryIdx] - 1];
              const activeRound = allRounds[currentRounds[categoryIdx]];
              const nextRound = allRounds[currentRounds[categoryIdx] + 1];
              const finalistsByCategory = Object.values(category.Finalists);

              return (
                <Fragment key={categoryIdx}>
                  <span
                    className={clsx(classes.paper, classes.flex)}
                    onClick={() => itemToggle(categoryIdx)}>
                    {category.Name}
                    <ExpandMore
                      className={clsx(
                        classes.left,
                        collapsed[categoryIdx] && classes.expandLess
                      )}
                    />
                  </span>
                  <Collapse
                    in={collapsed[categoryIdx]}
                    timeout="auto"
                    unmountOnExit>
                    <div className={clsx(classes.flex, classes.header)}>
                      {!(currentRounds[categoryIdx] === 0) && (
                        <div
                          onClick={() => onPrevRound(categoryIdx)}
                          className={classes.rectangleLeft}>
                          <div className={classes.rectangleLeftTxt}>
                            {[
                              '1',
                              '/',
                              prevRound && prevRound.Skirmishes.length
                            ].join('')}
                          </div>
                        </div>
                      )}
                      <div className={classes.rectangle}>
                        {category.Name}
                        <div className={classes.rectangleTxt}>
                          {currentRounds[categoryIdx] === roundsLen - 1
                            ? t('final')
                            : [
                                '1',
                                '/',
                                activeRound && activeRound.Skirmishes.length
                              ].join('')}
                        </div>
                      </div>
                      {!(currentRounds[categoryIdx] === roundsLen - 1) && (
                        <div
                          onClick={() => onNextRound(categoryIdx)}
                          className={classes.rectangleRight}>
                          <div className={classes.rectangleRightTxt}>
                            {currentRounds[categoryIdx] + 1 === roundsLen - 1
                              ? 'F'
                              : [
                                  '1',
                                  '/',
                                  nextRound && nextRound.Skirmishes.length
                                ].join('')}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={classes.list}>
                      {activeRound &&
                        activeRound.Skirmishes.map((skirmish, skirmishIdx) => {
                          const isFighterCellNotFull = skirmish.Fighters.some(
                            (fighter) => !fighter.Name.trim()
                          );

                          return (
                            <ul
                              key={skirmishIdx}
                              className={clsx(
                                classes.relative,
                                activeRound.Number === '1' &&
                                  isFighterCellNotFull &&
                                  classes.transferBattle
                              )}>
                              {skirmisheComponent(
                                skirmish.Number,
                                skirmish.Fighters
                              )}
                              {lastRoundNumber !== activeRound.Number &&
                                skirmishIdx % 2 === 0 &&
                                activeRound.Number === '1' &&
                                isFighterCellNotFull && (
                                  <li
                                    className={clsx(
                                      classes.link,
                                      classes.linkArrow
                                    )}>
                                    {/* <div className={classes.nextSkirmishNum}>
                                      {nextRound &&
                                        nextRound.Skirmishes[skirmishIdx]
                                          .Number}
                                    </div> */}
                                  </li>
                                )}
                            </ul>
                          );
                        })}
                    </div>
                    {!category.BronzeSkirmish?.Number
                      ? null
                      : activeRound &&
                        lastRoundNumber === activeRound.Number && (
                          <div className={clsx(classes.bronzeSkirmish)}>
                            <h3 className={classes.roundTitle}>
                              {t('bronzeSkirmish')}
                            </h3>
                            <ul className={clsx(classes.list)}>
                              {skirmisheComponent(
                                category.BronzeSkirmish.Number,
                                category.BronzeSkirmish.Fighters
                              )}
                            </ul>
                          </div>
                        )}
                    {finalistsByCategory &&
                      activeRound &&
                      lastRoundNumber === activeRound.Number &&
                      finalistsByCategory.some((it) => it.Id) && (
                        <div className={clsx(classes.list, classes.marginTop)}>
                          <h3 className={classes.roundTitle}>{t('winners')}</h3>
                          {finalistsByCategory.map((it, finalistsIdx) =>
                            it.Id ? (
                              <div className={classes.item} key={finalistsIdx}>
                                <div className={classes.flex}>
                                  {it.Place} {t('rank')}
                                </div>
                                <div
                                  className={clsx(
                                    classes.flex,
                                    classes.match,
                                    classes.finalistsMatch,
                                    classes.fighter
                                  )}>
                                  {it.Number}. {it.Name}
                                  {placesComponent(it)}
                                </div>
                              </div>
                            ) : null
                          )}
                        </div>
                      )}
                  </Collapse>
                </Fragment>
              );
            })}
          {bracketTatami.TameshiwariGLCategories && (
            <Tameshiwari
              {...{ bracketTatami }}
              {...{ itemToggle }}
              {...{ collapsed }}
              {...{ placesComponent }}
            />
          )}
          {bracketTatami.KataCategories && (
            <Katas
              {...{ bracketTatami }}
              {...{ itemToggle }}
              {...{ collapsed }}
              {...{ placesComponent }}
            />
          )}
        </>
      ) : null}
    </div>
  );
};

export default TournamentBracketSmallScreen;
