import { Component } from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { changeModal, fetchTournaments } from '../../helpers/util';
import { changeTitle } from 'helpers/actions';
import { finishedTournament } from 'helpers/selectors';
import { NUMBER_REG_EXP } from '../../helpers/constants';

import { AuthContext } from '../../AuthContext';
import SideModal from '../../components/Snackbar/SideModal';
import TournamentsList from '../../components/Dashboard/Dashboard';
import LoadingState from 'components/LoadingState/LoadingState';

class Dashboard extends Component {
  state = {
    pastTournaments: [],
    upcomingTournaments: [],
    loading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    currentTournamnets: [],
    currentDate: new Date(),
    viewportWidth: window.innerWidth,
    changeButton: true,
    isLoading: true
  };

  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      this.setState({ langOnLoad: currentLang });

      changeTitle(t('home'));
    }
  }

  componentDidMount() {
    const { t } = this.props;

    this.timerID = setInterval(() => this.tick(), 1000);

    this.fetchTournaments(null, null, '', () => {
      if (Array.isArray(this.state.tournamentsList)) {
        let upcomingTournaments = [];
        let pastTournaments = [];
        let tournamentsIndexArrow = [0]; // first index is for upcoming tournaments
        const len = this.state.tournamentsList.length;

        for (let i = 0; i < len; i++) {
          const tournament = this.state.tournamentsList[i];

          if (!finishedTournament(tournament)) {
            upcomingTournaments = [...upcomingTournaments, tournament];
          } else {
            const currentYear = moment(tournament.start_date).format('yyyy');
            const findIdx = pastTournaments.findIndex(
              (it) => it.year === currentYear
            );

            if (findIdx != -1) {
              pastTournaments[findIdx].data = [
                ...pastTournaments[findIdx].data,
                tournament
              ];
            } else {
              pastTournaments = [
                ...pastTournaments,
                { year: currentYear, data: [tournament] }
              ];

              tournamentsIndexArrow = [...tournamentsIndexArrow, 0];
            }
          }
        }

        this.setState({
          upcomingTournaments,
          pastTournaments,
          currentTournamnets: tournamentsIndexArrow,
          isLoading: false
        });
      }

      this.setState({ loading: false });
    });

    changeTitle(t('home'));

    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    window.removeEventListener('resize', this.onResize);
  }

  tick = () => this.setState({ currentDate: new Date() });

  onResize = () => {
    const { currentTournamnets } = this.state;
    const slider = document.getElementById('slider');
    const currentTournamnetsResetIdx = currentTournamnets?.map((it) => 0);

    if (slider) {
      // divide the parent width by card width, 480px, thus get the number of cards that fits the parent container
      const countCards = Math.floor(slider.offsetWidth / 480);

      this.setState({
        countCards,
        currentTournamnets: currentTournamnetsResetIdx,
        viewportWidth: window.innerWidth
      });
    }
  };

  closeSnackBar = () => this.setState({ showModal: false });

  nextTourn = (idx) => {
    const { currentTournamnets } = this.state;
    const current = [...currentTournamnets];
    const tournament = current[idx] + 1;

    current[idx] = tournament;
    this.setState({ currentTournamnets: current });
  };

  prevTourn = (idx) => {
    const { currentTournamnets } = this.state;
    const current = [...currentTournamnets];
    const tournament = current[idx] - 1;

    current[idx] = tournament;
    this.setState({ currentTournamnets: current });
  };

  handleTxtChange = (evt) => {
    const { name, value } = evt.target;

    if (NUMBER_REG_EXP.test(value) || !value) {
      this.setState({ [name]: value });
    }
  };

  getTournByPin = (cb) => {
    const { pin } = this.state;
    const { t } = this.props;

    this.fetchTournaments(pin, null, null, () => {
      const { tournamentData } = this.state;

      if (tournamentData?.id) {
        cb && cb(tournamentData);
      } else {
        this.changeModal(t('actionFailed'));
      }
    });
  };

  toggleButton = () => {
    const { changeButton } = this.state;
    this.setState({ changeButton: !changeButton });
  };

  render() {
    const {
      success,
      showModal,
      modalInfo,
      loading,
      pastTournaments,
      upcomingTournaments,
      currentTournamnets,
      countCards,
      currentDate,
      viewportWidth,
      pin,
      changeButton,
      isLoading,
      tournamentData
    } = this.state;
    const { role } = this.context.authState;

    return (
      <>
        <SideModal
          closeModal={this.closeSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {isLoading ? (
          <LoadingState />
        ) : (
          <>
            <TournamentsList
              handleTxtChange={this.handleTxtChange}
              {...{ pin }}
              {...{ viewportWidth }}
              {...{ countCards }}
              {...{ currentDate }}
              getTournByPin={this.getTournByPin}
              {...{ pastTournaments }}
              {...{ upcomingTournaments }}
              {...{ loading }}
              {...{ currentTournamnets }}
              nextTourn={this.nextTourn}
              prevTourn={this.prevTourn}
              {...{ role }}
              go2QuickRegistration={this.go2QuickRegistration}
              {...{ changeButton }}
              toggleButton={this.toggleButton}
            />
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(Dashboard);
