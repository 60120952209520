const styles = () => ({
  wrapperActions: {
    marginBottom: '1.5rem',
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr 3fr'
    },
    gap: '1rem',
    '@media (min-width: 768px) and (max-width: 1023px)': {
      gridTemplateColumns: '1fr 1fr'
    }
  },

  wrapperCategoryAndBtn: {
    display: 'grid',
    gap: '1rem',
    '@media (min-width: 768px) and (max-width: 1023px)': {
      gridColumn: 'span 2'
    },
    '@media (min-width: 768px)': {
      gridTemplateColumns: '2fr 1fr'
    }
  },

  saveBtn: {
    height: '3.85em'
  }
});

export default styles;
