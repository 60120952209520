import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  marginTop40: {
    marginTop: '40px'
  },
  flex: {
    display: 'flex'
  },

  centerVertically: {
    alignItems: 'center'
  },

  fullWidth: {
    width: '100%'
  },

  left: {
    marginLeft: 'auto'
  },
  expandLess: {
    transform: 'rotate(180deg)'
  },
  column: {
    flexDirection: 'column'
  },
  finalistsMatch: {
    padding: '.4em'
  },
  paper: {
    margin: '20px 0',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    fontWeight: 'bold',
    fontSize: '20px',
    transitionDuration: '0.8s',
    alignContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    }
  },
  transferBattle: {
    display: 'none'
  },
  teamKatas: {
    whiteSpace: 'pre-line'
  },

  panelHeader: {
    display: 'flex',
    background: 'rgb(137, 150, 172)',
    height: '3em',
    fontSize: '1rem',
    fontWeight: 700,
    color: '#fff',
    alignItems: 'center',
    borderRadius: '0.3rem'
  },

  container: {
    display: 'flex',
    width: 'fit-content',
    flexDirection: 'column'
  },
  bronzeSkirmish: {
    marginTop: '1em',
    marginLeft: 'auto'
  },
  skirmisheNum: {
    transform: 'rotate(-90deg)',
    height: 'fit-content',
    alignSelf: 'center'
  },

  fighters: {
    borderLeft: '1px solid rgba(0, 29, 61, 0.4)',
    width: '100%',
    '& > :first-child': {
      borderBottom: '1px solid rgba(0, 29, 61, 0.4)'
    }
  },

  fighter: {
    padding: '.5rem',
    height: '2em',
    display: 'flex',
    alignItems: 'center'
  },
  winnerTriangle: {
    borderTop: '5px solid transparent',
    borderLeft: `10px solid ${theme.palette.primary.main}`,
    borderBottom: '5px solid transparent'
  },
  bracket: {
    display: 'flex',
    flexDirection: 'row'
  },
  roundFighter: {
    marginLeft: '-3px'
  },
  section: {
    width: '30em'
  },
  roundTitle: {
    color: theme.palette.primary.main,
    fontSize: '0.95rem',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: '0.5em'
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    minHeight: '100%',
    transition: 'padding 0.2s ease-in-out, margin 0.2s ease-in-out',
    borderRight: '1px dashed #e5e5e5'
  },

  item: {
    height: '100%',
    listStyleType: 'none',
    flex: '0 1 auto',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
    padding: '1em 0',
    width: '48%',
    transition: 'padding 0.2s linear',
    '&:nth-child(odd)': {
      marginRight: '1em'
    },
    '&:nth-child(even)': {
      marginLeft: '1em'
    },
    '&:after': {
      transition: 'width 0.2s linear'
    },
    '@media (min-width: 38em)': {
      padding: '0.5em 1em',
      width: '100%',
      '&:nth-child(odd), &:nth-child(even)': {
        margin: 0
      }
    }
  },
  itemLink: {
    // NEED MORE WORK
    // '@media (min-width: 38em)': {
    //   '&:after': {
    //     boxSizing: 'border-box',
    //     position: 'absolute',
    //     right: 0,
    //     content: '""',
    //     display: 'block',
    //     width: '1em',
    //     height: '100%',
    //     borderRight: `2px solid ${theme.palette.primary.main}`
    //   },
    //   '&:nth-child(odd)': {
    //     '&:after': {
    //       top: '50%',
    //       borderTop: `2px solid  ${theme.palette.primary.main}`
    //     }
    //   },
    //   '&:nth-child(even)': {
    //     '&:after': {
    //       bottom: '50%',
    //       borderBottom: `2px solid  ${theme.palette.primary.main}`
    //     }
    //   }
    // },
    // '@media (min-width: 72em)': {
    //   padding: '0.5em 1.5em',
    //   '&:after': {
    //     width: '1.5em'
    //   }
    // }
  },
  match: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 29, 61, 0.4)',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    '@media (max-width:24em)': {
      padding: '0.75em 0.5em'
    }
  },
  matchLink: {
    // NEED MORE WORK
    // '@media (min-width:38em)': {
    //   '&:before, &:after': {
    //     boxSizing: 'border-box',
    //     position: 'absolute',
    //     left: 0,
    //     zIndex: 1,
    //     content: '""',
    //     display: 'block',
    //     width: '1em'
    //   },
    //   '&:before': {
    //     bottom: '50%',
    //     borderBottom: `1px solid  ${theme.palette.primary.main}`
    //   },
    //   '&:after': {
    //     top: '50%',
    //     borderTop: `1px solid  ${theme.palette.primary.main}`
    //   }
    // },
    // '@media (min-width: 72em)': {
    //   '&:before, &:after': {
    //     width: '1.5em'
    //   }
    // }
  },
  iconWrapper: {
    height: '1.5em'
  },
  icon: {
    width: '1.5em',
    height: '1.5em'
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'calc(100% - 0.5rem)'
  }
}));
