import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
    success: {
        backgroundColor: '#43A047',
    },
    warning: {
        backgroundColor: '#FFA000',
    },
    icon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: '10px',
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        width: '20vw',
    },
    margin: {
        margin: '10px',
    },
})