import clsx from 'clsx';

import { Button } from '@material-ui/core';

import { useStyles } from './StylesActionButtons';

const ActionButtonP2 = (props) => {
  const {
    label,
    id,
    onClick,
    disabled,
    className,
    isSaveBtn,
    style,
    autoFocus,
    heightException,
    specificallyStyledBtn,
    whiteBackground
  } = props;

  const classes = useStyles();

  return (
    <Button
      {...{ id }}
      className={clsx(
        classes.flex,
        !heightException && classes.btn,
        isSaveBtn
          ? classes.activeBtn
          : specificallyStyledBtn
          ? specificallyStyledBtn
          : classes.defaultBtn,
        whiteBackground && classes.whiteBackground,
        disabled && classes.disabledBtn,
        className
      )}
      {...{ style }}
      {...{ onClick }}
      {...{ disabled }}
      {...{ autoFocus }}
      fullWidth>
      {label}
    </Button>
  );
};
export default ActionButtonP2;
