const styles = () => ({
  gridButtons: {
    display: 'grid',
    marginTop: '1rem',
    gridGap: '1em',
    '@media (min-width:1025px)': {
      gridGap: '1%'
    }
  },
  viewAddButton: {
    '@media (min-width:1025px)': {
      gridTemplateAreas: `
        'searchInput searchInput searchInput checkboxNotAccepted add saveList'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateAreas: `
        'searchInput searchInput searchInput'
        'checkboxNotAccepted add saveList'
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateAreas: `
        'searchInput'
        'checkboxNotAccepted'
        'add'
        'saveList'
      `
    }
  },
  noviewAddButton: {
    '@media (min-width:1025px)': {
      gridTemplateAreas: `
        'searchInput searchInput searchInput searchInput checkboxNotAccepted saveList'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateAreas: `
        'searchInput searchInput'
        'checkboxNotAccepted saveList'
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateAreas: `
        'searchInput'
        'checkboxNotAccepted'
        'saveList'
      `
    }
  },

  padding0: {
    padding: 0
  },

  checkboxSize: {
    width: '1em',
    height: '1em',
    '&::before': {
      width: '1em',
      height: '1em'
    }
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginLeft0dot4: {
    marginLeft: '0.4rem'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  rowOrder: {
    borderRight: '1px solid #8996AC'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  margin0: {
    margin: 0
  },

  bgWhite: { background: '#fff' },

  plus: {
    marginRight: '.5em',
    fontSize: '35px',
    fontWeight: 400
  },

  flex: {
    display: 'flex'
  },

  maxWidth: {
    maxWidth: '10em'
  },

  centerVertically: {
    alignItems: 'center'
  },

  cardMainPart: {
    backgroundColor: '#AEBBD0 !important',
    color: '#262441 !important'
  },
  cardValuePart: {
    wordBreak: 'break-all'
  },

  exportBtn: {
    '@media (min-width:901px)': {
      width: 'fit-content',
      marginLeft: '0.5rem'
    }
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#001D3D'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#001D3D',
      opacity: 0.6
    }
  },

  switchWrapper: {
    minWidth: 'fit-content',
    padding: '0.5rem 0 0.5rem 1rem'
  },

  condensedCardWidth: {
    width: 'calc(100% - 6.5em - 1.5rem)'
  },

  center: {
    justifyContent: 'center'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  marginForRootCheckBox: {
    marginRight: 0,
    marginLeft: 0,
    borderRadius: '5px',
    border: '1px solid rgba(137, 150, 172, 0.7)',
    height: '48px'
  },
  emptyState: {
    marginTop: '40px'
  }
});

export default styles;
