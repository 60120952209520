import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  marginTop1: {
    marginTop: '1rem'
  },

  cardValuePart: {
    backgroundColor: '#AEBBD0',
    color: '#262441'
  },

  marginLeft1: {
    marginLeft: '1rem'
  }
});

export const useStylesRecords = makeStyles((theme) => ({
  gridRecordsValue: (props) => {
    return {
      '@media (min-width: 1024px)': {
        display: 'grid',
        gridTemplateColumns: props?.map((it) => it.width ?? '1fr').join(' '),
        gap: '0.5rem'
      },
      '@media (max-width:1023px) and (min-width:768px)': {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
      },
      '@media (max-width:1000px) and (min-width:480px)': {
        gridTemplateColumns: '1fr 1fr 1fr'
      },
      '@media (max-width:479px) and (min-width:300px)': {
        gridTemplateColumns: '1fr 1fr'
      }
    };
  }
}));
