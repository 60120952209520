const styles = () => ({
  importContainer: {
    display: 'flex',
    flexDirection: 'column'
  },

  labelsForModal: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  labelsForRadioButton: {
    paddingBottom: '0.3rem',
    marginBottom: '0.7rem',
    borderBottom: '1px solid #8996AC',
    '& .MuiTypography-body1': {
      width: '100%'
    }
  },

  radio: {
    marginRight: '0.3rem'
  },

  weight: {
    fontWeight: 500
  },

  modalInnerContentHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 5em',
    gap: '0.5rem',
    marginBottom: '1rem'
  },

  modalInnerContentBody: {
    display: 'grid',
    gridTemplateColumns: '1fr 5.5em',
    gap: '0.5rem'
  },

  modalWidth: {
    '@media (min-width:1024px)': {
      minWidth: '40em'
    }
  },

  marginLeftAuto: {
    marginLeft: 'auto'
  }
});

export default styles;
