import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '0.3rem',
    height: '3em',
    padding: '0.2em 0.5rem',
    color: '#8996AC',
    border: '1px solid #8996AC',
    fontSize: 'unset'
  },

  maxWidth: {
    width: '100%'
  },

  icon: {
    marginRight: '0.2rem',
    color: '#546e7a'
  },

  clear: {
    cursor: 'pointer',
    '&:hover': {
      color: '#b4c4cb'
    }
  }
}));
