const styles = () => ({
    flex: {
      display: 'flex'
    },

    column: {
      flexDirection: 'column'
    },

    wrapper: {
      alignItems: 'center'
    },

    img: {
      width: '400px',
      height: '80px',
      margin: '0 0 2.5rem 0'
    },

    marginBottom1: {
      marginBottom: '1rem'
    },

    marginRightAuto: {
      marginRight: 'auto'
    }
  });
  
  export default styles;