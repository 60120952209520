const styles = () => ({
  backdrop: {
    zIndex: 10
  },
  img: {
    height: 'calc(100% - 100px)',
    marginTop: '80px',
    '@media (max-width:900px)': {
      width: 'calc(100% - 20px)',
      height: 'auto'
    }
  },
  modalContent: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },

  gridRecordsAttachments: {
    '@media (min-width:768px)': {
      gridTemplateColumns: '7.5em 1fr'
    }
  },

  cardValuePart: {
    padding: '0 0 0.5rem 0.5rem',
    backgroundColor: '#FFFFFF'
  },

  docIc: {
    height: 'auto',
    width: '5rem'
  },

  actionIcDoc: {
    width: '3em',
    height: '3em'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  btnDownloadDoc: {
    width: '10.715em'
  },

  cardTxt: {
    fontWeight: 600
  },

  txtWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    wordSpacing: '100vw',
    '@media (max-width: 767px)': {
      fontWeight: 600,
      wordSpacing: 'normal'
    }
  },

  maxWidthModal: {
    '@media (min-width:1024px)': {
      maxWidth: '100%',
      minWidth: '40em'
    }
  },

  toolbar: {
    padding: 0,
    marginBottom: '1rem'
  },

  search: { margin: '0 1rem 0 0' },

  cardWrapper: {
    backgroundColor: '#FFFFFF',
    padding: '0 0 0.5rem 0.5rem'
  },

  gridButtons: {
    display: 'grid',
    marginTop: '1rem',
    '@media (min-width:1025px)': {
      gridTemplateColumns: 'repeat(6, 1fr)',
      gridTemplateAreas: `
        'searchInput searchInput filter filter exportButton filterButton'
      `,
      gridGap: '1%'
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateAreas: `
        'searchInput searchInput'
        'filter filter'
        'exportButton filterButton'
      `,
      gridGap: '1em'
    },
    '@media (max-width: 767px)': {
      gridTemplateAreas: `
        'searchInput searchInput'
        'filter filter'
        'exportButton filterButton'
      `,
      gridGap: '1em'
    }
  },

  verticallyCentered: {
    alignItems: 'center'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  selected: {
    backgroundColor: '#8996AC',
    border: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8996AC',
      opacity: 0.7
    }
  },

  arrowDropUp: {
    transform: 'rotate(180deg)'
  },

  flexGrow1: {
    flexGrow: 1
  },

  flex: {
    display: 'flex'
  },

  center: {
    textAlign: 'center'
  },

  marginLeft5: {
    marginLeft: '3px'
  },
  outlined: {
    '& .MuiOutlinedInput-root': {
      '& input': {
        width: '160px',
        padding: '0',
        height: '11px'
      }
    }
  },
  styleFilter: {
    width: '11em',
    marginLeft: '2rem',
    height: 'calc(100% + 0.4rem)',
    border: 'none',
    '& .MuiFormControl-root, & .MuiInputBase-root': {
      height: '100%'
    }
  },
  emptyState: {
    marginTop: '40px'
  }
});

export default styles;
