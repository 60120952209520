import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: 'calc(100vw - 100%)',
    '@media (min-width:901px)': {
      paddingBottom: '1.25rem'
    },
    '@media (max-width:900px)': {
      paddingBottom: '1rem'
    },
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: '#262441',
    backgroundColor: '#E9ECF6'
  },

  paddingTop: {
    paddingTop: '7rem'
  },

  body: {
    minHeight: 'calc(100vh - 10rem)',
    '@media (max-width:900px)': {
      width: 'calc(100vw - 3rem)'
    },
    '@media (min-width:901px)': {
      width: 'calc(100vw - 4rem)'
    },
    margin: '0 auto'
  },

  footer: {
    marginTop: '0.5rem',
    textAlign: 'center'
  },

  footerTxt: {
    color: '#000',
    fontSize: '1rem'
  }
}));
