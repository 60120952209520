import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  left: {
    marginLeft: 'auto'
  },

  expandLess: {
    transform: 'rotate(180deg)'
  },

  paper: {
    margin: '20px 0',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    fontWeight: 'bold',
    fontSize: '20px',
    transitionDuration: '0.8s',
    alignContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    }
  },

  marginBottom: {
    marginBottom: '1rem'
  }
}));
