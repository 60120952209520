export default {
  exit: 'Ieșire',
  lang: 'Limba',
  english: 'Engleză',
  russian: 'Rusă',
  romanian: 'Română',
  german: 'Germană',
  templates: 'Șabloane',
  template: 'Șablon',
  subject: 'Subiect',
  here: 'тут',
  apply: 'Aplicați',
  proceed: 'Purcede',
  picture: 'Imagine',
  photo: 'Fotografie',
  accountCreated: 'Cont creat!',
  accountFailToCreate: 'Contul nu a putut fi activat!',
  invitationListTitle: 'Lista admiterilor pentru înregistrare',
  chooseFromList: 'Selectați din listă',
  fail2AddUser:
    'Nu s-au salvat modificările. Un utilizator numit „{{name}}” există deja în sistem.',
  navigateTo: 'Navigheaza către',
  min: 'min',
  sec: 'sec',
  kg: 'kg',
  cm: 'cm',
  minute: 'Minută',
  minutes: 'Minute',
  second: 'Secundă',
  seconds: 'Secunde',
  hour: 'Oră',
  hours: 'Ore',
  days: 'Zile',
  day: 'Zi',
  tournamentDay: 'Ziua turneului',
  years: 'ani',
  errorBoundary: 'Oh nu! Ceva n-a mers bine',
  logout: 'Iesire',
  firstname: 'Prenume',
  lastname: 'Nume',
  firstNameNational: 'Prenume latin',
  lastNameNational: 'Nume latin',
  yourFirstName: 'Prenumele dvs.',
  yourLastName: 'Numele dvs.',
  yourPatronymic: 'Patronimicul dvs.',
  coachFirstName: 'Prenume Antrenor',
  coachLastName: 'Nume Antrenor',
  patronymic: 'Patronimic',
  tournament: 'Turneu',
  logIn: 'Autentificare',
  activateAccount: 'Activează contul',
  loginIssue: 'Nu s-a putut intra în sistem?',
  username: 'Nume Utilizator',
  password: 'Parola',
  passwordChangedSuccessfully: 'Parola a fost schimbată cu succes!',
  emailSent: 'V-a fost trimis un e-mail pentru a vă reseta parola',
  followInstructions: 'Urmați instrucțiunile din scrisoare.',
  categories: 'Categorii',
  categoriesForm: 'Categorii',
  deleteCategoryErrorsBcOfTourn: `Această categorie nu poate fi ștearsă deoarece este folosită în turneele ulterioare: {{name}}`,
  deleteGroupCategoryErrorsBcOfCategories: `Acest grup nu poate fi șters deoarece are categorii`,
  participantsNum: 'N. de participanții',
  participants: 'Participanti',
  participant: 'Participant',
  athletes: 'Sportivi',
  athlete: 'Sportiv',
  athleteForm: 'Sportivi',
  myTeam: 'Echipa mea',
  myOrganisation: 'Organizația mea',
  cluborFederationName: 'Clubul sau Organizația',
  yourCluborFederationName: 'Clubul sau Organizația dvs.',
  users: 'Utilizatori',
  logs: 'Log-uri',
  demoUsers: 'Utilizatori demonstrativi',
  expandList: 'Extinde lista',
  total: 'Total',
  sum: 'Total',
  upcoming: 'Viitor',
  finished: 'Trecut',
  planUpcomingEvents: 'Planul evenimentelor viitoare',
  pastEvents: 'Evenimente trecute',
  eventStatus: 'Statut eveniment',
  active: 'Active',
  inactive: 'Neactive',
  archive: 'Arhiva',
  showRed: 'Arată roșu',
  appointed: 'atasate',
  filled: 'completat',
  human: 'om',
  categoriesTotal: 'Total categorii {{name}}',
  categoriesUsed: 'Categoriile folosite',
  redCategories: 'Categoriile roșii',
  addRecord: 'Adauga {{name}}',
  boys: 'Baieti',
  girls: 'Fete',
  men: 'Barbati',
  women: 'Femei',
  femaleGender: 'sex feminin',
  maleGender: 'sex masculin',
  moreApps: 'Descărcați Aplicații',
  forDesktop: 'pentru Desktop',
  forMobile: 'pentru Mobil',
  downloadTournamentManagerInfo:
    'Aplicația este concepută pentru a vă ajuta să vă desfășurați turneul. Pentru a fi gata să organizezi un concurs, descarcă-l acum!',
  downloadCasterInfo:
    'O aplicație specială care emulează funcționarea unui tablou de bord sub sistemul de operare Windows. Alternativă bună la Raspberry Pi. Utilizați această aplicație dacă nu aveți un mini computer Raspberry Pi.',
  downloadCastManager:
    'O aplicație pentru configurarea tabloului de bord și gestionarea relației dintre operatorul de tatami și imaginea de pe ecran. Cu această aplicație, configurarea este mai ușoară și mai convenabilă!',
  downloadMobileInfo:
    'Descărcarea aplicației noastre mobile este acum disponibilă. Mai ușor, mai convenabil și mai rapid! Descărcați aplicația pe telefonul dvs. pentru a fi la curent cu ultimele evenimente.',
  downloadDiploma:
    'Acesta este un program pentru tipărirea și crearea de diplome de premiu, certificate și diplome. Dacă aveți o imprimantă la turneu, trimiteți imediat diplomele pentru tipărire din program imediat ce meciurile finale sunt finalizate. Folosește această aplicație pentru a recompensa finaliștii la turneu!',
  availableFor: 'Disponibil pentru',
  versionLTS: 'Recomandat pentru majoritatea utilizatorilor',
  version: 'Versiune',
  current: 'Actual',
  certificates: 'Certificate',
  certificate: 'Certificat',
  document: 'Document',
  noAttachmentsMsg: 'Se pare că nu există fișiere de descărcat',
  noCertificates: 'Nu aveți niciun certificat la momentul dat.',
  certificateNameLimitExceed:
    'Titlul documentului nu poate depăși 128 de caractere.',
  uploadDoc: 'Incarca document',
  addAttachment: 'Adăugați document',
  attachment: 'Fișier',
  attachments: 'Fișiere',
  documents: 'Documentație',
  documentsCount: 'Total documente',
  diplomas: 'Diplome',
  regulations: 'Regulament',
  attachmentType: 'Tipul documentului',
  supportedDocument: 'Formate acceptate',
  nameDocument: 'Titlul documentului',
  acceptRequest: 'Acceptă cererea',
  rejectRequest: 'Respinge cererea',
  uploadDate: 'Data incărcării',
  additional: 'Adiţional',
  toView: 'Vezi',
  viewDetails: 'Vezi detalii',
  diplomaName: 'Maria Popescu',
  diplomaCategory: 'Cadeți 14-15 ani -75 kg',
  diplomaConstructor: 'Diploma constructor',
  saveDiploma: 'Salvați șablonul',
  noTemplateForDiploma: 'Nu există un șablon stabilit pentru diplomă.',
  judgeWithSuchPositionExists:
    'Arbitrul „{{name1}}” a fost deja numit în funcția „{{position}}”. Doriți să-l înlocuiți cu arbitrul „{{name2}}”?',
  judicialQualifications: 'Documente care confirmă calificarea judiciară',
  failed2Load:
    'Nu s-a putut încărca fișierul. Nu-s drepturi sau fișierul e corupt',
  import: 'Import',
  informativeUploadImageMsg:
    'Pentru a importa cu succes fișierele, utilizați .png, .jpg, .jpe.',
  dragMsg: 'sau trageți documentul',
  rejectedFileError:
    'Acest fișier nu este în formatul necesar. Vă rugăm să încărcați un fișier cu formatul corect.',
  browse: 'Navigheaza',
  isDragRejectMsg:
    'Acest fișier nu poate fi atașat, deoarece formatul său nu este acceptat',
  certificateNameLabel: 'Numele certificatului',
  fileTooLargeMsg:
    'Acest fișier depășește 20MB. Vă rugăm să încărcați un fișier mai mic.',
  imgTooBig: 'Dimensiunea fișierului depășește limita permisă, 3840x3840',
  attachment2Large:
    'Dimensiunea atașamentului este prea mare pentru a fi încărcat.',
  maxChars: 'Maximum {{max}} caractere',
  minChars: 'Minim {{num}} caractere',
  dropFile: 'Aruncați fișierul pentru a-l atașa',
  remove: 'Elimina',
  insufficientData: 'Date insuficiente',
  successfullyAdded: 'Adăugat cu succes',
  resolveAllInputs: 'Rezolvați toate câmpurile',
  successfullyUpdated: 'Actualizare finalizată cu succes.',
  failed2Distribute:
    'Cu toate acestea, distribuirea participanților: {{name}} a eșuat.',
  search: 'Cautare',
  deleteEventGridMsg: 'Sigur doriți să ștergeți clasamentul?',
  deleteEventMsg: 'Sigur doriți să ștergeți acest eveniment?',
  deleteParticipantMsg: 'Sigur doriți să ștergeți acest participant?',
  deleteAthleteMsg: 'Sigur doriți să ștergeți acest sportiv?',
  deleteCategoryMsg: 'Sigur doriți să ștergeți acestă categorie?',
  deleteCategoryGroupMsg: 'Sigur doriți să ștergeți acest grup de categorii?',
  deleteUserMsg: 'Sigur doriți să ștergeți acest utilizator?',
  deleteCertificateMsg: 'Sigur doriți să ștergeți acest certificat?',
  deleteDocumenMsg: 'Sigur doriți să ștergeți acest document?',
  deleteJudgeMsg: 'Sigur doriți să ștergeți acest arbitru?',
  deleteMsg:
    'Sigur doriți să ștergeți disciplina kata pentru această categorie?',
  deleteRecord: 'Șterge {{name}}',
  deleteParticipantWithCategoriesMsg:
    'Pentru a șterge cu succes acest participant, mai întâi anulați toate categoriile participantului debifând caseta de selectare.',
  deleteAthleteWithCategoriesMsg:
    'Pentru a elimina cu succes acest atlet, mai întâi anulați atribuirea sa din toate categoriile de turnee prin debifarea căsuței.',
  deleteCategory: 'Ștergeți categoria',
  deleteCategoryGroup: 'Ștergeți grupul de categorii',
  deletePermanently: 'Ștergeți definitiv',
  confirm2deletePermanently:
    'Pentru a continua cu ștergerea definitivă a turneului, vă rugăm să confirmați acțiunea introducând codul din 6 cifre <<{{name}}>>',
  letsDoIt: 'Hai să o facem!',
  deleteUser: 'Ștergeți Utilizatorul',
  useLatinChars: 'Folosiți caractere latine',
  useCyrillicChars: 'Folosiți caractere chirilice',
  id: 'ID',
  ikoCard: 'I.K.O Card',
  kyuDan: 'Kyu/Dan',
  name: 'Nume',
  denomination: 'Numele evenimentului',
  age: 'Varsta',
  country: 'Tara',
  gender: 'Sex',
  units: 'Unități',
  weight: 'Greutate',
  weightFromTo: 'Greutate de la / pana la',
  weightInfoMark:
    'Două categorii vor fi create pe baza valorii specificate. O categorie - pentru greutate mai mică decât valoarea introdusă, a doua - pentru greutate mai mare decât aceasta.',
  category: 'Categorie',
  coach: 'Antrenor',
  coaches: 'Antrenori',
  coachForm: 'Antrenori',
  totalRegistrars: 'Registratori',
  countries: 'Țări',
  countriesForm: 'Țări',
  type: 'Tip',
  date: 'Data',
  eventStartDate: 'Data startării evenimentului',
  startDay: 'Data evenimentului',
  eventStart: 'Startul evenimentului',
  sportEvent: 'Eveniment sportiv',
  training: 'Instruire',
  nextKyuDan: 'Următorul Kyu/Dan',
  exams: 'Examene',
  time: 'Ora',
  preliminaryRounds: 'Runds preliminare',
  semifinalAndFinals: 'Semifinal și final',
  mainAndExtraTime: 'Timp principal și prelungiri',
  city: 'Oras',
  address: 'Adresa',
  secretary: 'Secretar sef',
  mainJudge: 'Judecător principal',
  allTournaments: 'Toate Turneele',
  allParticipants: 'Toți Participanții',
  from: 'De la',
  to: 'Pana la',
  birthday: 'Data nașterii',
  newerDate: 'Vă rugăm să introduceți o dată mai nouă',
  club: 'Club',
  region: 'Regiunea',
  role: 'Rol',
  upload: 'Incarca',
  description: 'Descriere',
  eventName: 'Denumire eveniment',
  eventType: 'Tip eveniment',
  submit: 'Trimite',
  more: 'Mai Mult',
  website: 'Site-ul web',
  height: 'Inaltime',
  history: 'Istoricul arbitrului în ultimul an',
  selectCoachFirst: 'Selectati antrenorul',
  noTournaments: 'Lipsesc Turneuri',
  registrationDate: 'Închiderea înscrierii',
  registrationDateParticipants: 'Data limită pentru înscrierea participanților',
  phone: 'Telefon',
  local: 'Turneu Local',
  bestResults: 'Cele mai bune rezultate din ultimii 3 ani',
  international: 'Turneu Internațional',
  trainingSchool: 'Tabere de Antrenament',
  cancel: 'Anulare',
  error: 'Eroare',
  notFound: 'Nu a fost găsit',
  appNotFound: 'Aplicația nu a fost găsită',
  deletedApp: 'Aplicația este ștearsă',
  noRights: 'Drepturi insuficiente',
  fillInputs: 'Completați toate intrările',
  incompleteData: 'Date incomplete',
  deletedSuccessfully: 'Șters cu succes',
  less: 'Mai putin',
  close: 'Inchide',
  required: 'Neaparat',
  updateFailed: 'Actualizarea a esuat',
  cannotSaveChanges:
    'Modificările dvs. nu au putut fi salvate. Vă rugăm să ne contactați pentru asistență.',
  invalidEmail: 'Email Invalid',
  requiredPasswordLength:
    'Parola dvs. trebuie să aibă cel puțin 4 și maximum 16 caractere',
  passwordsDoNotMatch: 'Parolele sunt diferite',
  emailAlreadyUsed: 'Email deja exista',
  serverError: 'Server-ul nu răspunde. Verifică-ți conexiunea.',
  wrongCurrentPasswordMsg: 'Parola incorecta. Vă rugăm să încercați din nou.',
  updatedPasswordMsg: 'Parolă actualizată',
  registerNewUserMsg:
    'A fost trimisă o scrisoare de confirmare la adresa dvs. de e-mail, faceți clic pe link pentru a vă confirma e-mailul',
  editParticipantInformationMsg:
    'pentru a edita informații despre un participant, faceți clic pe el în tabel',
  typeEmails: 'Type an email address',
  invalidEmailMsg:
    'Adresa de e-mail e nevalide. Editați adresa de e-mail pentru a trimite invitația.',
  signUp: 'Creați un cont',
  forgotPassword: 'Ati uitat parola?',
  changePassword: 'Modifica parola',
  chooseNewPassword: 'Introduceți o parolă nouă',
  submitChange: 'Modifica',
  alreadyHaveAnAccount: 'Aveti Profil',
  confirmPassword: 'Confirmati parola',
  send: 'Transmite',
  resetPasswordMsg:
    'Vă rugăm să introduceți adresa de e-mail pe care ați folosit-o pentru a vă înregistra. Vă vom expedia apoi un e-mail pentru a vă reseta parola.',
  emailVerficationFailedMsg:
    'Numărul de încercări de recuperare a parolei a fost depășit. Trebuie să încercați din nou în 24 de ore sau să contactați administrația',
  emailVerficationNoUserMsg:
    'Nu a fost trimis niciun e-mail de confirmare. Vă rugăm să încercați din nou mai târziu.',
  requestSentToYourEmail: 'Cererea a forst trimisa pe email',
  goBack: 'Înapoi',
  notSentTo:
    'Din păcate, invitația dvs. nu poate fi livrată unuia sau mai multor destinatari:',
  invalidPassword: 'Parolă incorectă',
  wrongEmail: 'E-mailul introdus nu este conectat la un cont.',
  noSymbols: 'Fara simboluri',
  lettersRequired: 'Literele sunt neaparate',
  digitsOnly: 'Sunt permise doar cifre',
  updateRecord: 'Actualizează {{name}}',
  registered: 'Înregistrați',
  registrationN: 'Înregistrare',
  passedRegistration: 'Înregistrat',
  notRegistered: 'Neînregistrat',
  noTournamentSpecified:
    'Turneul nu a putut fi localizat sau permisiunile de acces lipsesc',
  admisDocs: 'Documente',
  admisWeighs: 'Cântar',
  admisEquipment: 'Echipament',
  admisMedic: 'Medic',
  admins: 'Administratori',
  masters: 'Masteri',
  registerers: 'Registratori',
  coachesTotal: 'Total Antrenori',
  pageDoesNotExists: 'Pagina nu exista',
  insertPinFirst: 'Introduceti codul turneului',
  pin: 'Introdu PIN cod',
  selectParticipants: 'Alegeti participantii',
  getTournament: 'Afișează eveniment',
  attach: 'Adaugă la eveniment',
  events: 'Evenimente',
  event: 'Eveniment',
  eventStatistics: 'Event statistics',
  select: 'Selectează',
  approvedJudges: 'Antrenori aprobați',
  participantRequestSent: 'Cererea dumneavoastra a fost transmisa',
  participationApproved: 'Participare Acceptata',
  participationDisapproved: 'Participare Declanata',
  approveRegistration: 'Aprobă Participarea',
  participantsTotal: 'Participanți',
  requests: 'Requests',
  accesptedRequests: 'cereri acceptate',
  saveList: 'Obțineți raport',
  approve: 'Aproba',
  approbation: 'Aproba',
  male: 'Masculin',
  female: 'Feminin',
  unisex: 'Unisex',
  additionToCategoryFailed:
    'A existat cel puțin o adăugare nereușită din cauza depășirii termenului de înregistrare sau a numărului de participanți.',
  failToRemoveParticipant:
    'Ștergerea participantului a eșuat, acesta este atașat la un turneu',
  participantsRemainingMsg:
    'Actualizat cu succes. Categoriile care conțin participanți vor rămâne marcate.',
  noAttachedTatamisMsg:
    'Pentru a atribui categorii unui turneu, e necesar de a avea cel puțin un tatami',
  assignCategoriesToTournament: 'Adăugați categorii la turneu',
  kids: 'Copii',
  juniors: 'Juniori',
  cadets: 'Cadeți',
  adults: 'Adulți',
  veterans: 'Veteranii',
  eventCode: 'Cod PIN eveniment',
  organizer: 'Organizatori',
  tournOrganizer: 'Organizator',
  addNew: 'Adăugă',
  addAthlete: 'Adaugă sportiv',
  addCategory: 'Adauga Categorie',
  updateCategory: 'Actualizare Categorie',
  all: 'Tot',
  allPersons: 'Toți',
  edit: 'Editare',
  winners: 'Campioni',
  wainting: 'Aşteptare',
  winnerOf: 'Câștigător {{name}}',
  loserOf: 'Perdant {{name}}',
  listOfParticipants: 'Lista participanților',
  reports: 'Rapoarte',
  tournamentResults: 'Rezultatul echipelor la turneu. Aur, argint și bronz.',
  addCoach: 'Adăugați un Registrator',
  schemeJudges: 'Aranjament arbitri',
  closeScheme: 'Închide schema',
  hideRefereePlacementScheme: 'Ascunde diagrama de plasare a arbitrului',
  judgeNoTatami:
    'Pentru a accesa această funcție, trebuie mai întâi să adăugați arbitri și cel puțin un tatami la turneu',
  judges: 'Arbitri',
  approveJudges: 'Aprobați arbitrii',
  addJudge: 'Adaugă arbitru',
  updateJudge: 'Actualizați arbitru',
  positions: 'Post',
  removePosition: 'Eliminați poziția',
  experience: 'Experienţă',
  ikoQual: 'Calificarea IKO',
  rfQual: 'Categorie',
  tournamentReference: 'Referință la turneu.',
  reportWinners: 'Lista câștigătorilor turneului pe categorii.',
  reportCoachClub:
    'Lista generală a participanților la turneu după antrenor/club.',
  reportListOfCategoties:
    'Lista rezumată a participanților la turneu pe categorii.',
  reportParticipants: 'Formarea numerelor de înregistrare ale participanților.',
  reportRegistration:
    'Lista participanților la turneu care au trecut de înregistrare.',
  reportRunners: 'Formarea chitanțelor pentru înregistrarea la turneu.',
  reportRatting:
    'Categorie - Kumite, Evaluarea sportivilor pe baza rezultatelor luptelor.',
  reportCategories:
    'Lista generală a categoriilor de turnee cu setări și numărul de participanți.',
  reportCategoryType: 'Tabloul turneului la categoria {{name}}.',
  reportApplicants: 'Lista candidaților pentru participare la eveniment.',
  reportCategoryDistribution: 'Distribuția categoriilor după tatami.',
  tatami: 'Tatami',
  tatamis: 'Tatami',
  broadcastLink: 'Link de difuzare',
  videoLinks: 'Link-uri video',
  blocksCount: 'Numărul de blocuri',
  block: 'Bloc {{count}}',
  tatamiBlock: 'Bloc',
  addDeleteBlock: 'Ștergere / Adăugă blocul',
  blockName: 'Nume bloc',
  tatamiCategories: 'Tatami/Categorii',
  currentPassword: 'Parola Actuală',
  newPassword: 'Parola Noua',
  invalidCharacters: 'Caractere Inacceptabile',
  cannotHaveMoreThan32Chars: 'Nu poate contine mai mult decat 32 caractere',
  actionFailed: 'Acțiunea nu a fost finalizată',
  invalidValue: 'Valoare nevalidă',
  invalidDate: 'Data este nevalidă',
  invalidTime: 'Ora nevalidă',
  futureDate:
    'Data nu trebuie să fie anterioară datei curente și datei de înregistrare.',
  registrationDateError:
    'Data trebuie să coincidă sau să fie anterioară datei de începere a evenimentului',
  minimumAllowableWeight: 'Greutatea nu poate fi mai mica decat 10',
  noCategories: 'Categoriile Lipsesc',
  approvedAthletes: 'Sportivi autorizați',
  awaitingApproval: 'În așteptarea aprobării',
  approvedParticipants: 'Participanti acceptati',
  unapprovedParticipants: 'Participanti neacceptati',
  participantKicked: 'Participant Eliminat',
  newParticipant: 'Participant nou',
  save: 'Salveaza',
  Cancel: 'Anulare',
  closeDiscard: 'Confirmați',
  cancelUnsavedChangessMsg:
    'Închiderea acestei ferestre va anula orice modificări nesalvate.',
  discardChanges: 'Renunți la modificări?',
  noOptionsText: 'Nu sunt opțiuni',
  openText: 'Deschide',
  clearText: 'Sterge',
  noResults: 'Niciun rezultat',
  noRecords: 'Momentan nu există înregistrări.',
  recorderNoEvents: 'Momentan nu aveți acces la niciun eveniment',
  createRecordsMsg: 'Ai ocazia să faci un plan pentru evenimentele viitoare!',
  butCreateRecordsMsg:
    'Cu toate acestea, aveți ocazia să planificați evenimente viitoare!',
  profile: 'Profilul meu',
  userDetails: 'Detalii despre utilizator',
  invite: 'Invită',
  invitationsSent: 'Invitațiile au fost trimise',
  defaultInvitationTxt: 'Textul implicit al invitației',
  invitationTxtRu: 'Textul invitație în rusă',
  invitationTxtRo: 'Textul invitație în română',
  link2QuickRegistration: 'Link către înregistrarea electronică.',
  teamRepresentative: 'Reprezentantul echipei',
  teamRepresentativeNoSelection:
    'Vă rugăm să selectați un reprezentant al echipei.',
  sendTo: 'Catre',
  previewTooltip: 'Previzualizare',
  preview: 'Previzualizare e-mail',
  tooManyRequests: 'Limita de invitații a fost atinsă',
  restore: 'Restabileste',
  estimatedEventTime: 'Durata estimată a evenimentului',
  totalBlocks: 'Nr.de blocuri',
  totalTatami: 'Nr. de tatami',
  totalCategories: 'Nr. de categorii',
  assignParticipant: 'Atașați participanții la categorii',
  assignParticipantToTraining: 'Atașați participanții la training',
  assignYourself: 'atașați-vă',
  enableBronzeFight: 'Lupta pentru locul 3',
  numberOfPlaces: 'Locuri premiate',
  numberOfPlacesHelperTxt:
    'Reguli Absolute European Championship (8 locuri premiate)',
  removeParticipantCateory: 'Eliminați categoria participantului',
  removeParticipantCateoryMsg:
    'Sigur doriți să eliminați participantul din această categorie {{name}}?',
  removeParticipantTournament: 'Eliminați un participant din turneu',
  removeParticipantTournamenMsg:
    'Sigur doriți să eliminați un membru din toate categoriile?',
  editParticipantCategory: 'Editați categoria participantului',
  tournamentIsOverMsg: 'Nu pot fi atașați participanți, turneul s-a încheiat.',
  assignParticipantError: 'Atribuirea participantului a eșuat',
  threeParticipantsTeamKata: 'Ai deja 6 membri în acest grup',
  renumber: 'Renumerot',
  generateBracket: 'Arată clasamentul',
  bracketInformation: 'Clasament',
  drawStatistics: 'Statistica extragerii',
  firstPlaces: 'Primele locuri',
  secondPlaces: 'Locurile secunde',
  thirdPlaces: 'Locuri trei',
  printedDiplomas: 'Diplome printate',
  unprintedDiplomas: 'Diplome neprintate',
  totalCategories: 'Total categorii',
  totalParticipants: 'Total participanți',
  noBracketMsg: 'Nu există informații privind grila turneului',
  number: 'Număr',
  tournSettings: 'Setări pentru evenimente',
  categorySettings: 'Setări de categorie',
  generalSettings: 'Setări generale pentru evenimente',
  date2CalculateNumberOfYears:
    'Data pentru calcularea numărului de ani întregi',
  permissionToDownload: 'Cine ar trebui să aibă permisiunea de a descărca?',
  permissions: 'Permisiuni',
  registrar: 'Reprezentantul echipei',
  operator: 'Operator',
  user: 'Utilizator',
  status: 'Statut',
  parameter: 'Parametru',
  value: 'Valoarea',
  coachesOnly: 'Doar antrenori',
  masterOnly: 'Doar eu',
  noPartNumber: 'Dezactivați numerotarea participanților',
  participantNumbers: 'Numerotarea participanților',
  noCountry: 'Ascundeți Țara în timpul înregistrării',
  kataFinal: 'Finalul participanților KATA',
  publishTourn: 'Postează un eveniment',
  privatizeTourn: 'Privatizați turneul',
  eventClosed: 'Evenimentul e închis',
  registrationOpened: 'Înregistrarea este deschisă',
  registrationClosed: 'Înregistrarea este închisă',
  deleteEventGrid: 'Ștergeți clasamentul',
  closeTournament: 'Sigur doriți să închideți evenimentul?',
  closeTournamentMsg:
    'Odată ce evenimentul este închis, nu veți putea realiza careva modificări.',
  userExists: 'Există deja un cont cu această adresă de e-mail',
  doesNotExist: 'nu există',
  team: 'Echipa',
  teams: 'Echipe',
  judgeTeams: 'Echipe',
  judgeTeam: 'Echipa',
  deleteJudgeTeam: 'Ștergeți echipa',
  removeParticipantFromTourn: 'Eliminați participantul din turneu',
  removeParticipantTournMsg:
    'Sigur doriți să eliminați participantul din turneu?',
  reassign: 'Reatribuiți',
  reassignParticipantBeforeCategoryUnassingment:
    'Înainte de a elimina o categorie din turneu, asigurați-vă că toți participanții din categoria respectivă trebuie reatribuiți la categorii alternative',
  assign: 'Atribuiți',
  removeParticipantTourn: 'Eliminați un participant dintr-un turneu',
  resetAll: 'Reseteaza',
  generate: 'Distributie automata',
  pendingApproval: 'Aprobare în așteptare',
  rank: 'Locul',
  selectCertificate: 'Selectați Certificatul',
  selectCoaches: 'Selectaţi antrenorii cărora doriţi să expediati acest e-mail',
  valueExists: 'Valoarea există deja',
  emptyState: 'Datele lipsesc!',
  participantList: 'Lista participanților',
  categoriesList: 'Lista categoriilor',
  filter: 'Filtru',
  export: 'Export',
  participantRegistration: 'Participanții înregistraţi',
  runners: 'Runners',
  rating: 'Rating',
  categoriesCount: 'Numărul de categorii',
  participantsCount: 'numărul de participanți',

  //Quick registration to tourn
  coachRecorderUseQuickRegLinkInfo:
    'Pentru a vă înscrie la eveniment, trebuie să primiți un link de invitație sau un cod PIN de la organizator.',
  registerToTourn: 'Înregistrarea la eveniment',
  sendRequest: 'Trimite cerere',
  downloads: 'descărcări',
  download: 'Descarcă',
  otherVersions: 'Alte Versiuni',
  dojoInfo: 'Informații despre Dojo',
  tournamentInfo: 'Informații turneu',
  myFN: 'Prenumele meu',
  myLN: 'Numele meu',
  myEmail: 'Email-ul meu',
  email: 'Email',
  confirmEmail: 'Confirmați adresa de e-mail',
  myCoach: 'Antrenorul meu',
  myCountry: 'Țara mea',
  myCity: 'Orașul meu',
  myRegion: 'Regiunea mea',
  tournDoc: 'documentele evenimentului',
  doc: 'Documente',
  branchChief: 'Branch chief',
  nameBranchChief: 'Nume complet Branch Chief',
  summary: 'Sumar',
  failedToSendRequest: 'Cererea nu poate fi trimisă',
  failedToSendRequestMsg:
    'Pentru a trimite cererea, trebuie să aveți cel puțin un participant în cel puțin o categorie',
  copyTxtMsg:
    'Dacă trebuie să editați această aplicație, utilizați linkul de mai jos:',
  openRegistration:
    'Înregistrarea este deschisă. Ultima zi de înregistrare este {{date}}',
  closedRegistration: `Înregistrarea este închisă. Ultima zi de înregistrare a fost {{date}}`,
  openJudgeRegistration:
    'Înregistrarea judecătorilor este deschisă. Ultima zi de înregistrare {{date}}',
  closedJudgeRegistration:
    'Înregistrarea judecătorilor este închisă. Ultima zi de înregistrare a fost {{date}}',
  openRegistrationBtn: 'Deschide înregistrarea la turneu',
  closeRegistrationBtn: 'Închide înregistrarea la turneu',
  disableRegistration: 'Anulați înregistrarea',
  register: 'Inregistrează',

  //tour guide
  endTour: 'Închei turul',
  closeTour: 'Închideți turul',
  registerDojo: 'Înregistrați-vă dojo-ul completând și salvând forma curentă',
  addPartStep: 'Adăugați echipa dvs. la categoriile turneului',
  registerTeamStep: 'Înregistrează-ți echipa la turneu',

  useLogo: 'Vă oferă posibilitatea de a utiliza {{name}} mai târziu',
  logo: 'Logo',
  stamp: 'Sigiliu',
  signature: 'Semnătura',
  bronzeSkirmish: 'Bronz skirmish',
  round: 'Round',
  final: 'Final',
  score: 'Scor',
  useImgLater: 'Vă oferă posibilitatea de a utiliza {{name}} mai târziu',
  sort: 'Sortează',
  descending: 'Descendent',
  ascending: 'Ascendent',
  systemCategories: 'Categorii de sistem',
  customCategories: 'Categoriile mele',
  payments: 'Plăți',
  payment: 'Plată',
  monthlyPayment: 'Lunara',
  annualPayment: 'Аnuala',
  circleRound: 'Efectuați un rotund circular',
  tameshiwari: 'Tameshiwari',
  kataByFlags: 'Kata By Flags',
  fist: 'Pumn (Seiken)',
  secondTry: 'a doua încercare',
  footRib: 'Coasta piciorului (Sokuto)',
  elbow: 'Cot (Enpi)',
  edgeOfPalm: 'Marginea palmei (Shuto)',
  outcome: 'Rezultat',
  lastNameFirst: 'Prenume, apoi nume',
  indicateKataNames: 'Setați numele disciplinelor kata',
  kataDiscipline: 'disciplinele kata',
  red: 'Roșu',
  blueSky: 'Cer albastru',
  yellow: 'Galben',
  cyan: 'Cyan',
  violet: 'Violet',
  plum: 'Plum',
  green: 'Verde',
  white: 'Alb',
  blue: 'Albastru',
  transparent: 'Dezactivați',
  color: 'Culoarea',
  colorsHelperTxt: 'Alegeți culoarea care vă place',
  scoreboardColor: 'Culoarea tabloului de bord',
  roundKataFlags: 'Runda {{count}}',
  kumite: 'Kumite',
  kata: 'Kata',
  teamKata: 'Team Kata',
  pointKumite: 'Point Kumite',
  autoSelectionByAgeSex: 'Auto-selecție în funcție de vârstă și sex/greutate',
  noReport: 'În prezent nu există un astfel de raport.',
  successRegistration: 'Ești înregistrat! Acum vă puteți autentifica.',
  alternative: 'Alternativ',
  nameMinusCategories: 'Nume minus categoria',
  namePlusCategories: 'Nume plus categoria',
  assignCategories: 'Atribuiți categorii',
  assignTatami: 'Atribuiți tatami',
  distributeCategories: 'Distribuiți categoriile între tatami',
  distributeParticipantsByCategories:
    'Distribuiți participanții între categorii',
  distributeParticipantsByCategoriesError:
    'Asigurați-vă că ați selectat un participant și o categorie',
  transfer: 'Transfer',
  copy: 'Copie',
  assigned: 'Alocate',
  unassigned: 'Nealocate',
  specifyHeight: 'Specificați Inălțimea în timpul înregistrării',
  specifyCoach2: 'Specificați un al doilea antrenor',
  male0To11: 'Băieți',
  female0To11: 'Fete',
  male11To15: 'Băieți',
  female11To15: 'Fete',
  male16To17: 'Juniori',
  female16To17: 'Juniori',
  male18plus: 'Bărbați',
  female18plus: 'Femei',
  male35plus: 'Bărbați',
  female35plus: 'Femei',
  nameRegistrar: 'Numele grefierului',
  infRegistrar: 'Informații despre grefier',
  nameCategories: 'Nume Categorie',
  nameCategoriesForm: 'Nume Categorie',
  inanimateName: 'Denumire',
  groupAddUpdate: 'Grup',
  absoluteCategory: 'Categorie absolută',
  categoryGroup: 'grupa de categorii',
  owner: 'Creat de',
  nameEvent: 'Numele evenimentului',
  requestCall: 'Solicitați un apel',
  home: 'Acasă',
  aboutUs: 'Despre noi',
  products: 'Produse',
  contacts: 'Contacte',
  myAccount: 'Contul meu',
  createAccount: 'Creați un cont',
  softwareForProfessional: 'Software pentru profesioniști',
  managementSportingEvents: 'Managementul evenimentelor sportive.',
  fast: 'RAPID',
  comfortable: 'CONFORTABIL',
  safe: 'SIGUR',
  durationDays: 'Numărul de zile',
  contactUs: 'Conecteaza-te cu noi',
  showAll: 'Toate',
  showNotAccepted: 'Nu aprobat',
  provideThisAction: 'Sigur doriți să efectuați această acțiune?',
  yes: 'Dа',
  no: 'Nu',
  participantUpColor: 'Culoarea membrului de mai sus (stânga)',
  participantDownColor: 'Culoarea membrului de mai jos (dreapta)',
  leaderboardSettings: 'Setările panoului informativ',
  district: 'Zonă',
  startDateAndTime: 'Data și ora începerii lucrărilor comisiei',
  venueAndDatesOfTheEvent: 'Locul și datele evenimentului',
  placeAndDateOfTheCommission: 'Comisia de admitere a membrilor',
  applicationsForRegistrationAcceptedUntil:
    'Cererile de înscriere a participanților sunt acceptate până la',
  applicationsForRefereeingAreAcceptedUntil:
    'Cererile de arbitraj sunt acceptate până la',
  organisingCommittee: 'Comitetul de organizare',
  chiefJudge: 'Arbitru șef al competiției',
  chiefCompetitionSecretary: 'Secretar șef de concurs',
  chairman: 'Președintele Comitetului de Organizare',
  organizingCommitteeAddress: 'Adresse des Organisationskomitees',
  theCommission: 'Comisia de admitere a membrilor',
  commissionStartDate: 'Data de începere a comisionului',
  code: 'Cod',
  administrator: 'Administrator',
  master: 'Organizator',
  queryType1: 'returnează atât utilizatorii, cât și organizatorii acestora',
  queryType2: 'returnează doar organizatorii',
  queryType3: 'returnează numai utilizatorii organizatorului',
  recorder: 'Registrator',
  regions: 'Regiuni',
  cities: 'Orase',
  report: 'Raport',
  achievements: 'Realizări',
  registrationOfJudges: 'Înregistrarea arbitrilor la turneu',
  declared: 'declarat',
  accepted: 'admis',
  submitApplication: 'Acceptă cererea',
  deleteApplication: 'Respinge cererea',
  titleForReportsPortrait: 'Antetul raportului (portret)',
  titleForReportsLandscape: 'Antetul raportului (peisaj)',
  titleDescriptionPortrait:
    'Oferă opțiunea de a utiliza un antet pentru rapoarte în format portret',
  titleDescriptionLandscape:
    'Oferă opțiunea de a utiliza un antet pentru rapoartele peisaj',
  toggleReportHead: 'Dezactivați antetele raportului',
  go2Participants: 'Deschideți Participants',
  recommendSize:
    'Rezoluție recomandată a imaginii pixeli: {{size}} (Lățime x Inălțime)',
  sportsCategory: 'Categoria sport',
  indicateSportsRank: 'Specificați Rangul sportiv în timpul înregistrării',
  reference: 'Referinţă',
  qualJudgesRF: 'Calificarea judecătorilor din Federația Rusă',
  refereePositions: 'Pozițiile de arbitru',
  categoryTypes: 'Tipuri de categorii',
  typeOfCategories: 'Tipul categoriei',
  shortName: 'Abreviere',
  boyGirl: 'Male, Female, Male, Female',
  girlBoy: 'Female, Male, Female, Male',
  boyBoy: 'Male, Male, Female, Female',
  girlGirl: 'Female, Female, Male, Male',
  groupBy: 'A se grupa cu: ',
  portraitPageOrientation: 'Portret',
  landscapePageOrientation: 'Peisaj',
  titleReport: 'Titlul raportului',
  reportSettings: 'Setări raport',
  pageSettings: 'Setări pagină',
  run: 'Alerga',
  turnOn: 'Porniți: ',
  listOfJudges: 'Lista arbitrilor pe tatami și echipe',
  compositionOfTheJury: 'Componența juriului',
  judgeSignature: 'Semnătura judecătorului șef',
  secretarySignature: 'Semnătura secretarului șef',
  stampOnReports: 'Puneți sigiliul pe rapoarte',
  signatureOnReports: 'Puneți semnătura pe rapoarte',
  judgesForm: 'Judecători',
  useQRcode: 'Utilizați codul QR când vă înregistrați',
  registration: 'Setări de înregistrare a participanților',
  showIkoField: 'Specificați IKO în timpul înregistrării',
  showQdanField: 'Specificați Kyu/Dan în timpul înregistrării',
  showRegionField: 'Specificați Regiunea în timpul înregistrării',
  showClubField: 'Specificați Clubul în timpul înregistrării',
  showCityField: 'Specificați Orașul în timpul înregistrării',
  showMiddleName: 'Specificați Patronimic în timpul înregistrării',
  prohibitChangesAfterApproval: 'Interziceți modificarea după aprobare',
  preTournamentRegistration: 'Înregistrați-vă înainte de turneu',
  importExcelHeader:
    'Importul unei liste de participanți dintr-un fișier Excel',
  importSettingsFromOtherTournament: 'Import setări',
  importSettings: 'Importați setările pentru evenimente',
  AreYouSureYouWantToImportSettings:
    'Sigur doriți să importați setările din turneul {{from}} în turneul {{to}}?',
  info: 'Informație',
  docIsBeingWritten:
    'Documentația pentru această pagină este în curs de redactare...',
  useAlternativeCategoryName: 'Utilizați un nume alternativ de categorie',
  eventInfo: 'Informații despre eveniment',
  registrarInfo: 'Reprezentantul echipei',
  enterValue: 'Introduceți valoarea',
  selectValue: 'Selectați valoarea',
  declaredWeight: 'Greutate-1',
  sendingNotifications: 'Trimiteți alerte către botul Telegram',
  switchToParticipantMode: 'Comutați la modul participant',
  switchToCategoryMode: 'Comutați la modul categorie',
  participantMode: 'Modul participant',
  categoryMode: 'Modul categorie',
  deleteTatamiMsg: 'Sigur doriți să eliminați acest tatami?',
  categoriesWillBeMoved:
    'Toate categoriile vor fi mutate la cel mai apropiat tatami disponibil.',
  lastTatamiCannotBeRemoved: 'Ultimul tatami nu poate fi îndepărtat!',
  tournamentMustHaveOneTatami: 'Turneul trebuie să aibă cel puțin un tatami.',
  showRetired: 'Afișează Retras',
  listOfWinnersByCategory: 'Lista câștigătorilor pe categorii',
  awardProcedure: 'Procedura de atribuire',
  registrarsCount: 'Numărul registratorilor',
  creationDate: 'Data creării',
  monday: 'Luni',
  tuesday: 'Marţi',
  wednesday: 'Miercuri',
  thursday: 'Joi',
  friday: 'Vineri',
  saturday: 'Sâmbătă',
  sunday: 'Duminică',
  generateEmailToTournamentRegistrar:
    'După finalizarea acestei acțiuni, va fi trimis un e-mail organizatorului cu o listă de participanți',
  showDeleted: 'Afișează șterse',
  assignParticipantTooltip: 'Alocați participanții la categorii',
  assignToJudging: 'Atribuiți judecătorilor',
  removeFromJudging: 'Eliminați din judecată',
  help: 'Ajutor',
  participantsStatisctics: 'Statistica participanților',
  tatamiStatistics: 'Statistici tatami',
  statisticsByCategory: 'Statistici pe categorii',
  statistics: 'Statistici',
  selectTatamiNames: 'Nume tatami',
  englishABCD: 'A, B, C, D',
  russianABVG: 'А, Б, В, Г',
  numbersWithRoundBackground: '❶, ❷, ❸, ❹',
  numbersInCircle: '①, ②, ③, ④',
  roman: 'Ⅰ, Ⅱ, Ⅲ, Ⅳ',
  removeAppMsg: 'Sigur doriți să dezinstalați această versiune a aplicației?',
  next: 'Mai departe',
  back: 'Înapoi',
  numberOfEntriesPerPage: 'Numărul de intrări pe pagină',
  eventAddress: 'Locația evenimentului',
  addressCommission: 'Adresa comisiei de admitere a participanților',
  committeeEmail: 'E-mailul comitetului de organizare',
  fightFor3rdPlace: 'Luptă pentru locul 3',
  generalReportSettings: 'Setări generale ale raportului',
  reportSettings: 'Setări de raportare',
  orientation: 'Orientarea paginii',
  generalCategorySettings: 'Setări generale ale categoriei',
  license: 'Licență',
  LicenseTournamentExpert:
    'Licență de utilizare a pachetului software Tournament Expert:',
  tourn: 'de turnee',
  noEvents: 'Nu sunt evenimente planificate.',
  createEvent: 'Creați un eveniment',
  diploma: 'Diplomă',
  invitationLink: 'Link invitație',
  pincode: 'Codul PIN pentru eveniment',
  closeEvent: 'Închide evenimentul',
  importParticipantsFromExcel: 'Importul participanților din fișierul Excel',
  assignToPosition: 'Numirea la post',
  dateAndTimeOfTheEvent: 'Data și ora de începere',
  categoryOpponents: 'Rivalii de categorie',
  addTeamRepresentative: 'Adaugă un reprezentant al echipei',
  applyFilter: 'Aplica filtru',
  ListOfAppliedTeamsforEvent:
    'Lista reprezentanților echipelor care au aplicat pentru eveniment.',
  deleteAttachment: 'Ștergeți documentul',
  teamRepresentativesOnly: 'Numai reprezentanții echipelor',
  teamRepresentatives: 'Reprezentanții echipei',
  editAttachment: 'Editarea documentului',
  addOrganisation: 'Adăugați o Organizație',
  RegisterOrganisationInTheAdmissionList:
    'Înregistrarea Organizației în lista de admitere',
  registrationList: 'Lista de înregistrare',
  editRep: 'Actualizați reprezentantul echipei',
  title: 'Titlu',
  rate: 'Evaluare',
  hidePastTournaments: 'Ascundeți evenimentele anterioare',
  showPastTournaments: 'Afișați evenimentele anterioare',
  userWrite: 'Scrie aici',
  sendchatMessage: 'Trimite-ne un mesaj',
  weightCategories: 'Categorii de greutate',
  regionalEvent: 'Eveniment regional',
  internationalEvent: 'Eveniment internațional',
  historyTime: 'Mesajele sunt salvate timp de 48 de ore',
  replyTo: 'Răspunde la: ',
  constantName: 'Consultant',
  messagenoFound: 'Mesajul nu a fost găsit',
  setPassword: 'Setați parola',
  fightsNumbering: 'Numerotarea luptelor',
  loadingoldMessages: 'Încărcare...',
  loadpreviousMessages: 'Încarcă postările anterioare',
  viewHistory: 'Vezi istoricul',
  representatives: 'Reprezentanți',
  emailsDoNotMatch: 'Emailurile sunt diferite',
  documentNumber: 'Numărul Documentului',
  gridorderCategory: 'Sortați după vârstă',
  showDocNumField: 'Specificați Numărul Documentului la înregistrare',
};
