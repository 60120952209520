import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({ 
  flex: {
      display: 'flex'
  },
  marginRight15: {
    marginRight: '15px'
  },
  marginTop5: {
    marginTop: '10px'
  },
  alignItems: {
    alignItems: 'center'
  },
  root: {
    padding: '50px 20px 60px',
    '@media (max-width:504px)': {
      padding: '30px 20px 30px',
      minHeight: '220px'
    },
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#262441'
  },
  menu: {
    maxWidth: '1050px',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  width: {
    width: '100%'
  },
  logo: {
    color: '#fff',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '700'
  },
  green: {
    color: '#009a78',
  },
  panelContainer: {
    justifyContent: 'space-between',
    maxWidth: '1110px',
    '@media (max-width:700px)': {
      flexDirection: 'column'
    }
  },
  wrapper: {
    marginTop: '40px'
  },
  links: {
    '@media (min-width:505px)': {
      width: '30%',
    },
    display: 'inline-block'
  },
  linkContainer: {
    margin: '0px 10px 40px 0px',
    color: '#fff',
    fontWeight: 400,
    textDecoration: 'none'
  },
  contacts: {
    flexDirection: 'column',
    textAlign: 'center',
    '@media (min-width:1001px)': {
      textAlign: 'right'
    }
  },
  number: {
    '@media (min-width:505px)': {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 600,
    },
    textDecoration: 'none'
  },
  mail: {
    lineHeight: '21px',
    letterSpacing: '0.05em'
  },
  text: {
    marginTop: '11px',
    fontSize: '15px',
    whiteSpace: 'nowrap',
    color: '#fff'
  },
  socialLinks: {
    marginTop: '11px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (min-width:1001px)': {
      justifyContent: 'right'
    }
  },
  youtube: {
    width: '35px',
    height: '35px'
  },
  facebook: {
    width: '25px',
    height: '25px'
  },
  transparent: {
    border: '1px solid #E3E7EF',
    color: '#E3E7EF',
    fontSize: '13px',
    padding: '15px 35px',
    marginBottom: '25px'
  },
  block: {
    width: '150px',
    height: '50px',
    borderRadius: '5px'
  }, 
  marginLeft20: {
    marginLeft: '20px'
  },
  marginLeft40: {
    '@media (min-width:505px)': {
      marginLeft: '40px'
    },
    '@media (max-width:504px)': {
      marginTop: '40px'
    }
  },
  column: {
    '@media (max-width:504px)': {
      flexDirection: 'column'
    }
  }
});
