import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './StylesLoadingState';

const LoadingState = (props) => {
  const { className } = props;
  const classes = useStyles();

  return (
    <span className={clsx(classes.loadingContainer, className)}>
      <CircularProgress />
    </span>
  );
};
export default LoadingState;
