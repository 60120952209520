import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { Input } from '@material-ui/core';

import { ReactComponent as Search } from '../../assets/img/search.svg';
import { ReactComponent as Clear } from 'assets/img/clear.svg';

import { useStyles } from './StylesSearchInput';
import { Visibility } from '@material-ui/icons';

const SearchInput = (props) => {
  const {
    className,
    onChange,
    isSearchInactive,
    clearSearch,
    value,
    specificDetails,
    ...rest
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Input
        {...rest}
        className={clsx(classes.root, className ? className : classes.maxWidth)}
        {...{ value }}
        disableUnderline
        {...{ onChange }}
        placeholder={t('search')}
        startAdornment={
          <>
            {specificDetails?.componentBefore}
            <Search className={classes.icon} />
          </>
        }
        endAdornment={
          <>
            <Clear
              style={{ ...(isSearchInactive ? { visibility: 'hidden' } : {}) }}
              className={clsx(classes.icon, classes.clear)}
              onClick={clearSearch}
            />
            {specificDetails?.childrenElemEnd}
          </>
        }
        fullWidth
      />
    </>
  );
};

export default SearchInput;
