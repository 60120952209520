import { Component } from 'react';
import clsx from 'clsx';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { AuthContext } from '../../AuthContext';
import { withStyles } from '@material-ui/styles';

import {
  fetchCountries,
  toggleShow,
  textChangeHandler,
  updateUserDetails,
  fetchUsers,
  updatePassword,
  changeModal,
  changeImage,
  selectOption,
  onBlur,
  validateForm,
  downloadUplaodedFile,
  fetchReferenceRegion
} from '../../helpers/util';
import { formatDate } from '../../helpers/selectors';
import { changeTitle } from 'helpers/actions';
import {
  CLOSE_DISCARD,
  KEEP_EDITING,
  OK,
  serverDefaultPath
} from '../../helpers/constants';
import { ReactComponent as Logo } from '../../assets/img/logo_tournament_manager.svg';

import AccountDetails from '../../components/AccountDetails/AccountDetails';
import SideModal from '../../components/Snackbar/SideModal';
import Password from '../../components/Password/Password';
import Modal from '../../components/Modal/ModalNewDesign';
import styles from './styles';

class Account extends Component {
  constructor() {
    super();
    this.searchParams = new URLSearchParams(window.location.search);
    this.userParamId = this.searchParams.get('user_id');
    this.fetchCountries = fetchCountries.bind(this);
    this.toggleShow = toggleShow.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.updateUserDetails = updateUserDetails.bind(this);
    this.fetchUsers = fetchUsers.bind(this);
    this.updatePassword = updatePassword.bind(this);
    this.changeModal = changeModal.bind(this);
    this.changeImage = changeImage.bind(this);
    this.selectOption = selectOption.bind(this);
    this.onBlur = onBlur.bind(this);
    this.validateForm = validateForm.bind(this);
    this.downloadUplaodedFile = downloadUplaodedFile.bind(this);
    this.fetchReferenceRegion = fetchReferenceRegion.bind(this);
  }

  state = {
    //add imagePreview variable with null properties of default
    userForm: {},
    userFormErrors: {},
    countries: [],
    allRegions: [],
    selectedDate: new Date(),
    showModal: false,
    success: false,
    modalInfo: '',
    isLoading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    passwordForm: {},
    passwordErrors: {},
    isPasswordVisible: {},
    showError: {},
    open: false,
    viewportWidth: window.innerWidth,
    pressedBtn: null
  };

  static contextType = AuthContext;

  componentDidMount() {
    const { t } = this.props;

    this.fetchUsers(
      this.userParamId ? this.userParamId : localStorage.getItem('user_id'),
      'userForm',
      (user) => {
        user.country_id && this.fetchReferenceRegion(user.country_id);
        this.fetchStampSignature(user, true);
      }
    );

    this.fetchCountries();
    changeTitle(!this.userParamId ? t('profile') : t('userDetails'));
  }

  componentDidUpdate(prevProps, prevState) {
    const { userForm, isLoading, passwordForm, langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      !isLoading &&
      (userForm.first_name !== prevState.userForm.first_name ||
        userForm.last_name !== prevState.userForm.last_name ||
        userForm.profile_pic !== prevState.userForm.profile_pic)
    ) {
      this.setState({ shouldReloadPage: true });
    }

    if (langOnLoad !== currentLang) {
      changeTitle(t(!this.userParamId ? 'profile' : 'userDetails'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  onResize = () => this.setState({ viewportWidth: window.innerWidth });

  dateChangeHandler = (date) => {
    const formatted_date = formatDate(date);
    this.setState((prevState) => ({
      userForm: {
        ...prevState.userForm,
        birthday: formatted_date
      },
      selectedDate: date
    }));
  };
  closeModalHandler = () => this.setState({ showModal: false });

  fetchStampSignature = (user, shouldUpdateImg, previewStampSingature) => {
    if (user.stamp_id) {
      if (shouldUpdateImg || user.stamp) {
        this.downloadUplaodedFile(
          user.stamp_id,
          null,
          (file) => {
            this.setState((prevState) => ({
              userForm: {
                ...prevState.userForm,
                stampPreview: URL.createObjectURL(file)
              }
            }));
          },
          true
        );
      } else {
        this.setState((prevState) => ({
          userForm: {
            ...prevState.userForm,
            stampPreview: previewStampSingature.stampPreview
          }
        }));
      }
    }
    if (user.signature_id) {
      if (shouldUpdateImg || user.signature) {
        this.downloadUplaodedFile(
          user.signature_id,
          null,
          (file) => {
            this.setState((prevState) => ({
              userForm: {
                ...prevState.userForm,
                signaturePreview: URL.createObjectURL(file)
              }
            }));
          },
          true
        );
      } else {
        this.setState((prevState) => ({
          userForm: {
            ...prevState.userForm,
            signaturePreview: previewStampSingature.signaturePreview
          }
        }));
      }
    }
  };

  updateProfile = (evt) => {
    const { navigate, location } = this.props;
    const { userForm, shouldReloadPage } = this.state;

    //save in const signature and stamp preview
    const previewStampSingature = {
      signaturePreview: userForm.signaturePreview,
      stampPreview: userForm.stampPreview
    };

    this.updateUserDetails(evt, userForm, () => {
      if (!this.userParamId) {
        this.fetchUsers(
          userForm.id,
          'userForm',
          (user) => {
            this.fetchStampSignature(user, null, previewStampSingature);
            localStorage.setItem(
              'name',
              [user.first_name, user.last_name].join(' ')
            );
            localStorage.setItem('first_name', user.first_name);
            localStorage.setItem('last_name', user.last_name);
            user.photo &&
              localStorage.setItem('avatar', serverDefaultPath + user.photo);
            if (shouldReloadPage) {
              window.location.reload();
              this.setState({ shouldReloadPage: false });
            }
          },
          null,
          null,
          true
        );
      } else
        navigate('/users', {
          state: { prevUrl: location.pathname }
        });
    });
  };

  showModal = (evt) => {
    evt.preventDefault();
    const pressedBtn = evt.currentTarget.id;
    this.setState({ open: true, pressedBtn });
  };

  hideModal = () => {
    this.setState({
      open: false,
      pressedBtn: null
    });
  };

  changePassword = (evt) => {
    const { passwordForm } = this.state;

    this.validateForm(
      evt,
      { previous_password: '', password: '', confirm_password: '' },
      passwordForm,
      'passwordErrors',
      () => this.updatePassword(evt, passwordForm),
      () => {
        this.setState({
          showError: {
            previous_password: true,
            password: true,
            confirm_password: true
          }
        });
      }
    );
  };

  fetchRegionBySelectedCountry = (key) => {
    const { userForm } = this.state;
    let cpy = { ...userForm };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ userForm: cpy });
  };

  onUpdateUser = (evt) => {
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        userForm: {
          ...prevState.userForm,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.userForm;

        this.validateForm(
          evt,
          {
            first_name: '',
            last_name: ''
          },
          form,
          'userFormErrors',
          () => this.updateProfile(evt)
        );
      }
    );
  };

  render() {
    const {
      userForm,
      countries,
      allRegions,
      selectedDate,
      showModal,
      success,
      modalInfo,
      passwordForm,
      passwordErrors,
      isPasswordVisible,
      userFormErrors,
      showError,
      open,
      viewportWidth,
      pressedBtn
    } = this.state;
    const { t, classes } = this.props;
    const { role } = this.context.authState;

    let dialogTitle,
      onSwitchBetween,
      dialogContent,
      onClick,
      subHeader,
      buttonPurpose;

    if (pressedBtn === 'changePassword') {
      dialogTitle = t('changePassword');
      dialogContent = (
        <>
          <span>{t('requiredPasswordLength')}</span>
          <Password
            handleChange={(evt) =>
              this.textChangeHandler(
                evt,
                'passwordForm',
                passwordErrors,
                null,
                passwordForm
              )
            }
            values={passwordForm}
            errors={passwordErrors}
            toggleShow={this.toggleShow}
            isPasswordVisible={isPasswordVisible}
            onBlur={this.onBlur}
            showError={showError}
          />
        </>
      );
      buttonPurpose = t('submitChange');
      onClick = this.changePassword;
    } else if (pressedBtn === 'license') {
      dialogContent = (
        <div className={clsx(classes.flex, classes.column, classes.wrapper)}>
          <Logo className={clsx(classes.img)} />
          <span className={classes.marginBottom1}>
            {t('LicenseTournamentExpert')}
          </span>
          <span className={classes.marginRightAuto}>
            {['100', t('tourn')].join(' ')}
          </span>
        </div>
      );
      dialogTitle = t('license');
      onClick = this.hideModal;
      buttonPurpose = OK;
    }

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{
            dialogTitle,
            onSwitchBetween,
            dialogContent,
            onClick,
            buttonPurpose,
            subHeader
          }}
        />
        {/* <Modal
          {...{ open }}
          close={this.hideModal}
          onClick={this.changePassword}
          dialogTitle={t('changePassword')}
          dialogContent={t('requiredPasswordLength')}
          buttonPurpose={t('submitChange')}
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}>
          <Password
            handleChange={(evt) =>
              this.textChangeHandler(
                evt,
                'passwordForm',
                passwordErrors,
                null,
                passwordForm
              )
            }
            values={passwordForm}
            errors={passwordErrors}
            toggleShow={this.toggleShow}
            isPasswordVisible={isPasswordVisible}
            onBlur={this.onBlur}
            showError={showError}
          />
        </Modal> */}
        <AccountDetails
          updateUser={(evt) => this.onUpdateUser(evt)}
          values={userForm}
          {...{ countries }}
          handleChange={(evt) =>
            this.textChangeHandler(evt, 'userForm', userFormErrors)
          }
          dateChange={this.dateChangeHandler}
          {...{ selectedDate }}
          errors={userFormErrors}
          {...{ role }}
          onChangeImg={this.changeImage}
          showChangePassword={!this.userParamId}
          showModal={(evt) => this.showModal(evt)}
          selectOption={this.selectOption}
          fetchRegionBySelectedCountry={this.fetchRegionBySelectedCountry}
          {...{ allRegions }}
          {...{ viewportWidth }}
        />
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(Account)));
