import clsx from 'clsx';

import { TopbarP2 } from './components';
import { FooterP2 } from 'layouts/Main/components';
import { useStyles } from './StylesMinimal';

const Minimal = (props) => {
  const { children, hideNav, showExitBtn, pageTitle } = props;
  const classes = useStyles();

  return (
    <>
      <TopbarP2 {...{ pageTitle }} {...{ showExitBtn }} {...{ hideNav }} />
      <main className={clsx(classes.body)}>{children}</main>
      <FooterP2 {...{ hideNav }} />
    </>
  );
};

export default Minimal;
