import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import SideModal from '../../components/Snackbar/SideModal';
import EmailFormP2 from '../../components/EmailFormP2/EmailFormP2';
//old versoin
//import EmailForm from '../../components/EmailForm/EmailForm';
import { changeTitle } from '../../helpers/actions';
import {
  textChangeHandler,
  changeModal,
  sentEmailToResetPassword,
  validateForm
} from '../../helpers/util';
import PatternP2 from '../../components/PatternP2/PatternP2';
//old version
//import Pattern from '../../components/Pattern/Pattern';

class CouldNoutLogin extends Component {
  state = {
    isSending: false,
    success: false,
    showModal: false,
    open: false,
    couldNotLoginForm: {},
    couldNotLoginErrors: {}
  };
  textChangeHandler = textChangeHandler.bind(this);
  changeModal = changeModal.bind(this);
  sentEmailToResetPassword = sentEmailToResetPassword.bind(this);
  validateForm = validateForm.bind(this);

  componentDidMount() {
    changeTitle(this.props.t('loginIssue'));
    sessionStorage.clear();
  }
  hideSnackBar = () => this.setState({ showModal: false });
  openCouldNotLogin = () => {
    this.setState({
      shouldOpenCouldNotLogin: true,
      couldNotLoginForm: { email: '' },
      couldNotLoginErrors: { email: '' }
    });
  };

  render() {
    const {
      couldNotLoginErrors,
      couldNotLoginForm,
      isSending,
      success,
      showModal,
      modalInfo
    } = this.state;

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <PatternP2>
          <EmailFormP2
            textChange={(evt) =>
              this.textChangeHandler(
                evt,
                'couldNotLoginForm',
                couldNotLoginErrors
              )
            }
            sentEmailToResetPassword={(evt, action) => {
              this.validateForm(
                evt,
                { email: '' },
                couldNotLoginForm,
                'couldNotLoginErrors',
                () =>
                  this.sentEmailToResetPassword(evt, couldNotLoginForm, action)
              );
            }}
            errors={couldNotLoginErrors}
            {...{ isSending }}
          />
        </PatternP2>
      </>
    );
  }
}
export default withTranslation()(CouldNoutLogin);
