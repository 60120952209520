import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },
  paper: {
    padding: '21px',
    margin: '20px 0',
    backgroundColor: '#AEBBD0',
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: '20px',
    transitionDuration: '0.8s',
    alignContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    }
  },
  expandLess: {
    transform: 'rotate(180deg)'
  },
  export: {
    width: '180px'
  },
  left: {
    marginLeft: 'auto'
  },
  marginRight: {
    marginRight: '5px'
  },
  checkboxes: {
    margin: '15px 0',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
    alignContent: 'center'
  },
  marginTop5: {
    marginTop: '5px'
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  marginLeft10: {
    marginLeft: '10px'
  },
  numberWrapper: {
    paddingRight: '21px'
  },
  textWrapper: {
    padding: '21px',
    borderRadius: '0 10px 10px 0'
  },
  emptyState: {
    marginTop: '40px'
  }
});
