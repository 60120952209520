import { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ro, ru, de } from 'date-fns/locale';
import clsx from 'clsx';
import UploadImage from '../UploadImage/UploadImage';
import { TextField } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Filter from '../Filter/Filter';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker
} from '@material-ui/pickers';
import {
  DATE_PLACEHOLDER,
  ID,
  COUNTRY_ID,
  TYPE,
  PRIZE_PLACES,
  LABEL,
  ENABLED,
  NAME,
  getEventTypes,
  EMAIL
} from '../../helpers/constants';
import CheckboxBtn from '../CheckboxBtn/CheckboxBtn';
import {
  selectedValue,
  countryLabel,
  isSchool,
  finishedTournament,
  isMobileView
} from '../../helpers/selectors';
import { ReactComponent as TimeIcon } from '../../assets/img/watch.svg';
import { ReactComponent as DateIcon } from '../../assets/img/calendar.svg';
import { ReactComponent as Bronze } from '../../assets/img/thirdPlaceMedal.svg';
import { ReactComponent as CircleRound } from '../../assets/img/circle_round.svg';
import Button from '../Buttons/ActionButtons';
import { useStyle } from './Styles';
import { AuthContext } from 'AuthContext';

const TournamentForm = ({
  handleTextChange,
  countries,
  tournamentDateChange,
  registrationDateChange,
  tournamentTimeChange,
  errors,
  imageChange,
  values,
  saveForm,
  selectedCheckboxes,
  selectCheckbox,
  selectOption,
  state,
  specificDetails,
  showModal,
  calculateNumberOfYearsDateChange,
  tatamisTheme,
  fetchRegionBySelectedCountry,
  allRegions,
  tournamentData,
  viewportWidth,
  tgOrderTypes,
  hasKataOrKataGroup,
  hasTameshiwari
}) => {
  const [openBlocks, setOpenBlocks] = useState([
    ...(specificDetails?.expandBlock ? ['theCommission'] : [])
  ]);

  const classes = useStyle();
  const { t } = useTranslation();
  const isTraining = isSchool(values);

  const currentLang = localStorage.getItem('i18nextLng');
  const context = useContext(AuthContext);
  const role = context.authState.role;
  const isTournamentDefinitelyColsed =
    localStorage.getItem('role') !== 'administrator' &&
    finishedTournament(tournamentData);
  const isNotMaster =
    role === 'master' && tournamentData?.id && !+tournamentData?.is_master;
  const noAccess2Tourn =
    !!+tournamentData?.finished || isNotMaster || isTournamentDefinitelyColsed;
  const mobileScreen = isMobileView(viewportWidth);
  const is_finished = 'is_finished';
  const colors = [
    { id: 'white', name: t('white') },
    { id: 'red', name: t('red') },
    { id: 'blue', name: t('blue') },
    { id: 'transparent', name: t('transparent') }
  ];

  const simbols = [
    { id: 'en', name: t('englishABCD') },
    { id: 'ru', name: t('russianABVG') },
    { id: 'nw', name: t('numbersWithRoundBackground') },
    { id: 'nb', name: t('numbersInCircle') },
    { id: 'ri', name: t('roman') }
  ];

  const toggleBlocks = (key) => {
    const checkedBlocks = openBlocks.includes(key)
      ? openBlocks.filter((it) => it !== key)
      : [...openBlocks, key];

    setOpenBlocks(checkedBlocks);
  };

  useEffect(() => {
    if (
      Object.keys(errors)?.length > 0 &&
      !openBlocks?.includes('theCommission')
    ) {
      setOpenBlocks((prev) => [...prev, 'theCommission']);
    }
  }, [errors, openBlocks]);

  const filteredDownColors =
    values.participant_up_color !== 'transparent'
      ? colors.filter((color) => color.id !== values.participant_up_color)
      : colors;

  const filteredTopColors =
    values.participant_down_color !== 'transparent'
      ? colors.filter((color) => color.id !== values.participant_down_color)
      : colors;

  const generalSettingsCheck = [
    {
      id: 'country',
      country: true,
      required: true
    },
    {
      id: 'region',
      region: true,
      label: t('district')
    },
    {
      id: 'city',
      city: true
    },
    {
      id: 'tg_order_type',
      tg_order_type: true
    },
    {
      id: 'type',
      type: true
    },
    ...(!isTraining
      ? [
          {
            id: 'no_participant_number',
            label: t('noPartNumber'),
            hasCheckbox: true
          }
        ]
      : []),
    {
      id: 'registration_active',
      label: t('closeRegistrationBtn'),
      hasCheckbox: true
    },
    {
      id: 'published',
      label: t('publishTourn'),
      hasCheckbox: true
    },
    {
      id: 'use_notification_win',
      label: t('sendingNotifications'),
      hasCheckbox: true
    },
    {
      id: is_finished,
      label: t('closeEvent'),
      hasCheckbox: true
    },
    {
      id: 'section_import_on',
      label: t('importParticipantsFromExcel'),
      hasCheckbox: true
    },
    {
      id: 'limited_reg_on',
      label: t('registrationList'),
      hasCheckbox: true
    },
    {
      id: 'calc_age_date',
      label: t('date2CalculateNumberOfYears')
    }
  ];

  const participantRegistrationSettingsCheck = [
    {
      id: 'last_name_first',
      label: t('lastNameFirst'),
      hasCheckbox: true
    },
    {
      id: 'reg_judge_form',
      label: t('registrationOfJudges'),
      hasCheckbox: true
    },
    {
      id: 'use_reg_online',
      label: t('preTournamentRegistration'),
      hasCheckbox: true
    },
    {
      id: 'use_qr',
      label: t('useQRcode'),
      hasCheckbox: true
    },
    {
      id: 'show_reg_qdan',
      label: t('showQdanField'),
      hasCheckbox: true
    },
    {
      id: 'height_needed',
      label: t('specifyHeight'),
      hasCheckbox: true
    },
    {
      id: 'sport_rank_needed',
      label: t('indicateSportsRank'),
      hasCheckbox: true
    },
    {
      id: 'show_reg_patronymic',
      label: t('showMiddleName'),
      hasCheckbox: true
    },
    {
      id: 'show_reg_coach_2',
      label: t('specifyCoach2'),
      hasCheckbox: true
    },
    {
      id: 'show_reg_iko',
      label: t('showDocNumField'),
      hasCheckbox: true
    },
    {
      id: 'no_country',
      label: t('noCountry'),
      hasCheckbox: true
    },
    {
      id: 'show_reg_region',
      label: t('showRegionField'),
      hasCheckbox: true
    },
    {
      id: 'show_reg_city',
      label: t('showCityField'),
      hasCheckbox: true
    },
    {
      id: 'show_reg_club',
      label: t('showClubField'),
      hasCheckbox: true
    },
    {
      id: 'disallow_change_request',
      label: t('prohibitChangesAfterApproval'),
      hasCheckbox: true
    }
  ];

  const categorySettingsCheck = [
    {
      id: 'bronze_fight_enabled',
      label: (
        <span className={clsx(classes.flex, classes.alignItems)}>
          {t('fightFor3rdPlace')}
          <Bronze className={classes.marginLeft05} />
        </span>
      ),
      hasCheckbox: true,
      gridArea: 'bronze_fight_enabled'
    },
    ...(hasTameshiwari
      ? [
          {
            id: 'tameshiwari',
            hasCheckbox: true,
            gridArea: 'tameshiwari'
          }
        ]
      : []),
    { id: 'places_number', gridArea: 'places_number' },
    ...(hasKataOrKataGroup
      ? [
          {
            id: 'kata_final',
            gridArea: 'kata_final'
          }
        ]
      : []),
    {
      id: 'circle_round',
      label: (
        <span className={clsx(classes.flex, classes.alignItems)}>
          {t('circleRound')}
          <CircleRound className={classes.marginLeft05} />
        </span>
      ),
      hasCheckbox: true,
      gridArea: 'circle_round'
    },
    {
      id: 'use_alt_category_name',
      label: t('useAlternativeCategoryName'),
      hasCheckbox: true,
      gridArea: 'use_alt_category_name'
    }
  ];

  const leaderboardSettingsCheck = [
    { id: 'scoreboard_theme' },
    { id: 'participant_up_color' },
    { id: 'participant_down_color' },
    { id: 'TATAMI_NAME' }
  ];

  const allCheckboxes = [
    ...(!specificDetails?.hideComponent
      ? [
          {
            id: 'generalSettings',
            name: t('tournSettings'),
            checkboxes: generalSettingsCheck,
            className: classes.gridGeneralSettings
          },
          {
            id: 'registration',
            name: t('registration'),
            checkboxes: participantRegistrationSettingsCheck,
            className: classes.gridGeneralSettings
          },
          ...(!isTraining
            ? [
                {
                  id: 'categorySettings',
                  name: t('categorySettings'),
                  checkboxes: categorySettingsCheck,
                  className: classes.gridCategoriesSettings
                },
                {
                  id: 'leaderboardSettings',
                  name: t('leaderboardSettings'),
                  tooltip: t('colorsHelperTxt'),
                  checkboxes: leaderboardSettingsCheck,
                  className: classes.gridColorSettings
                }
              ]
            : [])
        ]
      : [])
  ];

  const blockName = (name, open, onClick) => {
    return (
      <span
        {...{ onClick }}
        className={clsx(classes.flex, classes.blockname, classes.fullWidth)}>
        {name}
        <ArrowDropDownIcon
          className={clsx(classes.arrow, open && classes.arrowDropUp)}
        />
      </span>
    );
  };

  const showSettings = () => (
    <div className={classes.marginLeft294}>
      {allCheckboxes.map(({ id, checkboxes, name, tooltip, className }) => (
        <Fragment key={`${name}_${id}`}>
          {name &&
            blockName(name, openBlocks.includes(id), () => toggleBlocks(id))}
          {openBlocks.includes(id) && (
            <>
              {tooltip && <div className={classes.tooltip}>{tooltip}</div>}
              <span className={clsx(classes.grid, className)} key={name}>
                {checkboxes.map(
                  ({ id, label, hasCheckbox, gridArea, className }) => (
                    <Fragment key={id}>
                      {hasCheckbox && (
                        <CheckboxBtn
                          style={{ gridArea: gridArea }}
                          classForLabel={
                            id === 'tameshiwari' && classes.tameshiwari
                          }
                          formControlClassName={clsx(
                            classes.marginRight0,
                            className
                          )}
                          disabled={
                            id !== is_finished
                              ? noAccess2Tourn
                              : isTournamentDefinitelyColsed
                          }
                          checked={selectedCheckboxes.includes(id)}
                          onChange={() => selectCheckbox(id)}
                          label={
                            id === 'tameshiwari' ? (
                              <span
                                className={clsx(
                                  classes.flex,
                                  classes.alignItems
                                )}>
                                {t('tameshiwari')}
                                {textFieldComponent(
                                  'tameshiwari',
                                  null,
                                  [
                                    classes.tameshiwariInput,
                                    classes.marginLeft1
                                  ],
                                  null,
                                  null,
                                  true,
                                  !selectedCheckboxes.includes('tameshiwari')
                                )}
                              </span>
                            ) : (
                              label
                            )
                          }
                        />
                      )}
                      {!isTraining &&
                        id === 'kata_final' &&
                        textFieldComponent(
                          'kata_final',
                          t('kataFinal'),
                          null,
                          null,
                          null,
                          true
                        )}
                      {!isTraining &&
                        id === 'places_number' &&
                        filter(
                          t('numberOfPlaces'),
                          PRIZE_PLACES,
                          'places_number',
                          LABEL,
                          LABEL,
                          null,
                          null,
                          null
                        )}
                      {!isTraining &&
                        id === 'scoreboard_theme' &&
                        filter(
                          t('scoreboardColor'),
                          tatamisTheme,
                          'scoreboard_theme',
                          ID,
                          NAME,
                          null,
                          null,
                          null
                        )}
                      {!isTraining &&
                        id === 'participant_up_color' &&
                        filter(
                          t('participantUpColor'),
                          filteredTopColors,
                          'participant_up_color',
                          ID,
                          NAME,
                          null,
                          null,
                          null
                        )}
                      {!isTraining &&
                        id === 'participant_down_color' &&
                        filter(
                          t('participantDownColor'),
                          filteredDownColors,
                          'participant_down_color',
                          ID,
                          NAME,
                          null,
                          null,
                          null
                        )}
                      {!isTraining &&
                        id === 'calc_age_date' &&
                        dateComponent(
                          calculateNumberOfYearsDateChange,
                          label,
                          id
                        )}
                      {id === 'participant_down_color' &&
                        filter(
                          t('selectTatamiNames'),
                          simbols,
                          'tatami_set',
                          ID,
                          NAME,
                          null,
                          null,
                          null
                        )}
                      {id === 'type' &&
                        filter(
                          t('eventType'),
                          getEventTypes(),
                          TYPE,
                          ID,
                          LABEL,
                          Boolean(errors[TYPE]),
                          errors[TYPE],
                          true,
                          null,
                          null,
                          null
                        )}

                      {id === 'country' &&
                        filter(
                          t('country'),
                          countries,
                          COUNTRY_ID,
                          ID,
                          {
                            firstName: 'iso3',
                            lastName: countryLabel(currentLang),
                            parathesis: true
                          },
                          Boolean(errors[COUNTRY_ID]),
                          errors[COUNTRY_ID],
                          true,
                          null,
                          (val) => fetchRegionBySelectedCountry(val),
                          null,
                          null,
                          null,
                          role === 'master'
                        )}

                      {id === 'region' &&
                        (allRegions?.length > 0
                          ? filter(
                              t('district'),
                              allRegions,
                              'region',
                              NAME,
                              NAME,
                              Boolean(errors?.region),
                              errors?.region,
                              null,
                              null,
                              null,
                              true,
                              { name: values.region }
                            )
                          : textFieldComponent(
                              'region',
                              t('district'),
                              NAME,
                              NAME,
                              Boolean(errors?.region),
                              errors?.region,
                              null,
                              null,
                              true,
                              { name: values.region }
                            ))}

                      {id === 'city' &&
                        textFieldComponent(
                          'city',
                          t('city'),
                          NAME,
                          NAME,
                          Boolean(errors?.city),
                          errors?.city,
                          null,
                          null,
                          true,
                          { name: values.city }
                        )}

                      {id === 'tg_order_type' &&
                        Array.isArray(tgOrderTypes) &&
                        filter(
                          t('fightsNumbering'),
                          tgOrderTypes,
                          'tg_order_type',
                          ID,
                          NAME,
                          Boolean(errors['tg_order_type']),
                          errors['tg_order_type'],
                          true,
                          null,
                          null,
                          null
                        )}
                    </Fragment>
                  )
                )}
              </span>
            </>
          )}
        </Fragment>
      ))}
    </div>
  );

  const mainTournInputs = [
    {
      name: 'address',
      label: t('eventAddress'),
      required: true,
      gridArea: 'address'
    },
    { time: true, gridArea: 'time' },
    { startEvent: true, gridArea: 'startEvent' },
    {
      name: 'duration_days',
      label: t('durationDays'),
      gridArea: 'duration_days'
    },
    {
      name: 'tournament_name',
      label: t('eventName'),
      required: true,
      gridArea: 'tournament_name'
    },
    {
      name: 'description',
      label: t('description'),
      rows: true,
      gridArea: 'description'
    }
  ];

  const commissionInputs = [
    { endReg: true, gridArea: 'endReg' },
    { reg_judge_date: true, gridArea: 'reg_judge_date' },
    { regDate: true, gridArea: 'regDate' },
    { reg_time: true, gridArea: 'reg_time' },
    {
      name: 'reg_address',
      label: t('addressCommission'),
      gridArea: 'reg_address'
    }
  ];

  const organisingCommitteeInputs = [
    ...(!specificDetails?.hideComponent
      ? [
          {
            name: 'main_judge',
            label: t('mainJudge'),
            gridArea: 'main_judge'
          },
          {
            name: 'secretary',
            label: t('secretary'),
            gridArea: 'secretary'
          },
          {
            name: 'org_phone',
            label: t('phone'),
            gridArea: 'org_phone'
          },
          {
            name: 'org_email',
            label: t('committeeEmail'),
            gridArea: 'org_email'
          },
          {
            name: 'chairman',
            label: t('chairman'),
            gridArea: 'chairman'
          },
          {
            name: 'org_address',
            label: t('organizingCommitteeAddress'),
            gridArea: 'org_address'
          }
        ]
      : [])
  ];

  const allInputs = [
    {
      id: 'mainTournInputs',
      inputs: mainTournInputs,
      className: classes.gridMainTournInputs
    },
    ...(!isTraining
      ? [
          {
            id: 'theCommission',
            inputs: commissionInputs,
            name: t('theCommission'),
            className: classes.gridCommission
          },
          ...(!specificDetails?.hideComponent
            ? [
                {
                  id: 'organisingCommittee',
                  inputs: organisingCommitteeInputs,
                  name: t('organisingCommittee'),
                  className: classes.gridСommittee
                }
              ]
            : [])
        ]
      : [])
  ];

  const switchBtns = [
    ...(!specificDetails?.hideComponent
      ? [
          ...(!isTournamentDefinitelyColsed
            ? [
                {
                  label: t('save'),
                  onClick: saveForm,
                  gridArea: 'save',
                  className: mobileScreen && classes.marginBottom5
                }
              ]
            : []),
          ...((specificDetails?.showEventBtns || !state) && values?.id
            ? [
                {
                  onClick: showModal,
                  label: t('importSettingsFromOtherTournament'),
                  id: 'IMPORT_SETTINGS',
                  className: [classes.disabled],
                  gridArea: 'import',
                  disabled: noAccess2Tourn
                },
                ...(!isTraining
                  ? [
                      {
                        onClick: showModal,
                        label: t('deleteEventGrid'),
                        id: 'REMOVE_TBK',
                        className: [classes.disabled],
                        gridArea: 'remove',
                        disabled: noAccess2Tourn
                      }
                    ]
                  : [])
              ]
            : [])
        ]
      : [])
  ];

  const buttonComponent = (
    label,
    onClick,
    className,
    isNoActionBtn,
    id,
    disabled,
    gridArea
  ) => (
    <Button
      {...{ id }}
      className={clsx(className)}
      isSaveBtn={!isNoActionBtn}
      {...{ onClick }}
      {...{ label }}
      {...{ disabled }}
      style={{ gridArea: gridArea }}
    />
  );

  const dateComponent = (onChange, label, param, className) => (
    <MuiPickersUtilsProvider
      locale={[ru, ro, de].find((it) => it.code === currentLang)}
      utils={DateFnsUtils}>
      <KeyboardDatePicker
        className={clsx(classes.asterisk, className)}
        InputLabelProps={{
          classes: {
            root: classes.overlappingLabel,
            shrink: classes.labelShrinked
          }
        }}
        disableToolbar
        format="dd/MM/yyyy"
        id="date-picker-inline"
        keyboardIcon={<DateIcon />}
        name="date"
        placeholder={DATE_PLACEHOLDER}
        variant="inline"
        {...{ label }}
        value={values[param]}
        onChange={(date) => onChange(date, param)}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        error={Boolean(errors[param])}
        helperText={errors[param]}
        required
        disabled={noAccess2Tourn}
        inputVariant="outlined"
      />
    </MuiPickersUtilsProvider>
  );

  const filter = (
    label,
    options,
    param,
    key,
    item,
    error,
    helperText,
    required,
    className,
    cb,
    freeSolo,
    additionalItem,
    gridArea,
    disabled
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      key,
      values[param],
      param === COUNTRY_ID || param === 'tg_order_type'
    );

    return (
      <Filter
        {...{ options }}
        style={{ gridArea: gridArea }}
        className={clsx(classes.asterisk, className)}
        onChange={(evt, val) =>
          selectOption(evt, val, 'tournamentData', param, key, errors, cb)
        }
        {...{ label }}
        {...{ item }}
        value={currentVal || ''}
        {...{ required }}
        {...{ helperText }}
        {...{ error }}
        id={param}
        disabled={noAccess2Tourn || disabled}
        variant="outlined"
      />
    );
  };

  const textFieldComponent = (
    name,
    label,
    className,
    required,
    rows,
    int,
    gridArea
  ) => (
    <TextField
      disabled={noAccess2Tourn}
      multiline={rows}
      style={{ gridArea: gridArea }}
      className={clsx(classes.asterisk, className)}
      value={values[name] || ''}
      {...{ name }}
      {...{ label }}
      {...{ required }}
      onChange={(evt) =>
        handleTextChange(
          evt,
          'tournamentData',
          errors,
          int,
          null,
          null,
          null,
          name === 'city'
        )
      }
      error={Boolean(errors[name])}
      helperText={errors[name]}
      variant="outlined"
      InputLabelProps={{
        style: { color: '#8996AC' }
      }}
    />
  );

  return (
    <span
      className={clsx(
        specificDetails?.className?.hasPaperStyle && classes.paper,
        specificDetails?.className?.noEvent,
        classes.flex,
        classes.marginTop
      )}>
      <div className={clsx(classes.fullWidth)}>
        {allInputs.map(({ id, inputs, name, className }, index) => (
          <div
            key={`${name}_${id}`}
            className={index !== 0 ? classes.marginLeft294 : undefined}>
            {name &&
              blockName(name, openBlocks.includes(id), () => toggleBlocks(id))}
            {(openBlocks.includes(id) || index === 0) && (
              <div className={clsx(classes.grid, className)}>
                {index === 0 && (
                  <UploadImage
                    className={classes.img}
                    id="upload-file"
                    isPoster={true}
                    img={values.imagePreview}
                    onChange={imageChange}
                  />
                )}
                {inputs.map((it, index) => (
                  <Fragment key={it.gridArea}>
                    {!it.startEvent &&
                      !it.endReg &&
                      !it.time &&
                      !it.regDate &&
                      !it.reg_time &&
                      !it.reg_judge_date &&
                      !it.committeeLabel &&
                      textFieldComponent(
                        it.name,
                        it.label,
                        it.className,
                        it.required,
                        it.rows,
                        null,
                        it.gridArea
                      )}

                    {it.startEvent &&
                      dateComponent(
                        tournamentDateChange,
                        t('eventStartDate'),
                        'date',
                        it.gridArea
                      )}
                    {it.endReg &&
                      dateComponent(
                        registrationDateChange,
                        t('registrationDateParticipants'),
                        'registration_date',
                        it.gridArea
                      )}
                    {it.time && (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          style={{ gridArea: it.gridArea }}
                          className={clsx(
                            classes.asterisk,
                            classes.inputCondensedView
                          )}
                          KeyboardButtonProps={{
                            'aria-label': 'change time'
                          }}
                          cancelLabel={t('cancel')}
                          okLabel={t('apply')}
                          ampm={false}
                          error={Boolean(errors.tour_time)}
                          helperText={errors.tour_time}
                          id="time-picker"
                          label={t('time')}
                          onChange={(date, time) =>
                            tournamentTimeChange(date, time, 'tour_time')
                          }
                          required
                          disabled={noAccess2Tourn}
                          value={
                            values.tour_time
                              ? new Date(
                                  null,
                                  null,
                                  null,
                                  values.tour_time.split(':')[0],
                                  values.tour_time.split(':')[1]
                                )
                              : null
                          }
                          inputVariant="outlined"
                          InputLabelProps={{
                            style: { color: '#8996AC' }
                          }}
                          keyboardIcon={<TimeIcon />}
                        />
                      </MuiPickersUtilsProvider>
                    )}

                    {it.regDate &&
                      dateComponent(
                        registrationDateChange,
                        t('commissionStartDate'),
                        'reg_date'
                      )}
                    {it.reg_time && (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          KeyboardButtonProps={{
                            'aria-label': 'change time'
                          }}
                          className={clsx(classes.asterisk)}
                          cancelLabel={t('cancel')}
                          okLabel={t('apply')}
                          ampm={false}
                          error={Boolean(errors.reg_time)}
                          helperText={errors.reg_time}
                          id="time-picker"
                          label={t('time')}
                          onChange={(date, time) =>
                            tournamentTimeChange(date, time, 'reg_time')
                          }
                          required
                          disabled={noAccess2Tourn}
                          value={
                            values.reg_time
                              ? new Date(
                                  null,
                                  null,
                                  null,
                                  values.reg_time.split(':')[0],
                                  values.reg_time.split(':')[1]
                                )
                              : null
                          }
                          inputVariant="outlined"
                          InputLabelProps={{
                            style: { color: '#8996AC' }
                          }}
                          keyboardIcon={<TimeIcon />}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                    {values.reg_judge_form === '1' &&
                      !specificDetails?.hide_regJudgeDate &&
                      it.reg_judge_date &&
                      dateComponent(
                        registrationDateChange,
                        t('applicationsForRefereeingAreAcceptedUntil'),
                        'reg_judge_date'
                      )}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        ))}
        {showSettings()}
        <div className={classes.marginLeft294}>
          <div
            className={clsx(
              classes.marginTop2,
              classes.grid,
              classes.gridButtons
            )}>
            {switchBtns.map((it, btnIdx) => (
              <Fragment key={btnIdx}>
                {buttonComponent(
                  it.label,
                  it.onClick,
                  it.className,
                  it.isNoActionBtn,
                  it.id,
                  it.disabled,
                  it.gridArea
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </span>
  );
};
export default TournamentForm;
