import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 0
  },
  flex: {
    display: 'flex'
  },

  centerVertically: {
    alignItems: 'center'
  },
  linkText: {
    color: '#000'
  },
  maxWidth: {
    width: '100%'
  },
  text: {
    color: '#fff'
  },
  issue: {
    color: '#ff0000',
    marginRight: '5px'
  },
  marginLeft: {
    marginLeft: 'auto'
  },
  condensedViewLeftAligned: {
    '@media (max-width:1300px)': {
      marginLeft: 'auto'
    }
  },
  marginTop: {
    marginTop: '10px'
  },
  listRecord: {
    border: '1px solid #000',
    height: 'fit-content',
    padding: '2px',
    borderRadius: '4px'
  },

  breakWord: {
    whiteSpace: 'break-spaces'
  },

  arrowDropUp: {
    transform: 'rotate(180deg)'
  },

  maxWidthListItem: {
    width: 'calc(100% - 1.5rem)'
  },

  arrowDropIc: {
    width: '1.5rem'
  },

  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block'
  },

  noEvent: {
    pointerEvents: 'none'
  }
}));
export default useStyles;
