import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Button from '../Buttons/ActionButtons';
import { ReactComponent as EmptyStateImg } from '../../assets/img/EmptyStateImg.svg';
import { useStyles } from './StylesEmptyState';

const EmptyState = (props) => {
  const {
    className,
    purpose,
    onClick,
    setNewComponent,
    emptyStateText,
    emptyStateSubText,
    buttonText
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <span className={clsx(classes.emptyState, classes.flex, className)}>
      <div className={classes.emptyStateText}>{emptyStateText}</div>
      <div className={classes.emptyStateText}>{emptyStateSubText}</div>
      <span className={clsx(classes.wrapper)}>
        <EmptyStateImg />
        <span className={classes.msgWrapper}>
          {setNewComponent && setNewComponent}
        </span>
      </span>
    </span>
  );
};
export default EmptyState;
