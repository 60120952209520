import React, { Fragment } from 'react';
import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  fetchReference,
  fetchCountries,
  fetchReferenceRegion,
  changeModal,
  rowsPerPageHandler,
  pageChangeHandler,
  updateReference,
  newReference
} from '../../helpers/util';
import { changeTitle } from 'helpers/actions';
import Table from '../../components/Table/Table';
import { withStyles } from '@material-ui/styles';
import { styles } from './styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Pagination from '../../components/TablePagination/TablePagination';
import { ADDITIONAL_INFO } from 'helpers/constants';
import EditIcon from '@material-ui/icons/Edit';
import { TableCell, TableRow, TextField } from '@material-ui/core';
import { Close, Done, Save } from '@material-ui/icons';
import SideModal from '../../components/Snackbar/SideModal';
import AddIcon from '@material-ui/icons/Add';

class Reference extends Component {
  state = {
    allReference: [],
    reference: {},
    changedValues: {},
    add: false,
    langOnLoad: localStorage.getItem('i18nextLng'),
    inputSwitch: false,
    addNewReference: false,
    createNewReference: false,
    rowsPerPage: 25,
    page: 1,
    errors: {},
    showModal: false,
    success: false
  };

  fetchReference = fetchReference.bind(this);
  fetchCountries = fetchCountries.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  changeModal = changeModal.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  updateReference = updateReference.bind(this);
  newReference = newReference.bind(this);

  componentDidUpdate(prevProps, prevState) {
    const { langOnLoad } = this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (langOnLoad !== currentLang) {
      changeTitle(t('reference'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  componentDidMount() {
    changeTitle(this.props.t('reference'));
    this.setState({ loading: false });

    this.fetchReference('all', (data) => {
      const updatedData = data.map((item) => ({
        ...item,
        currentState: 0,
        currentLang: 'en',
        newRef: false
      }));

      const searchBarsReference = Array(updatedData.length).fill({
        searchBar: '',
        items: []
      });

      this.setState({
        allReference: updatedData,
        searchBarsReference: searchBarsReference
      });
    });
  }

  fetchAndProcessReferenceData = (id, langsArray) => {
    return Promise.all(
      langsArray.map(
        (lang) =>
          new Promise((resolve) =>
            this.fetchReference(
              id,
              (data) => resolve(data),
              ADDITIONAL_INFO,
              lang
            )
          )
      )
    ).then((responses) => {
      let array = [];

      const referenceResponse = responses[0];
      const responseLen = referenceResponse.length;
      const langLen = langsArray.length;

      for (let i = 0; i < responseLen; i++) {
        const item = referenceResponse[i];
        let translations = { ...item };

        for (let j = 0; j < langLen; j++) {
          const currentLang = responses[j].find((val) => val.id === item.id);

          translations = {
            ...translations,
            [`name_${langsArray[j]}`]: currentLang ? currentLang.name : null,
            [`shortName_${langsArray[j]}`]: currentLang
              ? currentLang.shortName
              : null,
            [`altName_${langsArray[j]}`]: currentLang
              ? currentLang.altName
              : null
          };
        }

        array.push(translations);
      }

      return array;
    });
  };

  fetchReferenceData = (id, idx, currentState) => {
    const { allReference, searchBarsReference } = this.state;
    const langs = allReference[idx].langs;
    const langsArray = langs.split(',');
    let updatedAllReference = [...allReference];

    if (!currentState) {
      this.fetchAndProcessReferenceData(id, langsArray).then((array) => {
        updatedAllReference[idx] = {
          ...updatedAllReference[idx],
          parentID: [id],
          parentLangs: [langs],
          currentState: 1,
          items: [[...array]]
        };

        const updatedsearchBarsReference = searchBarsReference;

        updatedsearchBarsReference[idx] = {
          ...updatedsearchBarsReference[idx],
          items: [[...array]]
        };

        this.setState({ allReference: updatedAllReference });
      });
    } else if (currentState > 0) {
      updatedAllReference[idx] = {
        ...updatedAllReference[idx],
        parentID: [],
        parentLangs: [],
        currentState: 0,
        items: null,
        newRef: false
      };

      this.allClose(idx);

      this.setState({
        page: 1,
        allReference: updatedAllReference,
        IdxForUpdateName: null
      });
    }
  };

  handleRowClick = (item, idx, currentState) => {
    const { allReference, inputSwitch, searchBarsReference } = this.state;
    const langsArray = item.langs.split(',');
    const strId = item.id.toString();
    let updatedAllReference = [...allReference];

    !inputSwitch &&
      !allReference[idx].newRef &&
      this.fetchAndProcessReferenceData(strId, langsArray).then((array) => {
        updatedAllReference[idx] = {
          ...updatedAllReference[idx],
          currentState: currentState + 1,
          parentID: [...updatedAllReference[idx].parentID, item.id],
          parentLangs: [...updatedAllReference[idx].parentLangs, item.langs],
          items: [...updatedAllReference[idx].items, array],
          currentLang: 'en'
        };

        const updateSearchBarsReference = searchBarsReference;
        updateSearchBarsReference[idx] = {
          ...updateSearchBarsReference[idx],
          items: [...updateSearchBarsReference[idx].items, array]
        };

        this.setState({
          page: 1,
          allReference: updatedAllReference,
          searchBarsReference: updateSearchBarsReference
        });
      });
  };

  backLevel = (idx, currentState) => {
    currentState > 1 &&
      this.setState((prevState) => {
        const updatedAllReference = [...prevState.allReference];
        const currentState = prevState.allReference[idx].currentState;
        const updateSearchBarsReference = [...prevState.searchBarsReference];

        if (currentState > 0) {
          updatedAllReference[idx].items.pop();
          updatedAllReference[idx].parentID.pop();
          updatedAllReference[idx].parentLangs.pop();
          updateSearchBarsReference[idx].items.pop();

          updatedAllReference[idx] = {
            ...updatedAllReference[idx],
            currentState: currentState - 1
          };
        }

        return {
          page: 1,
          allReference: updatedAllReference,
          searchBarsReference: updateSearchBarsReference
        };
      });
  };

  radioButtonLangs = (ref, idx, currentState) => {
    const { allReference } = this.state;
    const { classes } = this.props;

    let langsArray = ref.parentLangs[currentState - 1].split(',');

    const switchRadioButton = (val) => {
      const updatedAllReference = [...allReference];

      updatedAllReference[idx] = {
        ...updatedAllReference[idx],
        currentLang: val,
        newRef: false
      };

      this.closeNewRef(idx);

      this.setState({
        allReference: updatedAllReference,
        inputSwitch: false,
        changedValues: {},
        reference: {},
        IdxForUpdateName: null
      });
    };

    return langsArray.map((lang, idx) => (
      <div
        key={idx}
        className={clsx(
          classes.margin020,
          classes.button,
          classes.pointer,
          classes.hover,
          lang === ref.currentLang ? classes.onClick : classes.notActive
        )}
        onClick={() => switchRadioButton(lang)}>
        {lang}
      </div>
    ));
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const { reference, changedValues, add, createNewReference } = this.state;

    this.setState({
      reference:
        !add || createNewReference
          ? { ...reference, [name]: value }
          : { ...reference },
      changedValues: { ...changedValues, [name]: value },
      errors: {}
    });
  };

  areaOfInput = (idx, key, tableId, param, newRef) => {
    const { allReference, reference, add, changedValues } = this.state;

    if (reference.id === tableId || newRef) {
      const lang = allReference[idx].currentLang;
      const langName = `name_${lang}`;

      const valuesNotNull =
        (reference[langName] !== null) & (reference[langName] !== undefined);

      const keyForEdit = ['order', 'langs', 'specId', 'permission', 'active'];

      const keyForAdd = [
        `name_${allReference[idx].currentLang}`,
        `shortName_${allReference[idx].currentLang}`,
        `altName_${allReference[idx].currentLang}`
      ];

      if (!valuesNotNull) {
        if (!add) {
          this.setState({ add: true });
        }
      } else {
        if (add) {
          this.setState({ add: false });
        }
      }

      if (
        (!valuesNotNull && keyForAdd.includes(key)) ||
        (valuesNotNull &&
          (keyForEdit.includes(key) || keyForAdd.includes(key))) ||
        (allReference[idx].newRef &&
          (keyForEdit.includes(key) || keyForAdd.includes(key)))
      ) {
        return (
          <TextField
            value={add ? changedValues[key] : reference[key]}
            name={key}
            onChange={(evt) => this.handleInputChange(evt)}
          />
        );
      }
    }
    return param && param;
  };

  updateReferenceData = async (evt, ref, idx) => {
    const { reference, allReference, changedValues, searchBarsReference } =
      this.state;
    const langsArray =
      ref.currentState === 1
        ? ref.langs.split(',')
        : ref.parentLangs[ref.currentState - 1].split(',');
    const parentID = ref.parentID[ref.currentState - 1];

    await this.updateReference(
      evt,
      reference.id,
      changedValues,
      ref.currentLang,
      () => {
        this.setState({ inputSwitch: false });

        this.fetchAndProcessReferenceData(parentID, langsArray).then(
          (array) => {
            const updatedAllReference = [...allReference];
            const updatedItems = [...updatedAllReference[idx].items];
            updatedItems[ref.currentState - 1] = array;

            const updatedSearchBarsReference = searchBarsReference;
            const updatedItemsSearchBarsReference = [
              ...updatedSearchBarsReference[idx].items
            ];
            updatedItemsSearchBarsReference[ref.currentState - 1] = array;

            updatedAllReference[idx] = {
              ...updatedAllReference[idx],
              items: updatedItems
            };

            updatedSearchBarsReference[idx] = {
              ...updatedSearchBarsReference[idx],
              searchBar: '',
              items: updatedItemsSearchBarsReference
            };

            this.setState({
              allReference: updatedAllReference,
              searchBarsReference: updatedSearchBarsReference,
              reference: {},
              changedValues: {}
            });
          }
        );
      }
    );
  };

  addLangAtParent = (idx, currentState) => {
    const { classes } = this.props;
    const { allReference, changedValues } = this.state;

    const updatedAllReference = [...allReference];
    const parentIDs = updatedAllReference[idx].parentID;
    const parentID = parentIDs[currentState - 1];

    const handleLangArrayChange = (newLangs) => {
      this.setState({ changedValues: { ...changedValues, langs: newLangs } });
    };

    const addParentLangForRef = (evt, parentID, changedValues, lang) => {
      const newParentLangs = [...updatedAllReference[idx].parentLangs];

      newParentLangs[currentState - 1] += ',' + changedValues.langs;

      changedValues.langs = newParentLangs[currentState - 1];

      updatedAllReference[idx] = {
        ...updatedAllReference[idx],
        parentLangs: newParentLangs
      };
      this.updateReference(evt, parentID, changedValues, lang);
      this.setState({
        allReference: updatedAllReference,
        addLangAtParent: false,
        changedValues: {}
      });
    };

    return (
      <>
        <TextField
          value={changedValues.langs || ''}
          name={'langs'}
          onChange={(evt) => handleLangArrayChange(evt.target.value)}
          inputProps={{ maxLength: 2, pattern: '[A-Za-z]+' }}
          className={classes.inputForLang}
        />
        <Done
          onClick={(evt) =>
            addParentLangForRef(evt, parentID, changedValues, 'en')
          }
          className={clsx(classes.pointer, classes.addWrapper)}
        />
      </>
    );
  };

  addNewLangForReference = async (evt, ref, idx) => {
    const { reference, allReference, changedValues, searchBarsReference } =
      this.state;

    const langsArray =
      allReference[idx].parentLangs[ref.currentState - 1].split(',');

    const parentID = ref.parentID[ref.currentState - 1];

    await this.newReference(
      evt,
      reference.id,
      parentID,
      changedValues,
      ref.currentLang,
      () => {
        this.setState({ inputSwitch: false });

        this.fetchAndProcessReferenceData(parentID, langsArray).then(
          (array) => {
            const updatedAllReference = [...allReference];
            const updatedItems = [...updatedAllReference[idx].items];
            updatedItems[ref.currentState - 1] = array;

            updatedAllReference[idx] = {
              ...updatedAllReference[idx],
              items: updatedItems
            };

            const updatedSearchBarsReference = searchBarsReference;
            const updatedItemsSearchBarsReference = [
              ...updatedSearchBarsReference[idx].items
            ];
            updatedItemsSearchBarsReference[ref.currentState - 1] = array;

            updatedSearchBarsReference[idx] = {
              ...updatedSearchBarsReference[idx],
              searchBar: '',
              items: updatedItemsSearchBarsReference
            };

            this.setState({
              allReference: updatedAllReference,
              searchBarsReference: updatedSearchBarsReference,
              reference: {},
              add: false,
              changedValues: {}
            });
          }
        );
      }
    );
  };

  activateNewRow = (idxRef) => {
    const { allReference } = this.state;
    const updatedAllReference = [...allReference];

    updatedAllReference[idxRef] = {
      ...updatedAllReference[idxRef],
      currentLang: 'en',
      newRef: true
    };

    this.setState({
      allReference: updatedAllReference,
      inputSwitch: false,
      reference: {}
    });
  };

  createNewRow = (IdxRef, tableBodyKeys) => {
    return (
      <TableRow>
        {tableBodyKeys.map((key, index) => {
          return (
            <Fragment key={index}>
              {key && (
                <TableCell>
                  <span>{this.areaOfInput(IdxRef, key)}</span>
                </TableCell>
              )}
            </Fragment>
          );
        })}
      </TableRow>
    );
  };

  addNewReference = async (evt, ref, idx) => {
    const { allReference, changedValues, searchBarsReference } = this.state;
    const langsArray =
      ref.currentState === 1
        ? ref.langs.split(',')
        : ref.parentLangs[ref.currentState - 1].split(',');
    const parentID = ref.parentID[ref.currentState - 1];

    await this.newReference(evt, null, parentID, changedValues, 'en', () => {
      this.fetchAndProcessReferenceData(parentID, langsArray).then((array) => {
        const updatedAllReference = [...allReference];
        const updatedItems = [...updatedAllReference[idx].items];
        updatedItems[ref.currentState - 1] = array;

        updatedAllReference[idx] = {
          ...updatedAllReference[idx],
          items: updatedItems,
          newRef: false
        };

        const updatedSearchBarsReference = searchBarsReference;
        const updatedItemsSearchBarsReference = [
          ...updatedSearchBarsReference[idx].items
        ];
        updatedItemsSearchBarsReference[ref.currentState - 1] = array;

        updatedSearchBarsReference[idx] = {
          ...updatedSearchBarsReference[idx],
          searchBar: '',
          items: updatedItemsSearchBarsReference
        };

        this.setState({
          allReference: updatedAllReference,
          searchBarsReference: updatedSearchBarsReference,
          reference: {},
          changedValues: {}
        });
      });
    });
  };

  createNewReference = async (evt) => {
    const { changedValues } = this.state;

    await this.newReference(evt, null, null, changedValues, 'en', () => {
      this.fetchReference('all', (data) => {
        const updatedData = data.map((item) => ({
          ...item,
          currentState: 0,
          currentLang: 'en'
        }));
        this.setState({
          allReference: updatedData,
          createNewReference: false,
          changedValues: {},
          reference: {}
        });
      });
    });
  };

  clearSearch = (idx, reference, currentState) => {
    const { allReference, searchBarsReference } = this.state;

    const updatedSearchBarsReference = searchBarsReference;
    updatedSearchBarsReference[idx].searchBar = '';
    updatedSearchBarsReference[idx].items[currentState - 1] =
      reference.items[currentState - 1];

    this.setState({ searchBarsReference: updatedSearchBarsReference });
  };

  searchHandler = (event, reference, idx, currentState) => {
    const { value } = event.target;
    const { searchBarsReference } = this.state;

    const updatedSearchBarsReference = [...searchBarsReference];

    const allItems = reference.items;

    const filteredItems = allItems[currentState - 1].filter((item) =>
      item[`name_${reference.currentLang}`]
        .toLowerCase()
        .includes(value.toLowerCase().trim())
    );
    updatedSearchBarsReference[idx].searchBar = value;
    updatedSearchBarsReference[idx].items[currentState - 1] = filteredItems;

    this.setState({
      searchBarsReference: updatedSearchBarsReference,
      page: 1
    });
  };

  closeNewRef = (idxRef) => {
    const { allReference } = this.state;
    const updatedAllReference = [...allReference];

    updatedAllReference[idxRef] = {
      ...updatedAllReference[idxRef],
      newRef: false
    };

    this.setState({ allReference: updatedAllReference });
  };

  allClose = (idxRef) => {
    this.closeNewRef(idxRef);
    this.setState({
      inputSwitch: false,
      changedValues: {},
      reference: {}
    });
  };

  startEditing = (idxRef, item) => {
    this.closeNewRef(idxRef);
    this.setState({
      reference: item,
      inputSwitch: true
    });
  };

  saveNewName = (evt, changedValues, idxRef, ref) => {
    const { allReference, AddName, langOnLoad } = this.state;

    AddName
      ? this.newReference(
          evt,
          ref.id,
          null,
          changedValues,
          ref.currentLang,
          null,
          AddName
        )
      : this.updateReference(
          evt,
          ref.id,
          changedValues,
          ref.lang,
          null,
          AddName
        );

    if (ref.currentLang === langOnLoad) {
      const updateAllReference = [...allReference];
      updateAllReference[idxRef] = {
        ...updateAllReference[idxRef],
        name: changedValues['name']
      };
      changedValues['name'] = '';

      this.setState({ allReference: updateAllReference });
    }

    this.setState({ IdxForUpdateName: null });
  };

  closeNewName = (changedValues) => {
    changedValues['name'] = '';
    this.setState({ IdxForUpdateName: null, changedValues });
  };

  validateForm = (evt) => {
    evt.persist();

    const { reference } = this.state;
    let errors = {};

    if (!reference.name_en) {
      errors['name_en'] = 'This field is required';
    }

    this.setState(
      {
        errors: { ...errors }
      },
      () => {
        if (Object.keys(errors).length === 0) {
          this.createNewReference(evt);
        }
      }
    );
  };

  updateMainReferenceName = (idx, id, currentLang) => {
    const { IdxForUpdateName } = this.state;

    this.setState({ IdxForUpdateName: IdxForUpdateName !== idx ? idx : null });

    if (idx !== IdxForUpdateName) {
      this.fetchReference(
        'all',
        (data) => {
          const [updatedData] = data.filter((item) => item.id === id);
          const isDataEmpty = !updatedData;
          this.setState({
            changedValues: {
              name: updatedData?.name || ''
            },
            AddName: isDataEmpty
          });
        },
        null,
        currentLang
      );
    }
  };

  closeSnackbar = () => this.setState({ showModal: false });

  render() {
    const {
      rowsPerPage,
      page,
      searchBarsReference,
      allReference,
      reference,
      inputSwitch,
      add,
      addLangAtParent,
      changedValues,
      createNewReference,
      IdxForAddLang,
      IdxForUpdateName,
      errors,
      success,
      showModal,
      modalInfo
    } = this.state;

    const { t, classes } = this.props;

    const inputs = [
      {
        name: 'order',
        label: 'order'
      },
      {
        name: 'langs',
        label: 'langs'
      },
      {
        name: 'name_en',
        label: 'nameEN'
      },
      {
        name: 'shortName_en',
        label: 'short_nameEN'
      },
      {
        name: 'altName_en',
        label: 'alt_nameEN'
      }
    ];

    return (
      <>
        <SideModal
          closeModal={this.closeSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <div className={classes.justifyContent}>
          {!createNewReference ? (
            <span
              className={clsx(
                classes.individualInnerPage,
                classes.selectedInnerPage,
                classes.pointer
              )}
              onClick={() => this.setState({ createNewReference: true })}>
              Добавить Справочник
            </span>
          ) : (
            <div className={classes.justifyContent}>
              {inputs.map((input, idx) => (
                <Fragment key={idx}>
                  <TextField
                    value={changedValues[input.name] || ''}
                    name={input.name}
                    label={input.label}
                    onChange={(evt) => this.handleInputChange(evt)}
                    className={clsx(classes.input, classes.marginRight10)}
                    error={errors[input.name] ? true : false}
                    helperText={errors[input.name]}
                  />
                </Fragment>
              ))}
              <div
                className={clsx(
                  classes.individualInnerPage,
                  classes.pointer,
                  classes.justifyContent,
                  classes.selectedInnerPage,
                  classes.marginTop20
                )}
                onClick={(evt) => this.validateForm(evt)}>
                Сохранить
              </div>
              <div
                className={clsx(
                  classes.individualInnerPage,
                  classes.pointer,
                  classes.justifyContent,
                  classes.selectedInnerPage,
                  classes.marginTop20,
                  classes.margin020
                )}
                onClick={() => this.setState({ createNewReference: false })}>
                Отмена
              </div>
            </div>
          )}
        </div>
        {allReference.map((ref, IdxRef) => (
          <Fragment key={IdxRef}>
            <div
              className={clsx(
                classes.fullWidthButton,
                classes.flex,
                classes.marginTop,
                classes.disclosure,
                classes.hover,
                ref.currentState && classes.onClick
              )}>
              <span>{ref.name}</span>
              <div className={classes.marginLeft20}>
                {IdxForUpdateName === IdxRef && (
                  <>
                    <TextField
                      value={changedValues['name']}
                      name={'name'}
                      onChange={(evt) => this.handleInputChange(evt)}
                      className={clsx(
                        classes.notActive,
                        classes.marginRight10,
                        classes.width300
                      )}
                    />
                    <Close
                      onClick={() => this.closeNewName(changedValues)}
                      className={clsx(
                        classes.scale,
                        classes.margin01005,
                        classes.pointer
                      )}
                    />
                    <Save
                      className={clsx(classes.scale, classes.pointer)}
                      onClick={(evt) =>
                        this.saveNewName(evt, changedValues, IdxRef, ref)
                      }
                    />
                  </>
                )}
              </div>
              <div className={clsx(classes.leftAuto, classes.flex)}>
                {ref.currentState > 0 && (
                  <>
                    <AddIcon
                      onClick={() =>
                        this.setState({
                          IdxForAddLang: IdxRef,
                          addLangAtParent: !addLangAtParent
                        })
                      }
                      className={clsx(
                        classes.addWrapper,
                        classes.pointer,
                        addLangAtParent && classes.rotate
                      )}
                    />
                    {addLangAtParent & (IdxForAddLang === IdxRef)
                      ? this.addLangAtParent(IdxRef, ref.currentState)
                      : null}
                    {this.radioButtonLangs(ref, IdxRef, ref.currentState)}
                    <EditIcon
                      className={clsx(
                        classes.pointer,
                        classes.updateName,
                        IdxForUpdateName === IdxRef
                          ? classes.notActive
                          : classes.onClick
                      )}
                      onClick={() =>
                        this.updateMainReferenceName(
                          IdxRef,
                          ref.id,
                          ref.currentLang
                        )
                      }
                    />
                  </>
                )}
                <ArrowDropDownIcon
                  className={clsx(
                    ref.currentState ? classes.arrowDropUp : undefined,
                    classes.pointer
                  )}
                  onClick={() =>
                    this.fetchReferenceData(
                      ref.id,
                      IdxRef,
                      ref.currentState,
                      ref.langs
                    )
                  }
                />
              </div>
            </div>
            {ref.currentState > 0 && (
              <Table
                search={searchBarsReference[IdxRef].searchBar}
                clearSearch={() =>
                  this.clearSearch(IdxRef, ref, ref.currentState)
                }
                textChange={(evt) =>
                  this.searchHandler(evt, ref, IdxRef, ref.currentState)
                }
                condensedTableData={{
                  info: [
                    { key: 'id', name: 'id' },
                    { key: 'order', name: 'order' },
                    { key: 'langs', name: 'langs' },
                    { key: 'specId', name: 'specId' },
                    { key: 'permission', name: 'permission' },
                    { key: 'active', name: 'active' },
                    { key: 'subRefs', name: 'subRefs' },
                    {
                      key: `name_${ref.currentLang}`,
                      name: `name_${ref.currentLang}`
                    },
                    {
                      key: `shortName_${ref.currentLang}`,
                      name: `shortName_${ref.currentLang}`
                    },
                    {
                      key: `altName_${ref.currentLang}`,
                      name: `altName_${ref.currentLang}`
                    }
                  ]
                }}
                header={[
                  { label: 'id' },
                  { label: 'order' },
                  { label: 'langs' },
                  { label: 'specId' },
                  { label: 'permission' },
                  { label: 'active' },
                  { label: 'subRefs' },
                  { label: `name_${ref.currentLang}` },
                  { label: `shortName_${ref.currentLang}` },
                  { label: `altName_${ref.currentLang}` }
                ]}
                body={
                  ref.currentState > 0 &&
                  searchBarsReference[IdxRef].items[
                    +ref.currentState - 1
                  ]?.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )
                }
                data={[
                  'id',
                  'order',
                  'langs',
                  'specId',
                  'permission',
                  'active',
                  'subRefs',
                  `name_${ref.currentLang}`,
                  `shortName_${ref.currentLang}`,
                  `altName_${ref.currentLang}`
                ]}
                shouldAllowEditing={() => true}
                onClick={(evt, item) =>
                  this.handleRowClick(item, IdxRef, ref.currentState)
                }
                paginationComponent={
                  searchBarsReference[IdxRef].items[+ref.currentState - 1]
                    ?.length > 0 && (
                    <Pagination
                      count={
                        searchBarsReference[IdxRef].items[+ref.currentState - 1]
                          ?.length
                      }
                      onPageChange={this.pageChangeHandler}
                      onRowsPerPageChange={this.rowsPerPageHandler}
                      {...{ page }}
                      {...{ rowsPerPage }}
                    />
                  )
                }
                indexReference={IdxRef}
                inputsInARow={true}
                actionIcons={(
                  item,
                  _,
                  className,
                  iconWrapper,
                  marginBottom
                ) => (
                  <>
                    {reference.id === item.id ? (
                      <Save
                        className={clsx(className, iconWrapper, marginBottom)}
                        onClick={(evt) =>
                          !add
                            ? this.updateReferenceData(evt, ref, IdxRef)
                            : this.addNewLangForReference(evt, ref, IdxRef)
                        }
                      />
                    ) : (
                      <EditIcon
                        className={clsx(className, iconWrapper, marginBottom)}
                        onClick={() => this.startEditing(IdxRef, item)}
                      />
                    )}
                  </>
                )}
                areaOfInput={this.areaOfInput}
                inputSwitch={inputSwitch}
                closeIcon={
                  <>
                    {ref.newRef ? (
                      <Save
                        className={clsx(
                          classes.iconWrapper,
                          classes.pointer,
                          classes.marginLeft5
                        )}
                        onClick={(evt) =>
                          this.addNewReference(evt, ref, IdxRef)
                        }
                      />
                    ) : (
                      <AddIcon
                        className={clsx(
                          classes.iconWrapper,
                          classes.pointer,
                          classes.marginLeft5
                        )}
                        onClick={() => this.activateNewRow(IdxRef, ref)}
                      />
                    )}
                    <ArrowBackIcon
                      onClick={() =>
                        this.backLevel(IdxRef, ref.currentState, ref)
                      }
                      className={clsx(
                        classes.iconWrapper,
                        classes.pointer,
                        classes.marginLeft5,
                        ref.currentState < 2 && classes.grey
                      )}
                    />
                    <Close
                      onClick={() => this.allClose(IdxRef)}
                      className={clsx(classes.iconWrapper, classes.pointer)}
                    />
                  </>
                }
                addNewReference={ref.newRef}
                createNewRow={this.createNewRow}
                editableTableCell
              />
            )}
          </Fragment>
        ))}
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(withRouter(Reference)));
