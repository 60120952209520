import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { AppBar, Toolbar, Tooltip } from '@material-ui/core';

import { ReactComponent as Menu } from '../../../../assets/img/menu_topbar.svg';
import { ReactComponent as ArrowBack } from '../../../../assets/img/back_topbar.svg';
import { ReactComponent as QuestionMarkIc } from 'assets/img/help_white_bg&grey_border.svg';

import ExpandedLogo from '../../../../assets/img/big_logo_header.png';
import ShortLogo from '../../../../assets/img/small_logo_header.png';

import { PRODUCT_LANG, LANG, LABEL } from '../../../../helpers/constants';
import { selectedValue, isMobileView } from '../../../../helpers/selectors';

import Filter from '../../../../components/Filter/Filter';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

import { useStyles } from './StyleTopbarP2';

const TopbarP2 = (props) => {
  const {
    onSidebarOpen,
    specificDetails,
    viewportWidth,
    role,
    onQuestionMarkOpen
  } = props;
  const hasMobileView = isMobileView(viewportWidth);
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    navigate(-1, {
      state: { prevUrl: location.pathname }
    });
  };

  const switchLang = (evt, item) => {
    i18n.changeLanguage(item.lang);
  };

  return (
    <div className={clsx(classes.root)}>
      <Toolbar className={classes.headerInnerContent}>
        <RouterLink to="/">
          {!hasMobileView ? (
            <img src={ExpandedLogo} alt={'Logo'} />
          ) : (
            <img src={ShortLogo} alt={'Logo'} />
          )}
        </RouterLink>
        {specificDetails?.hasGoBack && (
          <ArrowBack
            onClick={goBack}
            className={clsx(
              classes.alignRight,
              classes.marginRight,
              classes.onHover
            )}
          />
        )}
        <Filter
          className={clsx(
            classes.marginRight,
            classes.langBtn,
            classes.onHover,
            !specificDetails?.hasGoBack && classes.alignRight
          )}
          classes={{
            root: clsx(classes.flex),
            hasPopupIcon: classes.dropdownIcon
          }}
          shouldLookLikeBtn={true}
          options={PRODUCT_LANG}
          onChange={switchLang}
          item={LABEL}
          value={
            selectedValue(
              PRODUCT_LANG,
              LANG,
              localStorage.getItem('i18nextLng') || 'en'
            ) || ''
          }
          inputProps={{
            disableUnderline: true,
            readOnly: true,
            className: clsx(classes.langText, classes.fullHeight)
          }}
          freeSolo={true}
          langListbox={true}
        />
        <Tooltip arrow title={t('help')}>
          <QuestionMarkIc
            className={clsx(classes.marginRight, classes.onHover)}
            onClick={onQuestionMarkOpen}
          />
        </Tooltip>

        <Menu
          id="top"
          className={clsx(classes.onHover)}
          onClick={onSidebarOpen}
        />
      </Toolbar>
      <Breadcrumbs
        {...{ role }}
        specificDetails={{
          styles: { wrapper: classes.headerInnerContent },
          hasMobileView
        }}
      />
    </div>
  );
};

export default TopbarP2;
