import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },

  marginRight: {
    marginRight: '.5rem'
  },

  smallViewportWrap: {
    '@media (max-width:550px)': {
      flexWrap: 'wrap'
    }
  },

  noEvent: {
    pointerEvents: 'none'
  },

  textAlign: {
    textAlign: 'center'
  },

  verticallyCenter: {
    alignItems: 'center'
  },

  relative: {
    position: 'relative'
  },

  orderRowNum: {
    position: 'absolute',
    transform: 'rotate(-90deg)',
    right: '100%',
    width: '1em',
    fontWeight: 500
  },

  row: {
    transitionDuration: '0.5s',
    cursor: 'pointer',
    '&$selected, &$selected:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      '& td, & li': {
        color: '#fff',
        borderColor: '#fff'
      },
      color: '#fff'
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  cell: {
    border: 'none'
  },
  iconWrapper: {
    width: '2.2rem',
    height: '2.2rem',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    }
  },

  copyPaste: {
    width: '1.6em',
    height: '1.6em'
  },

  marginLeft: {
    marginLeft: '.65em'
  },

  marginLeft0dot5: {
    marginLeft: '.5rem !important'
  },

  marginBottom05: {
    marginBottom: '.5em'
  },
  icMarginLeft: {
    '@media (min-width:901px)': {
      marginLeft: '.5em'
    }
  },
  icon: {
    padding: '5px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#001D3D',
    cursor: 'pointer',
    '@media (min-width:901px)': {
      marginLeft: '.65em'
    },
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },

  showScroll: {
    '& .ps__rail-x': {
      opacity: 0.6
    }
  },

  dropdown: {
    '&:hover': {
      opacity: 0.5
    }
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)'
  },
  selected: {},
  noPadding: {
    paddingBottom: 0,
    paddingTop: 0
  },
  center: {
    justifyContent: 'center'
  },

  alignSelfStart: {
    alignSelf: 'start'
  },

  condensedViewNoResults: {
    padding: '0 10px 10px'
  },
  txt: {
    paddingBottom: '5px'
  },
  collaps: {
    margin: '10px'
  },
  marginTop10: {
    marginTop: '10px'
  },

  issue: {
    color: '#ff0000'
  },

  informativeTxt: {
    color: '#808080',
    fontSize: '12px'
  },
  smallViewportColumn: {
    '@media (max-width:900px)': {
      flexDirection: 'column'
    }
  },
  column: {
    flexDirection: 'column'
  },
  bold: {
    fontWeight: 'bold'
  },
  img: {
    width: '4.5em',
    height: '6em',
    objectFit: 'cover',
    marginBottom: '5px',
    border: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: '4px'
  },
  // style for condensed table
  cardTitle: {
    fontWeight: 500,
    fontSize: '1.1rem',
    paddingBottom: '5px'
  },
  cardTxt: {
    fontSize: '0.8rem'
  },
  paddingLeft10: {
    paddingLeft: '10px'
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  hideTable: {
    '@media (max-width:1300px)': {
      display: 'none'
    }
  },
  hideCondensedTable: {
    '@media (min-width:1301px)': {
      display: 'none'
    }
  },
  padding: {
    padding: '10px'
  },
  fullWidth: {
    width: '90%'
  },
  condensedCard: {
    paddingRight: '.5em'
  },

  ationIconsLeft: {
    marginLeft: 'auto'
  },

  left: {
    '@media (max-width:1300px)': {
      marginLeft: 'auto'
    }
  },

  minWidth: {
    minWidth: 'fit-content'
  },
  fitContent: {
    width: 'fit-content'
  },
  categoryCondensedView: {
    border: '1px solid #000',
    borderRadius: '4px',
    height: 'fit-content',
    padding: '2px'
  },
  arrowDropUp: {
    transform: 'rotate(180deg)'
  },
  resizeColumn: {
    overflowX: 'auto',
    resize: 'horizontal'
  },
  maxWidth: {
    maxWidth: '300px'
  },

  fullWidth: {
    width: '100%'
  },

  columnTxtMinusImgWidth: {
    width: 'calc(100% - 4.5em)'
  },

  redCheckmark: {
    color: '#FF0000'
  },

  tameshiwariInnerValue: {
    padding: '0.2rem 1rem',
    height: 'fit-content',
    alignSelf: 'center',
    marginLeft: '0.5rem'
  },

  redBorder: {
    border: '2px solid #FF0000'
  },

  blackBorder: {
    border: '2px solid'
  },

  paddingRight0: {
    paddingRight: 0
  },

  paddingLeft0: {
    paddingLeft: 0
  },

  borderBlackLeft: {
    border: '2px solid',
    borderLeft: 'none',
    height: '5em'
  },

  borderBlackRight: {
    border: '2px solid',
    borderRight: 'none',
    height: '5em'
  },

  maxWidthForSmallScreen: {
    minWidth: '200px'
  },

  opacity: {
    opacity: '0.5'
  },

  color: {
    color: 'white'
  },

  preLine: {
    whiteSpace: 'pre-line'
  },
  emptyState: {
    marginTop: '40px'
  }
});
