import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid #e0e6eb',
    width: '54px',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  icon: {
    color: theme.palette.primary.main
  },
  noEvent: {
    pointerEvents: 'none'
  },
  marginBottom: {
    marginBottom: '1em'
  },
  marginTop: {
    marginTop: '1em'
  },
  endAdornment: {
    paddingRight: 0
  },
  input: {
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-outlined': {
      lineHeight: 0
    },
    height: '60px'
  }
}));
