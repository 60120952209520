import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  drawer: {
    overflowX: 'hidden',
    backgroundColor: '#8996AC',
    padding: '1.5rem',
    width: '25em',
    '@media (max-width:900px)': {
      padding: '1.2em',
      width: '85%'
    }
  },
  signOutButton: {
    padding: '10px 5px',
    fontSize: '1.2rem',
    '&:hover': {
      opacity: 0.3
    },
    '@media (max-width:900px)': {
      fontSize: '1rem'
    }
  },
  icon: {
    marginRight: '.8em',
    width: '2em'
  },

  avatar: {
    width: '2.7em',
    height: '3.6em',
    borderRadius: '0.3rem'
  },

  profileWrapper: {
    display: 'grid',
    gridTemplateColumns: '3.5em 1fr 2em',
    borderBottom: '1px solid #fff',
    paddingBottom: '1rem'
  },

  logo: {
    width: '11em',
    marginLeft: '1rem'
  },

  langWrapper: {
    margin: '2rem 0 2rem 1rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 2em)'
  },

  lang: {
    color: '#fff',
    fontSize: '.9rem',
    padding: '0.5rem 0',
    fontWeight: 700,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.3
    }
  },

  activeLang: {
    color: '#005FEE'
  },

  profileDetailsWrapper: { display: 'grid', paddingLeft: '.5em' },

  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '10em',
    textOverflow: 'ellipsis',
    display: 'block'
  },

  name: {
    fontSize: '1.25rem',
    fontWeight: 700,
    color: '#005FEE'
  },

  onHover: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.7
    }
  },

  text: {
    color: '#fff'
  },

  role: {
    fontSize: '1rem',
    alignItems: 'end'
  }
}));
