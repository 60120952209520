import { Button, Link, TextField } from '@material-ui/core';
import Facebook from '../../../../assets/img/facebook.png';
import Youtube from '../../../../assets/img/youtube.png';
import Telegram from '../../../../assets/img/telegram.png';
import AppStore from '../../../../assets/img/AppStore.png';
import GooglePlay from '../../../../assets/img/GooglePlay.png';
import LogoFooter from '../../../../assets/img/logo_footer.png';
import { useTranslation } from 'react-i18next';
import {
  ANDROID_DOWNLOAD,
  CONTACT_EMAIL,
  CONTACT_NUMBER,
  iOS_DOWNLOAD
} from 'helpers/constants';
import { useStyles } from './StyleFooterP2';
import clsx from 'clsx';

const FooterP2 = (props) => {
  const { className, styleTxt, hideNav } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const lang = localStorage.getItem('i18nextLng');

  const panel = [
    {
      label: t('home'),
      url: `https://tournament.expert/${lang}/`
    },
    {
      label: t('aboutUs'),
      url: `https://tournament.expert/${lang}/about/`
    },
    {
      label: t('products'),
      url: `https://tournament.expert/${lang}/products/`
    },
    {
      label: t('contacts'),
      url: `https://tournament.expert/${lang}/contacts/`
    }
  ];

  const social = [
    {
      src: Facebook,
      alt: 'Facebook',
      className: clsx(classes.facebook, classes.marginRight15),
      url: 'https://www.facebook.com/tournament.system/'
    },
    {
      src: Youtube,
      alt: 'Youtube',
      className: clsx(
        classes.youtube,
        classes.marginTop5,
        classes.marginRight15
      ),
      url: 'https://www.youtube.com/@tournament.expert'
    },
    {
      src: Telegram,
      alt: 'Telegram',
      className: clsx(classes.facebook),
      url: 'https://t.me/TournamentExpert'
    }
  ];

  const buttons = [
    { src: GooglePlay, alt: 'Google play', href: ANDROID_DOWNLOAD },
    {
      src: AppStore,
      alt: 'App store',
      href: iOS_DOWNLOAD,
      styles: classes.marginLeft20
    }
  ];

  const contacts = [
    { label: CONTACT_NUMBER, styles: classes.number },
    { label: CONTACT_EMAIL, styles: classes.mail }
  ];

  return (
    <div className={clsx(classes.root, classes.flex, classes.alignItems)}>
      <div
        className={clsx(
          classes.menu,
          classes.flex,
          classes.width,
          classes.alignItems
        )}>
        <div className={classes.width}>
          <div className={clsx(classes.panelContainer, classes.flex)}>
            <div>
              <div className={clsx(classes.flex, classes.column)}>
                <a href={panel[0].url}>
                  <img src={LogoFooter} alt={'Logo'} />
                </a>
                <div className={classes.marginLeft40}>
                  {buttons.map((button) => (
                    <a
                      className={classes.fullWidth}
                      key={button.src}
                      href={button.href}>
                      <img
                        className={clsx(classes.block, button.styles)}
                        src={button.src}
                        alt={button.alt}
                      />
                    </a>
                  ))}
                </div>
              </div>
              <div className={classes.wrapper}>
                {!hideNav &&
                  panel.map((item, index) => (
                    <a
                      key={index}
                      href={item.url}
                      className={clsx(classes.links, classes.width)}>
                      <div className={classes.linkContainer}>{item.label}</div>
                    </a>
                  ))}
              </div>
            </div>
            <div className={clsx(classes.contacts)}>
              <Button
                className={clsx(classes.transparent)}
                onClick={() =>
                  (window.location.href =
                    'https://tournament.expert/ru/contacts/')
                }>
                {t('contactUs')}
              </Button>
              {contacts.map((item, index) => (
                <div key={index} className={clsx(classes.text, item.styles)}>
                  {item.label}
                </div>
              ))}
              <div className={clsx(classes.flex, classes.socialLinks)}>
                {social.map((item, index) => (
                  <a key={index} href={item.url}>
                    <img
                      src={item.src}
                      alt={item.alt}
                      className={item.className}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterP2;
