import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ro, ru, de } from 'date-fns/locale';
import clsx from 'clsx';

import { TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import { DATE_PLACEHOLDER, ID, LABEL } from '../../helpers/constants';
import { selectedValue } from '../../helpers/selectors';

import { ReactComponent as DateIcon } from '../../assets/img/date.svg';

import PagesHeader from '../PagesHeader/PagesHeader';
import Filter from '../Filter/Filter';
import Button from '../Buttons/ActionButtons';

import { useStyle } from './Styles';

const currentLang = localStorage.getItem('i18nextLng');

const PaymentForm = ({
  handleTextChange,
  tournamentDateChange,
  errors,
  values,
  saveForm,
  selectOption,
  closeFormHandler
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const PAY_TYPE = [
    { id: '1', label: t('monthlyPayment') },
    { id: '2', label: t('annualPayment') }
  ];

  const CURRENCY = [
    { id: 'MDL', label: 'MDL' },
    { id: 'USD', label: 'USD' },
    { id: 'EUR', label: 'EUR' },
    { id: 'RON', label: 'RON' },
    { id: 'RUB', label: 'RUB' }
  ];

  const inputs = [
    [
      { type: true },
      { currency: true },
      {
        name: 'amount',
        label: 'Amount',
        required: true,
        int: true,
        className: [
          classes.input,
          classes.paddingInput,
          classes.maxHeightTxtInput
        ]
      }
    ],
    [
      { date: true },
      {
        name: 'comment',
        label: 'Comment',
        rows: true,
        className: [classes.input, classes.inputCondensedView]
      },
      { btn: true }
    ]
  ];

  const buttonComponent = (label, onClick, className, isNoActionBtn, id) => (
    <Button
      {...{ id }}
      className={clsx(classes.marginTopBottom, className)}
      isSaveBtn={!isNoActionBtn}
      {...{ onClick }}
      {...{ label }}
    />
  );

  const filter = (
    label,
    options,
    param,
    key,
    item,
    error,
    helperText,
    required,
    className
  ) => (
    <Filter
      {...{ options }}
      className={clsx(
        classes.asterisk,
        classes.input,
        classes.paddingInput,
        classes.maxHeightAutocomplete,
        className
      )}
      onChange={(evt, val) =>
        selectOption(evt, val, 'paymentForm', param, key, errors)
      }
      {...{ label }}
      {...{ item }}
      value={selectedValue(options, key, values[param]) || ''}
      {...{ required }}
      {...{ helperText }}
      {...{ error }}
    />
  );
  const textFieldComponent = (name, label, int, className, required, rows) => (
    <TextField
      multiline={rows}
      className={clsx(classes.asterisk, classes.input, className)}
      value={values[name] || ''}
      {...{ name }}
      {...{ label }}
      {...{ required }}
      onChange={(evt) =>
        handleTextChange(evt, 'paymentForm', errors, int, null, null, null)
      }
      error={Boolean(errors[name])}
      helperText={errors[name]}
    />
  );

  return (
    <>
      <div className={classes.paper}>
        <PagesHeader onClick={closeFormHandler}>
          {values.id
            ? t('previewTooltip')
            : t('addRecord', { name: t('payment') })}
        </PagesHeader>
        <span className={clsx(classes.flex, classes.marginTop)}>
          <span className={clsx(classes.fullWidth)}>
            {inputs.map(
              (key, idx) =>
                key && (
                  <span
                    key={idx}
                    className={clsx(classes.flex, classes.condensedView)}>
                    {key.map((it, index) => (
                      <Fragment key={index}>
                        {!it.date &&
                          !it.currency &&
                          !it.type &&
                          !it.btn &&
                          textFieldComponent(
                            it.name,
                            it.label,
                            it.int,
                            it.className,
                            it.required,
                            it.rows
                          )}

                        {it.date && (
                          <MuiPickersUtilsProvider
                            locale={[ru, ro, de].find(
                              (it) => it.code === currentLang
                            )}
                            utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className={clsx(
                                classes.asterisk,
                                classes.maxHeightTxtInput,
                                classes.input,
                                classes.paddingInput
                              )}
                              disableToolbar
                              format="dd/MM/yyyy"
                              id="date-picker-inline"
                              keyboardIcon={<DateIcon />}
                              name="operationDate"
                              placeholder={DATE_PLACEHOLDER}
                              variant="inline"
                              label="Operation Date"
                              value={values.operationDate || null}
                              onChange={tournamentDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                              error={Boolean(errors.operationDate)}
                              helperText={errors.operationDate}
                              required
                            />
                          </MuiPickersUtilsProvider>
                        )}
                        {it.type &&
                          filter(
                            'Payment Type',
                            PAY_TYPE,
                            'payType',
                            ID,
                            LABEL,
                            Boolean(errors['payType']),
                            errors['payType'],
                            true,
                            classes.maxHeight
                          )}
                        {it.currency &&
                          filter(
                            'Currency',
                            CURRENCY,
                            'currency',
                            ID,
                            LABEL,
                            Boolean(errors['currency']),
                            errors['currency'],
                            true,
                            classes.maxHeight
                          )}
                        {it.btn && !values.id && (
                          <span
                            className={clsx(
                              classes.flex,
                              classes.input,
                              classes.paddingInput,
                              classes.end
                            )}>
                            {buttonComponent(t('save'), saveForm, [
                              classes.btn
                            ])}
                          </span>
                        )}
                      </Fragment>
                    ))}
                  </span>
                )
            )}
          </span>
        </span>
      </div>
    </>
  );
};
export default PaymentForm;
