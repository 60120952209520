import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/styles';
import NewPasswordForm from '../../components/NewPasswordForm/NewPasswordForm';
import {
  textChangeHandler,
  toggleShow,
  resetPassword,
  changeModal,
  onBlur
} from '../../helpers/util';
import { FORM } from '../../helpers/constants';
import styles from './styles';
import SideModal from '../../components/Snackbar/SideModal';
import PatternP2 from 'components/PatternP2/PatternP2';

class NewPassword extends Component {
  state = {
    verificationToken: this.props.match.params.token,
    errors: {
      password: '',
      confirm_password: ''
    },
    form: {},
    isLoading: false,
    newPasswordError: false,
    showModal: false,
    success: false,
    showError: {},
    isPasswordVisible: {}
  };

  textChangeHandler = textChangeHandler.bind(this);
  toggleShow = toggleShow.bind(this);
  resetPassword = resetPassword.bind(this);
  changeModal = changeModal.bind(this);
  onBlur = onBlur.bind(this);

  closeSideModal = () => this.setState({ showModal: false });

  render() {
    const {
      errors,
      isLoading,
      showModal,
      modalInfo,
      success,
      isPasswordVisible,
      redirect,
      form,
      showError
    } = this.state;
    const { classes, navigate, location } = this.props;

    const goToSignIn = () =>
      navigate('/sign-in', {
        state: { prevUrl: location.pathname }
      });

    return (
      <PatternP2 className={classes.root}>
        <SideModal
          closeModal={this.closeSideModal}
          success={success}
          show={showModal}
          info={modalInfo}
        />
        {isLoading ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <NewPasswordForm
            resetPassword={(evt) => this.resetPassword(evt, goToSignIn)}
            textChange={(evt) =>
              this.textChangeHandler(evt, FORM, errors, null, form)
            }
            {...{ errors }}
            showPassword={this.toggleShow}
            {...{ isPasswordVisible }}
            onBlur={this.onBlur}
            showError={showError}
          />
        )}
      </PatternP2>
    );
  }
}
export default withStyles(styles)(withRouter(NewPassword));
