import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as Clear } from 'assets/img/cancel_btn_grey_bg&border.svg';
import { ReactComponent as Document } from 'assets/img/document_new.svg';
import { ReactComponent as PNG } from 'assets/img/png.svg';
import { ReactComponent as JPG } from 'assets/img/jpg.svg';
import { ReactComponent as JPEG } from 'assets/img/jpeg.svg';

import { MAX_FILE_SIZE } from '../../../helpers/constants';

import Alert from '../../Alerts/Alert';
import Button from '../../Buttons/ActionButtons';

import useStyles from './StylesDropzone';

const Dropzone = (props) => {
  const {
    onDrop,
    fileData,
    removeFile,
    preview,
    specificDetails
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    rejectedFiles
  } = useDropzone({
    accept: specificDetails?.accept,
    minSize: 0,
    MAX_FILE_SIZE,
    onDrop,
    multiple: false
  });
  const isFileTooLarge =
    rejectedFiles.length > 0 && rejectedFiles[0].size > MAX_FILE_SIZE;
  const alertMsg = (message) => (
    <span className={clsx(classes.flex, classes.alertWrapper)}>
      <Alert className={classes.alertBanner} {...{ message }} />
    </span>
  );

  return (
    <>
      {rejectedFiles?.length > 0 &&
        !isFileTooLarge &&
        alertMsg(t('rejectedFileError'))}
      {isFileTooLarge && alertMsg(t('fileTooLargeMsg'))}
      <div
        className={clsx(
          classes.flex,
          classes.container,
          fileData?.files?.length === 0 && classes.centerHorizontally,
          isDragReject && classes.rejectFile,
          specificDetails?.className?.wrapper
        )}
        {...getRootProps()}>
        <input {...getInputProps()} />
        {fileData?.files?.map(
          (file) =>
            !isDragReject && (
              <div
                className={clsx(classes.flex, classes.centerVertically)}
                key={file.name}>
                <span className={clsx(classes.centerVertically, classes.flex)}>
                  <Document
                    className={clsx(classes.ic, classes.marginRight0dot65)}
                  />
                  <span className={clsx(classes.ellipsis)}>
                    {preview(file)}
                  </span>
                </span>
                <div
                  className={clsx(classes.removeButton, classes.textLink)}
                  onClick={(evt) => evt.stopPropagation()}>
                  <Clear
                    className={classes.ic}
                    onClick={() => removeFile(file)}
                  />
                </div>
              </div>
            )
        )}
        {specificDetails?.childrenElem}
        {!isDragActive && fileData?.files?.length === 0 && (
          <span
            className={classes.informativeMsg}
            style={{ display: 'grid', gap: '1rem', justifyItems: 'center' }}>
            <Button
              className={classes.btnUpload}
              label={
                <>
                  <span className={classes.plusIc}>+</span>
                  {t('uploadDoc')}
                </>
              }
            />
            <span>{t('dragMsg')}</span>
            {fileData?.tournament_file_type && (
              <span className={classes.flex} style={{ alignItems: 'center' }}>
                {t('supportedDocument')}:{' '}
                {+fileData?.tournament_file_type === 2 ? (
                  t('showAll')
                ) : (
                  <span
                    style={{
                      display: 'grid',
                      gap: '0.5rem',
                      gridTemplateColumns: 'repeat(3, max-content)'
                    }}>
                    <JPEG />
                    <JPG />
                    <PNG />
                  </span>
                )}
              </span>
            )}
          </span>
        )}
        {isDragReject && <p>{t('isDragRejectMsg')}</p>}
        {isDragActive && !isDragReject && fileData?.files?.length === 0 && (
          <p>{t('dropFile')}</p>
        )}
      </div>
    </>
  );
};

export default Dropzone;
