import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import Button from '../../Buttons/ActionButtons';
import clsx from 'clsx';

import { BRACKET, TATAMIS_TAB } from 'helpers/constants';
import { finishedTournament, isSchool } from '../../../helpers/selectors';

import { useStyles } from './StylesHeader';

const Header = ({ tournamentData, showBracketInformation }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isApprove = (tournamentData) =>
    tournamentData && +tournamentData.reg_judge_form === 1;
  const goTo = (path) =>
    navigate(path, {
      state: { prevUrl: location.pathname }
    });

  const isTournamentDefinitelyColsed = finishedTournament(tournamentData);
  const isTournamentOnHold = !!+tournamentData?.finished;
  const isTraining = isSchool(tournamentData);
  const actions = tournamentData
    ? [
        ...(!isTraining
          ? [
              {
                onClick: () =>
                  goTo(
                    `/event/${tournamentData.id}/event-setting?tab=${TATAMIS_TAB}`
                  ),
                label: [t('tatami'), t('categories')].join('/')
              }
            ]
          : []),
        {
          onClick: () => goTo(`/event/${tournamentData.id}/registration`),
          label: t('registrationN'),
          disabled: isTournamentDefinitelyColsed || isTournamentOnHold
        },
        {
          onClick: () => goTo(`/event/${tournamentData.id}/participants?tab=1`),
          label: t('participants')
        },
        ...(isApprove(tournamentData)
          ? [
              {
                onClick: () => goTo(`/event/${tournamentData.id}/staff`),
                label: t('judges')
              }
            ]
          : []),
        {
          onClick: () => goTo(`/event/${tournamentData.id}/coaches_requests`),
          label: t('approveRegistration')
        },
        ...(isApprove(tournamentData)
          ? [
              {
                onClick: () =>
                  goTo(`/event/${tournamentData.id}/judges_requests`),
                label: t('approveJudges')
              }
            ]
          : []),
        {
          onClick: () => goTo(`/event/${tournamentData.id}/select-attachment`),
          label: t('documents')
        },
        ...(!isTraining
          ? [
              {
                onClick: (evt) =>
                  showBracketInformation(evt, { targetID: BRACKET }),
                label: t('bracketInformation'),
                id: BRACKET
              }
            ]
          : []),
        {
          onClick: () => goTo(`/event/${tournamentData.id}/winners`),
          label: t('winners')
        },
        {
          onClick: () => goTo(`/event/${tournamentData.id}/reports`),
          label: t('reports')
        }
      ]
    : [];
  const button = (label, onClick, disabled, id, isNoActionBtn) => (
    <Button
      {...{ id }}
      {...{ disabled }}
      className={classes.button}
      {...{ onClick }}
      {...{ label }}
      isSaveBtn={!isNoActionBtn}
    />
  );

  return (
    <div className={clsx(classes.flex, classes.buttonsWrapper)}>
      {actions.map(({ label, onClick, disabled, id, isNoActionBtn }, index) => (
        <span key={index} className={clsx(disabled && classes.disabledBtn)}>
          {button(label, onClick, disabled, id, isNoActionBtn)}
        </span>
      ))}
    </div>
  );
};
export default Header;
