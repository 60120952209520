import { Link } from '@material-ui/core';
import { TOURNAMENT_EXPERT } from 'helpers/constants';

const Footer = (props) => {
  const { className, styleTxt } = props;

  return (
    <div {...{ className }}>
      <Link className={styleTxt} href="https://tournament.expert" target="_blank">
        {TOURNAMENT_EXPERT} {new Date().getFullYear()}
      </Link>
    </div>
  );
};

export default Footer;
