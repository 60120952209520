import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  marginRight: {
    marginRight: '20px'
  },
  maxWidth: {
    maxWidth: '220px',
    width: '100%'
  }
}));
