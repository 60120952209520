import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  ANDROID_DOWNLOAD,
  iOS_DOWNLOAD,
  CASTER,
  MANAGER,
  WINDOWS,
  DIPLOMA
} from '../../helpers/constants';
import { DDMMYYYY, isDesktopView } from '../../helpers/selectors';

import { ReactComponent as AppStore } from '../../assets/img/logo_appstore.svg';
import { ReactComponent as GooglePlay } from '../../assets/img/logo_googleplay.svg';

import { ReactComponent as TournamentManagerMobile } from '../../assets/img/logo_tournament_manage_mobile.svg';
import { ReactComponent as Diploma } from '../../assets/img/logo_diploma_system.svg';
import { ReactComponent as CastManager } from '../../assets/img/logo_cast_manager.svg';
import { ReactComponent as MatchCaster } from '../../assets/img/logo_match_caster.svg';
import { ReactComponent as TournamentManager } from '../../assets/img/logo_tournament_manager.svg';
import { ReactComponent as Clear } from 'assets/img/cancel_btn_grey_bg&border.svg';

import Button from '../Buttons/ActionButtons';
import Modal from 'components/Modal/ModalNewDesign';
import RadioBtn from 'components/RadioBtn/RadioBtn';

import { useStyles } from './StylesDownloadApp';

const MOBILE_BUTTONS = [
  { src: GooglePlay, href: ANDROID_DOWNLOAD },
  { src: AppStore, href: iOS_DOWNLOAD }
];

const DownloadApp = (props) => {
  const { apps, turnOnModal, role, viewportWidth } = props;

  const classes = useStyles(props);
  const { t } = useTranslation();
  const [toggled, onToggle] = useState({
    status: false,
    options: [],
    value: null
  });

  const hasDesktopSize = isDesktopView(viewportWidth);
  let currentData = [...apps];
  currentData.splice(1, 0, {
    app_type: 'manager_mobile',
    isMobile: true
  });

  const showModal = (elem) => {
    const title =
      `${elem?.app_type}` === '1'
        ? t('tournamentManager')
        : `${elem?.app_type}` === '2'
        ? CASTER
        : `${elem?.app_type}` === '3'
        ? MANAGER
        : `${elem?.app_type}` === '4' && DIPLOMA;
    onToggle({ status: true, title, options: elem.others });
  };
  const hideModal = () => {
    onToggle({ status: false, options: [] });
  };
  const selectRadioButton = (evt) => {
    evt.preventDefault();
    const { value } = evt.target;
    const cpy = { ...toggled };
    const selectedItem = toggled?.options?.find((it) => +it.id === +value);

    cpy.value = value;

    cpy.selectedItem = selectedItem;
    onToggle(cpy);
  };
  const downloadFile = (path) => {
    let link = document.createElement('a');
    link.href = path;
    link.click();
  };

  const DOWNLOAD_LINK = (myApp, myAppIdx) => (
    <>
      <a className={clsx(classes.fullWidth)} href={myApp.path} download>
        <Button
          id={`TEST_ID_APP_${myApp.app_type}`}
          className={clsx(classes.fullWidth)}
          isSaveBtn={true}
          label={t('download')}
        />
      </a>
      <Button
        id={`TEST_ID_APP_OTHER_VERSION_${myApp.app_type}`}
        className={clsx(
          classes.fullWidth,
          classes.otherVersionBtn,
          classes.onHover
        )}
        label={t('otherVersions')}
        onClick={() => showModal(myApp)}
      />
    </>
  );

  const MOBILE_COMPONENT = (buttons) =>
    Array.isArray(buttons) &&
    buttons?.map((key, btnIdx) => {
      const Icon = key?.src;

      return (
        <a
          key={btnIdx}
          className={classes.flex}
          target="_blank"
          rel="noreferrer"
          href={key.href}>
          <Icon className={clsx(classes.logoMobile, classes.fullWidth)} />
        </a>
      );
    });

  const CARD = (elem, LogoIcon, actionButtons, about) => (
    <span className={clsx(classes.gridElemWrapper, classes.paper)}>
      <LogoIcon className={clsx(classes.logo, classes.marginTop1)} />
      <p className={clsx(classes.about, classes.marginTop1)}>{about}</p>
      {(hasDesktopSize || (!hasDesktopSize && !elem.isMobile)) && (
        <span className={classes.marginTop1}>{elem.name}</span>
      )}
      {(hasDesktopSize || (!hasDesktopSize && !elem.isMobile)) && (
        <span className={clsx(classes.gridGap1, classes.marginTop1)}>
          {elem?.uploading_date && (
            <span>
              {t('version')} {t('from').toLowerCase()}
              {'  '}
              {DDMMYYYY(elem?.uploading_date)}
            </span>
          )}
          <span className={classes.flex}>
            {!elem?.isMobile
              ? `${t('availableFor')} ${WINDOWS}`
              : t('forMobile').toLowerCase()}
          </span>
        </span>
      )}
      <span
        className={clsx(
          classes.gridGap1,
          classes.fullWidth,
          classes.actionButtons
        )}>
        {actionButtons}
      </span>
    </span>
  );

  return (
    <>
      <Modal
        open={toggled?.status}
        close={hideModal}
        onClick={() =>
          toggled?.selectedItem && downloadFile(toggled?.selectedItem?.path)
        }
        dialogTitle={[t('otherVersions'), toggled?.title].join(' ')}
        dialogContent={
          <>
            <span
              className={clsx(classes.modalInnerContentHeader, classes.weight)}>
              <span>{t('version')}</span>
              <span>{t('date')}</span>
            </span>
            <RadioBtn
              options={toggled?.options}
              item="id"
              value={+toggled?.value || ''}
              onClick={(evt) => selectRadioButton(evt)}
              specificDetails={{
                labelElem: (el) => (
                  <span className={classes.modalInnerContentBody}>
                    <span>{el.name}</span>
                    <span>{DDMMYYYY(el.uploading_date)}</span>
                    {role === 'administrator' && (
                      <Clear
                        className={classes.othersItem}
                        onClick={() => turnOnModal(el.id)}
                      />
                    )}
                  </span>
                ),
                className: {
                  rootLabel: classes.labelsForRadioButton,
                  radioIcon: classes.radio,
                  radioGroup: [classes.fullWidth, classes.importContainer]
                }
              }}
            />
          </>
        }
        buttonPurpose={t('download')}
        specificDetails={{
          className: {
            ...(hasDesktopSize ? { paper: classes.modalWidth } : {}),
            actionBtn: classes.actionBtnModal
          }
        }}
      />

      {currentData?.map((app, appIdx) => {
        let icon, aboutApp;

        switch (`${app.app_type}`) {
          case '1':
            aboutApp = t('downloadTournamentManagerInfo');
            icon = TournamentManager;
            break;
          case '2':
            aboutApp = t('downloadCasterInfo');
            icon = MatchCaster;
            break;
          case '3':
            aboutApp = t('downloadCastManager');
            icon = CastManager;
            break;
          case '4':
            aboutApp = t('downloadDiploma');
            icon = Diploma;
            break;
          case 'manager_mobile':
            icon = TournamentManagerMobile;
            aboutApp = t('downloadMobileInfo');
            break;
          default:
        }

        return (
          <Fragment key={appIdx}>
            {CARD(
              app,
              icon,
              app.isMobile
                ? MOBILE_COMPONENT(MOBILE_BUTTONS)
                : DOWNLOAD_LINK(app, appIdx),
              aboutApp
            )}
          </Fragment>
        );
      })}
    </>
  );
};
export default DownloadApp;
