import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ro, ru, de } from 'date-fns/locale';

import {
  fetchPayments,
  changeModal,
  clearSearch,
  handleTextChange,
  fetchSingleParticipant,
  deletePayment
} from 'helpers/util';
import { changeTitle } from 'helpers/actions';
import {
  serverDefaultPath,
  DEFAULT_AVATAR,
  DATE_PLACEHOLDER
} from 'helpers/constants';
import { findSortDirection } from 'helpers/selectors';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

import { ReactComponent as DateIcon } from '../../assets/img/date.svg';

import Table from '../../components/Table/Table';
import SideModal from '../../components/Snackbar/SideModal';
import EmptyState from '../../components/EmptyState/EmptyState';
import LoadingState from '../../components/LoadingState/LoadingState';
import AddPayment from '../AddPayment/AddPayment.js';
import HeaderInfo from 'components/HeaderInfo/HeaderInfo';
import Modal from 'components/Modal/Modal';

import styles from './Styles';

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      showModal: false,
      shouldShowForm: false,
      shouldUpdateTable: false,
      success: false,
      loading: true,
      sortDirection: [],
      searchedValue: '',
      searchBar: '',
      paymentForm: {},
      aboutPart: {},
      filters: {},
      open: false
    };

    this.partIdParams = window.location.pathname.split('/')[2];

    this.fetchPayments = fetchPayments.bind(this);
    this.changeModal = changeModal.bind(this);
    this.clearSearch = clearSearch.bind(this);
    this.handleTextChange = handleTextChange.bind(this);
    this.fetchSingleParticipant = fetchSingleParticipant.bind(this);
    this.deletePayment = deletePayment.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { shouldUpdateTable, startDate, endDate, searchBar } = this.state;
    if (
      shouldUpdateTable &&
      shouldUpdateTable !== prevState.shouldUpdateTable
    ) {
      this.fetchData(startDate, endDate, searchBar);
    }
  }

  componentDidMount() {
    const { t } = this.props;
    this.fetchPayments(
      this.partIdParams,
      null,
      null,
      null,
      null,
      null,
      (body) => {
        this.fetchSingleParticipant(this.partIdParams, (part) =>
          this.setState({ aboutPart: part })
        );
        this.setState({ payments: body, loading: false });
      }
    );

    changeTitle(t('payment'));
  }

  fetchData = (startDate, endDate, search) => {
    const { sortDirection } = this.state;

    this.fetchPayments(
      this.partIdParams,
      startDate,
      endDate,
      search,
      sortDirection?.[0],
      sortDirection?.[2],
      (body) =>
        this.setState({
          shouldUpdateTable: false,
          payments: body
        })
    );
  };

  triggerTableUpdate = () => this.setState({ shouldUpdateTable: true });

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  pressEnterHandler = (event) => {
    if (event.key === 'Enter') {
      this.searchHandler();
    }
  };

  searchHandler = () => {
    const { searchBar, startDate, endDate } = this.state;
    this.setState({ searchedValue: searchBar }, () => {
      this.fetchData(startDate, endDate, searchBar);
    });
  };

  openForm = (evt, item) => {
    evt.preventDefault();

    const id = item && item.id;

    this.setState({
      shouldShowForm: true,
      paymentId: id,
      selectedRow: id
    });

    if (id) {
      this.setState({
        paymentForm: {
          ...item,
          operationDate: item.op_date,
          payType: item.pay_type
        }
      });
    } else {
      this.setState({
        paymentForm: {}
      });
    }

    window.scroll({
      top: document.querySelector('#top').offsetTop,
      behavior: 'smooth'
    });
  };

  closeParticipantFormHandler = () => {
    this.setState({ shouldShowForm: false, selectedRow: null });
  };

  onSort = (sortField, idxCell) => {
    const { searchedValue, sortDirection } = this.state;
    const direction = findSortDirection(sortDirection, sortField);

    this.setState({ sortDirection: [direction, idxCell, sortField] }, () => {
      this.fetchData(null, null, searchedValue);
    });
  };

  closeFormHandler = () => {
    this.setState({ shouldShowForm: false, selectedRow: null });
  };

  changeDate = (date) => {
    const { t } = this.props;

    if (!isNaN(Date.parse(date))) {
      const selectedYear = date.getFullYear();
      const selectedMonth =
        date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const selectedDay =
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const newDate = `${selectedYear}/${selectedMonth}/${selectedDay}`;

      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            startDate: newDate
          }
        }),
        () =>
          this.fetchPayments(
            this.partIdParams,
            newDate,
            null,
            null,
            null,
            null,
            (body) => this.setState({ payments: body })
          )
      );
    }
  };

  clearDate = () => {
    const { filters } = this.state;
    if (filters && filters.startDate) {
      this.setState(
        (prevState) => ({
          filters: {
            ...prevState.filters,
            startDate: null
          }
        }),
        () =>
          this.fetchPayments(
            this.partIdParams,
            null,
            null,
            null,
            null,
            null,
            (body) => this.setState({ payments: body })
          )
      );
    }
  };

  onShowModal = (evt, item) => {
    evt.preventDefault();

    if (item.id) {
      this.setState({ open: true, rowID: item.id, modalData: item });
    }
  };

  hideModal = () => {
    this.setState({ open: false, rowID: null });
  };

  render() {
    const {
      loading,
      success,
      showModal,
      modalInfo,
      shouldShowForm,
      searchBar,
      searchedValue,
      selectedRow,
      payments,
      sortDirection,
      aboutPart,
      filters,
      open,
      rowID,
      modalData
    } = this.state;
    const { t, classes } = this.props;

    const filter = [];

    const myAddForm = (
      <AddPayment
        state={this.state}
        closeFormHandler={this.closeFormHandler}
        triggerTableUpdate={this.triggerTableUpdate}
      />
    );

    const header = [
      { num: 1, label: 'Type' },
      { num: 2, label: 'Amount' },
      { label: 'Currency' },
      { num: 0, label: 'Operation Date' },
      { num: 3, label: 'Updated at' },
      { num: 4, label: 'Comment' },
      { label: 'Deleted' }
    ];

    const tableBody = [
      'pay_type',
      'amount',
      'currency',
      'op_date',
      'updated_at',
      'comment',
      'deleted'
    ];

    const condensedTableData = {
      mainName: 'amount',
      info: [
        { key: 'pay_type', name: 'Type' },
        { key: 'currency', name: 'Currency' },
        { key: 'op_date', name: 'Operation Date' },
        { key: 'updated_at', name: 'Updated at' },
        { key: 'comment', name: 'Comment' },
        { key: 'deleted', name: 'Deleted' }
      ]
    };

    const partSummary = [
      {
        label: t('name'),
        name: [aboutPart.first_name, aboutPart.last_name].join(' ')
      },
      {
        label: t('city'),
        name: aboutPart.city
      },
      {
        label: [t('club'), t('coach')].join('/ '),
        name: aboutPart.club ? aboutPart.club : aboutPart.coach
      }
    ];

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />

        <Modal
          {...{ open }}
          close={this.hideModal}
          onClick={(evt) => {
            this.deletePayment(evt, rowID, () => {
              this.hideModal();
              this.triggerTableUpdate();
            });
          }}
          subHeader={
            modalData &&
            modalData.id && (
              <span className={clsx(classes.flex, classes.marginTop1)}>
                {[modalData.amount, modalData.currency].join(' ')}
              </span>
            )
          }
          dialogTitle={t('deleteRecord', { name: t('payment') })}
          dialogContent={t('deleteMsg')}
          buttonPurpose={t('deleteRecord', { name: '' })}
        />

        {loading ? (
          <LoadingState />
        ) : payments ? (
          <>
            <HeaderInfo
              data={{
                moveTo: true,
                src:
                  aboutPart && aboutPart.photo
                    ? serverDefaultPath + aboutPart.photo
                    : DEFAULT_AVATAR,
                info: partSummary
              }}
            />
            {shouldShowForm && myAddForm}
            <Table
              search={searchBar}
              clearSearch={() =>
                this.clearSearch(searchedValue, () => this.searchHandler())
              }
              toolbarIndividualContent={
                <span className={classes.flex}>
                  <MuiPickersUtilsProvider
                    locale={[ru, ro, de].find(
                      (it) => it.code === localStorage.getItem('i18nextLng')
                    )}
                    utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      keyboardIcon={<DateIcon />}
                      name="start_date"
                      placeholder={DATE_PLACEHOLDER}
                      variant="inline"
                      label={t('date')}
                      value={filters.startDate || null}
                      onChange={this.changeDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      error={false}
                      helperText={null}
                    />
                  </MuiPickersUtilsProvider>
                  <Tooltip title={t('close')}>
                    <CloseIcon
                      className={classes.icon}
                      onClick={this.clearDate}
                    />
                  </Tooltip>
                </span>
              }
              textChange={this.handleTextChange}
              openEmptyForm={(evt) => this.openForm(evt)}
              pressEnter={this.pressEnterHandler}
              showButton={true}
              onClick={(evt, item) => this.openForm(evt, item)}
              {...{ filter }}
              {...{ condensedTableData }}
              data={tableBody}
              body={payments}
              {...{ header }}
              selected={(item) => {
                const id = shouldShowForm && item.id;
                return selectedRow === id;
              }}
              shouldAllowEditing={() => true}
              actionIcons={(
                item,
                _,
                className,
                iconWrapper,
                marginLeft,
                marginBottom
              ) => (
                <>
                  {item.deleted === '0' && (
                    <>
                      <Tooltip arrow title={t('previewTooltip')}>
                        <VisibilityOutlinedIcon
                          className={clsx(className, iconWrapper, marginBottom)}
                          onClick={(evt) => this.openForm(evt, item)}
                        />
                      </Tooltip>
                      <Tooltip
                        arrow
                        title={t('deleteRecord', { name: t('payment') })}>
                        <DeleteIcon
                          className={clsx(className, iconWrapper, marginBottom)}
                          onClick={(evt) =>
                            this.onShowModal(evt, item)
                          }></DeleteIcon>
                      </Tooltip>
                    </>
                  )}
                </>
              )}
              onSort={this.onSort}
              {...{ sortDirection }}
            />
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(withRouter(Payments)));
