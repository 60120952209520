import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '285px',
    background: '#ffffff',
    padding: '50px 30px',
    borderRadius: '10px'
  },
  inputField: {
    height: '78px',
    width: '100%'
  },
  fonSize20: {
    fontSize: '18px',
    fontWeight: 500
  },
  marginTop25: {
    marginTop: '25px'
  },
  marginTop10: {
    marginTop: '10px'
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: '1px solid #e0e6eb',
    height: '52px',
    width: '54px',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  icon: {
    color: theme.palette.primary.main
  },
  outlinedIcon: {
    pointerEvents: 'none'
  },
  endAdornment: {
    paddingRight: 0
  },
  disabledBtn: {
    display: 'flex',
    cursor: 'not-allowed',
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: 'rgb(63, 81, 181, 0.6)',
      color: '#fff'
    }
  },
  backgroundColor: {
    backgroundColor: '#2D63EE',
    borderRadius: '3px'
  },
  fontSize14: {
    fontSize: '14px'
  },
  black: {
      color: 'black'
  },
  marginRight5: {
    marginRight: '5px'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
  },
}));
