import React, { Fragment } from 'react';
import clsx from 'clsx';

import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';

import { FILTERS_CHECKBOXES } from 'helpers/constants';

import { useStyles } from './style';
import { useTranslation } from 'react-i18next';
import { Clear } from '@material-ui/icons';
import Button from '../Buttons/ActionButtons';

const Icon = ({ className }) => {
  const classes = useStyles();
  return <span className={clsx(classes.icon, className)}></span>;
};

const FilterCheckboxes = (props) => {
  const {
    categoryTypes,
    selectedCheckboxes,
    onSelectCheckbox,
    filterDetails,
    isFilterOpen,
    cancelFilter,
    withoutFilterList,
    role
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {isFilterOpen ? (
        <span className={clsx(classes.flex, classes.wrap, classes.wrapper)}>
          {FILTERS_CHECKBOXES(
            categoryTypes,
            filterDetails?.ownerCategories,
            filterDetails?.tounamentPresence,
            filterDetails?.noUnisex,
            filterDetails?.reds,
            filterDetails?.dropouts,
            role
          )?.map((group, groupIdx) => (
            <span key={groupIdx} className={clsx(classes.flex, classes.wrap)}>
              {group.map((item, idx) => (
                <Fragment key={idx}>
                  <CheckboxBtn
                    checkedIcon={<Icon className={classes.checkedIcon} />}
                    icon={<Icon />}
                    formControlClassName={classes.paper}
                    classControlLabel={classes.checkboxItem}
                    className={classes.checkboxIcon}
                    checked={selectedCheckboxes.some((it) => it.id === item.id)}
                    onChange={() => onSelectCheckbox(item)}
                    label={item.name}
                  />
                </Fragment>
              ))}
            </span>
          ))}
        </span>
      ) : (
        selectedCheckboxes?.length > 0 &&
        !withoutFilterList && (
          <Button
            onClick={() => cancelFilter()}
            className={clsx(classes.flex, classes.activFilterWrapper)}
            label={
              <label className={clsx(classes.flex, classes.fullWidth)}>
                <span
                  className={clsx(
                    classes.pointer,
                    classes.flex,
                    classes.center,
                    classes.flexGrow1
                  )}>
                  {t('filter')}:{' '}
                  {selectedCheckboxes.map((item, idx) => (
                    <Fragment key={idx}>
                      {idx > 0 && ', '}
                      {item.name}
                    </Fragment>
                  ))}
                </span>
                <Clear className={clsx(classes.clearIcon, classes.pointer)} />
              </label>
            }
          />
        )
      )}
    </>
  );
};

export default FilterCheckboxes;
