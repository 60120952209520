import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },
  size: {
    padding: '60px 30px',
    width: '285px',
    background: '#ffffff',
    borderRadius: '10px',
  },
  textWrapper: {
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '24px'
  },
  marginTop20: {
    marginTop: '20px'
  },
  padding30: {
    padding: '30px'
  },
  marginBottom: {
    marginBottom: '20px'
  },
  btn: {
    width: '100%',
    marginTop: '20px'
  },
  marginTop12: {
    marginTop: '12px'
  },
  txtField: {
    height: '65px'
  },
  left: {
    marginLeft: 'auto'
  },
  title: {
    fontSize: '14px',
    fontWeight: 500
  },
  blue: {
    backgroundColor: '#2D63EE'
  }, 
  borders: {
    borders: 'none'
  },
  
});
