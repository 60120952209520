import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyles } from './StylesDownloadFiles';

const DownloadFiles = (props) => {
  const { files, download, userToken, showIcons, showModal } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {Array.isArray(files) && files.length > 0 && files[0].id ? (
        files.map((it) => (
          <div className={clsx(classes.flex, classes.root)} key={it.id}>
            <span
              className={clsx(
                classes.flex,
                classes.itemName,
                !showIcons && classes.right,
                classes.text
              )}
              onClick={() => download(it.id, userToken)}>
              <InsertDriveFileIcon />
              <span className={classes.title}>{it.name}</span>
              <span
                className={clsx(
                  classes.flex,
                  classes.centerVertically,
                  classes.left
                )}>
                <GetAppIcon className={classes.icon} />
              </span>
            </span>
            {showIcons && (
              <>
                <span
                  className={clsx(
                    classes.left,
                    classes.flex,
                    classes.centerVertically,
                    classes.right
                  )}
                  onClick={(evt) => evt.stopPropagation()}>
                  <EditIcon
                    className={clsx(classes.icon, classes.margin)}
                    onClick={(evt) => showModal(evt, it)}
                  />
                  <DeleteIcon
                    id="deleteIcon"
                    className={classes.icon}
                    onClick={(evt) => showModal(evt, it.id)}
                  />
                </span>
              </>
            )}
          </div>
        ))
      ) : (
        <span className={clsx(classes.flex, classes.centerHorizontally)}>
          {t('noAttachmentsMsg')}
        </span>
      )}
    </>
  );
};
export default DownloadFiles;
