import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  navLink: {
    color: theme.palette.primary.contrastText,
    '& svg': {
      fill: '#FFF'
    }
  },

  list: {
    display: 'grid',
    gap: '1rem',
    padding: 0,
    marginTop: '1rem',
    marginLeft: '1rem'
  },

  activeLink: {
    color: '#005FEE',
    '& svg': {
      fill: '#005FEE'
    }
  },

  item: {
    fontSize: '.9rem',
    padding: 0,
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.3
    }
  },

  icon: {
    marginRight: '1rem',
    width: '1.945em',
    height: '1.945em'
  }
}));
