import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ReactComponent as InfoMark } from 'assets/img/info_mark.svg';
import { ReactComponent as DropdownUp } from 'assets/img/dropdown_black&white_bg.svg';

import { Tooltip } from '@material-ui/core';

import Table from 'components/Table/CardsList';
import ListItems from '../../components/Table/ListItems';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';

import { useStyles, useStylesRecords } from './TableListStyles';

const TableList = (props) => {
  const {
    page,
    rowsPerPage,
    selectedRow,
    tournamentData,
    tournamentIdParams,
    expandTableRow,
    collapsedList,
    filteredCoaches,
    collapsed,
    sortDirection,
    onSort,
    onSortCollapsible,
    itemToggle,
    showModalForm,
    onToggleStatus,
    shouldDisableEditing,
    isTraining
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const FIRST_NAME = !+tournamentData?.last_name_first
    ? 'first_name'
    : 'last_name';
  const LAST_NAME = !+tournamentData?.last_name_first
    ? 'last_name'
    : 'first_name';
  const FIRST_NAME_PART = !+tournamentData?.last_name_first
    ? 'participant_first_name'
    : 'participant_last_name';
  const LAST_NAME_PART = !+tournamentData?.last_name_first
    ? 'participant_last_name'
    : 'participant_first_name';

  const recordDataParams = (item, idx) => {
    return {
      selected: selectedRow === item.id,
      main: {
        label: t('teamRepresentative'),
        hasSort: true,
        sort: 'first_name',
        style: {
          cardMainPart: classes.cardValuePart
        },
        rowOrder: {
          value: (page - 1) * rowsPerPage + idx + 1
        },
        name: [
          item?.[FIRST_NAME],
          item?.[LAST_NAME],
          ...(!tournamentIdParams || !!+tournamentData?.show_reg_patronymic
            ? [item?.patronymic]
            : [])
        ].join(' '),
        hasPhoto: true,
        photo: item?.photo
      },
      info: [
        { sort: 'email', value: item?.email, name: t('email'), width: '13em' },
        ...(!+tournamentData?.no_country
          ? [{ sort: 'iso3', value: item.country_iso3, name: t('country') }]
          : []),
        ...(!!+tournamentData?.show_reg_region
          ? [{ sort: 'region', value: item.region, name: t('region') }]
          : []),
        ...(!!+tournamentData?.show_reg_city
          ? [{ sort: 'city', value: item.city, name: t('city') }]
          : []),
        ...(!!+tournamentData?.show_reg_club
          ? [{ sort: 'club', value: item.club, name: t('club') }]
          : []),
        {
          sort: 'participants_count',
          value: (
            <span
              onClick={(evt) => {
                expandTableRow(evt, item);
              }}
              className={clsx(
                classes.flex,
                classes.onHover,
                classes.centerVertically
              )}>
              {item?.participants_count}
              {+item?.participants_count > 0 && (
                <DropdownUp
                  className={clsx(
                    classes.flex,
                    classes.onHover,
                    classes.marginLeft0dot4,
                    !!collapsed?.find((it) => +it?.id === +item?.id) &&
                      classes.rotate180
                  )}
                />
              )}
            </span>
          ),
          name: t('participants')
        },
        !isTraining
          ? {
              sort: 'categories_count',
              value: item.categories_count,
              name: t('categories')
            }
          : {
              sort: 'examine_count',
              value: item.examine_count,
              name: t('exams')
            }
      ]
    };
  };

  const collapsedRecordDataParams = (item, idx, parent) => {
    return {
      main: {
        label: t('name'),
        hasSort: true,
        sort: !+tournamentData?.last_name_first
          ? 'participant_first_name'
          : 'participant_last_name',
        style: {
          cardMainPart: classes.bgWhite
        },
        rowOrder: {
          value: idx + 1
        },
        name: [
          item?.[FIRST_NAME_PART],
          item?.[LAST_NAME_PART],

          !!+tournamentData?.show_reg_patronymic
            ? [item?.participant_patronymic]
            : []
        ].join(' '),
        hasPhoto: true,
        photo: item?.photo
      },
      info: [
        { sort: 'gender', value: item?.gender, name: t('gender') },
        { sort: 'age', value: item?.age, name: t('age') },
        { sort: 'weight', value: item.weight, name: t('weight') },
        ...(!!+tournamentData?.height_needed
          ? [{ value: item?.height, name: t('height') }]
          : []),
        ...(!!+tournamentData?.show_reg_qdan
          ? [{ sort: 'qdan_name', value: item.qdan_name, name: t('kyuDan') }]
          : []),
        ...(!!+tournamentData?.sport_rank_needed
          ? [{ value: item.sport_rank_short, name: t('sportsCategory') }]
          : []),
        ...(isTraining
          ? [
              {
                sort: 'next_qdan_id',
                value: item?.next_qdan_id,
                name: t('nextKyuDan')
              }
            ]
          : []),
        ...(!isTraining
          ? [
              {
                value:
                  item?.categories_info?.length > 0 ? (
                    <ListItems
                      collapsed={collapsedList}
                      elem={item}
                      elemParamN="categories_info"
                      itemParamN="category_name"
                      subElemParamN="category_id"
                      paramElemName="participant_id"
                      shouldAllowEditing={false}
                    />
                  ) : (
                    '-'
                  ),
                list: true,
                name: (
                  <>
                    {t('categories')}
                    {item?.categories_info?.length > 2 && (
                      <span
                        className={clsx(
                          classes.marginLeft0dot4,
                          classes.numOfCategories
                        )}>
                        {item?.categories_info?.length}
                      </span>
                    )}
                    {item?.categories_info?.length > 2 && (
                      <DropdownUp
                        className={clsx(
                          classes.flex,
                          classes.onHover,
                          classes.marginLeft0dot4,
                          !!collapsedList[item.participant_id] &&
                            classes.rotate180
                        )}
                        onClick={() => {
                          itemToggle(item.participant_id);
                        }}
                      />
                    )}
                  </>
                )
              }
            ]
          : []),
        ...(!+tournamentData?.no_country
          ? [
              {
                sort: 'participant_country',
                value: item.participant_country,
                name: t('country')
              }
            ]
          : []),
        { value: item.coach_name, name: [t('coach'), t('club')].join('/') }
      ]
    };
  };

  const classes2 = useStylesRecords(recordDataParams({})?.info);

  return (
    <Table
      data={filteredCoaches.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      )}
      {...{
        recordDataParams,
        collapsedRecordDataParams,
        collapsed,
        onSort,
        onSortCollapsible
      }}
      // {...{ qdanList }}
      id="id"
      subTableDataKey="participants"
      {...{ sortDirection }}
      // shouldAllowEditing={() => true}
      specificDetails={{
        className: { gridRecordsValue: classes2.gridRecordsValue }
      }}
      actionIcons={(user, idx, className) => (
        <>
          <Tooltip
            arrow
            title={t(user.status === '1' ? 'rejectRequest' : 'acceptRequest')}>
            <span>
              <CheckboxBtn
                checked={!!+user.status}
                styles={{ icon: classes.checkboxSize }}
                className={clsx(classes.padding0)}
                formControlClassName={classes.margin0}
                onChange={(evt) => onToggleStatus(evt, user)}
                disabled={shouldDisableEditing}
              />
            </span>
          </Tooltip>
          <InfoMark
            className={clsx(className, classes.marginLeft1)}
            onClick={(evt) => showModalForm(evt, user, idx)}
          />
        </>
      )}
    />
  );
};

export default TableList;
