import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import DE from './helpers/languages/de';
import EN from './helpers/languages/en';
import RO from './helpers/languages/ro';
import RU from './helpers/languages/ru';

const countryLang = (currentVal) => {
  const codes = {
    MD: 'ro',
    RO: 'ro',
    RU: 'ru',
    UA: 'ru',
    DE: 'de'
  };

  if (codes[currentVal]) {
    return codes[currentVal];
  }
};

const myDetector = {
  name: 'myDetectorName',
  async lookup() {
    const storedLanguage = localStorage.getItem('i18nextLng');

    if (!storedLanguage) {
      const countryLang = await fetchUserLanguage();

      i18n.changeLanguage(countryLang);

      return countryLang;
    }

    return storedLanguage;
  }
};

const fetchUserLanguage = async () => {
  try {
    const { coords } = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
    const response = await fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${coords.latitude}&longitude=${coords.longitude}`
    );
    const data = await response.json();

    const currentLang = countryLang(data?.countryCode);

    return currentLang || 'en';
  } catch {
    return 'en';
  }
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(myDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: EN },
      ro: { translations: RO },
      ru: { translations: RU },
      de: { translations: DE }
    },
    supportedLngs: ['en', 'ro', 'ru', 'de'],
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false
    },
    detection: {
      order: ['myDetectorName', 'localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0
    }
  });

export default i18n;

export const getCurrentLanguage = () => {
  return i18n.language || 'en';
};
