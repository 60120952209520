import { Component } from 'react';
import clsx from 'clsx';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';

import { ReactComponent as DropdownUp } from 'assets/img/dropdown_black&white_bg.svg';
import { ReactComponent as InfoMark } from 'assets/img/info_mark.svg';

import { Tooltip } from '@material-ui/core';

import { ENABLED, serverDefaultPath } from '../../helpers/constants';
import {
  changeModal,
  fetchTournaments,
  changeHead,
  fetchTournRegistrar,
  textChangeHandler,
  changeDate,
  changeImage,
  onBlur,
  fetchReference,
  selectOption,
  fetchCountries,
  fetchQdan,
  validateForm,
  downloadUplaodedFile,
  downloadFile,
  fetchReferenceRegion,
  usersRegisterNewUser,
  fetchStaff,
  toggleUserStatus,
  pageChangeHandler,
  getJudgesCandidatesReport,
  rowsPerPageHandler
} from '../../helpers/util';
import {
  finishedTournament,
  findSortDirection,
  compareValueForSorting,
  DDMMYYYY
} from '../../helpers/selectors';

import { AuthContext } from 'AuthContext';
import { SearchInput } from 'components';

import SideModal from '../../components/Snackbar/SideModal';
import Table from '../../components/Table/CardsList';
import LoadingState from '../../components/LoadingState/LoadingState';
import HeaderTournInfo from '../../components/HeaderTournInfo/HeaderTournInfo';
import Toolbar from '../../components/Table/Toolbar/NewToolbar';
import EmptyState from '../../components/EmptyState/EmptyState';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import CoachForm from 'components/QuickTournRegistration/CoachForm/CoachForm';
import Pagination from '../../components/TablePagination/TablePagination';
import Button from '../../components/Buttons/ActionButtons';
import Modal from '../../components/Modal/ModalNewDesign';

import styles from './Styles';

const defaultForm = {
  first_name: '',
  last_name: '',
  email: ''
};

const TABLE_BODY_PARAMS = (value, tournamentData) => [
  {
    firstName: value?.firstName,
    lastName: value?.lastName,
    ...(!!+tournamentData?.show_reg_patronymic
      ? { patronymic: 'patronymic' }
      : {}),
    photo: 'photo'
  },
  'email',
  ...(!+tournamentData?.no_country ? ['country_iso3'] : []),
  ...(!!+tournamentData?.show_reg_region ? ['region'] : []),
  ...(!!+tournamentData?.show_reg_city ? ['city'] : []),
  ...(!!+tournamentData?.show_reg_club ? ['club'] : []),
  { dropdown: 'participants_count' }
];

class ApproveJudges extends Component {
  state = {
    tournamentId: this.props.match.params.id,
    open: false,
    showModal: false,
    modalInfo: '',
    success: false,
    filters: {
      searchBar: '',
      isSwitchSet: true
    },
    isLoading: true,
    langOnLoad: localStorage.getItem('i18nextLng'),
    tournamentData: {},
    allRegistrar: [],
    modalData: {},
    errors: {},
    statistics: {},
    countries: [],
    collapsed: [],
    sortDirection: [],
    recordsCount: 0,
    rowsPerPage: 25,
    page: 1,
    showError: {}
  };

  static contextType = AuthContext;

  changeModal = changeModal.bind(this);
  fetchTournaments = fetchTournaments.bind(this);
  changeHead = changeHead.bind(this);
  fetchTournRegistrar = fetchTournRegistrar.bind(this);
  textChangeHandler = textChangeHandler.bind(this);
  changeDate = changeDate.bind(this);
  changeImage = changeImage.bind(this);
  onBlur = onBlur.bind(this);
  fetchReference = fetchReference.bind(this);
  selectOption = selectOption.bind(this);
  fetchCountries = fetchCountries.bind(this);
  fetchQdan = fetchQdan.bind(this);
  validateForm = validateForm.bind(this);
  downloadUplaodedFile = downloadUplaodedFile.bind(this);
  downloadFile = downloadFile.bind(this);
  fetchReferenceRegion = fetchReferenceRegion.bind(this);
  usersRegisterNewUser = usersRegisterNewUser.bind(this);
  fetchStaff = fetchStaff.bind(this);
  getJudgesCandidatesReport = getJudgesCandidatesReport.bind(this);
  toggleUserStatus = toggleUserStatus.bind(this);
  pageChangeHandler = pageChangeHandler.bind(this);
  rowsPerPageHandler = rowsPerPageHandler.bind(this);

  componentDidMount() {
    const { tournamentId, langOnLoad } = this.state;
    const { t } = this.props;

    this.fetchTournaments(tournamentId, null, null, () => {
      const { tournamentData } = this.state;
      const shouldDisableEditing =
        finishedTournament(tournamentData) || !!+tournamentData?.finished;

      this.fetchData(() => {
        const tableBody = TABLE_BODY_PARAMS({}, tournamentData);
        const findIdx = tableBody.findIndex(
          (it) => it.dropdown === 'participants_count'
        );

        this.onSort(7, findIdx); // on initial mount, sort by participants_count DESC
      });

      this.fetchCountries();

      this.setState({ shouldDisableEditing });

      changeHead(tournamentData, t('approveJudges'));
    });

    // this.fetchReference(
    //   4113,
    //   (data) => {
    //     const filteredData = data.filter(
    //       (item) => item.name === 'judges_requests'
    //     );
    //     this.setState({ iframeURL: filteredData[0] });
    //   },
    //   null,
    //   langOnLoad
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    const { tournamentData, sortDirection, shouldUpdateTable, langOnLoad } =
      this.state;
    const { t } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    if (
      shouldUpdateTable &&
      prevState.shouldUpdateTable !== shouldUpdateTable
    ) {
      this.fetchData(() => {
        this.setState({ shouldUpdateTable: false });

        if (sortDirection?.length > 0) {
          this.onSort(sortDirection[2], null, true);
        } else {
          this.onFilterRecords();
        }
      });
    }

    if (langOnLoad !== currentLang) {
      changeHead(tournamentData, t('approveJudges'));

      this.setState({ langOnLoad: currentLang });
    }
  }

  fetchData = (cb) => {
    const { tournamentId, tournamentData } = this.state;

    this.fetchTournRegistrar(tournamentId, 2, (data) => {
      let recordsWithParticipants = [];
      const recordsCount = data?.length;
      let statistics = {
        totalRequests: 0,
        totalAcceptedRequests: 0,
        totalApprovedJudges: 0,
        totalUnapprovedJudges: 0
      };

      data?.map((it) => {
        if (+it?.participants_count > 0) {
          if (!!+it.status) {
            statistics.totalAcceptedRequests += 1;
            statistics.totalApprovedJudges += +it?.participants_count;
          } else {
            statistics.totalUnapprovedJudges += +it?.participants_count;
          }

          statistics.totalRequests += 1;
          recordsWithParticipants = [...recordsWithParticipants, it];
        }
      });

      this.setState(
        {
          statistics,
          recordsCount,
          allRegistrar: recordsWithParticipants,
          filteredRegistrar: recordsWithParticipants,
          isLoading: false
        },
        () => cb && cb()
      );
    });
  };

  clearSearch = () => {
    this.setState(
      (prevState) => ({ filters: { ...prevState.filters, searchBar: '' } }),
      () => this.onFilterRecords()
    );
  };

  closeModalHandler = () => this.setState({ showModal: false });

  onToggleJudgeStatus = (evt, item) => {
    this.toggleUserStatus(
      evt,
      item.id,
      () => this.setState({ shouldUpdateTable: true }),
      2
    );
  };

  searchHandler = (evt) => {
    const { value } = evt.target;

    this.setState(
      (prevState) => ({
        filters: { ...prevState.filters, searchBar: value },
        page: 1
      }),
      () => {
        this.onFilterRecords();
      }
    );
  };

  showModalForm = (evt, item, idx) => {
    evt.preventDefault();

    const id = item?.id;
    let state = {};

    if (item?.country_id) {
      this.fetchReferenceRegion(item?.country_id);
    }

    state = {
      open: true,
      selectedRow: id,
      errors: {}
    };

    if (id) {
      state = {
        ...state,
        modalData: {
          ...item,
          ...(item.photo
            ? { imagePreview: serverDefaultPath + item.photo }
            : {}),
          phone: item?.phone_number,
          currentRecordIdx: idx
        }
      };
    }

    this.setState(state);
  };

  hideModal = () => {
    this.setState({
      open: false,
      selectedRow: null,
      modalData: {},
      allRegions: []
    });
  };

  saveForm = (evt) => {
    const { tournamentData } = this.state;
    const regionVal = document.getElementById('region')?.value;

    this.setState(
      (prevState) => ({
        modalData: {
          ...prevState.modalData,
          ...(regionVal ? { region: regionVal } : {})
        }
      }),
      () => {
        const form = this.state.modalData;

        this.validateForm(
          evt,
          {
            ...defaultForm,
            ...(!+tournamentData?.no_country ? { country_id: '' } : {})
          },
          form,
          'errors',
          () => {
            this.usersRegisterNewUser(evt, tournamentData?.id, form, () => {
              this.setState({ shouldUpdateTable: true });
              this.hideModal();
            });
          },
          () => {
            this.setState({
              showError: { email: true }
            });
          }
        );
      }
    );
  };

  fetchRegionBySelectedCountry = (key) => {
    const { modalData } = this.state;
    let cpy = { ...modalData };
    cpy.region = '';

    this.fetchReferenceRegion(key);
    this.setState({ modalData: cpy });
  };

  expandTableRow = (evt, item) => {
    evt.preventDefault();

    const { tournamentData, collapsed } = this.state;
    const allCollapsedTables = [...collapsed];

    if (allCollapsedTables) {
      const findIndex = allCollapsedTables.findIndex(
        (it) => +it?.id === +item?.id
      );

      if (findIndex === -1) {
        this.fetchStaff(
          tournamentData?.id,
          item.id,
          (body) => {
            let rowSubTable = {
              id: item.id,
              records: body,
              sortDirection: []
            };

            if (body?.length !== item?.participants_count) {
              //This is done to update the drop-down list entries and the counter at the same time
              this.setState({ shouldUpdateTable: true });
            }

            this.setState((prevState) => ({
              collapsed: [...prevState.collapsed, rowSubTable]
            }));
          },
          null
        );
      } else {
        allCollapsedTables.splice(findIndex, 1);

        this.setState({ collapsed: allCollapsedTables });
      }
    }
  };

  onFilterRecords = () => {
    const { allRegistrar, filters } = this.state;
    const len = allRegistrar?.length;
    let newFilteredRecords = [];

    for (let i = 0; i < len; i++) {
      const el = allRegistrar[i];
      const bySearch = filters.searchBar
        ? [el.first_name, el.last_name].some((val) =>
            val.toLowerCase().includes(filters.searchBar.toLowerCase().trim())
          )
        : true;

      const byAssigned = filters.isSwitchSet ? !+el?.status : true;

      if (bySearch && byAssigned) {
        newFilteredRecords = [...newFilteredRecords, el];
      }
    }
    this.setState({ filteredRegistrar: newFilteredRecords }, () => {
      const { filteredRegistrar } = this.state;
      const len = filteredRegistrar?.length;

      this.setState({ recordsCount: len });
    });
  };

  onSort = (sortField, idxCell, noFirstTimeSort) => {
    const { allRegistrar, sortDirection, tournamentData } = this.state;
    const clone = [...allRegistrar];

    const direction = findSortDirection(
      sortDirection,
      sortField,
      noFirstTimeSort
    );
    const sortedData = compareValueForSorting(clone, sortField, direction);

    this.setState(
      {
        ...(!noFirstTimeSort
          ? { sortDirection: [direction, idxCell, sortField] }
          : {}),
        page: 1,
        allRegistrar: sortedData
      },
      () => this.onFilterRecords()
    );
  };

  onSwitchToggle = () => {
    this.setState(
      (prevState) => ({
        page: 1,
        filters: {
          ...prevState.filters,
          isSwitchSet: !prevState.filters.isSwitchSet
        }
      }),
      () => this.onFilterRecords()
    );
  };

  render() {
    const {
      success,
      modalInfo,
      showModal,
      filters,
      shouldDisableEditing,
      isLoading,
      filteredRegistrar,
      tournamentData,
      modalData,
      errors,
      statistics,
      countries,
      allRegions,
      showError,
      selectedRow,
      sortDirection,
      open,
      collapsed,
      recordsCount,
      page,
      rowsPerPage
    } = this.state;
    const { t, classes } = this.props;
    const { viewportWidth } = this.context;

    const FIRST_NAME = !+tournamentData?.last_name_first
      ? 'first_name'
      : 'last_name';
    const LAST_NAME = !+tournamentData?.last_name_first
      ? 'last_name'
      : 'first_name';
    const FIRST_NAME_JUDGE = !+tournamentData?.last_name_first
      ? 'firstName'
      : 'lastName';
    const LAST_NAME_JUDGE = !+tournamentData?.last_name_first
      ? 'lastName'
      : 'firstName';
    const openRegistration = tournamentData?.registration_active === ENABLED;

    const recordDataParams = (item, idx) => {
      return {
        selected: selectedRow === item?.id,
        main: {
          label: t('teamRepresentative'),
          hasSort: true,
          sort: !+tournamentData?.last_name_first ? 'first_name' : 'last_name',
          style: {
            cardMainPart: classes.cardMainPart,
            cardValuePart: classes.cardValuePart
          },
          rowOrder: {
            value: (page - 1) * rowsPerPage + idx + 1
          },
          name: [
            item?.[FIRST_NAME],
            item?.[LAST_NAME],
            ...(!this.tournamentIdParams ||
            !!+tournamentData?.show_reg_patronymic
              ? [item?.patronymic]
              : [])
          ].join(' '),
          hasPhoto: true,
          photo: item?.photo
        },
        info: [
          { sort: 'email', value: item?.email, name: t('email') },
          ...(!+tournamentData?.no_country
            ? [
                {
                  sort: 'country_iso3',
                  value: item.country_iso3,
                  name: t('country')
                }
              ]
            : []),
          ...(!!+tournamentData?.show_reg_region
            ? [{ sort: 'region', value: item.region, name: t('region') }]
            : []),
          ...(!!+tournamentData?.show_reg_city
            ? [{ sort: 'city', value: item.city, name: t('city') }]
            : []),
          ...(!!+tournamentData?.show_reg_club
            ? [{ sort: 'club', value: item.club, name: t('club') }]
            : []),
          {
            sort: 'participants_count',
            value: (
              <span
                onClick={(evt) => {
                  item?.participants_count > 0 &&
                    this.expandTableRow(evt, item);
                }}
                className={clsx(
                  classes.flex,
                  item?.participants_count > 0 && classes.onHover,
                  classes.centerVertically
                )}>
                {item?.participants_count}
                {+item?.participants_count > 0 && (
                  <DropdownUp
                    className={clsx(
                      classes.flex,
                      item?.participants_count > 0 && classes.onHover,
                      classes.marginLeft0dot4,
                      !!collapsed?.find((it) => +it?.id === +item?.id) &&
                        classes.rotate180
                    )}
                  />
                )}
              </span>
            ),
            name: t('judges')
          }
        ]
      };
    };

    const collapsedRecordDataParams = (item, idx, parent) => {
      return {
        main: {
          style: {
            cardMainPart: classes.bgWhite
          },
          rowOrder: {
            value: idx + 1,
            class: classes.rowOrder
          },
          name: [
            item?.[FIRST_NAME_JUDGE],
            item?.[LAST_NAME_JUDGE],
            !!+tournamentData?.show_reg_patronymic ? [item?.patronymic] : []
          ].join(' ')
        },
        info: [
          { value: item?.gender, name: t('gender') },
          { value: DDMMYYYY(item?.birthday), name: t('birthday') },
          { value: item.qdan_name, name: t('kyuDan') },
          ...(!+tournamentData?.no_country
            ? [{ value: item.iso3, name: t('country') }]
            : []),
          ...(!!+tournamentData?.show_reg_city
            ? [{ value: item.city, name: t('city') }]
            : []),
          ...(!!+tournamentData?.show_reg_region
            ? [{ value: item.region, name: t('region') }]
            : []),
          ...(!!+tournamentData?.show_reg_club
            ? [{ value: item.club, name: t('club') }]
            : []),
          { value: item.qualificationRfShort, name: t('rfQual') },
          { value: item.experience, name: t('experience') }
        ]
      };
    };

    const COACH_FIRST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_first_name'
      : 'coach_last_name';

    const COACH_LAST_NAME_VAL = !+tournamentData?.last_name_first
      ? 'coach_last_name'
      : 'coach_first_name';

    const COACH_FIRST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachFirstName')
      : t('coachLastName');

    const COACH_LAST_NAME_LABEL = !+tournamentData?.last_name_first
      ? t('coachLastName')
      : t('coachFirstName');

    const dojoInfo = [
      {
        name: FIRST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('firstname')
          : t('lastname'),
        required: true
      },
      {
        name: LAST_NAME,
        label: !+tournamentData?.last_name_first
          ? t('lastname')
          : t('firstname'),
        required: true
      },
      ...(!!+tournamentData?.show_reg_patronymic
        ? [
            {
              name: 'patronymic',
              label: t('patronymic')
            }
          ]
        : []),
      {
        name: 'email',
        label: t('email'),
        shouldEditCoach: false,
        required: true
      },
      { birthday: true, isDate: true },
      { gender: true, isFilter: true },
      {
        name: 'branch_chief',
        label: t('nameBranchChief')
      },
      {
        name: COACH_FIRST_NAME_VAL,
        label: COACH_FIRST_NAME_LABEL
      },
      {
        name: COACH_LAST_NAME_VAL,
        label: COACH_LAST_NAME_LABEL
      },
      ...(!+tournamentData?.no_country
        ? [
            {
              country: true,
              isFilter: true
            }
          ]
        : []),
      ...(!!+tournamentData?.show_reg_region
        ? [
            allRegions?.length > 0
              ? {
                  region: true,
                  isFilter: true
                }
              : {
                  name: 'region',
                  label: t('region')
                }
          ]
        : []),
      ...(!!+tournamentData?.show_reg_city
        ? [
            {
              name: 'city',
              label: t('city')
            }
          ]
        : []),
      ...(!!+tournamentData?.show_reg_club
        ? [
            {
              name: 'club',
              label: t('cluborFederationName')
            }
          ]
        : []),
      {
        label: t('phone'),
        name: 'phone'
      },
      { label: t('website'), name: 'website' },
      { save: true }
    ];

    const pageHeaderStatistics = {
      title: t('eventStatistics'),
      info: [
        {
          label: [t('total'), t('requests').toLowerCase()].join(' '),
          name: statistics?.totalRequests
        },
        {
          label: [t('total'), t('accesptedRequests')].join(' '),
          name: statistics?.totalAcceptedRequests
        },
        {
          label: t('approvedJudges'),
          name: statistics?.totalApprovedJudges
        },
        {
          label: t('awaitingApproval'),
          name: statistics?.totalUnapprovedJudges
        }
      ]
    };

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        {isLoading ? (
          <LoadingState />
        ) : tournamentData ? (
          <>
            <Modal
              {...{ open }}
              close={this.hideModal}
              dialogTitle={
                modalData?.id
                  ? t('teamRepresentative')
                  : t('addTeamRepresentative')
              }
              onSwitchBetween={
                modalData?.id && {
                  prev: (evt) => {
                    const nextItemIdx = modalData.currentRecordIdx - 1;

                    if (nextItemIdx >= 0) {
                      const item = filteredRegistrar[nextItemIdx];

                      this.showModalForm(evt, item, nextItemIdx);
                    }
                  },
                  next: (evt) => {
                    const nextItemIdx = modalData.currentRecordIdx + 1;
                    const len = filteredRegistrar?.length - 1;

                    if (len >= nextItemIdx) {
                      const item = filteredRegistrar[nextItemIdx];

                      this.showModalForm(evt, item, nextItemIdx);
                    }
                  }
                }
              }
              dialogContent={
                <CoachForm
                  inputs={dojoInfo}
                  onBlur={this.onBlur}
                  {...{ showError }}
                  {...{ tournamentData }}
                  changeTxt={this.textChangeHandler}
                  param={'modalData'}
                  {...{ countries }}
                  values={modalData}
                  selectOption={this.selectOption}
                  {...{ errors }}
                  saveCoachData={this.saveForm}
                  changeImage={this.changeImage}
                  changeDate={(date) =>
                    this.changeDate(date, 'modalData', errors, true)
                  }
                  {...{ openRegistration }}
                  preventEvent={modalData.id}
                  fetchRegionBySelectedCountry={
                    this.fetchRegionBySelectedCountry
                  }
                  {...{ allRegions }}
                />
              }
            />
            <HeaderTournInfo
              {...{ tournamentData, pageHeaderStatistics, viewportWidth }}
              shouldShowBtn
            />
            <span
              className={clsx(
                classes.gridButtons,
                classes.centerVertically,
                classes.marginBottom1,
                shouldDisableEditing
                  ? classes.noviewAddButton
                  : classes.viewAddButton
              )}>
              <Button
                onClick={() => {
                  this.getJudgesCandidatesReport({
                    tournament_id: tournamentData?.id
                  });
                }}
                isSaveBtn={true}
                label={t('saveList')}
                style={{ gridArea: 'saveList' }}
              />
              <CheckboxBtn
                checked={filters.isSwitchSet}
                onChange={(evt, checked) => {
                  this.onSwitchToggle(evt, checked);
                }}
                label={t('showNotAccepted')}
                style={{ gridArea: 'checkboxNotAccepted' }}
                classControlLabel={classes.marginForRootCheckBox}
              />
              <SearchInput
                className={classes.search}
                onChange={this.searchHandler}
                clearSearch={this.clearSearch}
                value={filters.searchBar}
                isSearchInactive={!filters.searchBar}
                style={{ gridArea: 'searchInput' }}
              />
              {!shouldDisableEditing && (
                <Button
                  id="add_new_button"
                  onClick={(evt, item, idx) =>
                    this.showModalForm(evt, item, idx)
                  }
                  isSaveBtn={true}
                  label={
                    <>
                      <span className={classes.plus}>&#x002B;</span>
                      {t('addNew')}
                    </>
                  }
                  style={{ gridArea: 'add' }}
                />
              )}
            </span>
            {recordsCount != 0 ? (
              <>
                <Table
                  onSort={this.onSort}
                  {...{ sortDirection }}
                  // shouldDisableEditing
                  {...{
                    recordDataParams,
                    collapsed,
                    collapsedRecordDataParams
                  }}
                  data={filteredRegistrar.slice(
                    (page - 1) * rowsPerPage,
                    (page - 1) * rowsPerPage + rowsPerPage
                  )}
                  // shouldAllowEditing={() => true}
                  // rowOrder={(page - 1) * rowsPerPage}
                  subTableDataKey="records"
                  id="id"
                  actionIcons={(item, idx, className) => (
                    <>
                      <Tooltip
                        arrow
                        title={t(
                          +item?.status === 1
                            ? 'rejectRequest'
                            : 'acceptRequest'
                        )}>
                        <span>
                          <CheckboxBtn
                            checked={+item?.status === 1}
                            styles={{
                              icon: classes.checkboxSize,
                              checked: classes.checkboxSize
                            }}
                            className={clsx(classes.padding0)}
                            formControlClassName={classes.margin0}
                            onChange={(evt) =>
                              this.onToggleJudgeStatus(evt, item)
                            }
                            disabled={shouldDisableEditing}
                          />
                        </span>
                      </Tooltip>
                      <InfoMark
                        className={clsx(className, classes.marginLeft1)}
                        onClick={(evt) => this.showModalForm(evt, item, idx)}
                      />
                    </>
                  )}
                />

                <Pagination
                  count={recordsCount}
                  onPageChange={this.pageChangeHandler}
                  onRowsPerPageChange={this.rowsPerPageHandler}
                  {...{ page }}
                  {...{ rowsPerPage }}
                />
              </>
            ) : (
              <EmptyState
                className={classes.emptyState}
                emptyStateText={t('noResults')}
              />
            )}
          </>
        ) : (
          <EmptyState />
        )}
      </>
    );
  }
}
export default withTranslation()(withStyles(styles)(withRouter(ApproveJudges)));
