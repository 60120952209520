import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { EMAIL } from '../../helpers/constants';
import { ReactComponent as Logo } from '../../assets/img/tournament_expert_LOGO.svg';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { TextField } from '@material-ui/core';
import Button from '../Buttons/ActionButtons';
import BlueCheckbox from '../../assets/img/bluecheckbox.png';
import { styles } from './StylesSigninP2';

const SignInP2 = (props) => {
  const { classes, formState, handleChange, signIn } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const toggleShow = () => {
    setPasswordVisible(!passwordVisible);
  };

  const buttons = [
    {
      label: t('logIn'),
      onClick: (evt) => signIn(evt, formState)
    }
  ];

  const checkboxes = [
    { label: t('loginIssue'), goTo: '/forgot-password' },
    { label: t('signUp'), goTo: '/sign-up' }
  ];

  const buttonComponent = (label, onClick) => (
    <Button
      {...{ label }}
      className={clsx(classes.blue, classes.marginTop12, classes.text)}
      isSaveBtn={true}
      {...{ onClick }}
    />
  );
  const fields = [
    { name: 'email', label: EMAIL, className: classes.marginTop25 },
    {
      name: 'password',
      label: t('password'),
      type: !passwordVisible && 'password',
      isPassowrdRelated: true,
      className: clsx(classes.marginTop12)
    }
  ];
  const checkbox = (label) => (
    <div className={clsx(classes.wrapper, classes.marginTop12)}>
      <img className={classes.marginRight} src={BlueCheckbox} alt="checkbox" />
      <span className={clsx(classes.black, classes.fontSize14)}>{label}</span>
    </div>
  );

  const textField = (name, label, type, isPassowrdRelated, className) => (
    <TextField
      key={name}
      {...{ className }}
      classes={{ root: classes.txtField }}
      error={hasError(name)}
      helperText={hasError(name) && formState.errors[name][0]}
      {...{ label }}
      {...{ name }}
      onChange={handleChange}
      type={type || 'text'}
      onKeyDown={(evt) => evt.key === 'Enter' && signIn(evt, formState)}
      value={formState.values[name] || ''}
      variant="outlined"
      InputProps={
        isPassowrdRelated && {
          className: classes.endAdornment,
          endAdornment: (
            <div
              className={clsx(
                classes.iconWrapper,
                passwordVisible && classes.eyeIcon
              )}
              onClick={toggleShow}>
              <VisibilityOutlinedIcon />
            </div>
          )
        }
      }
    />
  );

  return (
    <div className={clsx(classes.size, classes.text)}>
      <span className={classes.fontSize20}>{t('logIn')}</span>
      {fields.map((it) =>
        textField(
          it.name,
          it.label,
          it.type,
          it.isPassowrdRelated,
          it.className
        )
      )}
      {buttons.map((it, idx) =>
        it.onClick ? (
          <Fragment key={idx}>
            {buttonComponent(it.label, it.onClick, it.className)}
          </Fragment>
        ) : (
          <Link
            className={clsx(classes.flex, classes.marginTop12)}
            to={it.goTo}
            key={idx}>
            {buttonComponent(it.label, it.onClick, it.className)}
          </Link>
        )
      )}
      <div className={classes.marginTop20}>
        {checkboxes.map((it, idx) => (
          <Link to={it.goTo} key={idx}>
            {checkbox(it.label, it.goTo)}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(SignInP2);
