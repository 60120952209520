const styles = () => ({
  paper: {
    backgroundColor: '#fff',
    padding: '1em',
    borderRadius: '10px',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },
  flex: {
    display: 'flex'
  },
  column: {
    flexDirection: 'column'
  },
  cardImgPortrait: {
    width: '60%',
    height: '6.25em',
    '@media (max-width: 767px)': {
      width: '11.875em',
      height: '3em',
    }
  },
  cardImgLandscape: {
    width: '85%',
    height: '6.25em',
    '@media (max-width: 767px)': {
      width: '18.75em',
      height: '3em',
    }
  },
  h1: {
    fontSize: '18px',
    fontWeight: 700,
    wordBreak: 'break-word'
  },

  paddingBottom1: {
    paddingBottom: '1em',
  },

  btn: {
    width: '13.8em',
    '@media (max-width: 767px)': {
      width: '100%'
    }
  },

  justifyEnd: {
    margin: '20px 0',
    justifyContent: 'end'
  },

  opacity: {
    opacity: '0.5'
  },

  fontSize12: {
    fontSize: '12px'
  },

  sizeForSignature: {
    height: '10.313em',
    width: '10.313em',
    '@media (max-width: 1023px)': {
      height: '8.125em',
      width: '8.125em',
    }
  },

  inlineContainer: {
    marginTop: '15px',
    display: 'inline-flex',
    width: '49.5%',
    '@media (max-width: 767px)': {
      width: '100%'
    }
  },

  marginRight: {
    marginRight: '1%'
  },

  marginTop15: {
    marginTop: '15px'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },

  generalSettings: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '11px 14px',
    borderRadius: '4px',
    backgroundColor: '#8996AC',
    fontWeight: 600,
    fontSize: '18px',
    color: '#FFFFFF',
    cursor: 'pointer'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  marginBottom2: {
    marginBottom: '2rem'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  marginBottom1_5: {
    marginBottom: '1.5rem'
  },

  center: {
    alignItems: 'center'
  },

  width15: {
    width: '15px'
  },

  reportsLabel: {
    fontWeight: 600
  },

  fullWidth: {
    width: '100%'
  },

  maxWidth220: {
    width: '220px'
  },

  maxWidth150: {
    width: '150px'
  },

  marginLeft2: {
    marginLeft: '2rem'
  },

  wrapper: {
    borderRadius: '10px',
    backgroundColor: '#FFFFFF',
    padding: '0 1rem',
    marginBottom: '1rem',
    '@media (max-width: 1023px)': {
      paddingBottom: '1rem'
    },
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  marginRight6: {
    marginRight: '6rem'
  },

  marginLeft5: {
    marginLeft: '6rem'
  },

  btnColors: {
    backgroundColor: '#8996AC',
    color: '#FFFFFF',
    '&:hover': {
      color: '#262441'
    }
  },

  grid: {
    display: 'grid',
  },

  gridTemplateColumns3_2: {
    gridGap: '0.5em',
    gridTemplateColumns: 'repeat(3, 1fr)',
    '@media (max-width: 767px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    }
  },

  padding4: {
    padding: '4px'
  },

  marginLeft025: {
    marginLeft: '.25rem'
  },

  goBackArrow: {
    border: '1px solid #98A5BA',
    width: '2.2rem',
    height: '2.2rem',
    cursor: 'pointer',
    borderRadius: '0.2rem',
    backgroundColor: '#E9ECF6',
    color: '#8996AC'
  },

  marginLeftAuto: {
    marginLeft: 'auto'
  },

  margin10: {
    padding: '10px'
  },

  radio: {
    marginRight: '4px',
  }
});

export default styles;
