const styles = () => ({
  flex: {
    display: 'flex'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  icon: {
    cursor: 'pointer',
    alignSelf: 'end',
    marginBottom: '0.2rem',
    '&:hover': {
      opacity: 0.6
    }
  }
});

export default styles;
