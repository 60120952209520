import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex',
    width: 'auto'
  },
  permissionList: {
    borderBottom: '1px solid #8996AC',
    '& .MuiTypography-body1': {
      width: '100%'
    }
  },
  left: {
    marginLeft: 'auto'
  },
  icon: {
    cursor: 'pointer',
    color: '#6E747C',
    '&:hover': {
      color: '#86BBE0'
    },
    '&:active': {
      color: '#1E5A8C'
    }
  },
  iconWrapper: {
    display: 'flex',
    width: '45px',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#8996AC'
  },
  maxHeight: {
    width: '285px',
    height: '60px'
  },
  eyeIcon: {
    color: '#000'
  },

  permissionWrapper: {
    // borderBottom: '1px solid #6E747C',
  },

  noEvent: {
    pointerEvents: 'none'
  },

  marginLR: {
    margin: '0 1rem 0 0.5rem'
  },

  gridTextArea: {
    gridArea: 'gridTextArea',
    width: '285px',
    height: '50px'
  },
  gridFilterArea: {
    gridArea: 'gridFilterArea',
    width: '285px',
    height: '50px'
  },

  grid: {
    display: 'grid',
    gap: '24px',
    '@media (min-width:768px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        "lastname firstname"
        "username password"
        "role status"
      `
    },
    '@media (max-width:767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        "lastname"
        "firstname"
        "username"
        "password"
        "role"
        "status"
        `
    }
  }
});

export const styleFilter = {
  width: '100%',
  padding: '10px 0 10px 0'
};

export const margins = {
  display: 'flex',
  flexDirection: 'column'
};
