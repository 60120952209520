import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  wrap: {
    flexWrap: 'wrap',
    '@media (max-width: 767px)': {
      width: '100%'
    }
  },

  wrapper: {
    margin: '0.5rem -0.5rem '
  },

  paper: {
    backgroundColor: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid rgba(137, 150, 172, 0.7)'
  },

  checkboxItem: {
    margin: '0.5em',
    height: '3em',
    minWidth: '9em',
    '&  .MuiTypography-body1': {
      marginRight: 'auto'
    }
  },

  checkboxIcon: {
    padding: 0,
    marginRight: '0.5rem'
  },

  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(45,99,238,.2), inset 0 -1px 0 rgba(45,99,238,.1)',
    backgroundColor: '#fff',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    }
  },

  checkedIcon: {
    backgroundColor: '#2D63EE',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  },

  activFilterWrapper: {
    minHeight: '3rem',
    height: '100%',
    width: '100%',
    padding: '0.5em',
    backgroundColor: '#8996AC',
    borderRadius: '5px',
    color: '#FFFFFF',
    marginBottom: '1rem',
    fontWeight: 600,
    alignItems: 'center',
    '&:hover': {
      color: '#262441'
    },
    '& .MuiButton-label ': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },

  pointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  alignItemsCenter: {
    alignItems: 'center'
  },

  clearIcon: {
    backgroundColor: '#FFFFFF',
    color: '#8996AC',
    borderRadius: '2px'
  },

  flexGrow1: {
    flexGrow: 1
  },

  center: {
    justifyContent: 'center'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },

  fullWidth: {
    width: '100%'
  }
}));
