import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useStyles } from './style';

const HeaderInfo = (props) => {
  const { data } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <span className={clsx(classes.flex, classes.fullWidth, classes.paper)}>
      <span className={clsx(classes.flex, classes.fullWidth)}>
        <img className={classes.img} alt="pic" src={data.src} />
        <span className={clsx(classes.marginLeftRight, classes.fullWidth)}>
          {data.info.map((it, idx) => (
            <span
              key={idx}
              className={clsx(
                classes.flex,
                classes.wordBreak,
                classes.paddingBottom
              )}>
              {it.label && (
                <span className={clsx(classes.secondaryTxt)}>{it.label}</span>
              )}
              {it.name}
            </span>
          ))}
        </span>
        {data.moveTo && (
          <Tooltip arrow title={t('goBack')}>
            <ArrowBackIcon
              onClick={() =>
                navigate('/federation', {
                  state: { prevUrl: location.pathname }
                })
              }
              className={clsx(classes.iconBtn, classes.marginLeft20)}
            />
          </Tooltip>
        )}
      </span>
    </span>
  );
};

export default HeaderInfo;
