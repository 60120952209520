import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    border: '1px solid #A2ABBA',
    borderRadius: '7px',
    padding: '10px',
    height: '410px'
  },
  emptyStateText: {
    textAlign: 'center'
  },
  wrapper: {
    gap: '6px',
    margin: '20px 0 20px 0'
  },
  btn: {
    width: '320px',
    margin: '45px auto 0 auto',
    textAlign: 'center'
  }
}));
