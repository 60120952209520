export const styles = (theme) => ({
  flex: {
    display: 'flex'
  },

  column: {
    flexDirection: 'column'
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  marginTop0: {
    marginTop: 0
  },

  margin1lg: {
    '@media (min-width:901px)': {
      margin: '0 1rem'
    }
  },

  docImg: {
    width: '5em',
    height: '5em',
    objectFit: 'contain',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '0.5rem'
  },

  downloadIc: {
    width: '1.5em',
    height: '1.5em',
    padding: '5px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#001D3D',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },

  centerVertically: {
    alignItems: 'center'
  },

  left: {
    marginLeft: 'auto'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  halfWidth: {
    width: '50%'
  },

  sortLabel: {
    color: '#99A5B1'
  },

  fullWidth: {
    width: '100%'
  },

  labelLine: {
    borderTop: '1px solid',
    width: '25em',
    marginLeft: '0.2rem'
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#2D63EE'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#2D63EE',
      opacity: 0.6
    }
  },

  noEvent: {
    pointerEvents: 'none'
  },

  checkboxDefault: {
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#2D63EE'
    }
  },
  maxWidth: {
    [theme.breakpoints.up('lg')]: {
      minWidth: 'fit-content'
    }
  },

  maxWidthConfirmDelete: {
    [theme.breakpoints.up('lg')]: {
      minWidth: '20em'
    }
  },

  addressText: {
    fontSize: '14px',
    color: '#8996AC'
  },

  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  iframeSize: {
    border: 'none',
    [theme.breakpoints.up('lg')]: {
      width: '780px',
      height: '430px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '500px',
      height: '430px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '303px',
      height: '303px'
    }
  },

  marginBottom2: {
    marginBottom: '2rem'
  },
  copyTxt: {
    marginRight: '12px',
    cursor: 'pointer'
  },
  alignItemsCenter: {
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});
