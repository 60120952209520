import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  flex: {
    display: 'flex'
  },
  txt: {
    fontSize: '28px',
    fontWeight: 500
  },
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '60vh'
  },
  image: {
    marginTop: '20px',
    maxWidth: '560px'
  }
}));
