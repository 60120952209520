export const PROD_API_DEFAULT_PATH = 'https://api.tournament.expert';
export const DEV_API_DEFAULT_PATH = 'https://api-dev.tournament.expert';

export const PROD_API_URL = `${PROD_API_DEFAULT_PATH}/mobile_api`;
export const DEV_API_URL = `${DEV_API_DEFAULT_PATH}/mobile_api`;
export const PROD_AVATARS_PATH = `${PROD_API_DEFAULT_PATH}/images/avatars/`;
export const DEV_AVATARS_PATH = `${DEV_API_DEFAULT_PATH}/images/avatars/`;
export const PROD_SCHOOL_PATH = `${PROD_API_DEFAULT_PATH}/school`;
export const DEV_SCHOOL_PATH = `${DEV_API_DEFAULT_PATH}/school`;
export const PROD_FEDERATION_PATH = `${PROD_API_DEFAULT_PATH}/federations`;
export const DEV_FEDERATION_PATH = `${DEV_API_DEFAULT_PATH}/federations`;
export const PROD_RECORDER_PATH = `${PROD_API_DEFAULT_PATH}/recorder`;
export const DEV_RECORDER_PATH = `${DEV_API_DEFAULT_PATH}/recorder`;
export const PROD_V2_PATH = `${PROD_API_DEFAULT_PATH}/v2`;
export const DEV_V2_PATH = `${DEV_API_DEFAULT_PATH}/v2`;
export const PROD_LOGS_PATH = `${PROD_API_DEFAULT_PATH}/logs`;
export const DEV_LOGS_PATH = `${DEV_API_DEFAULT_PATH}/logs`;
export const DEV_URL = 'http://ksnk-app.hosting.netinfo.md';
export const PROD_URL = 'https://app.tournament.expert';
