import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { TIME_OUT } from '../../helpers/constants';
import { useStyles } from './StylesSideModal';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon
};
const Snack = (props) => {
  const { info, success, closeModal, show } = props;
  const classes = useStyles();
  const Icon = success ? variantIcon['success'] : variantIcon['warning'];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      autoHideDuration={success ? TIME_OUT : undefined}
      onClose={closeModal}
      open={Boolean(show)}>
      <SnackbarContent
        className={success ? classes.success : classes.warning}
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classes.icon} />
            {info}
          </span>
        }
        action={
          <IconButton color="inherit" onClick={closeModal}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        }
      />
    </Snackbar>
  );
};
export default Snack;
