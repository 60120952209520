const styles = () => ({
  gridRecordsAttachments: {
    '@media (min-width:768px)': {
      gridTemplateColumns: '7.5em 1fr'
    }
  },

  docIc: {
    height: 'auto',
    width: '5rem'
  },

  actionIcDoc: {
    width: '3em',
    height: '3em'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  btnDownloadDoc: {
    width: '10.715em'
  },

  txtWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    wordSpacing: '100vw',
    '@media (max-width: 767px)': {
      fontWeight: 600,
      wordSpacing: 'normal'
    }
  },

  maxWidthModal: {
    '@media (min-width:1024px)': {
      maxWidth: '100%',
      minWidth: '40em'
    }
  },

  toolbar: {
    padding: 0,
    marginBottom: '1rem',
    display: 'grid',
    marginTop: '1rem',
    '@media (min-width:1025px)': {
      gridTemplateColumns: 'repeat(6, 1fr)',
      gridTemplateAreas: `
              'searchInput searchInput searchInput searchInput searchInput addButton'
            `,
      gridGap: '1%'
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateAreas: `
              'searchInput searchInput searchInput'
              'addButton addButton addButton'
            `,
      gridGap: '1em'
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
              'searchInput searchInput'
              'addButton addButton'
            `,
      gridGap: '1em'
    }
  },

  search: { margin: '0 1rem 0 0' },

  txtWrapper: {
    display: 'flex'
  },

  cardWrapper: {
    backgroundColor: '#FFFFFF',
    gridTemplateColumns: 'repeat(4, max-content)',
    padding: '0 0 0.5rem 0.5rem'
  },

  center: {
    textAlign: 'center'
  },

  emptyState: {
    marginTop: '40px'
  }
});

export default styles;
