import clsx from 'clsx';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@material-ui/core';

import { selectedValue } from '../../helpers/selectors';
import {
  GENDER,
  NAME,
  ID,
  UPDATE_CATEGORY,
  ADD_NEW_GROUP_CATEGORY,
  UPDATE_GROUP_CATEGORY,
  ADD_CATEGORY
} from '../../helpers/constants';

import Filter from '../Filter/Filter';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import Button from '../Buttons/ActionButtons';
import LoadingState from 'components/LoadingState/LoadingState';

import { useStyles } from './style';

export default ({
  formParamName,
  value,
  error,
  textChange,
  isUpdateForm,
  selectGender,
  categoryTypes,
  selectCategoryType,
  saveCategory,
  onSelectAbsoluteCategory,
  shouldShowForm,
  disabledFields,
  classNamePaper,
  shouldHideSaveFormBtn,
  isLoading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isCategory =
    shouldShowForm === UPDATE_CATEGORY ||
    shouldShowForm === ADD_CATEGORY ||
    shouldShowForm === UPDATE_GROUP_CATEGORY;
  const isGroup = value?.weighted;

  const inputs = [
    {
      type: true,
      disabled: disabledFields?.type
    },
    ...(shouldShowForm === ADD_NEW_GROUP_CATEGORY &&
    +value.group_category_type === 2
      ? [{ checkbox: true, className: classes.checkboxAbsoluteCatsm }]
      : []),
    {
      name: 'group_category_age_from',
      label: [t('age'), t('from').toLowerCase()].join(' '),
      int: true,
      disabled: disabledFields?.group_category_age_from
    },
    ...(!(
      shouldShowForm === ADD_NEW_GROUP_CATEGORY &&
      +value.group_category_type === 2 &&
      value.isAbsoluteChecked
    )
      ? !value?.isCategoryAbsolute
        ? [
            {
              name: 'group_category_age_to',
              label: [t('age'), t('to').toLowerCase()].join(' '),
              int: true,
              disabled: disabledFields?.group_category_age_to
            }
          ]
        : []
      : []),
    {
      gender: true,
      disabled: disabledFields?.gender
    },
    ...(isCategory && isGroup && !value?.isCategoryAbsolute
      ? [
          {
            name: 'category_weight_from',
            label:
              `${value?.group_category_type}` !== '8'
                ? t('weight')
                : t('units'),
            int: true,
            disabled: disabledFields?.category_weight_from
          }
        ]
      : []),
    [
      {
        name: 'group_category_name',
        label:
          isCategory && isGroup && !value?.isCategoryAbsolute
            ? t('nameMinusCategories')
            : t('nameCategoriesForm'),
        disabled: disabledFields?.group_category_name
      },
      {
        name: 'group_category_alt_name',
        label:
          isCategory && isGroup && !value?.isCategoryAbsolute
            ? [t('alternative'), t('nameMinusCategories').toLowerCase()].join(
                ' '
              )
            : [
                t('alternative'),
                t(
                  isCategory && !value.weighted
                    ? 'nameCategoriesForm'
                    : 'inanimateName'
                ).toLowerCase()
              ].join(' ')
      }
    ],
    ...(isCategory && isGroup && !value?.isCategoryAbsolute
      ? [
          [
            {
              name: 'category_name_plus',
              label: t('namePlusCategories'),
              disabled: disabledFields?.category_name_plus
            },
            {
              name: 'category_alt_name_plus',
              label: [
                t('alternative'),
                t('namePlusCategories').toLowerCase()
              ].join(' '),
              disabled: disabledFields?.category_alt_name_plus
            }
          ]
        ]
      : []),
    ...(`${value.group_category_type}` === '2' && isCategory
      ? [{ infoMsg: true }]
      : []),
    ...(!(shouldHideSaveFormBtn && shouldHideSaveFormBtn())
      ? [{ save: true }]
      : [])
  ];

  const textField = (textFieldItem) => (
    <TextField
      className={clsx(
        classes.input,
        classes.ellipsis,
        classes.fullWidth,
        textFieldItem.className
      )}
      value={value[textFieldItem.name] || ''}
      name={textFieldItem.name}
      label={textFieldItem.label}
      onChange={(evt) =>
        textChange(
          evt,
          formParamName,
          error,
          textFieldItem.int,
          null,
          null,
          null,
          null,
          null,
          value,
          isUpdateForm
        )
      }
      InputLabelProps={{
        classes: {
          shrink: classes.fullWidth
        }
      }}
      disabled={textFieldItem.disabled}
      error={Boolean(error[textFieldItem.name])}
      helperText={
        Boolean(error[textFieldItem.name]) && error[textFieldItem.name]
      }
      variant="outlined"
    />
  );

  const filter = (
    options,
    onChange,
    label,
    key,
    name,
    item,
    givenClassName,
    disabled //on update, disable input if it is a group or group category
  ) => (
    <Filter
      className={clsx(classes.filter, classes.input, givenClassName)}
      {...{ options }}
      {...{ label }}
      value={selectedValue(options, key, value[name]) || ''}
      {...{ onChange }}
      {...{ item }}
      variant="outlined"
      error={Boolean(error[name])}
      helperText={Boolean(error[name]) && error[name]}
      {...{ disabled }}
    />
  );

  return (
    <div
      className={clsx(classes.paper, classNamePaper ?? classes.paperMargins)}>
      {!isLoading ? (
        <>
          <div className={clsx(classes.marginTop15, classes.grid)}>
            {inputs.map((it, index) => (
              <Fragment key={index}>
                {!it.type &&
                  !it.gender &&
                  !it.checkbox &&
                  !it.save &&
                  !it.infoMsg &&
                  (Array.isArray(it) ? (
                    <span className={classes.gridAltNames}>
                      {it.map((subIt, subItemIdx) => (
                        <Fragment key={subItemIdx}>{textField(subIt)}</Fragment>
                      ))}
                    </span>
                  ) : (
                    textField(it)
                  ))}
                {it.type &&
                  filter(
                    categoryTypes,
                    selectCategoryType,
                    t('type'),
                    ID,
                    'group_category_type',
                    NAME,
                    it.className,
                    it.disabled
                  )}
                {it.gender &&
                  filter(
                    [...GENDER, { name: 'U', label: t('unisex') }],
                    selectGender,
                    t('gender'),
                    NAME,
                    'group_category_gender',
                    NAME,
                    it.className,
                    it.disabled
                  )}
                {it.checkbox && (
                  <CheckboxBtn
                    formControlClassName={it.className}
                    checked={value?.isAbsoluteChecked}
                    onChange={onSelectAbsoluteCategory}
                    label={t('absoluteCategory')}
                    labelPlacement="start"
                  />
                )}
                {it.save && (
                  <Button
                    className={clsx(classes.flex, classes.btn)}
                    onClick={saveCategory}
                    label={t('save')}
                    isSaveBtn={true}
                  />
                )}
              </Fragment>
            ))}
          </div>
        </>
      ) : (
        <LoadingState className={classes.loadingState} />
      )}
    </div>
  );
};
