import clsx from 'clsx';

import { CLEAR_ICON_PERMISSION_MODAL } from '../../helpers/constants';

import { useStyles } from './Styles';
import CheckboxBtn from 'components/CheckboxBtn/CheckboxBtn';

const Permissions = (props) => {
  const {
    tournamentsList,
    selectCheckboxes,
    selectedCheckboxes,
    specificDetails
  } = props;

  const classes = useStyles();

  return (
    <>
      {Array.isArray(tournamentsList) && tournamentsList.length > 0 && (
        <div>
          {tournamentsList.map((it, idx) => (
            <span
              key={idx}
              className={clsx(classes.flex, classes.permissionList)}>
              <span
                className={clsx(
                  classes.flex,
                  classes.permissionList,
                  classes.permissionWrapper
                )}></span>
              <span
                className={clsx(
                  classes.flex,
                  classes.icon,
                  ...specificDetails.className.actionBtn
                )}
                id={CLEAR_ICON_PERMISSION_MODAL}
                // onClick={(evt) => resetPermission(evt, it)}
              >
                <CheckboxBtn
                  checked={selectedCheckboxes.some((item) => item.id === it.id)}
                  className={clsx(classes.checkboxBtn)}
                  label={it.tournament_name}
                  classControlLabel={classes.marginRight0}
                  onChange={() => selectCheckboxes(it)}
                />
              </span>
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default Permissions;
