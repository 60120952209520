/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { List, ListItem } from '@material-ui/core';
import { useStyles } from './StylesSidebarNav';

const Item = ({ icon: Icon, title, id, onClick, className }) => {
  const classes = useStyles();

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...{ id, onClick }}>
      <Icon className={classes.icon} />
      {title}
    </ListItem>
  );
};

const SidebarNav = ({ pages, onClose }) => {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {pages.map(({ title, href, icon, onClick, id }, pageIdx) => {
        return href ? (
          <NavLink
            end
            onClick={() => {
              onClose();
              document.querySelector('#top') &&
                window.scroll({
                  top: document.querySelector('#top').offsetTop,
                  behavior: 'smooth'
                });
            }}
            to={href}
            key={pageIdx}
            className={({ isActive }) =>
              clsx(classes.navLink, isActive && classes.activeLink)
            }>
            <Item {...{ icon, title, id }} />
          </NavLink>
        ) : (
          <Item
            key={pageIdx}
            {...{ icon, title, id, onClick }}
            className={classes.navLink}
          />
        );
      })}
    </List>
  );
};

export default SidebarNav;
