import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import PaymentForm from '../../components/Payment/PaymentFrom';
import SideModal from '../../components/Snackbar/SideModal';
import {
  selectOption,
  textChangeHandler,
  addPayment,
  changeModal,
  validateForm
} from '../../helpers/util';

const defaultInputs = {
  payType: '',
  amount: '',
  currency: '',
  operationDate: ''
};

class AddPaymnet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      success: false,
      showModal: false,
      paymentForm: { ...(props.state ? props.state.paymentForm : {}) }
    };

    this.partIdParams = window.location.pathname.split('/')[2];

    this.selectOption = selectOption.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.changeModal = changeModal.bind(this);
    this.addPayment = addPayment.bind(this);
    this.validateForm = validateForm.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this.props;

    if (state && prevProps.state.paymentForm !== state.paymentForm) {
      this.setState({
        paymentForm: state.paymentForm,
        errors: {}
      });
    }
  }

  tournamentDateChangeHandler = (date) => {
    const { errors } = this.state;
    const { t } = this.props;

    if (date === null) {
      errors.operationDate = t('required');
    } else if (isNaN(Date.parse(date))) {
      errors.operationDate = t('invalidDate');
    } else {
      const selectedYear = date.getFullYear();
      const selectedMonth =
        date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      const selectedDay =
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
      const payDate = `${selectedYear}-${selectedMonth}-${selectedDay}`;

      delete errors.operationDate;
      this.setState((prevState) => ({
        paymentForm: {
          ...prevState.paymentForm,
          operationDate: payDate
        },
        errors
      }));
    }
  };

  closeModalHandler = () => this.setState({ showModal: false });

  render() {
    const { errors, success, showModal, modalInfo, paymentForm } = this.state;
    const { closeFormHandler, triggerTableUpdate } = this.props;

    return (
      <>
        <SideModal
          closeModal={this.closeModalHandler}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <PaymentForm
          handleTextChange={this.textChangeHandler}
          tournamentDateChange={this.tournamentDateChangeHandler}
          {...{ errors }}
          values={paymentForm}
          saveForm={(evt) =>
            this.validateForm(evt, defaultInputs, paymentForm, 'errors', () => {
              paymentForm && paymentForm.id
                ? undefined
                : this.addPayment(this.partIdParams, paymentForm, () =>
                    triggerTableUpdate()
                  );
            })
          }
          selectOption={this.selectOption}
          {...{ closeFormHandler }}
        />
      </>
    );
  }
}
export default withTranslation()(withRouter(AddPaymnet));
