import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useStyles } from './styles';

const NotFound = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.flex, classes.content)}>
      <span className={classes.txt}>404: {t('pageDoesNotExists')}</span>
      <img
        alt="Under development"
        className={classes.image}
        src="/images/404_Error.png"
      />
    </div>
  );
};

export default NotFound;
