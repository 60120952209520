import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center'
  },
  text: {
    marginRight: '5px'
  },
  successIcon: {
    fontSize: 250,
    color: theme.palette.primary.main
  },
  failIcon: {
    fontSize: 250,
    color: '#FF4136'
  }
}));
