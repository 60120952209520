import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  marginTop40: {
    marginTop: '40px',
    '@media (max-width:1023px)': {
      marginTop: '20px'
    }
  },
  marginTop20: {
    '@media (max-width:767px)': {
      marginTop: '20px'
    }
  },
  fullWidth: {
    width: '100%'
  },
  iconWrapper: {
    height: '52px',
    alignItems: 'center',
    borderLeft: '1px solid #e0e6eb',
    width: '54px',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  icon: {
    color: theme.palette.primary.main
  },
  outlinedIcon: {
    pointerEvents: 'none'
  },
  endAdornment: {
    paddingRight: 0
  },
  asterisk: {
    '@media (max-width:505px)': {
      marginTop: '20px'
    },
    '& .MuiFormLabel-asterisk': {
      color: '#005FEE'
    },
    maxHeight: '60px',
    color: '#888'
  },
  txtField: {
    border: 'none',
    height: '60px',
    minWidth: '220px'
  },
  grey: {
    color: 'grey'
  },
  inputContainer: {
    display: 'grid',
    marginTop: '20px',
    gap: '25px',
    '@media (min-width:1024px)': {
      gridColumnGap: '1.5rem',
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateAreas: `
        'last_name first_name patronymic'
        'email confirmEmail country'
        'region city phone'
        'club club club'
        'branch_chief password confirm_password'
        'cancel save .'
        `
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridColumnGap: '1.5rem',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        'patronymic first_name'
        'last_name email '
        'confirmEmail country'
        'region city'
        'phone branch_chief '
        'club club'
        'password confirm_password'
        'cancel save'
        `
    },
    '@media (max-width:767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        'last_name'
        'first_name'
        'patronymic'
        'email '
        'confirmEmail'
        'country'
        'region'
        'city'
        'phone'
        'club'
        'branch_chief'
        'password'
        'confirm_password'
        'save'
        'cancel'
        `
    }
  },
  first_name: { gridArea: 'first_name' },
  last_name: { gridArea: 'last_name' },
  patronymic: { gridArea: 'patronymic' },
  email: { gridArea: 'email' },
  confirm_email: { gridArea: 'confirmEmail' },
  country: { gridArea: 'country' },
  region: { gridArea: 'region' },
  city: { gridArea: 'city' },
  phone: { gridArea: 'phone' },
  club: { gridArea: 'club' },
  branch_chief: { gridArea: 'branch_chief' },
  password: { gridArea: 'password' },
  confirm_password: { gridArea: 'confirm_password' },
  save: { gridArea: 'save' },
  cancel: { gridArea: 'cancel' },

  background: {
    padding: '35px',
    maxWidth: '800px',
    background: '#ffffff',
    borderRadius: '10px'
  },
  fontSize20: {
    fontSize: '20px',
    fontWeight: 500
  },
  width200: {
    width: '200px'
  }
}));

export default useStyles;
