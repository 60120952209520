import React, { Component, Fragment } from 'react';
import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'components/withRouter';

import { Divider, TextField } from '@material-ui/core';

import { ReactComponent as Bronze } from '../../assets/img/thirdPlaceMedal.svg';

import {
  KEEP_EDITING,
  CLOSE_DISCARD,
  MODAL_EDIT_IC,
  UPDATE_CATEGORY,
  UPDATE_GROUP,
  DELETE_ICON,
  TEST_ID_INFO_BTN
} from '../../helpers/constants';
import {
  convertMs,
  setCategoryName,
  selectedValue,
  isDesktopView
} from '../../helpers/selectors';
import {
  fetchCategoriesPerTourn,
  fetchParticipantsByTournamentCategories,
  selectOption,
  distributeParticipantByCategory,
  toggleTatamiCategoryV2,
  updateTournamentCategoryTime,
  fetchKataNames,
  updateTournKataNames,
  updateTournamentCategoryKataFinal,
  exportTournamentCategories,
  downloadFile,
  textChangeHandler,
  updateCategoryGroup,
  updateCategory,
  changeModal,
  fetchParticipantsByTournamentV2
} from '../../helpers/util';

import Filter from '../../components/Filter/Filter';
import CategoriesBlock from '../../components/AddTournamentCategories/AddTournamentCategories';
import Modal from '../../components/Modal/ModalNewDesign';
import CheckboxBtn from '../../components/CheckboxBtn/CheckboxBtn';
import LoadingState from '../../components/LoadingState/LoadingState';
import SideModal from '../../components/Snackbar/SideModal';
import AddCategory from '../../components/AddCategory/AddCategory';

import styles from './style';

const STATISTICS_DETAILS = (t, statistics, types) => ({
  title: t('statisticsByCategory'),
  state: 'statisticsByCategory',
  styles: { list: { display: 'grid', gridTemplateColumns: '1fr 1fr' } },
  info: [
    {
      label: t('categoriesTotal', { name: '' }),
      name: statistics.assignedCount
    },
    {
      label: t('categoriesUsed'),
      name: statistics.filledCount
    },
    ...[].concat(
      ...types.map((it) => [
        ...(statistics.types[`assignedCount_${it.id}`] > 0
          ? [
              {
                label: t('categoriesTotal', { name: it.name.toUpperCase() }),
                name: statistics.types[`assignedCount_${it.id}`]
              },
              {
                label: t('categoriesUsed'),
                name: statistics.types[`filledCount_${it.id}`]
              }
            ]
          : [])
      ])
    ),
    {
      label: t('redCategories'),
      name: statistics.issueCount
    }
  ]
});

const EXTRA_TIME = (t, item) => {
  const convertedTimeMT = convertMs(item.main_time_ms);
  const resultTimeMT = [convertedTimeMT.minutes, convertedTimeMT.seconds].join(
    ':'
  );
  const convertedTimeET = convertMs(item.extra_time_ms);
  const resultTimeET = [convertedTimeET.minutes, convertedTimeET.seconds].join(
    ':'
  );
  const convertedTimeFMT = convertMs(item.final_main_time_ms);
  const resultTimeFMT = [
    convertedTimeFMT.minutes,
    convertedTimeFMT.seconds
  ].join(':');
  const convertedTimeFET = convertMs(item.final_extra_time_ms);
  const resultTimeFET = [
    convertedTimeFET.minutes,
    convertedTimeFET.seconds
  ].join(':');

  return [
    {
      title: t('preliminaryRounds'),
      subtitle: t('mainAndExtraTime'),
      time: [
        {
          label: 'MT',
          value: item.main_time_ms && resultTimeMT,
          param: 'main_time_ms'
        },
        {
          label: 'ET',
          value: item.extra_time_ms && resultTimeET,
          param: 'extra_time_ms'
        }
      ]
    },
    {
      title: t('semifinalAndFinals'),
      subtitle: t('mainAndExtraTime'),
      time: [
        {
          label: 'FMT',
          value: item.final_main_time_ms && resultTimeFMT,
          param: 'final_main_time_ms'
        },
        {
          label: 'FET',
          value: item.final_extra_time_ms && resultTimeFET,
          param: 'final_extra_time_ms'
        }
      ]
    }
  ];
};
const TOGGLE_CATEGORY_STATUS = 'TOGGLE_CATEGORY_STATUS';

class TournamentAddCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      tournamentData: props.tournamentData || {},
      categoryTypes: props?.categoryTypes ?? [],
      filteredCategories: [],
      kata_names: [],
      selectedCheckboxes: [],
      errors: {},
      role: localStorage.getItem('role'),
      langOnLoad: localStorage.getItem('i18nextLng'),
      loggedUserId: localStorage.getItem('user_id'),
      open: false,
      modalData: {},
      searchBar: '',
      isSending: false,
      loading: true
    };

    this.fetchCategoriesPerTourn = fetchCategoriesPerTourn.bind(this);
    this.distributeParticipantByCategory =
      distributeParticipantByCategory.bind(this);
    this.fetchParticipantsByTournamentCategories =
      fetchParticipantsByTournamentCategories.bind(this);
    this.toggleTatamiCategoryV2 = toggleTatamiCategoryV2.bind(this);
    this.updateTournamentCategoryTime = updateTournamentCategoryTime.bind(this);
    this.fetchKataNames = fetchKataNames.bind(this);
    this.selectOption = selectOption.bind(this);
    this.updateTournKataNames = updateTournKataNames.bind(this);
    this.updateTournamentCategoryKataFinal =
      updateTournamentCategoryKataFinal.bind(this);
    this.exportTournamentCategories = exportTournamentCategories.bind(this);
    this.downloadFile = downloadFile.bind(this);
    this.textChangeHandler = textChangeHandler.bind(this);
    this.updateCategoryGroup = updateCategoryGroup.bind(this);
    this.updateCategory = updateCategory.bind(this);
    this.changeModal = changeModal.bind(this);
    this.fetchParticipantsByTournamentV2 =
      fetchParticipantsByTournamentV2.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isEditing, modalData, langOnLoad, statistics } = this.state;
    const { categoryTypes, t, onGetPageHeaderStatistics } = this.props;
    const currentLang = localStorage.getItem('i18nextLng');

    // if (
    //   isEditing &&
    //   modalData?.selectedKataNames.round_1 &&
    //   modalData?.selectedKataNames !== prevState.modalData?.selectedKataNames
    // ) {
    //   this.setState({ isAttemptingToEditModalFields: true });
    // }

    if (langOnLoad !== currentLang) {
      onGetPageHeaderStatistics(
        STATISTICS_DETAILS(t, statistics, categoryTypes)
      );

      this.setState({ langOnLoad: currentLang });
    }

    if (prevProps?.categoryTypes !== categoryTypes) {
      this.setState({ categoryTypes: categoryTypes });
    }
  }

  componentDidMount() {
    this.fetchKataNames();
    this.fetchMainDataTournCategories(true);
  }

  fetchMainDataTournCategories = (isFilteringUnneeded, successMsg) => {
    const { tournamentId, onGetPageHeaderStatistics, t } = this.props;

    this.fetchCategoriesPerTourn(
      tournamentId,
      () => {
        const { categories, categoryTypes } = this.state;

        const len = categories.length;
        let newCategories = [];
        let filteredCategories = [];

        const countForStatistics = this.initializeStatistics(categoryTypes);

        for (let i = 0; i < len; i++) {
          const category = categories[i];
          let presentCategory = {};

          if (category.weight_f) {
            const weightFLen = category.weight_f.length;

            for (let j = 0; j < weightFLen; j++) {
              const weight_f = category.weight_f[j];

              if (!!+weight_f.tournament_presence) {
                // Absolute Category
                const hasSameCategory = category.weight_t?.some(
                  (t) => +weight_f.category_id === +t.category_id
                );

                if (!hasSameCategory) {
                  presentCategory = this.updateNewCateoryTime(
                    weight_f,
                    presentCategory
                  );
                  countForStatistics.assignedCount += 1;
                  countForStatistics.types[
                    `assignedCount_${category.type}`
                  ] += 1;

                  if (!!+weight_f.participants_count) {
                    countForStatistics.filledCount += 1;
                    countForStatistics.types[
                      `filledCount_${category.type}`
                    ] += 1;
                  }

                  if (+weight_f.participants_count === 1) {
                    countForStatistics.issueCount += 1;
                  }
                }
              }
            }
          }

          if (category.weight_t) {
            const weightTLen = category.weight_t.length;

            for (let j = 0; j < weightTLen; j++) {
              const weight_t = category.weight_t[j];

              if (!!+weight_t.tournament_presence) {
                presentCategory = this.updateNewCateoryTime(
                  weight_t,
                  presentCategory
                );
                countForStatistics.assignedCount += 1;
                countForStatistics.types[`assignedCount_${category.type}`] += 1;

                if (!!+weight_t.participants_count) {
                  countForStatistics.filledCount += 1;
                  countForStatistics.types[`filledCount_${category.type}`] += 1;
                }

                if (+weight_t.participants_count === 1) {
                  countForStatistics.issueCount += 1;
                }
              }
            }
          }

          if (!!+category?.kata?.tournament_presence) {
            this.updateKataCounts(category.kata, category, countForStatistics);
          }

          if (+category?.type === 5) {
            presentCategory = {
              bronze_fight_enabled: category?.kata?.bronze_fight_enabled
            };
          }

          newCategories = [
            ...newCategories,
            Object.keys(presentCategory).length !== 0
              ? { ...category, ...presentCategory }
              : category
          ];
        }

        this.setState({ statistics: countForStatistics }, () => {
          const { statistics } = this.state;
          onGetPageHeaderStatistics(
            STATISTICS_DETAILS(t, statistics, categoryTypes)
          );
        });

        if (isFilteringUnneeded) {
          filteredCategories = [...newCategories];

          this.setState({
            loading: false,
            filteredCategories,
            categories: newCategories
          });
        } else {
          //using setState here when adding new KUMITE will show input to set time
          this.setState({ loading: false, categories: newCategories }, () =>
            this.onSelectCheckbox({})
          );
        }
      },
      successMsg
    );
  };

  initializeStatistics = (categoryTypes) => {
    return {
      assignedCount: 0,
      filledCount: 0,
      issueCount: 0,
      types: categoryTypes.reduce((acc, it) => {
        acc[`assignedCount_${it.id}`] = 0;
        acc[`filledCount_${it.id}`] = 0;
        return acc;
      }, {})
    };
  };

  updateNewCateoryTime = (category, presentCategory) => {
    //used for weighted categories
    return {
      bronze_fight_enabled: category.bronze_fight_enabled,
      ...(!presentCategory.main_time_ms ||
      category.main_time_ms === presentCategory.main_time_ms
        ? {
            main_time_ms: category.main_time_ms,
            main_time_ms_in_sec: category.main_time_ms / 1000
          }
        : {}),
      ...(!presentCategory.final_main_time_ms ||
      category.final_main_time_ms === presentCategory.final_main_time_ms
        ? {
            final_main_time_ms: category.final_main_time_ms,
            final_main_time_ms_in_sec: category.final_main_time_ms / 1000
          }
        : {}),
      ...(!presentCategory.extra_time_ms ||
      category.extra_time_ms === presentCategory.extra_time_ms
        ? {
            extra_time_ms: category.extra_time_ms,
            extra_time_ms_in_sec: category.extra_time_ms / 1000
          }
        : {}),
      ...(!presentCategory.final_extra_time_ms ||
      category.final_extra_time_ms === presentCategory.final_extra_time_ms
        ? {
            final_extra_time_ms: category.final_extra_time_ms,
            final_extra_time_ms_in_sec: category.final_extra_time_ms / 1000
          }
        : {})
    };
  };

  updateKataCounts = (kata, category, countForStatistics) => {
    countForStatistics.assignedCount += 1;
    countForStatistics.types[`assignedCount_${category.type}`] += 1;

    if (+kata.participants_count !== 0 && kata.participants_count !== '0/0') {
      countForStatistics.filledCount += 1;
      countForStatistics.types[`filledCount_${category.type}`] += 1;
    }

    if (+kata.participants_count === 1 || kata.participants_count === '1/1') {
      countForStatistics.issueCount += 1;
    }
  };

  //will perform both the filter and search action at the same time, when selecting a filter or when searching
  onTxtSearch = (value, categories) => {
    let newCategories = [];
    const len = categories && categories.length;

    for (let i = 0; i < len; i++) {
      const item = categories[i];
      const blockName = setCategoryName(item);

      if (
        [blockName, item.g_name].some((it) =>
          it.toLowerCase().includes(value.toLowerCase().trim())
        )
      ) {
        newCategories = [...newCategories, item];
      }
    }

    return newCategories;
  };

  onSearch = (evt) => {
    const { value } = evt.target;

    this.setState({ searchBar: value }, () => this.onSelectCheckbox({}));
  };

  onToggleSave = (currentGroup, elIdx, cb) => {
    let categoriesIds = [];
    let categoryGroup = { ...currentGroup };

    //take all our needed categories (from weight from, weight to and kata)
    if (!!categoryGroup?.kata) {
      const toggledStatus = !categoryGroup.kata.tournament_presence;
      categoryGroup.kata.tournament_presence = +toggledStatus;
      categoriesIds = [
        ...categoriesIds,
        {
          category_id: categoryGroup.kata.category_id,
          category_n: categoryGroup.kata.name,
          presence: categoryGroup.kata.tournament_presence
        }
      ];
    } else if (!!categoryGroup.weight_t) {
      //check if weight from and weight to are not null
      const toggledStatus = !categoryGroup.weight_t[elIdx].tournament_presence;
      categoryGroup.weight_t[elIdx].tournament_presence = +toggledStatus;
      categoriesIds = [
        ...categoriesIds,
        {
          category_id: categoryGroup.weight_t[elIdx].category_id,
          category_n: categoryGroup.weight_t[elIdx].name,
          presence: categoryGroup.weight_t[elIdx].tournament_presence
        }
      ];

      if (categoryGroup.weight_f) {
        let num = categoryGroup.weight_t?.length;
        let greatestMinusCategoryPresenceIdx;
        // weight_to length is equal t weight_f, since the second is mirror of the first

        while (num--) {
          if (
            !greatestMinusCategoryPresenceIdx &&
            !!+categoryGroup?.weight_t?.[num]?.tournament_presence
          ) {
            greatestMinusCategoryPresenceIdx = num;
            categoryGroup.weight_f[num].tournament_presence = 1;
            categoriesIds = [
              ...categoriesIds,
              {
                category_id: categoryGroup.weight_f[num].category_id,
                category_n: categoryGroup.weight_f[num].name,
                presence: categoryGroup.weight_f[num].tournament_presence
              }
            ];
          } else if (!!+categoryGroup?.weight_f?.[num]?.tournament_presence) {
            categoryGroup.weight_f[num].tournament_presence = 0;
            categoriesIds = [
              ...categoriesIds,
              {
                category_id: categoryGroup.weight_f[num].category_id,
                category_n: categoryGroup.weight_f[num].name,
                presence: categoryGroup.weight_f[num].tournament_presence
              }
            ];
          }
        }
      }
    }

    cb && cb(categoryGroup, categoriesIds);
  };

  addCategorieshandler = (e, currentGroup, el, elIdx) => {
    const { categories, filteredCategories } = this.state;
    const { tournamentId } = this.props;
    let cpyCategories = [...categories];
    let cpyFilteredCategories = [...filteredCategories];

    if (!+el.participants_count) {
      this.onToggleSave(currentGroup, elIdx, (categoryGroup, categoriesIds) => {
        this.toggleTatamiCategoryV2(
          {
            tournament_id: tournamentId,
            category_presence_list: categoriesIds
          },
          () => {
            let findCategoryIdx, findFilteredCategoryIdx;

            if (!!categoryGroup?.kata) {
              // KATA
              findCategoryIdx = categories?.findIndex((it) => {
                return +it?.kata?.category_id === +el?.category_id;
              });
              findFilteredCategoryIdx = cpyFilteredCategories?.findIndex(
                (it) => {
                  return +it?.kata?.category_id === +el?.category_id;
                }
              );

              if (findCategoryIdx !== -1) {
                cpyCategories[findCategoryIdx] = categoryGroup;
              }

              if (findFilteredCategoryIdx !== -1) {
                cpyFilteredCategories[findFilteredCategoryIdx] = categoryGroup;
              }
            } else if (!!categoryGroup?.weight_t) {
              // KUMITE
              findCategoryIdx = categories?.findIndex((it) => {
                return +it?.weight_t?.[elIdx]?.category_id === +el?.category_id;
              });
              findFilteredCategoryIdx = cpyFilteredCategories?.findIndex(
                (it) => {
                  return (
                    +it?.weight_t?.[elIdx]?.category_id === +el?.category_id
                  );
                }
              );

              if (findCategoryIdx !== -1) {
                cpyCategories[findCategoryIdx] = categoryGroup;
              }

              if (findFilteredCategoryIdx !== -1) {
                cpyFilteredCategories[findFilteredCategoryIdx] = categoryGroup;
              }

              this.setState(
                {
                  categories: cpyCategories,
                  filteredCategories: cpyFilteredCategories
                },
                () => {
                  const { categories } = this.state;
                  const { onGetPageHeaderStatistics, categoryTypes, t } =
                    this.props;

                  const len = categories.length;
                  const countForStatistics =
                    this.initializeStatistics(categoryTypes);

                  for (let i = 0; i < len; i++) {
                    const category = categories[i];

                    if (category.weight_f) {
                      const weightFLen = category.weight_f.length;

                      for (let j = 0; j < weightFLen; j++) {
                        const weight_f = category.weight_f[j];

                        if (!!+weight_f.tournament_presence) {
                          // Absolute Category
                          const hasSameCategory = category.weight_t?.some(
                            (t) => +weight_f.category_id === +t.category_id
                          );

                          if (!hasSameCategory) {
                            countForStatistics.assignedCount += 1;
                            countForStatistics.types[
                              `assignedCount_${category.type}`
                            ] += 1;

                            if (!!+weight_f.participants_count) {
                              countForStatistics.filledCount += 1;
                              countForStatistics.types[
                                `filledCount_${category.type}`
                              ] += 1;
                            }

                            if (+weight_f.participants_count === 1) {
                              countForStatistics.issueCount += 1;
                            }
                          }
                        }
                      }
                    }

                    if (category.weight_t) {
                      const weightTLen = category.weight_t.length;

                      for (let j = 0; j < weightTLen; j++) {
                        const weight_t = category.weight_t[j];

                        if (!!+weight_t.tournament_presence) {
                          countForStatistics.assignedCount += 1;
                          countForStatistics.types[
                            `assignedCount_${category.type}`
                          ] += 1;

                          if (!!+weight_t.participants_count) {
                            countForStatistics.filledCount += 1;
                            countForStatistics.types[
                              `filledCount_${category.type}`
                            ] += 1;
                          }

                          if (+weight_t.participants_count === 1) {
                            countForStatistics.issueCount += 1;
                          }
                        }
                      }
                    }

                    if (!!+category?.kata?.tournament_presence) {
                      this.updateKataCounts(
                        category.kata,
                        category,
                        countForStatistics
                      );
                    }
                  }

                  this.setState({ statistics: countForStatistics }, () => {
                    const { statistics } = this.state;

                    onGetPageHeaderStatistics(
                      STATISTICS_DETAILS(t, statistics, categoryTypes)
                    );
                  });
                }
              );
            }
          }
        );
      });
    } else {
      this.setState(
        {
          open: true,
          pressedIcon: TOGGLE_CATEGORY_STATUS,
          modalData: { form_title_name: el.category_name }
        },
        () => {
          this.fetchParticipantsByTournamentCategories(
            tournamentId,
            { empty_categories: 1 },
            (distributeCategories) => {
              const findCurrentCategory = distributeCategories?.find(
                (it) => +it.id === +el.category_id
              );
              this.setState((prev) => ({
                modalData: {
                  ...prev.modalData,
                  allCategoriesForDistribution: distributeCategories,
                  categoryGroup: currentGroup,
                  currentCategory: {
                    ...el,
                    elIdx,
                    participants: findCurrentCategory?.participants
                  }
                }
              }));
            }
          );
        }
      );
    }
  };

  onSelectCheckbox = (key) => {
    const { selectedCheckboxes } = this.state;
    const { tournamentData } = this.props;
    const checkedValues = selectedCheckboxes.some((item) => item.id === key.id)
      ? selectedCheckboxes.filter((it) => it.id !== key.id)
      : [...selectedCheckboxes, key];

    this.setState({ selectedCheckboxes: checkedValues }, () => {
      let filteredCategories = [];

      if (checkedValues.length > 0) {
        let genderArray = [];
        let ageArray = [];
        let typeArray = [];
        let ownerList = [];
        let assignList = [];

        checkedValues.map((it) => {
          if (it.gender) genderArray = [...genderArray, it.gender];
          if (it.age) ageArray = [...ageArray, it.age];
          if (it.type) typeArray = [...typeArray, it.id];
          if (it.owner) ownerList = [...ownerList, it.owner];
          if (it.tournament_presence)
            assignList = [...assignList, it.tournament_presence];
          return true;
        });

        const categoriesLen =
          this.state.categories && this.state.categories.length;

        for (let i = 0; i < categoriesLen; i++) {
          const category = this.state.categories[i];

          const filterByAssignList =
            assignList.length > 0
              ? assignList.some((el) => {
                  if (category.kata) {
                    return +category.kata.tournament_presence === +el;
                  } else if (category.weight_t) {
                    if (+el === 0) {
                      return category.weight_t.every(
                        (kumite_cat) => +kumite_cat.tournament_presence === +el
                      );
                    } else if (+el === 1) {
                      return category.weight_t.some(
                        (kumite_cat) => +kumite_cat.tournament_presence === +el
                      );
                    }
                  } else {
                    return false;
                  }
                })
              : category;

          const filterByGenderList =
            genderArray.length > 0
              ? genderArray.some((el) => category.gender === el)
              : category;

          const filterByAgeList =
            ageArray.length > 0
              ? ageArray.some((el) =>
                  category.age_to
                    ? +category.age_from >= el[0] && +category.age_to <= el[1]
                    : +category.age_from >= el[0] && +category.age_from <= el[1]
                )
              : category;

          const filterByTypeList =
            typeArray.length > 0
              ? typeArray.some((el) => +category?.type === +el)
              : category;

          const filterByOwnerList =
            ownerList.length > 0
              ? ownerList.some(
                  (el) =>
                    // master_id === 1 (is Admin)
                    (+el === 0 &&
                      (+category.master_id === 1 || !category.master_id)) ||
                    (+el === 1 &&
                      +category.master_id === +tournamentData?.organizer_id &&
                      +category.master_id !== 1)
                )
              : category;

          if (
            filterByGenderList &&
            filterByAgeList &&
            filterByTypeList &&
            filterByOwnerList &&
            filterByAssignList
          ) {
            filteredCategories = [...filteredCategories, category];
          }
        }
      } else filteredCategories = [...this.state.categories];

      filteredCategories = this.onTxtSearch(
        this.state.searchBar,
        filteredCategories
      );

      this.setState({
        filteredCategories,
        errors:
          {} /*discard errors when filtering or searching because we show the default value of the time input*/
      });
    });
  };

  cancelFilter = () => {
    this.setState({
      filteredCategories: [...this.state.categories],
      selectedCheckboxes: []
    });
  };

  onTimeEdit = (time, val, param) => {
    const { errors, modalData } = this.state;
    const { t } = this.props;
    let copyErrors = { ...errors };
    let copyModalData = { ...modalData };
    let newTime;
    let timeInSeconds = 0;

    if (time === null) {
      copyErrors[param] = t('required');
    } else if (isNaN(time)) {
      copyErrors[param] = t('invalidTime');
    } else {
      delete copyErrors[param];

      //use Hrs as min, min as sec
      newTime = (time.getHours() * 60 + time.getMinutes()) * 1000;
      timeInSeconds = time.getHours() * 60 + time.getMinutes();
    }

    copyModalData = {
      ...copyModalData,
      [param]: newTime,
      [`${param}_in_sec`]: timeInSeconds
    };

    this.setState({
      errors: copyErrors,
      modalData: copyModalData
    });
  };

  txtChange = (evt) => {
    const { modalData } = this.state;
    const { name, value } = evt.target;
    let copyModalData = { ...modalData };

    copyModalData.kata = {
      ...copyModalData.kata,
      [name]: value
    };

    this.setState({
      modalData: copyModalData
    });
  };

  setBronzeRound = (evt) => {
    const { modalData } = this.state;
    let copyModalData = { ...modalData };
    const { checked } = evt.target;

    copyModalData = { ...copyModalData, bronze_fight_enabled: checked };

    this.setState({ modalData: copyModalData });
  };

  showModal = (evt, item, currentPressedIcon) => {
    evt.preventDefault();

    if (currentPressedIcon !== TEST_ID_INFO_BTN) {
      const { categoryTypes } = this.state;
      const currentCategoryType = categoryTypes.find(
        (it) => +it?.id === +item?.type
      );
      let selectedKataNames = {};

      if (item?.kata) {
        selectedKataNames = {
          category_type: item.type,
          category_default_name: `${setCategoryName(item)} (${
            item.kata.category_name || ''
          })`,
          category_id: item.kata.category_id,
          round_1:
            currentPressedIcon === DELETE_ICON || !item.kata.kara_round_1
              ? []
              : item.kata.kara_round_1,
          round_2:
            currentPressedIcon === DELETE_ICON || !item.kata.kara_round_2
              ? []
              : item.kata.kara_round_2,
          ...(+item.type === 5 // is KATA BY FLAGS
            ? {
                round_3:
                  (currentPressedIcon !== DELETE_ICON &&
                    item.kata.kata_round_3) ??
                  []
              }
            : {})
        };
      }

      this.setState((prevState) => ({
        pressedIcon: currentPressedIcon,
        open: true,
        modalData: {
          ...prevState.modalData,
          ...item,
          group_category_name: item?.g_name,
          group_category_alt_name: item?.g_alt_name,
          group_category_age_from: item?.age_from,
          group_category_age_to: item?.age_to,
          group_category_gender: item?.gender,
          group_category_type: +item?.type,
          ...(!+item.group_id
            ? {
                category_id: currentCategoryType.weighted
                  ? item?.weight_f?.[0]?.category_id
                  : item?.kata?.category_id
              }
            : {}),
          category_type_name: currentCategoryType?.name,
          form_title_name: item?.g_name,
          selectedKataNames
        }
      }));
    } else {
      this.onFilterParticipantsBySelectedCategory(item);
    }
  };

  hideModal = () => {
    if (this.state.isAttemptingToEditModalFields) {
      this.setState({ shouldShowDiscardChanges: true });
    } else {
      this.setState({
        open: false,
        shouldShowDiscardChanges: false,
        modalData: {},
        errors: {},
        isEditing: false
      });
    }
  };

  onFilterParticipantsBySelectedCategory = (item) => {
    const { tournamentId } = this.props;

    this.fetchParticipantsByTournamentV2(
      null,
      null,
      null,
      null,
      tournamentId,
      null,
      (data) => {
        this.setState((prevState) => ({
          modalData: {
            ...prevState.modalData,
            ...item,
            participants: data.data
          },
          open: true,
          pressedIcon: TEST_ID_INFO_BTN
        }));
      },
      item.category_id,
      1,
      13
    );
  };

  onSelectKataNames = (kata, round) => {
    let copyModalData = { ...this.state.modalData };

    copyModalData.selectedKataNames[round] = copyModalData?.selectedKataNames[
      round
    ].some((it) => +it === +kata)
      ? copyModalData?.selectedKataNames[round].filter((it) => +it !== +kata)
      : [...copyModalData?.selectedKataNames[round], +kata];

    this.setState({ isEditing: true, modalData: copyModalData });
  };

  discardOrKeepEditing = (evt, targetID) => {
    if (targetID === KEEP_EDITING) {
      this.setState({ shouldShowDiscardChanges: false });
    } else if (targetID === CLOSE_DISCARD) {
      this.setState({ isAttemptingToEditModalFields: false }, () =>
        this.hideModal()
      );
    }
  };

  onClearSearch = () => {
    this.setState({ searchBar: '' }, () => this.onSelectCheckbox({}));
  };

  hideSnackbar = () => {
    this.setState({ showModal: false });
  };

  onSaveModalForm = (evt) => {
    const { modalData, loggedUserId } = this.state;
    const { tournamentId, tournamentData } = this.props;
    let categoriesList = [];
    let promises = [];

    if (modalData?.weight_t && modalData?.weight_f) {
      const weightFLen = modalData.weight_f?.length;
      const weightTLen = modalData.weight_t?.length;

      for (let i = 0; i < weightFLen; i++) {
        if (modalData.weight_f[i].tournament_presence) {
          categoriesList = [
            ...categoriesList,
            modalData.weight_f[i].category_id
          ];
        }
      }

      for (let i = 0; i < weightTLen; i++) {
        if (modalData.weight_t[i].tournament_presence) {
          categoriesList = [
            ...categoriesList,
            modalData.weight_t[i].category_id
          ];
        }
      }
    } else if (
      +modalData?.type === 5 &&
      !!+modalData?.kata?.tournament_presence
    ) {
      categoriesList = [modalData?.kata?.category_id];
    }

    if (
      (+modalData?.type === 5 && !!+modalData?.kata?.tournament_presence) ||
      (modalData?.weight_t && modalData?.weight_f)
    ) {
      promises.push(
        new Promise((resolve) =>
          this.updateTournamentCategoryTime(
            evt,
            tournamentId,
            categoriesList,
            modalData?.weight_t &&
              modalData?.weight_f && {
                main_time_ms: modalData.main_time_ms_in_sec,
                extra_time_ms: modalData.extra_time_ms_in_sec,
                final_main_time_ms: modalData.final_main_time_ms_in_sec,
                final_extra_time_ms: modalData.final_extra_time_ms_in_sec
              },
            true,
            modalData?.bronze_fight_enabled,
            () => resolve(true)
          )
        )
      );
    }
    if (modalData?.kata) {
      if (
        !!+modalData?.kata?.tournament_presence &&
        +modalData.type !== 4 &&
        +modalData.type !== 5
      ) {
        promises.push(
          new Promise((resolve) =>
            this.updateTournamentCategoryKataFinal(
              evt,
              tournamentId,
              modalData?.kata,
              () => resolve(true)
            )
          )
        );
      }

      if (!!+modalData?.kata?.tournament_presence && +modalData.type !== 4) {
        promises.push(
          new Promise((resolve) =>
            this.updateTournKataNames(
              evt,
              tournamentId,
              modalData.selectedKataNames,
              () => resolve(true)
            )
          )
        );
      }
    }

    if (
      +modalData?.master_id === +loggedUserId &&
      !!+tournamentData.use_alt_category_name
    ) {
      if (!!+modalData?.group_id) {
        promises.push(
          new Promise((resolve) =>
            this.updateCategoryGroup(evt, modalData, () => resolve(true))
          )
        );
      } else {
        promises.push(
          new Promise((resolve) =>
            this.updateCategory(evt, modalData, () => resolve(true))
          )
        );
      }
    }

    Promise.all(promises)
      .then(() => {
        this.triggerUpdate();
      })
      .catch((err) => console.error(err));
  };

  triggerUpdate = () => {
    this.fetchMainDataTournCategories();
    this.hideModal();
  };

  onSaveDistribution = () => {
    const { tournamentId } = this.props;
    const { modalData } = this.state;

    const participantsIds = modalData?.currentCategory?.participants?.map(
      (el) => el.id
    );

    this.distributeParticipantByCategory(
      tournamentId,
      participantsIds,
      modalData.transfer2Category,
      null,
      modalData?.currentCategory?.category_id,
      (result) => {
        this.onToggleSave(
          modalData?.categoryGroup,
          modalData?.currentCategory?.elIdx,
          (categoryGroup, categoriesIds) => {
            this.toggleTatamiCategoryV2(
              {
                tournament_id: tournamentId,
                category_presence_list: categoriesIds
              },
              () => {
                this.fetchMainDataTournCategories(null, true);
                this.hideModal();
              }
            );
          }
        );
      },
      true
    );
  };

  render() {
    const {
      filteredCategories,
      categoryTypes,
      selectedCheckboxes,
      errors,
      kata_names,
      open,
      shouldShowDiscardChanges,
      pressedIcon,
      searchBar,
      loading,
      isSending,
      success,
      showModal,
      loggedUserId,
      modalData,
      modalInfo
    } = this.state;

    const {
      t,
      classes,
      shouldDisableEditing,
      tournamentId,
      viewportWidth,
      tournamentData,
      navigate,
      location
    } = this.props;
    const hasDesktopSize = isDesktopView(viewportWidth);

    let onClick,
      dialogTitle,
      shouldShowTopBottomDivider,
      buttonPurpose,
      classNameBtn,
      dialogContent,
      subHeader;

    if (pressedIcon === MODAL_EDIT_IC) {
      dialogTitle = t('generalCategorySettings');
      dialogContent = (
        <div style={{ display: 'grid', gridRowGap: '1rem' }}>
          {+modalData?.master_id === +loggedUserId &&
            !!+tournamentData.use_alt_category_name && (
              <>
                <div>
                  <h5>
                    {[t('alternative'), `${t('nameCategoriesForm')}`].join(' ')}
                  </h5>
                  <Divider />
                </div>
                <AddCategory
                  classNamePaper={classes.classNamePaper}
                  textChange={this.textChangeHandler}
                  formParamName={'modalData'}
                  value={modalData}
                  {...{ categoryTypes }}
                  error={{}}
                  shouldShowForm={
                    !!+modalData?.group_id ? UPDATE_GROUP : UPDATE_CATEGORY
                  }
                  disabledFields={{
                    group_category_age_from: true,
                    group_category_age_to: true,
                    group_category_name: true,
                    gender: true,
                    type: true
                  }}
                  isUpdateForm={true}
                  shouldHideSaveFormBtn={() => true}
                />
              </>
            )}
          {modalData?.weight_t &&
            modalData?.weight_f &&
            EXTRA_TIME(t, modalData).map((timeElem, timeElemIdx) => (
              <Fragment key={timeElemIdx}>
                <div>
                  <span className={clsx(classes.flex)}>
                    <h5 style={{ paddingRight: '0.5rem' }}> {t('time')}:</h5>
                    {timeElem.title}
                  </span>
                  ({timeElem.subtitle})
                  <Divider />
                </div>
                <div>
                  {timeElem.time.map(({ label, value, param }, timeIdx) => (
                    <MuiPickersUtilsProvider key={timeIdx} utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        KeyboardButtonProps={{
                          disabled: true,
                          style: { display: 'none' }
                        }}
                        ampm={false}
                        onChange={(date, time) =>
                          this.onTimeEdit(date, time, param)
                        }
                        value={
                          (value &&
                            new Date(
                              null,
                              null,
                              null,
                              value.split(':')[0],
                              value.split(':')[1]
                            )) ||
                          ''
                        }
                        views="seconds"
                        classes={{ root: classes.timeInputWrapper }}
                        InputProps={{
                          disableUnderline: true,
                          className: classes.time,
                          startAdornment: (
                            <span className={classes.marginRight0dot5}>
                              {label}:
                            </span>
                          )
                        }}
                        error={Boolean(errors[param])}
                        helperText={errors[param]}
                      />
                    </MuiPickersUtilsProvider>
                  ))}
                </div>
              </Fragment>
            ))}
          {((+modalData?.type === 5 &&
            !!+modalData?.kata?.tournament_presence) ||
            (modalData?.weight_t && modalData?.weight_f)) && (
            <>
              <CheckboxBtn //set bronze round (Kumite)
                classControlLabel={classes.marginRight0}
                checked={!!+modalData.bronze_fight_enabled}
                onChange={this.setBronzeRound}
                label={
                  <span
                    className={clsx(classes.flex, classes.centerVertically)}>
                    {t('enableBronzeFight')}
                    <Bronze className={classes.marginLeft08} />
                  </span>
                }
              />
            </>
          )}
          {!!+modalData?.kata?.tournament_presence &&
            +modalData.type !== 4 &&
            +modalData.type !== 5 && (
              <>
                <div>
                  <h5>{[t('round'), 'II'].join(' ')}</h5>
                  <Divider />
                </div>
                <TextField
                  value={modalData.kata.kata_final || ''}
                  name="kata_final"
                  className={clsx(
                    classes.txtInput,
                    shouldDisableEditing && classes.noEvent
                  )}
                  variant="outlined"
                  onChange={this.txtChange}
                />
              </>
            )}
          {!!+modalData?.kata?.tournament_presence && +modalData.type !== 4 && (
            <>
              <div>
                <h5>{t('indicateKataNames')}</h5>
                <Divider />
              </div>
              <span>
                {t('roundKataFlags', { count: 'I' })}{' '}
                {t('roundKataFlags', { count: 'II' })}{' '}
                {+modalData?.selectedKataNames?.category_type === 5
                  ? t('roundKataFlags', { count: 'III' })
                  : ''}
              </span>
              {pressedIcon !== DELETE_ICON &&
                kata_names.map((kata_name) => (
                  <span
                    className={clsx(classes.flex, classes.centerVertically)}
                    key={kata_name.id}>
                    {[
                      'round_1',
                      'round_2',
                      ...(+modalData?.selectedKataNames?.category_type === 5
                        ? ['round_3']
                        : [])
                    ].map((round) => (
                      <CheckboxBtn
                        key={`${round}_${kata_name.id}`}
                        checked={modalData?.selectedKataNames?.[round]?.some(
                          (el) => +el === +kata_name.id
                        )}
                        onChange={() =>
                          this.onSelectKataNames(kata_name.id, round)
                        }
                      />
                    ))}
                    {kata_name.name}
                  </span>
                ))}
            </>
          )}
        </div>
      );
      buttonPurpose = t('save');
      classNameBtn = Object.keys(errors)?.length > 0 && [
        classes.noEvent,
        classes.disabled
      ];
      shouldShowTopBottomDivider = true;
      onClick = (evt) => this.onSaveModalForm(evt);
    } else if (pressedIcon === TOGGLE_CATEGORY_STATUS) {
      onClick = this.onSaveDistribution;
      dialogTitle = t('distributeParticipantsByCategories');
      buttonPurpose = t('transfer');
      dialogContent = (
        <span>
          {t('reassignParticipantBeforeCategoryUnassingment')}
          <Filter
            className={clsx(classes.label, classes.marginTop1)}
            classes={{
              inputRoot: classes.outlined
            }}
            value={
              selectedValue(
                modalData?.allCategoriesForDistribution,
                'id',
                modalData.transfer2Category,
                true
              ) || ''
            }
            options={modalData?.allCategoriesForDistribution ?? []}
            label={t('categories')}
            onChange={(evt, val) => {
              this.selectOption(
                evt,
                val,
                'modalData',
                'transfer2Category',
                'id'
              );
            }}
            item={'name'}
            variant="outlined"
          />
        </span>
      );
    } else if (pressedIcon === TEST_ID_INFO_BTN) {
      onClick = () =>
        navigate(
          `/event/${tournamentId}/participants?tab=2&category=${modalData.category_id}`,
          {
            state: { prevUrl: location.pathname }
          }
        );
      dialogContent = modalData?.participants?.map((it, idx) => (
        <span
          className={clsx(classes.flex, classes.marginBottom05)}
          key={it.participant_id}>
          {idx + 1}. {it.participant_name}
          {'   '}(
          {[
            it.gender,
            it.age,
            it.weight && `${it.weight}kg`,
            it.qdan_name
          ].join(', ')}
          )
        </span>
      ));
      dialogTitle = t('categoryOpponents');
      buttonPurpose = t('proceed');
      subHeader = modalData && (
        <span style={{ display: 'grid', gridRowGap: '0.5rem' }}>
          <span>
            {[
              t('block', {
                count:
                  modalData.participants?.[0]?.categories_info?.[0]
                    ?.category_tatami_block_id
              }),
              modalData.participants?.[0]?.categories_info?.[0]
                ?.category_tatami_block
                ? `: ${modalData.participants?.[0]?.categories_info?.[0]?.category_tatami_block}`
                : ''
            ].join('')}
          </span>
          <span>
            {[
              t('tatami'),
              modalData.participants?.[0]?.categories_info?.[0]
                ?.category_tatami_name
            ].join(' ')}
          </span>
          <span>{modalData.category_name}</span>
        </span>
      );
    } else {
      onClick = (evt) =>
        this.updateTournKataNames(
          evt,
          tournamentId,
          modalData?.selectedKataNames,
          this.triggerUpdate
        );
      dialogTitle = t('deleteRecord', { name: t('kataDiscipline') });
      buttonPurpose = t('remove');
      dialogContent = t('deleteMsg');
    }

    return (
      <>
        <SideModal
          closeModal={this.hideSnackbar}
          {...{ success }}
          show={showModal}
          info={modalInfo}
        />
        <Modal
          {...{ open }}
          close={this.hideModal}
          {...{
            onClick,
            dialogTitle,
            shouldShowTopBottomDivider,
            buttonPurpose,
            dialogContent,
            classNameBtn
          }}
          subHeader={
            subHeader ? (
              subHeader
            ) : (
              <>
                {setCategoryName(modalData)}
                {modalData?.form_title_name && (
                  <>
                    {'  '}({modalData?.form_title_name})
                  </>
                )}
              </>
            )
          }
          {...{ shouldShowDiscardChanges }}
          discardOrKeepEditing={this.discardOrKeepEditing}
          specificDetails={{
            className: {
              ...(hasDesktopSize ? { paper: classes.modalWidth } : {})
            }
          }}
        />
        {loading ? (
          <LoadingState />
        ) : (
          <CategoriesBlock
            {...{ tournamentData }}
            {...{ categoryTypes }}
            showModal={this.showModal}
            {...{ selectedCheckboxes }}
            onSelectCheckbox={this.onSelectCheckbox}
            categories={filteredCategories}
            {...{ tournamentId }}
            exportTournamentCategories={this.exportTournamentCategories}
            toggleCategoryStatus={this.addCategorieshandler}
            {...{ isSending }}
            {...{ shouldDisableEditing }}
            {...{ viewportWidth }}
            {...{ kata_names }}
            onSearch={this.onSearch}
            onClearSearch={this.onClearSearch}
            {...{ searchBar }}
            cancelFilter={this.cancelFilter}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(
  withStyles(styles)(withRouter(TournamentAddCategories))
);
