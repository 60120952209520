export default {
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  loader: {
    marginTop: 30
  }
};
