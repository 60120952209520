import clsx from 'clsx';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useStyles } from './styles';

const CheckboxBtn = (props) => {
  const {
    onChange,
    label,
    checked,
    indeterminate,
    className,
    styles,
    classControlLabel,
    formControlClassName,
    labelPlacement,
    disabled,
    classForLabel,
    style
  } = props;

  const classes = useStyles();

  const Icon = ({ className }) => {
    return (
      <span className={clsx(classes.icon, className, styles?.icon)}></span>
    );
  };

  return (
    <FormControlLabel
      {...{ style }}
      className={clsx(classes.checkboxIcon, formControlClassName)}
      classes={{
        root: classControlLabel,
        label: classForLabel
      }}
      {...{ onChange }}
      control={
        <Checkbox
          classes={{ root: className }}
          {...{ checked }}
          {...{ indeterminate }}
          indeterminateIcon={
            <Icon className={clsx(classes.indeterminateIcon)} />
          }
          color="primary"
          {...{ disabled }}
          checkedIcon={
            <Icon className={clsx(classes.checkedIcon, styles?.checked)} />
          }
          icon={<Icon />}
        />
      }
      {...{ label }}
      {...{ labelPlacement }}
    />
  );
};
export default CheckboxBtn;
