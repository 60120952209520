import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  ID,
  NAME,
  QDAN_EXAM,
  INTERMIDIATE_SCHOOL_INFO,
  NEXT_QDAN,
  TRAINING
} from '../../helpers/constants';
import { selectedValue } from '../../helpers/selectors';
import CheckboxBtn from '../CheckboxBtn/CheckboxBtn';
import Filter from '../Filter/Filter';
import { useStyles } from './styles';

const Assign2School = (props) => {
  const { t } = useTranslation();
  const {
    intermidiateSchoolInfo,
    qdanList,
    selectOption,
    selectCheckboxSchool
  } = props;
  const classes = useStyles();

  return (
    <>
      <CheckboxBtn
        checked={intermidiateSchoolInfo.training}
        onChange={(evt) =>
          selectCheckboxSchool(
            evt,
            intermidiateSchoolInfo,
            INTERMIDIATE_SCHOOL_INFO,
            TRAINING,
            true
          )
        }
        label={t(TRAINING)}
      />
      <span
        className={clsx(
          classes.flex,
          classes.center,
          classes.maxHeightTxtInput
        )}>
        <CheckboxBtn
          classControlLabel={classes.labelCheckbox}
          checked={intermidiateSchoolInfo.nextQdan}
          onChange={(evt) =>
            selectCheckboxSchool(
              evt,
              intermidiateSchoolInfo,
              INTERMIDIATE_SCHOOL_INFO,
              NEXT_QDAN,
              true
            )
          }
          disabled={!intermidiateSchoolInfo.training}
        />
        <Filter
          className={clsx(classes.label, classes.fullWidth)}
          classes={{
            inputRoot: classes.outlined
          }}
          options={qdanList}
          label={t('nextKyuDan')}
          value={
            selectedValue(qdanList, ID, intermidiateSchoolInfo.next_qdan_id) ||
            ''
          }
          onChange={(evt, val) =>
            selectOption(evt, val, INTERMIDIATE_SCHOOL_INFO, QDAN_EXAM, ID)
          }
          item={NAME}
          variant="outlined"
          disabled={
            !intermidiateSchoolInfo.training || !intermidiateSchoolInfo.nextQdan
          }
        />
      </span>
    </>
  );
};

export default Assign2School;
