import { makeStyles } from '@material-ui/styles';

export const useChatbotStyles = makeStyles((theme) => ({
  chatbotButton: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    width: '70px',
    height: '70px',
    padding: '0',
    backgroundColor: 'transparent',
    transition: 'transform 0.2s',
    border: 'none',
    cursor: 'pointer',
    '&:active': {
      transform: 'scale(0.9)'
    },
    '& svg': {
      width: '100%',
      height: '100%'
    },
    '@media (max-width: 767px)': {
      bottom: '10px',
      right: '10px'
    }
  },
  chatbotModal: {
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    borderRadius: '8px',
    width: '420px',
    height: '520px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:focus': {
      outline: 'none'
    },

    '@media (max-width: 767px)': {
      width: '100%',
      height: '100%',
      bottom: '0',
      right: '0',
      borderRadius: '0'
    }
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  description: {
    padding: theme.spacing(2),
    backgroundColor: '#e9ebf7',
    borderRadius: '5px',
    marginBottom: theme.spacing(2)
  },
  messageList: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',

    maxHeight: '381px',

    padding: 0,
    margin: 0,
    listStyle: 'none',
    flexDirection: 'column',

    '@media (max-width: 1024px)': {
      maxHeight: '45vh',
      width: '100%'
    },
    '@media (max-width: 767px)': {
      maxHeight: '75vh',
      width: '100%'
    },
    '@media (max-width: 480px)': {
      maxHeight: '80vh'
    }
  },

  userMessage: {
    marginRight: '8px',
    padding: '10px 10px'
  },
  messageBox: {
    maxWidth: '80%',
    backgroundColor: '#e9ebf7',
    padding: '8px 16px',
    borderRadius: '12px',
    wordBreak: 'break-word',
    fontSize: '0.75rem',
    flexDirection: 'column'
  },

  consultantResponse: {
    padding: '10px 10px'
  },

  inputContainer: {
    display: 'flex',
    height: '60px',
    alignItems: 'center',
    marginTop: '2px',
    borderRadius: '5px',
    backgroundColor: '#f5f5f5'
  },
  textField: {
    flex: 1,
    height: '60px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      overflow: 'hidden'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent'
    },
    '& .MuiOutlinedInput-input': {
      maxHeight: '250px',
      overflowY: 'auto',
      paddingRight: '10px',
      marginRight: '-15px'
    }
  },
  sendButton: {
    minWidth: '50px',
    minHeight: '50px',
    backgroundColor: '#3f51b5',
    color: '#fff',
    borderRadius: '4px',
    padding: '0',
    '& svg': {
      width: '35px',
      height: '35px'
    },
    '&:hover': {
      backgroundColor: '#303f9f'
    }
  },
  modalBackdrop: {
    backdropFilter: 'none',
    backgroundColor: 'transparent'
  },
  timestamp: {
    fontSize: '0.75rem',
    color: '#888',
    marginTop: '4px',
    alignSelf: 'flex-end'
  },
  lineup: {
    background: 'linear-gradient(30deg, #1f3f96 0%, #2c64ed 100%)',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    padding: '1em',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  lineupContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    paddingLeft: '10px'
  },
  lineuptext: {
    color: '#fff'
  },
  historyTime: {
    color: '#fff',
    fontSize: '13px'
  },
  exitBtn: {
    color: '#fff !important',
    marginLeft: '10px'
  },
  backdrop: {
    backgroundColor: 'transparent !important'
  },

  replyInfo: {
    display: 'flex',
    marginTop: '-20px',
    maxHeight: '20px'
  },
  replyText: {
    marginLeft: '20px',
    zIndex: 1
  },

  closeReply: {
    margin: '55px'
  },

  replyIndicator: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '5px'
  },

  arrowIcon: {
    width: '15px',
    height: '15px',
    fill: '#888',
    position: 'relative'
  },
  replyConteiner: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    border: '1px solid #A6A9C9',
    padding: '2px 4px',
    borderRadius: '8px'
  },
  loadMoreButton: {
    position: ' relative',
    width: '90%',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
    marginLeft: '5%',
    backgroundColor: '#f2f4f8',
    border: 'none',
    borderRadius: '10px',
    color: '#000',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#d9e0ea'
    },
    '&:active': {
      backgroundColor: '#c3cad4'
    }
  }
}));
