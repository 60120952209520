import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },

  upperWrapper: {
    marginTop: '1rem',
    display: 'grid',
    gridColumnGap: '1rem',
    '@media (min-width:768px)': {
      gridTemplateColumns: '2fr 1fr'
    },
    '@media (max-width:767px)': {
      gridTemplateColumns: '2fr 11em'
    }
  },

  wrap: {
    flexWrap: 'wrap'
  },

  justifyContent: {
    justifyContent: 'space-between'
  },

  subText: {
    marginTop: '0.5rem'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  subSvg: {
    width: '16px',
    height: '16px'
  },

  subTextDigit: {
    padding: '0 0.5rem 0 0.2rem',
    alignSelf: 'center'
  },

  wordBreak: {
    wordBreak: 'break-word'
  },

  extraTime: {
    padding: '0 0.2rem'
  },

  leftAuto: {
    marginLeft: 'auto'
  },

  column: {
    flexDirection: 'column'
  },

  tatamiSubWrapper: {
    flexDirection: 'column',
    padding: ' 0 0.5rem 0 0',
    textTransform: 'lowercase'
  },

  marginBottom0And5: {
    marginBottom: '0.5rem'
  },

  fullWidth: {
    width: '100%'
  },

  droppableBrick: {
    height: '5em',
    justifyContent: 'center',
    textAlign: 'center',
    alignContent: 'center',
    marginBottom: '0.5rem',
    borderRadius: '0.3rem'
  },

  droppableBrickBlock: {
    minHeight: '5em'
  },

  selectedBlock: {
    background: '#fff',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: '7.1em',
    border: '1px solid #8996AC',
    borderLeft: '1px solid #fff',
    marginLeft: '-1px',
    marginBottom: '0',
    padding: '1rem'
  },

  block: {
    background: '#fff',
    color: '#8996AC',
    margin: 'center',
    '@media (min-width:768px)': {
      height: 'inherit',
      width: 'inherit',
      borderRadius: 'inherit',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0.5rem 0 0 1rem'
    },
    '@media (max-width:767px)': {
      width: '10.5em',
      alignSelf: 'end'
    }
  },

  tab: {
    '@media (min-width:768px)': {
      width: '10.125em'
    },
    '@media (max-width:767px)': {
      width: '8.3em'
    }
  },

  seletedTab: {
    border: '.5rem solid #E9ECF6'
  },

  padding05: {
    padding: '0.5rem'
  },

  flipCard: {
    position: 'relative',
    height: '5.2em',
    backgroundColor: 'transparent',
    perspective: '1000px',
    transformStyle: 'preserve-3d',
    transition: 'all 0.8s ease'
  },

  cardIsFliped: {
    transform: 'rotateY(180deg)'
  },

  back: {
    transform: 'rotateY(180deg)'
  },

  side: {
    width: 'calc(100% - 1rem)',
    height: 'calc(100% - 1rem)',
    position: 'absolute',
    backfaceVisibility: 'hidden',
    [`-webkit-backface-visibility`]: 'hidden'
  },

  textContainer: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2
  },

  flipIc: {
    width: '2em',
    height: '2em',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.6'
    }
  },

  txtInput: {
    maxWidth: '7em',
    '& .MuiOutlinedInput-input': {
      padding: '10px'
    },
    '& .MuiInputLabel-outlined': {
      lineHeight: 0
    }
  },

  centerVertically: {
    alignItems: 'center'
  },

  centerHorizontally: {
    justifyContent: 'center'
  },

  ellipsis: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  blockTxt: {
    textAlign: 'center',
    padding: '0.5rem'
  },

  marginRight: {
    marginRight: '1rem'
  },

  subLabel: {
    color: '#8996AC',
    marginBottom: '0.5rem'
  },

  subLabelCategories: {
    marginTop: '.5rem'
  },

  subLabelTatamiBlock: {
    marginTop: '3.5rem'
  },

  textAlign: {
    textAlign: 'center'
  },

  listStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },

  itemIndexes: {
    position: 'absolute',
    background: '#8996AC',
    right: 0,
    top: 0,
    color: '#fff',
    minWidth: '2em',
    textAlign: 'center',
    borderTopRightRadius: '0.3rem',
    borderBottomLeftRadius: '0.3rem'
  },

  hide: {
    display: 'none'
  },

  tatamisWrapper: {
    height: '100%',
    borderRadius: '0.3rem',
    padding: '1rem 1rem 0 1rem',
    background: '#fff',
    border: '1px solid #8996AC',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },

  sortBtns: {
    background: '#8996AC',
    color: '#fff'
  },

  droppableWrapper: {
    height: '100%',
    padding: '1rem 1rem 0 1rem',
    border: '1px solid #8996AC',
    borderRadius: '0.3rem',
    display: 'grid',
    '@media (min-width:1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridGap: '1rem',
      paddingBottom: '17px'
    },
    '@media (max-width:1023px)': {
      gridTemplateColumns: '1fr',
      gridRowGap: '1rem'
    }
  }
});

export const getTatamiTabStyle = (isDraggingOver, bg) => ({
  background: isDraggingOver ? '#8996AC' : bg,
  color: bg ? '#fff' : '#000'
});

export const getListStyle = (isDraggingOver, numOfTatamis) => ({
  background: isDraggingOver && 'rgb(137, 150, 172,0.1)'
});

export const getItemStyle = (isDragging, draggableStyle) => ({
  padding: '0.5rem',
  // change background colour if dragging
  background: isDragging && '#2D63EE',
  color: isDragging && '#fff',
  borderRadius: '0.3rem',
  height: '5em',
  border: '.1rem solid rgb(137, 150, 172,0.3)',
  // needed styles to apply on draggables
  ...draggableStyle
});
