import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ReactComponent as AddIcon } from 'assets/img/new_assign_grey.svg';
import { ReactComponent as EditIcon } from 'assets/img/new_edit_grey.svg';
import { ReactComponent as ClearIcon } from 'assets/img/cancel_btn_grey_bg&border.svg';

import { Tooltip } from '@material-ui/core';

import { ADD_ICON, CLEAR_ICON } from '../../helpers/constants';

import Table from 'components/Table/CardsList';
import ListItems from 'components/Table/ListItems';
import Radio from 'components/RadioBtn/RadioBtn';

import { useStyles, useStylesRecords } from './TableListStyles';

const ListItemsComponent = ({
  elem,
  elemParamN,
  itemParamN,
  subElemParamN,
  openModal,
  shouldDisableEditing,
  className
}) => {
  return (
    <ListItems
      {...{ elem, elemParamN, itemParamN, subElemParamN }}
      shouldAllowEditing={true}
      styles={className}
      specificDetails={{ className }}
      actionIconsList={(elem, subElemValue, currentClasses) => (
        <>
          {!shouldDisableEditing && (
            <ClearIcon
              id={CLEAR_ICON}
              onClick={(e) => {
                e.stopPropagation();
                openModal(e, elem, CLEAR_ICON);
              }}
              className={clsx(currentClasses)}
            />
          )}
        </>
      )}
      showAllItemsAtOnce
    />
  );
};

const TableList = (props) => {
  const {
    sortDirection,
    onSort,
    filteredRecords,
    shouldDisableEditing,
    isSchemeView,
    showModalForm,
    showModal,
    page,
    rowsPerPage,
    onSelectRecord,
    judgesTypes,
    selectedRow,
    tournamentData,
    tournamentIdParams,
    getTatamiTheme
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const FIRST_NAME = !+tournamentData?.last_name_first
    ? 'firstName'
    : 'lastName';
  const LAST_NAME = !+tournamentData?.last_name_first
    ? 'lastName'
    : 'firstName';

  const recordDataParams = (item, idx) => {
    const findTheme = [...judgesTypes]
      .flat()
      ?.find((it) => +it?.t_id === +item?.positionId)?.color;

    return {
      selected: selectedRow === item?.tjId,
      main: {
        label: t('name') + ' ' + t('judges'),
        hasSort: true,
        sort: !+tournamentData?.last_name_first ? 'firstName' : 'lastName',
        style: {
          cardMainPart: classes.cardValuePart
        },
        rowOrder: {
          value: (page - 1) * rowsPerPage + idx + 1,
          class: classes.rowOrder
        },
        name: [
          item?.[FIRST_NAME],
          item?.[LAST_NAME],
          ...(!tournamentIdParams || !!+tournamentData?.show_reg_patronymic
            ? [item?.patronymic]
            : [])
        ].join(' ')
      },
      info: [
        {
          sort: 'gender',
          value: item?.gender,
          name: t('gender'),
          width: 'minmax(2em, 5.5em)'
        },
        {
          sort: 'qdan_name',
          value: item?.qdan_name,
          name: t('kyuDan'),
          width: '4.5em'
        },
        {
          sort: 'qualificationRfOrder',
          value: item?.qualificationRfShort,
          name: t('rfQual'),
          width: '4.8em'
        },
        { sort: 'age', value: item?.age, name: t('age'), width: '4.5em' },
        {
          sort: 'experience',
          value: item?.experience,
          name: t('experience'),
          width: '4.8em'
        },
        {
          value: item?.positionName && (
            <ListItemsComponent
              elem={{ item, position: [{ positionName: item.positionName }] }}
              elemParamN="position"
              itemParamN="positionName"
              {...{ shouldDisableEditing }}
              openModal={(e, el, currentTargetId) => {
                showModal(e, item, el, currentTargetId);
              }}
              className={{
                categoryListItem: classes.categoryListItem,
                categoryTxt: classes.categoryTxt,
                gridDropdown: classes.listItemPosition,
                inlineStyle: getTatamiTheme(
                  findTheme !== -1 ? findTheme : DEFAULT_JUDGES_COLOR
                )
              }}
            />
          ),
          name: t('positions')
        },
        {
          value: (
            <ListItemsComponent
              elem={item}
              elemParamN="tatamis"
              itemParamN="tatami_name"
              subElemParamN="tt_id"
              {...{ shouldDisableEditing }}
              openModal={(e, el, currentTargetId) => {
                showModal(e, item, el, currentTargetId);
              }}
            />
          ),
          name: t('tatamis'),
          width: '4em'
        },
        {
          value: (
            <ListItemsComponent
              elem={item}
              elemParamN="teams"
              itemParamN="team_n"
              subElemParamN="tt_id"
              {...{ shouldDisableEditing }}
              openModal={(e, el, currentTargetId) => {
                showModal(e, item, el, currentTargetId);
              }}
            />
          ),
          name: t('judgeTeams'),
          width: '4.2em'
        },
        ...(!+tournamentData?.no_country
          ? [
              {
                sort: 'iso3',
                value: item.iso3,
                name: t('country'),
                width: '4.5em'
              }
            ]
          : []),
        ...(!!+tournamentData?.show_reg_region
          ? [
              {
                sort: 'region',
                value: item.region,
                name: t('region')
              }
            ]
          : []),
        ...(!!+tournamentData?.show_reg_city
          ? [
              {
                sort: 'city',
                value: item.city,
                name: t('city')
              }
            ]
          : []),
        ...(!!+tournamentData?.show_reg_club
          ? [{ sort: 'club', value: item.club, name: t('club') }]
          : [])
      ]
    };
  };

  const classes2 = useStylesRecords(recordDataParams({})?.info);

  return (
    <Table
      {...{ recordDataParams, sortDirection, onSort }}
      data={filteredRecords.slice(
        (page - 1) * rowsPerPage,
        (page - 1) * rowsPerPage + rowsPerPage
      )}
      onClick={(evt, item, idx) => {
        !shouldDisableEditing && !isSchemeView && showModalForm(evt, item, idx);
        !shouldDisableEditing && onSelectRecord(evt, item);
      }}
      specificDetails={{
        className: { gridRecordsValue: classes2.gridRecordsValue }
      }}
      actionIcons={(item, idx, className) =>
        !shouldDisableEditing && (
          <>
            {isSchemeView ? (
              <Tooltip arrow title={t('assign')}>
                <Radio
                  value={(selectedRow === item?.tjId && selectedRow) || ''}
                  item={item?.tjId}
                  onClick={(e) => {
                    onSelectRecord(e, item);
                  }}
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip arrow title={t('updateJudge')}>
                  <EditIcon
                    className={clsx(className)}
                    onClick={(evt) => showModalForm(evt, item, idx)}
                  />
                </Tooltip>
                <Tooltip arrow title={t('assignToPosition')}>
                  <AddIcon
                    id={ADD_ICON}
                    className={clsx(className, classes.marginLeft1)}
                    onClick={(e) => {
                      showModal(e, item, null, ADD_ICON);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </>
        )
      }
    />
  );
};

export default TableList;
