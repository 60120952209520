import { Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { KATAS_GRADES } from '../../../helpers/constants';
import { useStyles } from './Style';

const Katas = (props) => {
  const { bracketTatami, itemToggle, collapsed, placesComponent } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {bracketTatami.KataCategories.map((kata, kataIdx) => {
        const kataRoundsLen = kata.Rounds && kata.Rounds.length;
        const secondKataRoundFromEnd =
          kataRoundsLen && kataRoundsLen > 1 && kata.Rounds[kataRoundsLen - 2];
        const lastKataRound = kataRoundsLen && kata.Rounds[kataRoundsLen - 1];

        const winners =
          lastKataRound.Katas.length > 1
            ? lastKataRound.Katas
            : secondKataRoundFromEnd.Katas;
        const orderedWinners =
          winners && winners.sort((a, b) => +a.Place - +b.Place);

        return (
          <Fragment key={kata.Id}>
            <div
              className={clsx(classes.paper, classes.flex)}
              onClick={() => itemToggle(kata.Id)}>
              {kata.Name}
              <ExpandMore
                className={clsx(
                  classes.left,
                  collapsed[kataIdx] && classes.expandLess
                )}
              />
            </div>
            <Collapse in={collapsed[kata.Id]} timeout="auto" unmountOnExit>
              {kata?.Rounds?.map((kataRound, kataRoundIdx) => {
                return (
                  kataRound?.Katas?.length > 0 &&
                  kataRoundIdx <
                    2 /**As specified in the ticket(#932), kata categories are limited to two rounds at most */ && (
                    <div className={classes.marginBottom} key={kataRoundIdx}>
                      <span
                        className={clsx(classes.flex, classes.kataRoundTitle)}>
                        {[t('round'), kataRoundIdx + 1].join(' ')}
                      </span>
                      {kataRound?.Katas?.map((roundKatas, roundKatasIdx) => {
                        return (
                          <span
                            className={clsx(
                              classes.flex,
                              classes.kataFighterWrapper
                            )}
                            key={roundKatasIdx}>
                            <span
                              className={clsx(classes.flex, classes.orderNum)}>
                              {roundKatasIdx + 1}
                            </span>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.column,
                                classes.kataFighter
                              )}>
                              <span
                                className={clsx(
                                  classes.flex,
                                  classes.kataName
                                )}>
                                {Array.isArray(roundKatas.TeamMembers) &&
                                roundKatas.TeamMembers.length > 0 ? (
                                  <span
                                    className={clsx(
                                      classes.flex,
                                      classes.column
                                    )}>
                                    <span className={classes.flex}>
                                      {roundKatas.TeamName}:
                                    </span>
                                    {roundKatas.TeamMembers.map(
                                      (teamMember, teamMemberIdx) => (
                                        <span key={teamMemberIdx}>
                                          {[
                                            teamMember.Country
                                              ? `(${teamMember.Country})`
                                              : '',
                                            teamMember.Number
                                              ? `${teamMember.Number}.`
                                              : '',
                                            teamMember.Name
                                          ].join(' ')}
                                        </span>
                                      )
                                    )}
                                  </span>
                                ) : (
                                  [
                                    roundKatas.Country
                                      ? `(${roundKatas.Country})`
                                      : '',
                                    roundKatas.Number
                                      ? `${roundKatas.Number}.`
                                      : '',
                                    roundKatas.Name
                                  ].join(' ')
                                )}
                              </span>
                              <span className={classes.flex}>
                                {KATAS_GRADES.grades.map((grade, gradeIdx) => {
                                  const maxGrade =
                                    roundKatas.Max != '0' &&
                                    KATAS_GRADES.grades.find(
                                      (it) => roundKatas[it] === roundKatas.Max
                                    );
                                  const minGrade =
                                    roundKatas.Min != '0' &&
                                    KATAS_GRADES.grades.find(
                                      (it) => roundKatas[it] === roundKatas.Min
                                    );

                                  return (
                                    <span
                                      className={clsx(
                                        classes.flex,
                                        classes.score,
                                        (grade === minGrade ||
                                          grade === maxGrade) &&
                                          classes.minmaxScore
                                      )}
                                      key={gradeIdx}>
                                      {roundKatas[grade]}
                                    </span>
                                  );
                                })}
                              </span>
                            </span>
                            <span
                              className={clsx(
                                classes.flex,
                                classes.column,
                                classes.relative,
                                classes.result
                              )}>
                              {KATAS_GRADES.result.map((result, resultIdx) => (
                                <span
                                  className={clsx(
                                    classes.flex,
                                    classes.resultItem,
                                    result === 'Sum'
                                      ? classes.sum
                                      : classes.place
                                  )}
                                  key={resultIdx}>
                                  <span className={clsx(classes.flex)}>
                                    {[
                                      result === 'Sum' ? t('score') : t('rank'),
                                      roundKatas[result]
                                    ].join(': ')}
                                  </span>
                                </span>
                              ))}
                            </span>
                          </span>
                        );
                      })}
                    </div>
                  )
                );
              })}
              {lastKataRound.Katas[0] && +lastKataRound.Katas[0].Place ? (
                <div className={classes.marginBottom}>
                  <span className={clsx(classes.flex, classes.kataRoundTitle)}>
                    {t('winners')}
                  </span>
                  <span
                    className={clsx(
                      classes.flex,
                      classes.column,
                      classes.kataWinners
                    )}>
                    {orderedWinners.map((kataWinner, kataWinnerIdx) => (
                      <span
                        key={kataWinnerIdx}
                        className={clsx(
                          classes.flex,
                          classes.kataWinner,
                          kataWinnerIdx !== orderedWinners.length - 1 &&
                            classes.borderBottom
                        )}>
                        {Array.isArray(kataWinner.TeamMembers) &&
                        kataWinner.TeamMembers.length > 0 ? (
                          <span className={clsx(classes.flex, classes.column)}>
                            <span className={classes.flex}>
                              {kataWinner.TeamName}:
                            </span>
                            {kataWinner.TeamMembers.map(
                              (teamMember, teamMemberIdx) => (
                                <span key={teamMemberIdx}>
                                  {[
                                    teamMember.Country
                                      ? `(${teamMember.Country})`
                                      : '',
                                    teamMember.Number
                                      ? `${teamMember.Number}.`
                                      : '',
                                    teamMember.Name
                                  ].join(' ')}
                                </span>
                              )
                            )}
                          </span>
                        ) : (
                          [
                            kataWinner.Country ? `(${kataWinner.Country})` : '',
                            kataWinner.Number ? `${kataWinner.Number}.` : '',
                            kataWinner.Name
                          ].join(' ')
                        )}

                        {placesComponent(kataWinner)}
                      </span>
                    ))}
                  </span>
                </div>
              ) : null}
            </Collapse>
          </Fragment>
        );
      })}
    </>
  );
};

export default Katas;
