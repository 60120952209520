import { Component } from 'react';
import { withRouter } from '../../components/withRouter';
import { withTranslation } from 'react-i18next';
import validate from 'validate.js';
import { loadReCaptcha } from 'react-recaptcha-google';

import { AuthContext } from '../../AuthContext';
import { changeTitle } from '../../helpers/actions';
import { changeModal, signIn, onBlur, SeeModal } from '../../helpers/util';

import SideModal from '../../components/Snackbar/SideModal';
import SignInP2 from '../../components/SignInP2/SignInP2';
import PatternP2 from '../../components/PatternP2/PatternP2';

import { InstallPendo } from '../../analytics';

class Login extends Component {
  state = {
    isSending: false,
    success: false,
    showModal: false,
    open: false,
    login: {
      values: {},
      touched: {},
      errors: {}
    }
  };
  changeModal = changeModal.bind(this);
  signIn = signIn.bind(this);
  onBlur = onBlur.bind(this);
  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    const { login } = this.state;

    if (login.values !== prevState.login.values) {
      this.validateLogin();
    }

    window.addEventListener('beforeunload', this.onReload);
  }

  componentDidMount() {
    const { t } = this.props;
    const modalType = localStorage.getItem('showSideModal');
    let modalMessage;

    if (!localStorage.getItem('token')) {
      this.context.changeAuthState('');
    }

    if (modalType === 'Registration') {
      modalMessage = t('successRegistration');
    } else if (modalType === 'Password') {
      modalMessage = t('passwordChangedSuccessfully');
    } else {
      modalMessage = `${t('emailSent')} ${modalType}. ${t(
        'followInstructions'
      )}`;
    }
    SeeModal(modalMessage, this.changeModal);

    changeTitle(t('logIn'));
    loadReCaptcha();
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
    if (window.pendo) {
      const pendoBadge = document.querySelector(
        '._pendo-launcher-badge-bottom-left_'
      );
      if (pendoBadge) pendoBadge.style.visibility = 'hidden';
    } else {
      InstallPendo({}, {});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onReload);
  }

  onReload = () => sessionStorage.clear();

  validateLogin = (action) => {
    const { login } = this.state;
    const { t } = this.props;
    const errors = validate(
      login.values,
      {
        email: {
          presence: { allowEmpty: false, message: t('required') },
          length: { maximum: 64 }
        },
        password: {
          presence: { allowEmpty: false, message: t('required') },
          length: {
            minimum: 4,
            maximum: 16,
            message: t('requiredPasswordLength')
          }
        }
      },
      { fullMessages: false }
    );
    this.setState(
      (prevState) => ({
        login: {
          ...prevState.login,
          errors: errors || {}
        }
      }),
      () => action && action(this.state.login)
    );
  };

  onLogin = (evt) => {
    const { login } = this.state;
    const { navigate, location } = this.props;

    evt.preventDefault();

    this.validateLogin((val) => {
      if (Object.keys(val.errors).length === 0) {
        this.signIn(evt, login, (key) => {
          this.context.changeAuthState(key);

          if (localStorage.getItem('signinQuickRegister')) {
            navigate('/register_to_tournament', {
              state: { prevUrl: location.pathname }
            });

            // When multiple tabs are open, to refresh all tabs
            localStorage.setItem(
              'navigated_2_register_2_tournament',
              `navigate_${Math.random()}`
            );
          }
        });
      } else {
        this.setState((prevState) => ({
          login: {
            ...prevState.login,
            touched: {
              email: true,
              password: true
            }
          }
        }));
      }
    });
  };

  onLoadRecaptcha = () => {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.setState({ errors: { captcha: '' } });
    }
  };

  // verifyCallback = recaptchaToken => {
  //   const { errors } = this.state;
  //   delete errors.captcha;
  //   this.setState({ errors });
  // };

  fillLoginForm = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      login: {
        ...prevState.login,
        values: {
          ...prevState.login.values,
          [name]: value
        },
        touched: {
          ...prevState.login.touched,
          [name]: true
        }
      }
    }));
  };

  hideSnackBar = () => this.setState({ showModal: false });

  render() {
    const { success, showModal, modalInfo, login } = this.state;

    return (
      <>
        <SideModal
          closeModal={this.hideSnackBar}
          success={success}
          show={showModal}
          info={modalInfo}
        />
        <PatternP2>
          <SignInP2
            handleChange={this.fillLoginForm}
            formState={login}
            signIn={this.onLogin}
          />
        </PatternP2>
      </>
    );
  }
}
export default withTranslation()(withRouter(Login));
