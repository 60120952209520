import { Fragment } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { compareValueForSorting } from '../../../helpers/selectors';

import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';

import Table from '../../Table/Table';

import { useStyles } from './Styles';

export default ({ bracketTatami, itemToggle, collapsed }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isRuLang = localStorage.getItem('i18nextLng') === 'ru';
  const FIRST_NAME = !isRuLang ? 'FirstName' : 'LastName';
  const LAST_NAME = !isRuLang ? 'LastName' : 'FirstName';
  const header = [
    { label: t('number') },
    { label: t('name') },
    { label: t('fist') },
    { label: t('secondTry') },
    { label: t('footRib') },
    { label: t('secondTry') },
    { label: t('elbow') },
    { label: t('secondTry') },
    { label: t('edgeOfPalm') },
    { label: t('secondTry') },
    { label: t('outcome') },
    { label: t('rank') }
  ];
  const tableBody = [
    'Number',
    { firstName: FIRST_NAME, lastName: LAST_NAME, noPhoto: true },
    'Fist',
    'FistSecondAttempt',
    'RibLeg',
    'RibLegSecondAttempt',
    'Elbow',
    'ElbowSecondAttempt',
    'EdgeOfThePalm',
    'EdgeOfThePalmSecondAttempt',
    'Total',
    'Place'
  ];
  const condensedTableData = {
    mainName: { firstName: FIRST_NAME, lastName: LAST_NAME },
    info: [
      { key: 'Fist', name: t('fist') },
      { key: 'RibLeg', name: t('footRib') },
      { key: 'Elbow', name: t('elbow') },
      { key: 'EdgeOfThePalm', name: t('edgeOfPalm') }
    ]
  };

  return (
    <>
      {bracketTatami.TameshiwariGLCategories.map(
        (tameshiwari, tameshiwariIdx) => {
          const sortTameshiwari = tameshiwari.TameshivaryParticipants
            ? compareValueForSorting(
                tameshiwari.TameshivaryParticipants,
                'Place',
                2
              )
            : [];

          return (
            <Fragment key={tameshiwari.Id}>
              <div
                className={clsx(classes.flex, classes.paper)}
                onClick={() => itemToggle(tameshiwari.Id)}>
                {tameshiwari.Name}
                <ExpandMore
                  className={clsx(
                    classes.left,
                    collapsed[tameshiwariIdx] && classes.expandLess
                  )}
                />
              </div>
              <Collapse
                in={collapsed[tameshiwari.Id]}
                timeout="auto"
                unmountOnExit>
                <div className={classes.marginBottom}>
                  <Table
                    shouldHideTableToolbar
                    order
                    {...{ condensedTableData }}
                    {...{ header }}
                    body={sortTameshiwari}
                    data={tableBody}
                    colSpan={8}
                  />
                </div>
              </Collapse>
            </Fragment>
          );
        }
      )}
    </>
  );
};
