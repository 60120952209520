import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'grid',
    '@media (min-width:1024px)': {
      overflowX: 'auto',
      gridTemplateColumns: '1fr 1fr'
    },
    gap: '1rem'
  },

  canvasContainer: {
    position: 'relative'
  },

  outerCanvasWrapper: {
    display: 'flex',
    '@media (min-width:1024px)': {
      justifyContent: 'center'
    },
    '@media (max-width:1023px)': {
      overflowX: 'auto',
      overflowY: 'hidden'
    }
  },

  fieldsWrapper: {
    height: 'max-content',
    display: 'grid',
    gap: '1rem'
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },

  leftAuto: {
    marginLeft: 'auto'
  },

  field: {
    display: 'grid',
    gridTemplateColumns: '1fr 2rem',
    gap: '0.2rem',
    padding: '0.5rem',
    background: '#E9ECF6',
    border: '1px solid #A2AAAB',
    borderRadius: '0.3rem',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer'
    },
    '@media (max-width:767px)': {
      gridArea: 'name'
    }
  },

  fields: {
    display: 'grid',
    gap: '1rem',
    '@media (min-width:768px)': {
      gridTemplateColumns: 'repeat(2, 9em) repeat(3, 4em)'
    },
    '@media (max-width:767px)': {
      gridTemplateAreas: "'name  name ''. .' '. .'",
      marginBottom: '1rem'
    }
  },

  leftAuto: {
    marginLeft: 'auto'
  },

  saveBtn: {
    marginTop: '1rem',
    '@media (min-width:1024px)': {
      width: '18.215em'
    },
    '@media (max-width:1023px) and @media (min-width:768px)': {
      width: '11.072em'
    }
  },

  outlined: {
    background: '#E9ECF6',
    '& .MuiOutlinedInput-root': {
      '& input': {
        padding: '0',
        height: '11px'
      }
    }
  },

  textField: {
    '& .MuiOutlinedInput-input': {
      padding: 0
    }
  },

  textFieldFontAlign: {
    '& .MuiOutlinedInput-input': {
      display: 'none'
    }
  },

  textFieldCanvas: {
    height: '100%',
    width: '100%',

    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #000'
    },
    '& .MuiOutlinedInput-input': {
      padding: 0,
      background: '#fff'
    },
    '& .MuiOutlinedInput-root': {
      height: '100%',
      width: '100%'
    }
  },

  draggable: {
    position: 'absolute',
    cursor: 'grab',
    zIndex: 1
  },

  resizeHandle: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '10px',
    height: '10px',
    background: '#000',
    cursor: 'se-resize'
  }
}));
