import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#fff',
    padding: '1.5em'
  },

  flex: {
    display: 'flex'
  },

  fullWidth: {
    width: '100%'
  },

  asterisk: {
    '& .MuiFormLabel-asterisk': {
      color: '#005FEE'
    }
  },

  column: {
    flexDirection: 'column'
  },

  h1: {
    fontSize: '18px',
    fontWeight: 700,
    marginBottom: '1.5rem',
    wordBreak: 'break-word'
  },

  cardImg: {
    width: '10.313em',
    height: '10.313em',
    flexShrink: 0,
    '@media (max-width: 767px)': {
      width: '8.125em',
      height: '8.125em'
    }
  },

  cardTxt: {
    width: 'calc(100% - 5em)',
    marginLeft: '1em'
  },

  role: {
    fontSize: '1rem'
  },

  about: {
    width: 'calc(100% - 7em)'
  },

  marginTop: {
    marginTop: '1em'
  },

  formWrapper: {
    display: 'grid',
    width: '100%',
    gridGap: '1.2rem',
    marginTop: '1.875rem',
    '@media (min-width:1025px)': {
      gridTemplateColumns: '208px repeat(4, 1fr)',
      gridTemplateAreas: `
        'img firstName lastName patronymic email'
        'img birthday gender branchChief branchChief'
        'img coachLastName coachLastName coachFirstName coachFirstName'
        'img country region city .'
        '. club club phone website'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        'img lastName'
        'img firstName'
        'img patronymic'
        'img email'
        'birthday gender'
        'branchChief branchChief'
        'coachLastName coachLastName'
        'coachFirstName coachFirstName'
        'country region'
        'city .'
        'club club'
        'phone website'
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        'img'
        'lastName'
        'firstName'
        'patronymic'
        'email'
        'birthday'
        'gender'
        'branchChief'
        'coachLastName'
        'coachFirstName'
        'country'
        'region'
        'city'
        'club'
        'phone'
        'website'
      `
    }
  },

  logoStampWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '30px',
    '@media (max-width: 767px)': {
      marginTop: 0
    }
  },

  img: {
    gridArea: 'img',
    '@media (min-width:1024px)': {
      width: '13rem',
      height: '17.375rem'
    },
    '@media (max-width:1023px)': {
      width: '15rem',
      height: '20rem'
    },
    '@media (max-width: 767px)': {
      justifySelf: 'center',
      marginBottom: '1.5rem'
    }
  },

  myProfileLabel: {
    fontWeight: 700,
    fontSize: '20px',
    '@media (max-width: 767px)': {
      textAlign: 'center'
    }
  },

  marginLeft230: {
    '@media (min-width:1025px)': {
      marginLeft: '225px'
    }
  },

  description: {
    maxWidth: '180px',
    fontSize: '16px',
    wordBreak: 'break-word',
    lineHeight: '24px'
  },

  marginTop20: {
    marginTop: '20px'
  },

  gridButton: {
    display: 'grid',
    gridGap: '1rem',
    marginTop: '50px',
    '@media (min-width:1025px)': {
      gridTemplateColumns: '206px repeat(4, 1fr)',
      gridTemplateAreas: `
        '. . license changePassword save'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateAreas: `
        'license changePassword save'
      `
    },
    '@media (max-width: 767px)': {
      marginTop: '30px',
      gridTemplateColumns: '1fr',
      gridTemplateAreas: `
        'license'
        'changePassword'
        'save'
      `
    }
  }
}));
