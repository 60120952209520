import { Translate } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import authP2 from '../../assets/img/authP2.png';
import PatternIcon from '../../assets/img/patternP2.png';

export const useStyles = makeStyles((theme) => ({
  flex: {
    '@media (min-width:1024px)': {
      display: 'flex'
    }
  },
  quote: {
    '@media (min-width:1024px)': {
      backgroundImage: `url(${authP2})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top right',
      display: 'block'
    }
  },
  rainbowBox: {
    width: '100%',
    height: '35px',
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 0, 1) 20%, rgba(0, 128, 0, 1) 30%, rgba(255, 0, 0, 1) 40%, rgba(128, 128, 128, 1) 50%, rgba(60, 170, 255, 1) 60%, rgba(255, 255, 255, 1) 70%, rgba(255, 255, 0, 1) 80%, rgba(0, 128, 0, 1) 90%, rgba(255, 0, 0, 1) 100%)',
    backdropFilter: 'blur(8px)',
    opacity: '0.2'
  },
  red: {
    color: '#F5635C'
  },
  blue: {
    color: '#005FEE'
  },
  green: {
    color: '#00BB92'
  },
  padding: {
    padding: '30px 45px 90px',
    '@media (min-width:1024px)': {
      padding: '60px 45px 90px'
    },
    '@media (max-width:1023px)': {
      padding: '60px 45px 90px'
    }
  },
  mainContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#e3e7ef',
    justifyContent: 'center',
    '@media (min-width:1024px)': {
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    '@media (max-width:1023px)': {
      fontSize: '19px',
      backgroundImage: `url(${PatternIcon})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundSize: '70%',
      backgroundPosition: 'calc(50% + 15px) 100%'
    }
  },
  doubleContainer: {
    maxWidth: '1110px',
    justifyContent: 'space-between',
    padding: '0px',
    backgroundColor: 'transparent'
  },
  relative: {
    '@media (min-width:1024px)': {
      width: '50%'
    },
    position: 'relative',
    zIndex: 1
  },
  fontSize32: {
    '@media (min-width:1024px)': {
      fontSize: '30px',
      lineHeight: '48px'
    },
    '@media (max-width:1023px)': {
      fontSize: '28px',
      lineHeight: '44px'
    },
    '@media (max-width:767px)': {
      fontSize: '19px',
      lineHeight: '36px'
    }
  },
  mainText: {
    marginBottom: '8px',
    color: '#0c1a3d',
    textAlign: 'left',
    fontWeight: 'bold',
    display: 'block',
    maxWidth: '450px'
  },
  txtWrapper: {
    fontSize: '26px',
    '@media (max-width:1023px)': {
      fontSize: '22px'
    },
    '@media (max-width:767px)': {
      fontSize: '16px'
    },
    lineHeight: '32px',
    fontWeight: 700,
    textTransform: 'none',
    paddingBottom: '30px'
  },
  signForm: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width:1023px)': {
      paddingTop: '40px'
    },
    '@media (max-width:767px)': {
      paddingTop: '20px'
    }
  },
  size: {
    maxHeight: '230px',
    '@media (max-width:1023px)': {
      display: 'none'
    }
  }
}));
