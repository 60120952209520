import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

import { PRODUCT_LANG, LANG, LABEL } from '../../../../helpers/constants';
import { selectedValue } from '../../../../helpers/selectors';
import { onLogout } from 'helpers/util';

import Filter from '../../../../components/Filter/Filter';
import { AppBar, Toolbar } from '@material-ui/core';
import Burger from '../../../../assets/img/burger.png';
import { ReactComponent as Logo } from '../../../../assets/img/short_logo_white.svg';
import { AuthContext } from '../../../../AuthContext';
import Big_logo_header from '../../../../assets/img/big_logo_header.png';
import Small_logo_header from '../../../../assets/img/small_logo_header.png';

import { useStyles } from './StyleTopbarP2';

const TopbarP2 = (props) => {
  const { hideNav, showExitBtn } = props;

  const classes = useStyles();
  let navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const context = useContext(AuthContext);
  const { viewportWidth } = context;
  const smallviewport = viewportWidth <= 504;

  const lang = localStorage.getItem('i18nextLng');

  const [nav, setNav] = useState(false);

  const panel = [
    {
      label: t('home'),
      url: `https://tournament.expert/${lang}/`
    },
    {
      label: t('aboutUs'),
      url: `https://tournament.expert/${lang}/about/`
    },
    {
      label: t('products'),
      url: `https://tournament.expert/${lang}/products/`
    },
    {
      label: t('contacts'),
      url: `https://tournament.expert/${lang}/contacts/`
    }
  ];

  const switchLang = (evt, item) => {
    i18n.changeLanguage(item.lang);
  };

  return (
    <AppBar
      className={clsx(
        classes.root,
        classes.paperShadow,
        classes.width,
        classes.position
      )}>
      <Toolbar
        classes={{
          gutters: clsx(classes.toolbar, classes.width, classes.position)
        }}>
        <span id="top" className={classes.maxWidth}>
          <a href={panel[0].url}>
            {!smallviewport ? (
              <img src={Big_logo_header} alt={'Logo'} />
            ) : (
              <img src={Small_logo_header} alt={'Logo'} />
            )}
          </a>
        </span>
        <div className={clsx(classes.menu, classes.fontSize)}>
          <div className={classes.flex}>
            <div>
              <Filter
                classes={{
                  inputRoot: clsx(
                    classes.blue,
                    classes.fontW700,
                    classes.fontSize18,
                    classes.z10
                  ),
                  hasPopupIcon: classes.dropdownIcon
                }}
                shouldLookLikeBtn={true}
                options={PRODUCT_LANG}
                onChange={switchLang}
                item={LABEL}
                inputProps={{ disableUnderline: true, readOnly: true }}
                value={
                  selectedValue(
                    PRODUCT_LANG,
                    LANG,
                    localStorage.getItem('i18nextLng') || 'en'
                  ) || ''
                }
              />
            </div>
            {showExitBtn && (
              <span
                className={clsx(classes.black, classes.exitBtn, classes.flex)}
                onClick={() => {
                  onLogout(context, () =>
                    navigate('/sign-in', {
                      state: { prevUrl: location.pathname }
                    })
                  );
                }}>
                <CloseIcon />
                {!smallviewport && (
                  <span className={classes.logOut}>{t('logout')}</span>
                )}
              </span>
            )}
            {!hideNav && (
              <>
                <div
                  className={clsx(classes.mobileMenu, nav && classes.active)}>
                  {panel.map((it, idx) => (
                    <a
                      key={idx}
                      href={it.url}
                      className={clsx(
                        classes.panel,
                        it.label === t('home') ? classes.chosen : classes.black
                      )}>
                      <span>{it.label}</span>
                    </a>
                  ))}
                </div>
                <div
                  onClick={() => setNav(!nav)}
                  className={clsx(classes.mobileBtn)}>
                  {nav ? (
                    <CloseIcon className={classes.absolute} />
                  ) : (
                    <img src={Burger} alt="Burger" className={classes.size} />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopbarP2;
