const styles = () => ({
  marginBottom1: {
    marginBottom: '1rem'
  },

  freeSpaceWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    lineHeight: '1.5rem',
    backgroundColor: '#fafafa',
    padding: '16px',
    fontSize: '14px',
    borderBottom: '1px solid #eeeeee'
  },

  leftAuto: {
    marginLeft: 'auto'
  },

  freeSpaceLabel: {
    color: '#263238',
    fontWeight: '500'
  }
});

export default styles;
