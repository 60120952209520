import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  dialogPaper: {
    maxWidth: '1200px',
    width: '100%',
    margin: 'auto'
  },

  btn: {
    backgroundColor: '#2D63EE',
    color: 'white',
    border: '1px solid #fff',
    borderRadius: '6px',
    marginLeft: 'auto',
    width: '250px',
    '&:hover': {
      backgroundColor: '#1C48B9'
    }
  },

  onHoldEditing: {
    paddingBottom: 0,
    pointerEvents: 'none'
  },

  header: {
    backgroundColor: '#E8E8E8',
    padding: '1rem',
    justifyContent: 'space-between',
    fontSize: '1.25rem',
    fontWeight: 700,
    color: '#262441'
  },

  wrapper_header: {
    background: 'rgb(232,232,232)',
    position: 'sticky',
    top: 0,
    zIndex: 10
  },

  footer: {
    padding: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: '#E8E8E8'
  },

  marginRight1: {
    marginRight: '1rem'
  },

  left: {
    marginLeft: 'auto'
  },

  column: {
    flexDirection: 'column'
  },

  contentWrappper: {
    height: '650px',
    width: '1400px',
    background: '#fff'
  },

  contentSubheader: {
    margin: '0 1.5rem',
    fontWeight: 500
  },

  onHover: {
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.6'
    }
  }
}));
