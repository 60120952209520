export const styles = () => ({
    marginTop12: {
      marginTop: '12px'
    },
    marginTop25: {
        marginTop: '25px'
    },
    marginTop20: {
        marginTop: '20px'
    },
    marginBottom10: {
        marginBottom: '10px'
    },
    marginRight: {
      marginRight: '5px'
    },
    txtField: {
      height: '78px',
      width: '100%'
    },
    iconWrapper: {
      height: '52px',
      display: 'flex',
      alignItems: 'center',
      borderLeft: '1px solid #e0e6eb',
      width: '54px',
      justifyContent: 'center',
      cursor: 'pointer'
    },
    eyeIcon: {
      color: '#7b88d1'
    },
    endAdornment: {
      paddingRight: 0
    },
    flex: {
      display: 'flex'
    },
    size: {
        padding: '30px',
        width: '285px',
        background: '#ffffff',
        borderRadius: '10px'
    },
    borderRadius: {
        borderRadius: '10px'
    },
    blue: {
        backgroundColor: '#2D63EE'
    },
    fontSize20: {
        fontSize: '20px',
        fontWeight: 500
    },
    fontSize14: {
        fontSize: '14px'
    },
    black: {
        color: 'black'
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    
  });
  