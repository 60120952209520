import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Navigate, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import { AuthContext } from './AuthContext';
import { default as TournamentCertificateView } from './containers/Tournaments/Attachments/Attachment';
import { default as LoginView } from './containers/Login/Login';
import { default as SignUpView } from './containers/Login/SignUp';
import { default as CouldNoutLoginView } from './containers/Login/CouldNoutLogin';
import { default as InviteView } from './containers/InviteCoaches/InviteCoaches';
import { default as RegisterToTournView } from './containers/RegisterToTourn/RegisterToTourn';
import { default as SettingsView } from './containers/Settings/Settings';
import { default as RegistrarsCoachesView } from './containers/RegistrarsCoaches/RegistrarsCoaches';
import { default as ReportsView } from './containers/Reports/Reports';
import { default as AccountView } from './containers/Account/Account';
import { default as AddCategoryToTournamentView } from './containers/TournamentTatamisAndCategories/TournamentTatamisAndCategories';
import { default as NotFoundView } from './components/NotFound/NotFound';
import { default as SingleTournamentView } from './containers/Tournamnet/Tournament';
import { default as AddCoachView } from './containers/AddCoach/AddCoach';
import { default as ApproveJudgesView } from './containers/ApproveJudges/ApproveJudges';
import { default as Staff } from './containers/Staff/Staff';
import { default as UsersView } from './containers/Users/Users';
import { default as LogsView } from 'containers/Logs/Logs';
import { default as WinnersView } from './containers/Winners/Winners';
import { default as UserListView } from './containers/UsersList/UserList';
import { default as TournamentsView } from './containers/Tournaments/Tournaments';
import { default as CategoriesView } from './containers/Categories/Categories';
import { default as FederationView } from './containers/Federation/Federation';
import { default as PaymentsView } from './containers/Payments/Payments';
import { default as NewPasswordView } from './containers/NewPassword/NewPassword';
import { default as DashboardView } from './containers/Dashboard/Dashboard';
import { default as AddTournamentView } from './containers/AddTournament/AddTournament';
import { default as ActivateAccountView } from './containers/ActivateAccout/ActivateAccout';
import { default as Reference } from 'containers/Reference/Reference';
import { default as GridTournament } from 'pages/GridTournament/GridTournament';

const MyRoutes = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const context = useContext(AuthContext);
  const { role } = context.authState;

  return (
    <Routes>
      {/* PRIVATE ROUTES */}
      <Route element={<ProtectedRoute isAllowed={role} />}>
        <Route
          path="account"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('profile') }}>
              <AccountView />
            </MainLayout>
          }
        />
      </Route>
      <Route element={<ProtectedRoute isAllowed={role} />}>
        <Route
          path="/"
          element={
            role === 'administrator' ||
            role === 'master' ||
            role === 'coach' ||
            role === 'recorder' ? (
              <MainLayout>
                <DashboardView />
              </MainLayout>
            ) : role === 'operator' ? (
              <Navigate replace to="more-apps" />
            ) : (
              <Navigate replace to="account" />
            )
          }
        />
      </Route>
      <Route
        element={
          <ProtectedRoute
            isAllowed={
              role === 'administrator' || role === 'master' || role === 'coach'
            }
          />
        }>
        <Route
          path="/participants"
          element={
            <MainLayout
              specificDetails={{
                hasGoBack: true,
                title:
                  localStorage.getItem('role') === 'administrator'
                    ? t('athletes')
                    : t('myTeam')
              }}>
              <UserListView />
            </MainLayout>
          }
        />
      </Route>

      {/* ADMIN, MASTER, RECORDER */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={
              role &&
              (role === 'administrator' ||
                role === 'master' ||
                role === 'recorder')
            }
          />
        }>
        <Route
          path="/event/:id/participants/"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('participants') }}>
              <UserListView />
            </MainLayout>
          }
        />
      </Route>

      {/* ADMIN, MASTER, OPERATOR */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={
              role &&
              (role === 'administrator' ||
                role === 'master' ||
                role === 'operator')
            }
          />
        }>
        <Route
          path="more-apps"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('moreApps') }}>
              <SettingsView />
            </MainLayout>
          }
        />
      </Route>

      {/* ADMIN AND MASTER */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={role === 'administrator' || role === 'master'}
          />
        }>
        <Route
          path="event/:id/registration"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('registrationN') }}>
              <InviteView />
            </MainLayout>
          }
        />
        <Route
          path="/event/:id/select-attachment"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('documents') }}>
              <TournamentCertificateView />
            </MainLayout>
          }
        />
        <Route
          path="/event/:id/coaches_requests"
          element={
            <MainLayout
              specificDetails={{
                hasGoBack: true,
                title: t('approveRegistration')
              }}>
              <AddCoachView />
            </MainLayout>
          }
        />
        <Route
          path="/event/:id/judges_requests"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('approveJudges') }}>
              <ApproveJudgesView />
            </MainLayout>
          }
        />
        <Route
          path="/event/:id/staff"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('judges') }}>
              <Staff />
            </MainLayout>
          }
        />
        <Route
          path="/event/:id/edit"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('event') }}>
              <AddTournamentView
                specificDetails={{
                  className: {
                    hasPaperStyle: true
                  }
                }}
              />
            </MainLayout>
          }
        />
        <Route
          path="/event/add"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('event') }}>
              <AddTournamentView
                specificDetails={{
                  className: {
                    hasPaperStyle: true
                  }
                }}
              />
            </MainLayout>
          }
        />
        <Route
          path="/events"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('events') }}>
              <TournamentsView />
            </MainLayout>
          }
        />
        <Route
          path="/event/:id/reports"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('reports') }}>
              <ReportsView />
            </MainLayout>
          }
        />
        <Route
          path="/event/:id/winners"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('winners') }}>
              <WinnersView />
            </MainLayout>
          }
        />
        <Route
          path="categories"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('categories') }}>
              <CategoriesView />
            </MainLayout>
          }
        />
        <Route
          path="/event/:id"
          element={
            <MainLayout
              specificDetails={{
                hasGoBack: true,
                title: location.state?.tournament_name
              }}>
              <SingleTournamentView />
            </MainLayout>
          }
        />

        <Route
          path="/event/:id/event-setting"
          element={
            <MainLayout
              specificDetails={{
                hasGoBack: true,
                title: [t('tatami'), t('categories')].join('/')
              }}>
              <AddCategoryToTournamentView />
            </MainLayout>
          }
        />
        <Route
          path="users"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('users') }}>
              {role === 'master' ? <RegistrarsCoachesView /> : <UsersView />}
            </MainLayout>
          }
        />
        <Route
          path="event/:id/grid"
          element={
            <MainLayout specificDetails={{ hasGoBack: true }}>
              <GridTournament />
            </MainLayout>
          }
        />
      </Route>

      {/* MASTER */}

      <Route element={<ProtectedRoute isAllowed={role && role === 'master'} />}>
        <Route
          path="organisation"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('myOrganisation') }}>
              <FederationView />
            </MainLayout>
          }
        />
        <Route
          path="participant/:id/payments/"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('payments') }}>
              <PaymentsView />
            </MainLayout>
          }
        />
      </Route>

      {/* ADMIN */}
      <Route
        element={
          <ProtectedRoute isAllowed={role && role === 'administrator'} />
        }>
        <Route
          path="/reference"
          element={
            <MainLayout
              specificDetails={{ hasGoBack: true, title: t('reference') }}>
              <Reference />
            </MainLayout>
          }
        />
        <Route
          path="/logs"
          element={
            <MainLayout specificDetails={{ hasGoBack: true, title: t('logs') }}>
              <LogsView />
            </MainLayout>
          }
        />
      </Route>

      {/* QUICK REGISTRATION */}
      <Route
        element={
          <ProtectedRoute
            isAllowed={
              localStorage.getItem('invitation_tournId') &&
              (role === 'coach' || role === 'master')
            }
          />
        }>
        <Route
          path="register_to_tournament"
          element={
            <MainLayout
              specificDetails={{
                title: t('registerToTourn'),
                hasGoBack: true
              }}>
              <RegisterToTournView />
            </MainLayout>
          }
        />
      </Route>

      {/* PUBLIC ROUTES*/}
      <Route
        path="*"
        element={
          <MinimalLayout hideNav>
            <NotFoundView />
          </MinimalLayout>
        }
      />
      <Route
        path="/register_to_tournament/:token"
        element={
          localStorage.getItem('signinQuickRegister') ? (
            <MinimalLayout hideNav specificDetails={{ title: t('logIn') }}>
              <LoginView />
            </MinimalLayout>
          ) : (
            <RegisterToTournView />
          )
        }
      />
      <Route
        path="sign-in"
        element={
          !role ? (
            <MinimalLayout hideNav specificDetails={{ title: t('logIn') }}>
              <LoginView />
            </MinimalLayout>
          ) : (
            <Navigate to="/" />
          )
        }
      />
      <Route
        path="forgot-password"
        element={
          !role ? (
            <MinimalLayout
              hideNav
              specificDetails={{ title: t('forgotPassword') }}>
              <CouldNoutLoginView />
            </MinimalLayout>
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
      <Route
        path="sign-up"
        element={
          !role ? (
            <MinimalLayout hideNav specificDetails={{ title: t('signUp') }}>
              <SignUpView />
            </MinimalLayout>
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
      <Route
        path="/reset_password/:token"
        element={
          !role ? (
            <MinimalLayout hideNav>
              <NewPasswordView />
            </MinimalLayout>
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
      <Route
        path="/activate/:userToken"
        element={
          !role ? (
            <MinimalLayout hideNav>
              <ActivateAccountView />
            </MinimalLayout>
          ) : (
            <Navigate replace to="/" />
          )
        }
      />
    </Routes>
  );
};

export default MyRoutes;
