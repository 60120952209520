import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  cardValuePart: {
    backgroundColor: '#AEBBD0',
    color: '#262441'
  },

  rowOrder: {
    borderRight: '1px solid #8996AC'
  },

  listItemPosition: {
    maxWidth: '25em'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  categoryTxt: {
    overflow: 'hidden'
  },

  categoryListItem: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    textOverflow: 'ellipsis'
  }
});

export const useStylesRecords = makeStyles((theme) => ({
  gridRecordsValue: (props) => {
    return {
      '@media (min-width: 1451px)': {
        display: 'grid',
        gridTemplateColumns: props?.map((it) => it.width ?? '1fr').join(' '),
        gap: '0.5rem'
      },
      '@media (max-width:1450px) and (min-width:768px)': {
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
      },
      '@media (max-width:1000px) and (min-width:480px)': {
        gridTemplateColumns: '1fr 1fr 1fr'
      },
      '@media (max-width:479px) and (min-width:300px)': {
        gridTemplateColumns: '1fr 1fr'
      }
    };
  }
}));
