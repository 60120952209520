import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Tooltip } from '@material-ui/core';

import {
  DEFAULT_AVATAR,
  DEFAULT_TOURNAMENT,
  serverDefaultPath
} from '../../helpers/constants';
import { isSmallScreen, organizerName } from '../../helpers/selectors';

import Button from '../Buttons/ActionButtons';
import RegistrationStatus from '../RegistrationStatus/RegistrationStatus';

import { useStyles } from './StylesNewDesign';

const HeaderTournInfoNewDesign = (props) => {
  const { summaryHeader, tournamentData, shouldShowBtn, viewportWidth } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1, {
      state: { prevUrl: location.pathname }
    });
  };

  const tournType = () => {
    switch (tournamentData?.tournament_type) {
      case 'local':
        return t('regionalEvent');
      case 'international':
        return t('internationalEvent');
      case 'training_school':
        return t('trainingSchool');
    }
  };

  const headerTournMaster = {
    img: {
      src: tournamentData?.poster && serverDefaultPath + tournamentData?.poster
    },
    button: {
      id: 'goBack',
      onClick: () => goBack(),
      label: <ArrowBackIcon />
    },
    info: [
      { name: tournamentData?.tournament_name },
      {
        name: tournamentData?.start_date?.split('-').reverse().join('/')
      },
      {
        label: t('organizer'),
        name: [
          tournamentData.organizer_club,
          ', ',
          organizerName(tournamentData)?.firstN,
          ' ',
          organizerName(tournamentData)?.lastN,
          ' '
        ]
      },
      {
        label: t('eventType'),
        name: tournType()
      }
    ]
  };

  const currentDataObj =
    (summaryHeader && summaryHeader(tournType())) ?? headerTournMaster;

  const btnComponent = (key, className) => (
    <Button
      id={key.button.id}
      className={clsx(classes.flex, className, classes.left)}
      onClick={key.button.onClick}
      label={key.button.label}
      isSaveBtn
    />
  );

  const cardHeaderContent = (key, idx, className, classPaper) =>
    tournamentData &&
    Object.keys(tournamentData).length > 0 && (
      <>
        <span
          key={idx}
          className={clsx(classes.flex, className, classes.column)}>
          <div
            className={clsx(
              classes.textAlign,
              classes.marginBottom5,
              classPaper,
              classes.padding3
            )}>
            <h4 className={classes.title}>{key.title}</h4>
          </div>
          <div className={clsx(classes.flex, classPaper, classes.padding10)}>
            <img
              className={classes.img}
              alt="pic"
              src={
                key.img.src ?? (idx === 0 ? DEFAULT_TOURNAMENT : DEFAULT_AVATAR)
              }
            />
            <span
              className={clsx(
                classes.marginLeftRight,
                classes.panelWithoutImgBtn
              )}>
              {key.info.map((it, infoIdx) => (
                <span
                  key={`${it.label}_${idx}_${infoIdx}`}
                  className={clsx(
                    classes.flex,
                    classes.column,
                    isSmallScreen(viewportWidth) && classes.column,
                    classes.marginBottom0Dot3
                  )}>
                  {it.label && (
                    <span className={clsx(classes.labelName)}>{it.label}</span>
                  )}
                  <span className={classes.ellipsis}>{it.name}</span>
                </span>
              ))}
            </span>
            {shouldShowBtn && key.button ? (
              key.button.className ? (
                btnComponent(key, key.button.className)
              ) : (
                <Tooltip arrow title={t('goBack')}>
                  <span className={classes.heightFitContent}>
                    {btnComponent(key, classes.goBackArrow)}
                  </span>
                </Tooltip>
              )
            ) : (
              Array.isArray(key.button) && (
                <span className={clsx(classes.flex, classes.left)}>
                  {key.button.map((icBtn, idxIcBtn) => (
                    <span
                      key={idxIcBtn}
                      className={clsx(
                        classes.flex,
                        idxIcBtn !== key.button?.length - 1 &&
                          classes.marginRight1
                      )}>
                      {icBtn.ic}
                    </span>
                  ))}
                </span>
              )
            )}
          </div>
        </span>
      </>
    );

  const headerComponent = (
    <span className={clsx(classes.flex, classes.condensedView)}>
      {Array.isArray(currentDataObj)
        ? currentDataObj.map((item, idx) => (
            <Fragment key={idx}>
              {cardHeaderContent(
                item,
                idx,
                [
                  idx === 0 &&
                    !isSmallScreen(viewportWidth) &&
                    classes.marginRight1Dot5,
                  classes.marginBottom1,
                  !isSmallScreen(viewportWidth) && classes.halfWidth
                ],
                [classes.paper, classes.borderRadius]
              )}
            </Fragment>
          ))
        : cardHeaderContent(currentDataObj, 0, [
            classes.paper,
            classes.borderRadius
          ])}
    </span>
  );

  return (
    <div className={clsx(classes.flex, classes.column, classes.marginBottom2)}>
      <div className={clsx(classes.flex, classes.column)}>
        {headerComponent}
      </div>
      <h5
        className={clsx(
          classes.flex,
          classes.column,
          classes.registrationStatus,
          classes.textAlign
        )}>
        <RegistrationStatus {...{ tournamentData }} />
      </h5>
    </div>
  );
};

export default HeaderTournInfoNewDesign;
