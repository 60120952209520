const styles = () => ({
  flex: {
    display: 'flex'
  },

  maxHeight: {
    '& .MuiFormControl-root': {
      height: '5em'
    }
  },

  marginTop1: {
    marginTop: '1rem'
  },

  marginBottom05: {
    marginBottom: '0.5rem'
  },

  marginLeft1: {
    marginBottom: '-1rem'
  },

  column: {
    flexDirection: 'column'
  },

  formSave: {
    height: '3.35em',
    width: '100%'
  },

  checkbox: {
    marginRight: 0
  }
});

export default styles;
