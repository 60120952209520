import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Dialog } from '@material-ui/core';
import { ReactComponent as Exit } from 'assets/img/close_white_bg&grey_border.svg';
import { CLOSE, SAVE } from '../../helpers/constants';
import Button from '../Buttons/ActionButtons';
import { useStyles } from './StylesHelpInfo';

const HelpInfo = (props) => {
  const {
    open,
    close,
    children,
    buttonPurpose,
    onClick,
    dialogTitle,
    dialogContent,
    shouldShowDiscardChanges,
    subHeader,
    specificDetails,
    onSwitchBetween,
    classNameBtn,
    closeButton,
    closeButtonlabel
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const buttonData = [
    onClick && {
      id: SAVE,
      label: buttonPurpose,
      onClick,
      isSaveBtn: true,
      className: [classes.btn, classNameBtn],
      autoFocus: true
    },
    closeButton && {
      id: CLOSE,
      label: closeButtonlabel ? closeButtonlabel : t('close'),
      onClick: closeButton,
      className: [classes.btn]
    }
  ];

  const modalContent = (
    <>
      <span className={clsx(classes.wrapper_header)}>
        <span className={clsx(classes.flex, classes.header)}>
          <span className={classes.marginRight1}>{dialogTitle}</span>

          <Exit
            className={clsx(classes.onHover, !onSwitchBetween && classes.left)}
            onClick={close}
          />
        </span>
        {subHeader && (
          <span className={clsx(classes.flex, classes.contentSubheader)}>
            {subHeader}
          </span>
        )}
      </span>
      <span
        className={clsx(classes.contentWrappper, {
          [classes.onHoldEditing]: shouldShowDiscardChanges
        })}>
        {dialogContent}
        {children}
      </span>
    </>
  );

  return (
    <Dialog
      open={open}
      onClose={close}
      classes={{
        paper: clsx(classes.dialogPaper, specificDetails?.className?.paper)
      }}>
      <span className={clsx(classes.column)}>
        {modalContent}
        <span className={clsx(classes.footer)}>
          {buttonData.map(
            (it, idx) =>
              it && (
                <Fragment key={idx}>
                  <Button
                    id={it.id}
                    isSaveBtn={it.isSaveBtn}
                    onClick={it.onClick}
                    className={clsx(it.className, classes.btn)}
                    label={it.label}
                  />
                </Fragment>
              )
          )}
        </span>
      </span>
    </Dialog>
  );
};

export default HelpInfo;
