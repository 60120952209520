import clsx from 'clsx';

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip
} from '@material-ui/core';
import { useStyles } from './StylesRadioBtn';

const RadioBtn = (props) => {
  const {
    options,
    onClick,
    value,
    label,
    item,
    className,
    radioClass,
    tooltipTitleParam,
    containerRadioClass,
    specificDetails
  } = props;

  const classes = useStyles();

  const intermidiateRadioBtn = (value, label) => (
    <FormControlLabel
      classes={{
        root: clsx(
          classes.margin,
          className,
          specificDetails?.className?.rootLabel
        )
      }}
      key={value}
      {...{ value, label }}
      control={
        <Radio
          classes={{
            root: clsx(
              classes.radio,
              radioClass,
              specificDetails?.className?.radioIcon
            )
          }}
          color="primary"
        />
      }
    />
  );
  const finalRadioBtn = (value, label, title) =>
    title ? (
      <Tooltip key={value} {...{ title }}>
        {intermidiateRadioBtn(value, label)}
      </Tooltip>
    ) : (
      intermidiateRadioBtn(value, label)
    );

  return (
    <RadioGroup
      {...{ value }}
      {...{ onClick }}
      className={clsx(
        classes.radioGroup,
        containerRadioClass,
        specificDetails?.className?.radioGroup
      )}>
      {Array.isArray(options)
        ? options.map((it) =>
            finalRadioBtn(
              it[item],
              specificDetails?.labelElem
                ? specificDetails?.labelElem(it)
                : it[label],
              it?.[tooltipTitleParam]
            )
          )
        : finalRadioBtn(item, label)}
    </RadioGroup>
  );
};
export default RadioBtn;
