import { useParams, useNavigate, useLocation } from 'react-router-dom';

export function withRouter(Component) {
  return (props) => {
    let params = { params: useParams() };
    let navigate = useNavigate();
    let location = useLocation();
    return (
      <Component
        {...props}
        match={params}
        {...{ navigate }}
        {...{ location }}
      />
    );
  };
}
