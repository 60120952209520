import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  active: {
    color: '#00BB92'
  },

  closed: {
    color: '#F5635C'
  }
}));
