import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.primary.disabled,
      color: theme.palette.primary.contrastText
    }
  },

  margin05: {
    margin: '0.5em'
  },
  
  flex: {
    display: 'flex'
  },

  buttonsWrapper: {
    display: 'grid',
    gridGap: '14px',
    
    '@media (min-width: 1024px)': {
      gridTemplateColumns: '250px repeat(auto-fill, minmax(225px, 1fr));'
    },

    '@media (max-width: 1023px)': {
      gridTemplateColumns: 'repeat(auto-fill, minmax(225px, 1fr));'
    }
  },

  disabledBtn: {
    cursor: 'not-allowed'
  },
  noEvent: {
    pointerEvents: 'none'
  },
  title: {
    textAlign: 'left'
  },
  column: {
    flexDirection: 'column'
  }
}));
