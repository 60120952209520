import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  wordBreak: {
    wordBreak: 'break-word'
  },
  root: {
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  informativeTxt: {
    fontWeight: 'bold',
    marginRight: '5px',
    width: '100%'
  },
  icon: {
    cursor: 'pointer',
    color: '#8996AC',
    marginLeft: '0.5rem',
    '&:hover': {
      color: '#1a237e'
    }
  },

  success: {
    color: '#00cc44'
  }
}));
