export const styles = (theme) => ({

  leftAuto: {
    marginLeft: 'auto'
  },

  flex: {
    display: 'flex'
  },

  arrowDropUp: {
    transform: 'rotate(180deg)'
  },

  onClick: {
    backgroundColor: '#001D3D',
    color: 'white'
  },

  fullWidthButton: {
    fontSize: '20px',
    fontWeight: 450,
    width: '100%',
    border: '1px solid gray',
    borderRadius: '4px',
    outline: 'none',
    fontSize: '20px',
    padding: '8px 16px',
    transition: 'background-color 0.3s, color 0.3s',
  },

  marginTop: {
    marginTop: '1rem',
  },

  opacity: {
    opacity: '0.5'
  },
  
  margin020: {
    margin: '0 10px'
  },

  pointer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },

  hover: {
    '&:hover': {
      backgroundColor: '#001D3D',
      color: 'white',
    }
  },

  button: {
    border: '1px solid gray',
    borderRadius: '4px',
    padding: '2px 4px'
  },

  notActive: {
    backgroundColor: 'white',
    color: 'black'
  },

  backgroundColor: {
    backgroundColor: '#F2F1EF'
  },

  inputForLang: {
    backgroundColor: 'white',
    borderRadius: '4px',
    width: '40px',
    '& .MuiInputBase-input': {
      color: 'black'
    }
  },
  input: {
    '& .MuiInputBase-input': {
      color: 'black',
      borderRadius: '4px'
    }
  },

  iconWrapper: {
    color: '#fff',
    borderRadius: '4px',
    backgroundColor: '#001D3D',
    width: '2.2rem',
    height: '2.2rem'
  },

  marginLeft5: {
    marginRight: '10px'
  },

  grey: {
    backgroundColor: 'grey'
  },

  addWrapper: {
    '&:hover': {
      backgroundColor: '#001D3D',
      opacity: 0.7
    }
  },

  rotate: {
    transform: 'rotate(45deg)'
  },

  marginRight10: {
    marginRight: '10px'
  },

  justifyContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  individualInnerPage: {
    padding: '10px 20px',
    backgroundColor: '#f0f0f0',
    color: '#000',
    border: '1px solid #ccc',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#001D3D',
      color: '#fff'
    }
  },

  selectedInnerPage: {
    backgroundColor: '#001D3D',
    color: '#fff'
  },

  marginTop20: {
    marginTop: '20px',
    width: '150px'
  },

  marginLeft20: {
    marginLeft: '20px'
  },

  width300: {
    width: '300px'
  },

  scale: {
    scale: 1.25
  },

  margin01005: {
    margin: '0px 10px 0px 2px'
  },

  updateName: {
    marginTop: '2px',
    margin: '0 10px',
    borderRadius: '4px',
    scale: 1.15
  }
});
