import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  radioGroup: {
    width: 'fit-content',
    display: 'inline-block',
    '& .MuiRadio-colorPrimary.Mui-checked': {
      color: '#2D63EE'
    }
  },

  radio: {
    color: '#005FEE',
    padding: '0 0.5rem',
    borderRadius: '50%',
    width: '1em',
    '&.MuiFormControlLabel-root': {
      margin: 0
    }
  },

  margin: {
    margin: 0
  }
});
