import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    tablePagination: {
        maxWidth: '400px',
        paddingTop: '2rem',
        '&.MuiCardActions-spacing > div > div > p:nth-child(4)': {
            width: '100%',
            textAlign: 'center',
        },
        '&.MuiCardActions-spacing > div > div > div.MuiTablePagination-actions > button:nth-child(2)': {
            marginLeft: 'auto',
            marginRight: '20px',
            backgroundColor: 'transparent',
            '&::before': {
                fontSize: '16px',
                fontWeight: '400'
            }
        },
        '&.MuiCardActions-spacing > div > div > div.MuiTablePagination-actions > button:nth-child(1)': {
            backgroundColor: 'transparent',
            '&::after': {
                fontSize: '16px',
                fontWeight: '400'
            }
        },
        '&.MuiCardActions-spacing > div > div > div.MuiTablePagination-actions > button': {
            '@media (max-width:900px)': {
                maxWidth: '50px',
            },
        },
    },
    toolbar: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        '&.MuiToolbar-gutters': {
            padding: 0,
        },
    },
    selectRoot: {
        marginRight: 0,
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    text: {
        '&.MuiIconButton-root': {
            fontSize: '14px',
            padding: '3px 6px',
            color: '#000000',
            borderRadius: '0px'
        }
    },

    transparent: {
        backgroundColor: 'transparent'
    },

    white: {
        '&.Mui-disabled': {
          backgroundColor: '#FFFFFF',
          border: '1px solid rgba(137, 150, 172, 0.7)'
        }
    },

    numberOfEntriesPerPage: {
        float: 'left',
        fontSize: '14px',
        padding: '3px 6px',
        color: '#000000',
        borderRadius: '0px'
    },

    input: {
        zIndex: 1,
        marginLeft: '-5px',
        paddingRight: '2px',
        float: 'right'
    },
}));
