const styles = () => ({
  gridButtons: {
    display: 'grid',
    marginTop: '1rem',
    gridGap: '1em',
    '@media (min-width:1025px)': {
      gridGap: '1%'
    }
  },

  viewAddButton: {
    '@media (min-width:1025px)': {
      gridTemplateAreas: `
        'searchInput searchInput searchInput checkboxNotAccepted add saveList'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateAreas: `
        'searchInput searchInput searchInput'
        'checkboxNotAccepted add saveList'
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateAreas: `
        'searchInput'
        'checkboxNotAccepted'
        'add'
        'saveList'
      `
    }
  },
  noviewAddButton: {
    '@media (min-width:1025px)': {
      gridTemplateAreas: `
        'searchInput searchInput searchInput searchInput checkboxNotAccepted saveList'
      `
    },
    '@media (min-width: 768px) and (max-width: 1024px)': {
      gridTemplateAreas: `
        'searchInput searchInput'
        'checkboxNotAccepted saveList'
      `
    },
    '@media (max-width: 767px)': {
      gridTemplateAreas: `
        'searchInput'
        'checkboxNotAccepted'
        'saveList'
      `
    }
  },

  plus: {
    marginRight: '.5em',
    fontSize: '35px',
    fontWeight: 400
  },

  maxWidth: {
    maxWidth: '10em'
  },

  centerVertically: {
    alignItems: 'center'
  },

  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#001D3D'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#001D3D',
      opacity: 0.6
    }
  },

  switchWrapper: {
    minWidth: 'fit-content',
    padding: '0.5rem 0 0.5rem 1rem'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  marginForRootCheckBox: {
    marginLeft: 0,
    marginRight: 0,
    padding: '4px',
    border: '1px solid #8996AC',
    borderRadius: '5px'
  },
  emptyState: {
    marginTop: '40px'
  }
});

export default styles;
