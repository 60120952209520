import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import DoneIcon from '@material-ui/icons/Done';
import { Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './styles';

export default ({ success }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      {success ? (
        <DoneIcon className={classes.successIcon} />
      ) : (
        <CloseIcon className={classes.failIcon} />
      )}
      <div>
        <span className={classes.text}>
          {[
            success ? t('accountCreated') : t('accountFailToCreate'),
            t('navigateTo')
          ].join(' ')}
        </span>
        <Link component={RouterLink} to="/sign-in" variant="h6">
          {t('logIn')}
        </Link>
      </div>
    </div>
  );
};
