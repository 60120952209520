import clsx from 'clsx';
import PatternIcon from '../../assets/img/patternP2.png';
import { useStyles } from './StyleP2';
import { Footer } from 'layouts/Main/components';
import { useTranslation } from 'react-i18next';

const PatternP2 = ({ children }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
        <div className = {clsx(classes.quote, classes.mainContainer, classes.padding)}>
            <div className={clsx(classes.flex, classes.doubleContainer)}>
                <div className={clsx(classes.relative)}>
                    <span className={clsx(classes.mainText, classes.fontSize32)}>
                        {t('softwareForProfessional')}
                        <br/>
                        <span>{t('managementSportingEvents')}</span>
                    </span>
                    <span className={clsx(classes.txtWrapper)}>
                        <span className={clsx(classes.red)}> {t('fast')} </span>
                        <span className={clsx(classes.blue)}> {t('comfortable')} </span>
                        <span className={clsx(classes.green)}> {t('safe')} </span>
                    </span>
                    <img src={PatternIcon} alt="pattern" className = {classes.size}/>
                </div>
                <div className={clsx(classes.signForm)}>
                    {children}
                </div>
            </div>
        </div>
        <div className={classes.rainbowBox}></div>
    </div>
  );
};
export default PatternP2;
