import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },
  fullWidth: {
    width: '100%'
  },
  outlined: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 0
    }
  },
  center: {
    alignItems: 'center'
  },
  maxHeightTxtInput: {
    height: '60px'
  },
  label: {
    '& .MuiInputLabel-outlined': {
      lineHeight: 0
    }
  },
  labelCheckbox: {
    '&.MuiFormControlLabel-root': {
      marginRight: 0
    }
  }
}));
