const styles = () => ({
  flex: {
    display: 'flex'
  },

  fullWidth: {
    width: '100%'
  },

  spaceBetween: {
    justifyContent: 'space-between'
  },
  break: {
    wordBreak: 'break-all'
  },
  link: {
    textDecoration: 'underline',
    '&:hover': {
      color: '#005FEE',
      cursor: 'pointer'
    }
  }
});

export default styles;
