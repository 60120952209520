import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  },

  column: {
    flexDirection: 'column'
  },

  marginTop1: {
    marginTop: '1rem'
  },

  assignCategoryFilters: {
    width: 'calc(100% - 5em)'
  },

  inputRightMargin: {
    paddingRight: '20px'
  },

  marginRight0dot5: {
    marginRight: '.5rem'
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  maxHeightAutocomplete: {
    '& .MuiFormControl-root': {
      height: '5em'
    }
  }
}));
