import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import {
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignCenter
} from '@mui/icons-material';

import { TextField, InputAdornment } from '@material-ui/core';

import { ReactComponent as Clear } from 'assets/img/cancel_btn_white_bg&border.svg';

import {
  ID,
  FONT_TYPES_OPTIONS,
  FONT_WEIGHT_OPTIONS,
  ADD_ADDITIONAL,
  ADDITIONAL_FIELD
} from 'helpers/constants';
import { isDesktopView, selectedValue } from 'helpers/selectors';

import Filter from '../Filter/Filter';
import Button from '../Buttons/ActionButtons';

import { useStyles } from './StylesDiploma';

const FONT_SIZE_OPTIONS = Array.from({ length: 41 }, (_, i) => ({
  id: `${i + 10}`
}));

export const TEXT_ALIGN_OPTIONS = [
  { id: 'left', icon: <FormatAlignLeft /> },
  { id: 'center', icon: <FormatAlignCenter /> },
  { id: 'right', icon: <FormatAlignRight /> }
];

const FieldOptions = ({ it, onAddTextField, onFilterSetDiploma }) => {
  const classes = useStyles();

  return (
    <div className={classes.fields} key={it?.itIdx}>
      <span
        className={classes.field}
        style={{
          ...(it?.isActive ? { background: '#1566DB', color: '#fff' } : {})
        }}
        onClick={() => onAddTextField(it)}>
        <span className={classes.ellipsis}>{it?.label}</span>
        {it?.isActive && (
          <Clear
            style={{
              width: '20px',
              height: '20px',
              marginLeft: 'auto'
            }}
          />
        )}
      </span>
      {[
        { options: FONT_TYPES_OPTIONS, item: 'id', arg: 'fontFamily' },
        {
          options: FONT_SIZE_OPTIONS,
          item: 'id',
          arg: 'fontSize',
          defaultVal: 'default_fontSize',
          int: true
        },
        {
          options: FONT_WEIGHT_OPTIONS,
          item: 'label',
          arg: 'fontWeight'
        },
        { options: TEXT_ALIGN_OPTIONS, item: 'label', arg: 'fontAlign' }
      ].map((key, keyIdx) => {
        const currentVal = key.options.find(
          (res) => res.id === it?.params?.[key.arg]
        );

        return (
          <Filter
            classes={{
              root: classes.outlined
            }}
            key={keyIdx}
            value={
              selectedValue(
                key.options,
                ID,
                it?.params?.[key.arg]
                  ? key?.int
                    ? Math.round(it?.params?.[key.arg])
                    : it?.params?.[key.arg]
                  : it?.params?.[key.defaultVal],
                key?.int
              ) || ''
            }
            className={clsx(
              classes.textField,
              key.arg === 'fontAlign' && classes.textFieldFontAlign
            )}
            onChange={(e, val) =>
              onFilterSetDiploma(e, val, {
                ...it,
                arg: key.arg,
                name: 'id'
              })
            }
            options={key?.options}
            item={key?.item}
            variant="outlined"
            specificDetails={{
              ...(key.arg === 'fontAlign'
                ? {
                    renderOptionLabel: (option) => option.icon,
                    InputProps: {
                      startAdornment: currentVal ? currentVal?.icon : null,
                      style: {
                        padding: '10px',
                        display: 'inline',
                        height: '50px'
                      }
                    }
                  }
                : {})
            }}
          />
        );
      })}
    </div>
  );
};

const DraggableItem = ({ onChangeTxt, onMouseDown, val }) => {
  const classes = useStyles();
  const { name, label } = val;
  const { x, y, width, fontSize, fontFamily, fontWeight, fontAlign } =
    val.params;

  return (
    <div
      key={name}
      id={`textField-${name}`}
      className={classes.draggable}
      style={{
        position: 'absolute',
        left: `${x}px`,
        top: `${y}px`,
        width: `${width}px`,
        fontSize: `${fontSize}px`,
        fontFamily: fontFamily,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        pointerEvents: 'auto'
      }}
      onMouseDown={(e) => onMouseDown(e, val)}>
      <TextField
        variant="outlined"
        name="label"
        value={label}
        onChange={(e) => onChangeTxt(e, val)}
        inputProps={{
          style: { textAlign: `${fontAlign}` },
          readOnly: val.name !== ADDITIONAL_FIELD
        }}
        InputProps={{
          style: {
            background: '#fff',
            fontSize: `${fontSize}px`,
            fontFamily: `${fontFamily}`,
            fontWeight: `${fontWeight}`
          },
          ...(val?.name === ADDITIONAL_FIELD && val?.params?.isActive
            ? {
                startAdornment: (
                  <InputAdornment position="start">{`{${
                    val?.additionalIdx + 1
                  }}`}</InputAdornment>
                )
              }
            : {})
        }}
        className={classes.textFieldCanvas}
      />
      <div id={`resizeHandle-${name}`} className={classes.resizeHandle} />
    </div>
  );
};

const SetDiploma = ({
  data,
  drawImageOnCanvas,
  onMouseDown,
  onSaveDiploma,
  onAddTextField,
  onFilterSetDiploma,
  viewportWidth,
  onChangeTxt,
  showModal
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const desktopScreen = isDesktopView(viewportWidth);

  useEffect(() => {
    drawImageOnCanvas();
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.outerCanvasWrapper}>
        <div id="canvasContainer" className={classes.canvasContainer}>
          <canvas
            id="canvas"
            style={{
              border: '1px solid rgba(137, 150, 172, 0.7)',
              borderRadius: '0.3rem'
            }}
          />
          {data?.textFields?.map((val, valIdx) => {
            return val?.params?.map((v, vIdx) => {
              if (v?.isActive) {
                const label =
                  val?.name !== ADDITIONAL_FIELD ? val.label : v.label;
                const draggableProps = {
                  val: {
                    ...val,
                    itIdx: valIdx,
                    additionalIdx: vIdx,
                    label,
                    params: { ...v }
                  },
                  onChangeTxt,
                  onMouseDown
                };

                return (
                  <DraggableItem
                    key={`${valIdx}_${vIdx}`}
                    {...draggableProps}
                  />
                );
              }
              return null;
            });
          })}
        </div>
      </div>
      <div
        style={{ ...(desktopScreen ? { marginLeft: 'auto' } : {}) }}
        className={classes.fieldsWrapper}>
        {data?.textFields?.map((it, itIdx) => {
          return (
            <Fragment key={`${itIdx}`}>
              {it?.name === ADDITIONAL_FIELD && it.params[0]?.isActive && (
                <Button
                  className={clsx(classes.onHover, classes.btnDownloadDoc)}
                  onClick={() =>
                    onAddTextField({ ...it, itIdx, targetID: ADD_ADDITIONAL })
                  }
                  label={t('addRecord', { name: t('additional') })}
                  disabled={it.params?.length >= 6}
                />
              )}
              {it.params?.map((v, vIdx) => {
                return (
                  <Fragment key={`${itIdx}_${vIdx}`}>
                    <FieldOptions
                      it={{
                        ...it,
                        itIdx,
                        additionalIdx: vIdx,
                        isActive: v.isActive,
                        label:
                          it.name !== ADDITIONAL_FIELD
                            ? it.label
                            : [
                                it.name === ADDITIONAL_FIELD && v.isActive
                                  ? `{${vIdx + 1}}`
                                  : '',
                                it.label
                              ].join(' '),
                        params: { ...v }
                      }}
                      {...{ onAddTextField, onFilterSetDiploma }}
                    />
                  </Fragment>
                );
              })}
            </Fragment>
          );
        })}
        <span
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '1rem'
          }}>
          <Button
            isSaveBtn={true}
            className={classes.saveBtn}
            onClick={showModal}
            label={t('toView')}
          />
          <Button
            isSaveBtn={true}
            className={clsx(classes.saveBtn, classes.leftAuto)}
            onClick={onSaveDiploma}
            label={t('saveDiploma')}
          />
        </span>
      </div>
    </div>
  );
};

export default SetDiploma;
