import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ro, ru, de } from 'date-fns/locale';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';

import { TextField } from '@material-ui/core';

import { ReactComponent as DateIcon } from '../../../assets/img/date.svg';

import {
  ID,
  COUNTRY_ID,
  AVATAR,
  NAME,
  GENDER
} from '../../../helpers/constants';
import { selectedValue, countryLabel } from '../../../helpers/selectors';

import UploadImage from '../../UploadImage/UploadImage';
import Filter from '../../Filter/Filter';
import Button from '../../Buttons/ActionButtons';

import { useStyles, bgColor } from './Styles';

const CoachForm = (props) => {
  const {
    countries,
    allRegions,
    fetchRegionBySelectedCountry,
    changeTxt,
    values,
    selectOption,
    changeImage,
    saveCoachData,
    onBlur,
    inputs,
    errors,
    param,
    preventEvent,
    showError,
    changeDate,
    tournamentData
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const currentLang = localStorage.getItem('i18nextLng');

  const filter = (
    label,
    options,
    attribute,
    value,
    key,
    item,
    className,
    error,
    helperText,
    required,
    cb,
    freeSolo,
    additionalItem
  ) => {
    const currentVal = selectedValue(
      [...options, ...(additionalItem ? [additionalItem] : [])],
      key,
      value,
      attribute === COUNTRY_ID
    );

    return (
      <Filter
        className={clsx(
          classes.maxHeightAutocomplete,
          className,
          classes.asterisk,
          preventEvent && classes.noEvent
        )}
        {...{ options }}
        label={<span style={{ color: '#8996AC' }}>{label}</span>}
        value={currentVal || ''}
        required
        onChange={(evt, val) =>
          selectOption(evt, val, param, attribute, key, errors, cb)
        }
        {...{ item }}
        variant="outlined"
        {...{ error }}
        {...{ helperText }}
        {...{ required }}
        id={attribute}
      />
    );
  };

  return (
    <div className={clsx(classes.paper, classes.grid)}>
      <UploadImage
        id={`${param}-file`}
        className={clsx(classes.formAvatar, preventEvent && classes.noEvent)}
        onChange={(evt) => changeImage(evt, param, AVATAR, 'imagePreview')}
        img={values.imagePreview}
      />
      {inputs.map(
        (
          {
            name,
            label,
            shouldEditCoach,
            required,
            num,
            country,
            region,
            save,
            isFilter,
            className,
            isDate,
            gender
          },
          idx
        ) => (
          <Fragment key={idx}>
            {!save && !isFilter && !isDate && (
              <TextField
                className={clsx(
                  classes.maxHeightTxtInput,
                  classes.asterisk,
                  (preventEvent || shouldEditCoach) && classes.noEvent,
                  className
                )}
                value={values[name] || ''}
                id={`coach-${name}`}
                {...{ name }}
                label={
                  <>
                    {num && <sup className={classes.labelNum}>{num}</sup>}
                    {label}
                  </>
                }
                {...{ onBlur }}
                {...{ required }}
                onChange={(evt) =>
                  changeTxt(
                    evt,
                    param,
                    errors,
                    null,
                    null,
                    null,
                    tournamentData
                  )
                }
                error={
                  (name === 'email' ? showError?.[name] : true) &&
                  Boolean(errors[name])
                }
                helperText={
                  (name === 'email' ? showError?.[name] : true) && errors[name]
                }
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#8996AC' },
                  shrink: true
                }}
              />
            )}
            {!preventEvent && save && (
              <Button
                label={t('save')}
                onClick={saveCoachData}
                className={clsx(classes.formSave)}
                isSaveBtn
              />
            )}
            {isFilter && (
              <>
                {region &&
                  filter(
                    t('district'),
                    allRegions,
                    'region',
                    values.region,
                    NAME,
                    NAME,
                    className,
                    Boolean(errors?.region),
                    errors?.region,
                    null,
                    null,
                    true,
                    { name: values.region }
                  )}
                {gender &&
                  filter(
                    t('gender'),
                    GENDER,
                    'gender',
                    values.gender,
                    NAME,
                    NAME,
                    className
                  )}
                {country &&
                  filter(
                    t('country'),
                    countries,
                    COUNTRY_ID,
                    values.country_id,
                    ID,
                    {
                      firstName: 'iso3',
                      lastName: countryLabel(currentLang),
                      parathesis: true
                    },
                    className,
                    Boolean(errors.country_id),
                    errors.country_id,
                    true,
                    (val) =>
                      fetchRegionBySelectedCountry &&
                      fetchRegionBySelectedCountry(val)
                  )}
              </>
            )}
            {isDate && (
              <>
                <MuiPickersUtilsProvider
                  locale={[ru, ro, de].find((it) => it.code === currentLang)}
                  utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    cancelLabel={t('cancel')}
                    okLabel={t('apply')}
                    keyboardIcon={<DateIcon />}
                    className={clsx(
                      classes.maxHeightTxtInput,
                      className,
                      preventEvent && classes.noEvent
                    )}
                    inputVariant="outlined"
                    name="birthday"
                    format="dd/MM/yyyy"
                    id="date-picker-inline"
                    label={t('birthday')}
                    value={values.birthday || null}
                    onChange={changeDate}
                    KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    error={Boolean(errors.birthday)}
                    helperText={errors.birthday}
                    InputLabelProps={{
                      style: { color: '#8996AC' },
                      shrink: true
                    }}
                  />
                </MuiPickersUtilsProvider>
              </>
            )}
          </Fragment>
        )
      )}
    </div>
  );
};

export default CoachForm;
