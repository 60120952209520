import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  grid: {
    display: 'grid'
  },

  gridDropdown: {
    display: 'grid',
    border: '1px solid #8996AC',
    gridRowGap: '0.5rem',
    padding: '0.2rem',
    borderRadius: '0.3rem',
    gridTemplateColumns: '1fr minmax(1em, 2em)'
  },

  gridRecords: {
    display: 'grid',
    '@media (min-width:768px)': {
      gridTemplateColumns: '15em 1fr'
    }
  },

  gridRecordsValue: {
    display: 'grid',
    background: '#fff',
    padding: '0.05rem',
    borderTopRightRadius: '.5rem',
    borderBottomRightRadius: '.5rem'
  },

  exportParticipantList: {
    gridArea: 'export'
  },

  addNewBtn: {
    gridArea: 'addNew'
  },

  submitApplication: {
    gridArea: 'submit'
  },

  centerVertically: {
    alignItems: 'center'
  },

  radiusIc: {
    borderRadius: '.2rem',
    border: '1px solid #98A5BA'
  },

  filter: {
    position: 'absolute',
    top: '2.7rem',
    left: '5rem'
  },

  rotate180: {
    transform: 'rotate(180deg)'
  },

  flex: {
    display: 'flex'
  },

  column: {
    flexDirection: 'column'
  },

  marginLeft1: {
    marginLeft: '1rem'
  },

  marginLeft0dot75: {
    marginLeft: '.75rem'
  },

  placeholderRecord: {
    color: '#8996AC',
    fontSize: '0.8rem',
    whiteSpace: 'nowrap',
    paddingBottom: '0.5rem'
  },

  recordValuesInnerWrapper: {
    lineHeight: '1.5rem',
    padding: '0.45rem'
  },

  isCategoriesColumn: {
    '@media (max-width:1023px)': {
      gridArea: 'categories'
    }
  },

  noEvent: {
    pointerEvents: 'none'
  },

  marginLeft0dot5: {
    marginLeft: '.5rem'
  },

  marginBottom1: {
    marginBottom: '1rem'
  },

  block: {
    display: 'block'
  },

  scrollToTopBtn: {
    display: 'none',
    bottom: '3.5rem',
    right: '30px',
    zIndex: 10,
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 29, 61, 0.5)',
    width: '3em',
    height: '3em',
    position: 'fixed',
    padding: '5px',
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  searchIpnut: {
    gridArea: 'search',
    height: '3em',
    padding: '0.2em 0.5rem',
    color: '#8996AC',
    border: '1px solid #8996AC',
    borderRadius: '.5rem',
    margin: 0,
    fontSize: 'unset',
    boxShadow: 'none'
  },

  female: {
    backgroundColor: '#FBB7C0',
    color: '#262441'
  },

  male: {
    backgroundColor: '#AEBBD0',
    color: '#262441'
  },

  borderMale: {
    border: '1px solid #8996AC'
  },

  borderFemale: {
    border: '1px solid #dea4ac'
  },

  thumbnail: {
    width: '54px',
    height: '72px',
    objectFit: 'cover',
    borderRadius: '0.5rem',
    '@media (max-width:767px)': {
      top: '0.5rem',
      backgroundColor: '#fff',
      position: 'absolute'
    }
  },

  buttonsSize: {
    width: '1.5em',
    height: '1.5em'
  },

  recordName: {
    lineHeight: '0.9rem',
    '@media (min-width:768px)': {
      minHeight: '2.75rem'
    }
  },

  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  numOfCategories: {
    fontSize: '.8rem'
  },

  marginLeft04: {
    marginLeft: '0.4rem'
  },

  avatarAndNameWrapper: {
    background: '#fff',
    padding: '1.2rem 0',
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    '@media (max-width:767px)': {
      position: 'relative'
    }
  },

  marginRight0dot5: {
    marginRight: '.5rem'
  },

  actionIc: {
    '@media (max-width:767px)': {
      position: 'absolute',
      right: '0.5rem',
      top: '0.5rem'
    }
  },

  issue: {
    color: '#ff0000',
    marginRight: '5px'
  },

  avatarAndName: {
    padding: '0.5rem',
    '@media (min-width:768px)': {
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem'
    }
  },

  nameWithoutAvatar: {
    width: 'calc(100% - 4em)',
    '@media (max-width:767px)': {
      marginLeft: '5em'
    }
  },

  marginTop05: {
    margin: '0.25rem 0 0 0'
  },

  categoryListItem: {
    padding: '0 0.2rem'
  },

  marginLeft10: {
    marginLeft: '10px'
  },

  sortLabel: {
    '@media (max-width:767px)': {
      maxWidth: '130px',
      fontSize: '11px'
    }
  },
  marginLeftAuto: {
    marginLeft: 'auto'
  },

  padding75: {
    paddingTop: '0.75rem'
  },

  textColor: {
    color: '#2D63EE',
    border: '1px solid #2D63EE'
  },

  paddingLeft01: {
    '@media (min-width:768px)': {
      paddingLeft: '1rem'
    }
  },

  categoryTxt: {
    wordBreak: 'break-all'
  },

  collpasewrapperInner: {
    '& .MuiCollapse-wrapperInner': { display: 'grid', gap: '0.625rem' }
  },

  height32: {
    height: '32px'
  }
}));

export const useStylesRecords = makeStyles((theme) => ({
  gridRecordsValue: (props) => {
    return {
      '@media (min-width:1024px)': {
        gridTemplateAreas: "'" + props?.map((it) => '.').join(' ') + "'",
        gridTemplateColumns:
          '' +
          props
            ?.map((it) =>
              it.list ? '3fr' : it.key === 'coach_name' ? '2fr' : '1fr'
            )
            .join(' ') +
          ''
      },
      '@media (max-width:1023px) and (min-width:768px)': {
        gridTemplateAreas:
          "'. . . . . . .' '. categories categories categories categories categories categories'",
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
      },
      '@media (max-width:767px)': {
        gridTemplateAreas:
          "'. . . . .' '. . . . .' 'categories categories categories categories categories'"
      }
    };
  }
}));

export const useStylesJudges = makeStyles((theme) => ({
  gridRecordsValue: (props) => ({
    '@media (min-width:1024px)': {
      gridTemplateAreas: "'" + props?.map(() => '.').join(' ') + "'",
      gridTemplateColumns: '' + props?.map(() => '1fr').join(' ') + ''
    },
    '@media (max-width:1023px)': {
      gridTemplateAreas: "'. . . . .'"
    }
  })
}));

export const useStylesActionComponent = makeStyles((theme) => ({
  gridAction: (props) => {
    const hasTabs = props.some((it) => it.val.includes('. .'));
    const hasAddNew = props.some((it) => it.val.includes('addNew'));
    const hasSubmit = props.some((it) => it.val.includes('submit'));
    let templateAreasTablet = '';

    // Tabs, AddNew, and Submit positions are dynamic
    if (hasTabs && hasAddNew && hasSubmit) {
      templateAreasTablet = "'. . addNew' 'search export submit'";
    } else if (!hasTabs && hasAddNew && hasSubmit) {
      templateAreasTablet = "'addNew search' 'export submit'";
    } else if (hasTabs && hasAddNew && !hasSubmit) {
      templateAreasTablet = "'. . addNew' 'search search export'";
    } else if (hasTabs && !hasAddNew && !hasSubmit) {
      templateAreasTablet = "'. .' 'search export'";
    } else {
      templateAreasTablet = "'search export'";
    }

    return {
      display: 'grid',
      gridColumnGap: '1rem',
      '@media (min-width:1024px)': {
        gridTemplateAreas: "'" + props?.map((it) => it.val).join(' ') + "'",
        gridTemplateColumns: '' + props?.map((it) => it.size).join(' ') + ''
      },
      '@media (max-width:1023px) and (min-width:768px)': {
        gridRowGap: '1rem',
        gridTemplateAreas: templateAreasTablet
      },
      '@media (max-width:767px)': {
        gridRowGap: '1rem',
        gridTemplateAreas: [
          "'search search'",
          ...(hasTabs ? ["'. .'"] : []),
          ...(hasAddNew ? ["'addNew addNew'"] : []),
          "'export export'",
          ...(hasSubmit ? ["'submit submit'"] : [])
        ].join('')
      }
    };
  }
}));
