import clsx from 'clsx';
import { CardActions, IconButton, TablePagination } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Next } from '../../assets/img/next.svg';
import { ReactComponent as Back } from '../../assets/img/back.svg';

import { useStyles } from './StylesTablePagination';

const Pagination = (props) => {
  const {
    count,
    onRowsPerPageChange,
    rowsPerPage,
    page,
    onPageChange,
    className
  } = props;

  const { t } = useTranslation();

  const classes = useStyles();

  const TablePaginationActions = (count, page, rowsPerPage, onPageChange) => {
    let totalPages = Math.ceil(count / rowsPerPage);
    let lastPage = Math.ceil(count % rowsPerPage);

    const handleButtonClick = (event, newPage) => {
      onPageChange(event, newPage);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 2);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page);
    };

    const createButton = (page, className, onClick, disabled, icon) => {
      const initialRows = (page - 1) * rowsPerPage + 1;
      const endRows = Math.min(page * rowsPerPage, count);

      return (
        <>
          <IconButton
            className={clsx(className)}
            onClick={onClick}
            disabled={disabled}>
            {!icon ? `${initialRows}-${endRows}` : page}
          </IconButton>
        </>
      );
    };

    const paginationItems = [];
    let startPage = Math.max(0, page - 2);
    let endPage = Math.min(totalPages - 1, page);

    if (startPage > 0) {
      paginationItems.push(
        createButton(
          '1',
          classes.text,
          (event) => handleButtonClick(event, 0),
          false
        ),
        !(page === 3) && <span>...</span>
      );
    }

    for (let p = startPage; p <= endPage; p++) {
      paginationItems.push(
        createButton(
          p + 1,
          [classes.text, classes.white],
          (event) => handleButtonClick(event, p),
          p + 1 === page
        )
      );
    }

    if (endPage < totalPages - 1) {
      paginationItems.push(
        !(page === totalPages - 2) && <span>...</span>,
        createButton(
          totalPages,
          classes.text,
          (event) => handleButtonClick(event, totalPages - 1),
          false
        )
      );
    }

    return (
      <div className={clsx(classes.text, classes.wrapper)}>
        {page !== 1 &&
          createButton(<Back />, null, handleBackButtonClick, false, true)}
        {paginationItems}
        {page !== totalPages &&
          createButton(<Next />, null, handleNextButtonClick, false, true)}
      </div>
    );
  };

  return (
    <CardActions className={classes.wrapper}>
      <div id="pagination" className={classes.tablePagination}>
        <TablePagination
          classes={{
            ...className,
            toolbar: clsx(classes.toolbar, classes.fullWidth),
            root: classes.fullWidth,
            actions: clsx(classes.fullWidth),
            selectRoot: classes.selectRoot,
            input: classes.input
          }}
          component="div"
          count={parseInt(count)}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          labelDisplayedRows={() => null}
          labelRowsPerPage={
            <span className={classes.numberOfEntriesPerPage}>
              {t('numberOfEntriesPerPage')}
              {':'}
            </span>
          }
          ActionsComponent={() => null}
        />
        {TablePaginationActions(count, page, rowsPerPage, onPageChange)}
      </div>
    </CardActions>
  );
};
export default Pagination;
