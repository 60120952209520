import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'grid',
    position: 'fixed',
    width: '100%',
    paddingLeft: 'calc(100vw - 100%)',
    zIndex: 10,
    height: '6em',
    boxShadow: 'none',
    justifyContent: 'center',
    background: '#E9ECF6',
    alignItems: 'center'
  },
  flex: {
    display: 'flex'
  },
  column: {
    flexDirection: 'column'
  },
  alignRight: {
    marginLeft: 'auto'
  },
  marginRight: {
    marginRight: '1em'
  },

  pageTitle: {
    fontSize: '2rem',
    fontWeight: 500,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    display: 'block',
    margin: '0 1em',
    '@media (max-width:900px)': {
      fontSize: '22px',
      margin: '0 .5em'
    }
  },

  headerInnerContent: {
    padding: 0,
    '@media (max-width:767px)': {
      width: 'calc(100vw - 3rem)'
    },
    '@media (min-width:768px)': {
      width: 'calc(100vw - 5.125rem)'
    }
  },

  logo: {
    height: '3em',
    '@media (max-width:900px)': {
      height: '2em',
      width: 'auto'
    },
    width: 'fit-content'
  },

  langBtn: {
    border: '0.001em solid rgba(137,150,172, 0.9)',
    backgroundColor: '#fff',
    borderRadius: '0.3rem',
    width: '2.3em',
    height: '2.3em'
  },

  fullHeight: {
    height: '100%'
  },

  langText: {
    fontSize: '1.2rem',
    padding: '0.2rem',
    color: '#8996AC',
    fontWeight: 700,
    '& .MuiAutocomplete-input': {
      textAlign: 'center',
      cursor: 'pointer',
      textOverflow: 'clip'
    }
  },

  dropdownIcon: {
    '& .MuiAutocomplete-hasPopupIcon, & .MuiAutocomplete-inputRoot': {
      paddingRight: '1em'
    }
  },

  onHover: {
    '&:hover': {
      opacity: 0.6,
      cursor: 'pointer'
    }
  },

  exit: {
    cursor: 'pointer',
    marginLeft: '.65em'
  }
});
