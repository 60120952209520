import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex'
  },
  centerHorizontally: {
    justifyContent: 'center'
  },
  centerVertically: {
    alignItems: 'center'
  },
  left: {
    marginLeft: 'auto'
  },
  itemName: {
    width: '100%',
    alignItems: 'center',
    padding: '15px 0 15px 10px'
  },
  right: {
    marginRight: '10px'
  },
  root: {
    boxShadow: '0 5px 10px 0 rgba(63,63,68,0.15)',
    fontWeight: 'bold',
    transitionDuration: '0.8s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(63,81,181,0.05)'
    }
  },
  text: {
    color: '#6E747C',
    '&:hover': {
      color: '#86BBE0'
    },
    '&:active': {
      color: '#1E5A8C'
    }
  },
  icon: {
    width: '35px',
    height: '35px',
    '&.MuiSvgIcon-root > path': {
      pointerEvents: 'none'
    },
    '@media (max-width:900px)': {
      maxWidth: '30px',
      maxHeight: '30px'
    },
    padding: '5px',
    borderRadius: '4px',
    color: '#8996AC',
    backgroundColor: '#E9ECF6',
    border: '1px solid #8996AC',
    '&:hover': {
      opacity: 0.6
    },
    '&:active': {
      opacity: 0.5
    }
  },
  margin: {
    margin: '0 20px'
  },
  title: {
    wordBreak: 'break-word',
    padding: '0 .2em'
  }
});
