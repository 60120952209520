import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { TextField } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useStyles } from './StylesPassword';

const Password = (props) => {
  const {
    handleChange,
    values,
    errors,
    toggleShow,
    isPasswordVisible,
    onBlur,
    showError
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const data = [
    {
      label: t('currentPassword'),
      name: 'previous_password',
      param: 'password',
      error: Boolean(errors.previous_password),
      helperText: errors.previous_password,
      className: classes.marginTop
    },
    {
      label: t('newPassword'),
      name: 'password',
      param: 'new_password',
      error: showError.password && Boolean(errors.password),
      helperText: showError.password && errors.password
    },
    {
      label: t('newPassword'),
      name: 'confirm_password',
      param: 'confirm_password',
      error: showError.confirm_password && Boolean(errors.confirm_password),
      helperText: showError.confirm_password && errors.confirm_password
    }
  ];

  return (
    <>
      {data.map((it, idx) => (
        <TextField
          key={idx}
          className={clsx(classes.input, classes.flex, it.className)}
          label={it.label}
          name={it.name}
          onChange={handleChange}
          type={!isPasswordVisible[it.param] ? 'password' : 'text'}
          value={values[it.name] || ''}
          variant="outlined"
          {...{ onBlur }}
          error={it.error}
          helperText={it.helperText}
          InputProps={{
            className: classes.endAdornment,
            endAdornment: (
              <div
                id={it.param}
                className={clsx(
                  classes.iconWrapper,
                  isPasswordVisible[it.param] && classes.icon
                )}
                onClick={toggleShow}>
                <VisibilityOutlinedIcon className={classes.noEvent} />
              </div>
            )
          }}
        />
      ))}
    </>
  );
};

export default Password;
