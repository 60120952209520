import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles({
    noPadding: {
        padding: 0,
    },
    error: {
        backgroundColor: '#FFECEB',
        marginBottom: '10px',
    },
    alertMsg: {
        padding: '5px'
    },
})