import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  row: ({ isContentExists, isFilterExists, showButton }) => ({
    display: 'grid',
    alignItems: 'center',
    gridGap: '10px',
    '@media (min-width:1024px)': {
      gridTemplateColumns: 'repeat(8, 1fr)',
      gridTemplateAreas: showButton
        ? `'searchInput searchInput searchInput ${isFilterExists} ${isFilterExists} ${isContentExists} add filterButton'`
        : `'searchInput searchInput searchInput searchInput ${isFilterExists} ${isFilterExists} ${isContentExists} filterButton'`
    },
    '@media (max-width:1023px) and (min-width:768px)': {
      gridTemplateColumns: showButton ? '1fr 1fr 1fr' : '1fr 1fr',
      gridTemplateAreas: showButton
        ? `'searchInput searchInput searchInput'
          '${isFilterExists} ${isFilterExists} ${isFilterExists}'
          '${isContentExists} add filterButton'`
        : `'searchInput searchInput'
          '${isFilterExists} ${isFilterExists}'
          '${isContentExists} filterButton'`
    },
    '@media (max-width:767px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: showButton
        ? `'searchInput searchInput'
           '${isFilterExists} ${isFilterExists}'
           '${isContentExists} add'
           'filterButton filterButton'`
        : `'searchInput searchInput'
           '${isFilterExists} ${isFilterExists}'
           '${isContentExists} filterButton'`
    }
  }),

  marginBottom1: {
    marginBottom: '1em'
  },

  flex: {
    display: 'flex'
  },

  padding: {
    padding: '0 .65em'
  },

  marginLR0dot65: {
    margin: '0 0.65rem'
  },

  paddingRight: {
    paddingRight: '.65em'
  },
  paddingLeft: {
    '@media (min-width:900px)': {
      paddingLeft: '.65em'
    }
  },
  plus: {
    marginRight: '.5em',
    fontSize: '26px'
  },
  filter: {
    width: '100%'
  },
  addButton: {
    width: '10em',
    margin: '.5em 0',
    marginLeft: 'auto'
  },
  verticallyCentered: {
    alignItems: 'center'
  },
  center: {
    textAlign: 'center'
  },

  flexGrow1: {
    flexGrow: 1
  },

  arrowDropUp: {
    transform: 'rotate(180deg)'
  },

  selected: {
    backgroundColor: '#8996AC',
    border: 'none',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#8996AC',
      opacity: 0.7
    }
  },
  outlined: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      width: '10em',
      margin: '.5em 0',
      padding: 0
    }
  }
}));

export default useStyles;
