import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    '@media (max-width:1023px)': {
      flexDirection: 'column'
    }
  },

  padding: {
    padding: '0 .65em'
  },

  marginLR0dot65: {
    margin: '0 0.65rem'
  },

  paddingRight: {
    paddingRight: '.65em'
  },
  paddingLeft: {
    '@media (min-width:900px)': {
      paddingLeft: '.65em'
    }
  },
  plus: {
    marginRight: '.5em',
    fontSize: '26px'
  },
  filter: {
    width: '100%',
    '@media (min-width:1024px)': {
      width: '87.5em'
    }
  },
  addButton: {
    width: '10em',
    margin: '.5em 0',
    marginLeft: 'auto'
  }
}));

export default useStyles;
